import React, { useEffect, useState } from "react";
import { getMyFeedData, getUser, deleteMyPost } from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import Feeds from "../../components/feed/feeds";
import { getProfile } from "../../utils/localStorageServices";
import { getPostFeedsGuestUser } from "../../store/post";
import AlertModal from "../../components/feed/modals/alertModal";
import Skeleton from "react-loading-skeleton";

const IndexFeed = (props) => {
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sort, setSort] = useState("createdAt");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [feedsData, setFeedsData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteitem, setDeleteItem] = useState("");
  const profile = getProfile();

  useEffect(() => {
    setLoadingMain(true);
    window.scrollTo(0, 0);
    fetchFeeds(1, false);
  }, [sort, order]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page, sort, order]);

  //Get post by location code start
  // useEffect(() => {
  //   if (!profile?.profile) {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           setLatitude(position.coords.latitude);
  //           setLongitude(position.coords.longitude);
  //           props.getPostFeedsGuestUser({ lat: position.coords.latitude, long: position.coords.longitude }, (res) => {
  //             if (res.status === 200) {
  //               setFeedsData(res.data.data);
  //             } else {
  //               toast(
  //                 <AlertError
  //                   message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
  //                 />,
  //               );
  //             }
  //           });
  //         },
  //         (error) => {
  //           console.error("Error getting location:", error);
  //           toast(<AlertError message={error} />);
  //         },
  //       );
  //     } else {
  //       <AlertError message={"Geolocation is not supported by this browser."} />;
  //     }
  //   }
  // }, []);
  //Get post by location code end
  const fetchFeeds = (page, append = true) => {
    const payload = {
      page,
      sort,
      order,
    };

    if (page === 1) {
      setLoadingMain(true);
    } else {
      setLoadingMore(true);
    }

    props.getMyFeedData(payload, (res) => {
      if (res && res.status === 200) {
        if (append) {
          setFeedsData((prevData) => [...(prevData || []), ...res.data.data]);
        } else {
          setFeedsData(res.data.data);
        }
        setLoadingMain(false);
        setLoadingMore(false);
      } else {
        setLoadingMain(false);
        setLoadingMore(false);
        toast(<AlertError message={res?.data?.message || "Something Went Wrong"} />);
      }
    });
  };

  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    const isSmallScreen = window.innerWidth <= 768; // Check for mobile/small screens
    const threshold = isSmallScreen ? 800 : 50; // Increase threshold for smaller screens
    if (scrollTop + clientHeight >= scrollHeight - threshold && !loadingMore) {
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        fetchFeeds(newPage);
        return newPage;
      });
    }
  };

  const handleDeletePost = (item) => {
    setLoadingMain(true);
    const id = deleteitem?._id;
    props.deleteMyPost(id, (res) => {
      if (res && res.status === 200) {
        fetchFeeds(1, false);
        toggleDeleteModal();
        setLoadingMain(false);
        toast(<AlertSuccess message={"Post deleted"} />);
      } else {
        setLoadingMain(false);
        toast(<AlertError message={res?.data?.message || "Something Went Wrong"} />);
      }
    });
  };

  const toggleDeleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(!showDeleteModal);
  };

  const feedData = props.getUser?.myFeeds?.data;

  return (
    <>
      <div className="d-flex flex-column">
        <div className="panel-head">
          {/* {loadingMain ? (
            <Skeleton borderRadius={"10px"} height={40} />
          ) : (
            <div className="d-flex justify-content-between">
              <h1>Posts</h1>
              <div className="accor-edit-btn">
                <Link className="edit-green-btn" to={profile?.profile ? "/add-post" : "/login"}>
                  Add Post
                </Link>
              </div>
            </div>
          )} */}
        </div>
        <div className="panel-body flex-grow-1">
          <Feeds myPostData={feedsData} setPostData={setFeedsData} deleteHandler={toggleDeleteModal} />
          {loadingMore && (
            <>
              {[1, 2, 3, 4].map((skeletonItem) => (
                <div class="widget d-flex flex-wrap mt-2" key={skeletonItem}>
                  <div class="widget-icon">
                    <Skeleton borderRadius={"50%"} height={45} width={45} />
                  </div>
                  <div class="widget-content">
                    <div class="widget-text-holder">
                      <div class="w-heading-block position-relative">
                        <div class="w-heading-box d-flex align-items-center flex-wrap ">
                          <div className="w-label-box fw-semibold text-capitalize">
                            <Skeleton borderRadius={"10px"} height={45} width={150} />
                          </div>
                        </div>
                      </div>
                      <div className="w-image-holder mt-35">
                        <div className="d-flex flex-wrap">
                          <div
                            className=" position-relative"
                            style={{
                              width: "100%",
                            }}
                          >
                            <Skeleton borderRadius={"10px"} height={"40vh"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <AlertModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingMain}
        deleteHandler={handleDeletePost}
        message="Are you sure you want to Delete ?"
        type={"Delete"}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMyFeedData: (params, callback) => dispatch(getMyFeedData(params, callback)),
  deleteMyPost: (params, callback) => dispatch(deleteMyPost(params, callback)),
  getPostFeedsGuestUser: (params, callback) => dispatch(getPostFeedsGuestUser(params, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IndexFeed));
