import { useState, useEffect } from "react";

const useCheckForMediumScreen = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth <= 1024
  );

  const handleWindowSizeChange = () => {
    setIsMediumScreen(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return isMediumScreen;
};

export default useCheckForMediumScreen;
