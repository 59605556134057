import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { getMisc, searchAll } from "../../store/misc";
import AlertError from "../../common/alerts/alertError";
import PageInfoSidebar from "../../components/common/pageInfoSidebar/pageInfoSidebar";
import SearchListCard from "../../components/common/listingCard/searchListCard";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import leftArrow from "../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../include/images/pagination-right-arrow-green.svg";
import { sideBarApisListings } from "../../store/sidebarApis";
import noRecord from "../../include/images/nrb-img.svg";

const Search = (props) => {
  // Get the search term from URL params
  const [page, setPage] = useState(1); // Current page
  const [loadingMain, setLoadingMain] = useState(false); // Loading state
  const [detail, setDetail] = useState([]); // API response data
  const [selectedCategory, setSelectedCategory] = useState(""); // Filter category
  const USRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country === "US");
  const otherRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country !== "US");
  const blogCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.blogCategoryList &&
    props?.sideBarApisListings?.blogCategoryList.data;
  // const [blogCategory, setBlogCategory] = useState([]); scienceAndEducationCategory
  const newsCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.newsCategoryList &&
    props?.sideBarApisListings?.newsCategoryList.data;
  const speciesCategories =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.speciesCategory &&
    props?.sideBarApisListings?.speciesCategory.data;
  const scienceAndEducationCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.scienceAndEducationCategory &&
    props?.sideBarApisListings?.scienceAndEducationCategory.data;
  const organizationList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.organizationList &&
    props?.sideBarApisListings?.organizationList.data;
  const environmentalistList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.environmentalist &&
    props?.sideBarApisListings?.environmentalist.data;
  const zooAndParksList =
    props?.sideBarApisListings && props?.sideBarApisListings?.zoo && props?.sideBarApisListings?.zoo.data;
  const wetmarketList =
    props?.sideBarApisListings && props?.sideBarApisListings?.wetMarket && props?.sideBarApisListings?.wetMarket.data;
  const [showMenu, setShowMenu] = useState(false);
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const textTobesearch = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    if (textTobesearch) {
      fetchData(textTobesearch);
    }
  }, [textTobesearch, page]);
  useEffect(() => {
    if (textTobesearch) {
      fetchData(textTobesearch);
    }
  }, [textTobesearch, page]);

  const fetchData = (searchText) => {
    const payload = {
      keyword: searchText,
      page,
    };

    setLoadingMain(true);
    props.searchAll(payload, (res) => {
      if (res && res.status === 200) {
        let tempDetail;

        switch (selectedCategory) {
          case "Species":
            tempDetail = res.data.data?.filter((item) => item.type === "species");
            break;
          case "Region":
            tempDetail = res.data.data?.filter((item) => item.type === "region");
            break;
          case "Organization":
            tempDetail = res.data.data?.filter((item) => item.type === "organization");
            break;
          case "Zoos & Wildlife Reserves":
            tempDetail = res.data.data?.filter((item) => item.type === "zoo wetMarket");
            break;
          case "War on the Environment- Threats":
            tempDetail = res.data.data?.filter((item) => item.type === "wetMarket");
            break;
          default:
            tempDetail = res.data.data;
            break;
        }
        setDetail(tempDetail);
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
        props.toast(<AlertError message={res?.data?.message || "Something Went Wrong"} />);
      }
    });
  };
  console.log("prasad", detail);
  const totalPages = props?.misc?.searchAllData?.totalCount || 0;
  const dataPerPage = props?.misc?.searchAllData?.recordsPerPage || 0;
  const noOfPage = Math.ceil(totalPages / dataPerPage);
  return (
    <PageLayout>
      <div>
        <div class="content-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="ei-category-container d-flex flex-wrap">
                  <div class="ei-category-left">
                    <PageInfoSidebar
                      page={page}
                      setPage={setPage}
                      USRegions={USRegion}
                      otherRegion={otherRegion}
                      blogCategories={blogCategory}
                      newsCategory={newsCategory}
                      setShowMenu={setShowMenu}
                      showMenu={showMenu}
                      speciesCategories={speciesCategories}
                      zooAndParksList={zooAndParksList}
                      organizationList={organizationList}
                      environmentalistList={environmentalistList}
                      wetmarketList={wetmarketList}
                      scienceAndEducationCategory={scienceAndEducationCategory}
                      selectSpeciesCategory={selectSpeciesCategory}
                      setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                      setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                      selectSpeciesCategoryName={selectSpeciesCategoryName}
                    />
                  </div>
                  <div class="ei-category-right">
                    <div className="ei-category-details">
                      <div className="ei-heading">
                        <h4>
                          Showing results for "{textTobesearch}" ({totalPages})
                        </h4>
                      </div>
                      {detail?.length !== 0 && (
                        <ul className="ei-grid-list d-flex flex-wrap">
                          {loadingMain
                            ? Array.from({ length: 52 }, (item, index) => (
                                <SearchListCard
                                  key={index}
                                  img={item?.coverImage?.medium}
                                  name={item?.name}
                                  id={item?._id}
                                  loading={loadingMain}
                                  slug={item?.slug}
                                  type={item?.type}
                                />
                              ))
                            : detail.map((item, idx) => (
                                <SearchListCard
                                  key={idx}
                                  img={item?.coverImage?.medium}
                                  name={item.name}
                                  page="home"
                                  id={item._id}
                                  loading={loadingMain}
                                  slug={item?.slug}
                                  type={item?.type}
                                  handleClick={() => {}}
                                />
                              ))}
                        </ul>
                      )}
                      {detail?.length === 0 && !loadingMain ? (
                        <ul className="blog-list d-flex flex-wrap justify-content-center">
                          <div className="no-records-container d-flex align-items-center justify-content-center">
                            <div className="no-records-box text-center">
                              <div className="nrb-image">
                                <img src={noRecord} alt="" />
                              </div>
                              <h6>No Records Found</h6>
                            </div>
                          </div>
                        </ul>
                      ) : (
                        detail &&
                        noOfPage > 1 && (
                          <div className="pagination-row d-flex justify-content-between">
                            <div className="pagination-arrow">
                              <Link
                                className={`pagination-arrow-box d-flex align-items-center  ${
                                  page === 1 ? "no-pointer" : ""
                                }`}
                                onClick={() => page > 1 && setPage(page - 1)}
                              >
                                <img src={page === 1 ? leftArrow : leftArrowGreen} alt="" />
                                <span className="hover-link">Previous</span>
                              </Link>
                            </div>
                            <div className="pagination-count d-flex flex-wrap">
                              {Array.from({ length: noOfPage }).map((_, i) => {
                                const pageNumber = i + 1;
                                return (
                                  <Link
                                    key={pageNumber}
                                    className={`pagination-count-box ${pageNumber === page ? "active" : ""}`}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    <h6>{pageNumber}</h6>
                                  </Link>
                                );
                              })}
                            </div>
                            <div className="pagination-arrow">
                              <Link
                                className={`pagination-arrow-box d-flex align-items-center ${
                                  page === noOfPage ? "no-pointer" : ""
                                }`}
                                onClick={() => page < noOfPage && setPage(page + 1)}
                              >
                                <span className="hover-link">Next</span>
                                <img src={page === noOfPage ? rightArrow : rightArrowGreen} alt="" />
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchAll: (params, callback) => {
    dispatch(searchAll(params, callback));
  },
});

const mapStateToProps = (state) => ({
  misc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Search));
