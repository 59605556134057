import { useEffect } from "react";
import { useHistory } from "react-router-dom";


//Custom Hook For redirection to Auth page
const Useauthredirect = () => {
    const history = useHistory();

    useEffect(() => {
        const visitedBefore = localStorage.getItem("visitedBefore");
        if (!visitedBefore && history.location.pathname !== "/auth") {
            history.push("/auth");
        }
    }, [history]);

    return null;
};

export default Useauthredirect;
