import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/x-close-24x24.svg";
import close1 from "../../../include/images/close-24x24.svg";
import ReactPlayer from "react-player";
import essayIcon from "../../../include/images/Essay 2.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";

const ViewModal = (props) => {
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const mediaType = props.data?.file.mediaType;
  const imageOriginal = props.data?.file.original;
  return (
    <Modal
      className="modal fade signup-modal font-family-poppins"
      id="viewmodal"
      show={props.show}
      onHide={props.onHide}
      style={{ marginTop: "4%" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: "600px" }}>
          <div className="signup-modal-parent d-flex flex-wrap">
            <div className="signup-modal-box">
              <div className="gmb-media-box">
                <div
                  className="signup-modal-close ms-auto d-block d-lg-none"
                  data-bs-dismiss="modal"
                  onClick={props.onHide}
                >
                  <img src={close1} alt="x close icon" />
                </div>
                {mediaType && (
                  <div className="gmb-media position-relative">
                    {mediaType === "image" && <img className="w-100 h-100" src={imageOriginal} alt="" />}
                    {mediaType === "sketch" && <img className="w-100 h-100" src={imageOriginal} alt="" />}
                    {mediaType === "video" && (
                      <>
                        {imageOriginal && imageOriginal.startsWith("https") ? (
                          <ReactPlayer className="w-auto h-auto" url={imageOriginal} controls />
                        ) : (
                          <video controls className="w-100 h-auto">
                            <source src={process.env.REACT_APP_MEDIA + imageOriginal} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </>
                    )}
                    {mediaType === "essay" && <img className="w-100 h-100" src={imageOriginal} alt="" />}
                    {mediaType === "essay1" && (
                      <div className="doc-background">
                        <img className="w-100 h-100" src={essayIcon} alt="" />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="gmb-content-box  d-flex flex-wrap">
                <div className="gmb-content-left">
                  <p>{props?.data?.file?.description ? stripHtmlTags(props?.data?.file?.description) : ""}</p>
                  <div className="gmb-cl-list d-flex flex-wrap">
                    {props.sectionsArray?.map((sectionName) => {
                      const sectionData = props?.data?.file?.[sectionName?.name];
                      if (Array.isArray(sectionData)) {
                        return sectionData.map((sectionItem, idx) => (
                          <div className="gmb-cl-item text-capitalize" key={idx}>
                            <Link
                              to={{
                                pathname: `${sectionName.path}/${sectionItem.slug}`,
                                state: { id: sectionItem._id },
                              }}
                            >
                              #{capitalizeContentInBrackets(sectionItem.name)}
                            </Link>
                          </div>
                        ));
                      }
                      return null;
                    })}
                  </div>
                </div>
                <div className="gmb-content-right text-capitalize">
                  <h5>{props?.data?.file?.firstName}</h5>
                  <div className="gmb-cr-list d-flex flex-column">
                    {props?.data?.file?.class && (
                      <div className="gmb-cr-item">
                        <div className="gmb-cr-title text-uppercase">Grade </div>
                        <div className="gmb-cr-number fw-medium">{props?.data?.file?.class}</div>
                      </div>
                    )}
                    {props?.data?.file?.school && (
                      <div className="gmb-cr-item">
                        <div className="gmb-cr-title text-uppercase">School</div>
                        <div className="gmb-cr-number fw-medium">{props?.data?.file?.school}</div>
                      </div>
                    )}
                    {props?.data?.file?.teacherName && (
                      <div className="gmb-cr-item">
                        <div className="gmb-cr-title text-uppercase">Teacher</div>
                        <div className="gmb-cr-number fw-medium">{props?.data?.file?.teacherName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-modal-close-box d-none d-lg-block">
              <div className="signup-modal-close ms-auto" data-bs-dismiss="modal" onClick={props.onHide}>
                <img src={close} alt="x close icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewModal;
