import React from "react";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
} from "@react-google-maps/api";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import orgIcon from "../../include/images/Marker/Organisations.svg"
import wetMarketIcon from "../../include/images/Marker/Wetmarket.svg";
import speciesIcon from "../../include/images/Marker/Species.svg";
import zooIcon from "../../include/images/Marker/Zoos.svg";
import envIcon from "../../include/images/Marker/Environmentalist.svg";
const NewMarker = (props) => {
  const [zoom, setZoom] = useState(props.zoom ? 2.32 : 1.62);
  const [center, setCenter] = useState({
    lat: 0.745,
    lng: 0.745,
  });
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);
  const [hoveredCords, setHoveredCords] = useState({ lat: "", lng: "" });
  let center1;
  const mapContainerStyle = {
    marginTop: "21px",
    height: "773px",
    width: "100%",
  };
  const mapData =
    (props.data &&
      props.data?.map((item) => ({
        name: item.name,
        coordinates: item.coordinates,
        section: item.section,
        slug: item.slug ? item.slug : item.id,
        image: item.image
      }))) ||
    [];

  const handleMarkerClick = (item) => {
    const url = `/${item.section}/${item.slug}`
    history.push({
      pathname: url,
      state: { id: item.id },
    });
  };
  const chooseMarker = (section) => {
    if (section == "organizations") {
      return orgIcon;
    } else if (section == "environmentalists") {
      return envIcon;
    } else if (section == "zoos-and-wildlife-reserves") {
      return zooIcon;
    } else if (section == "war-on-the-environment-threats") {
      return wetMarketIcon;
    } else if (section == "endangered-species") {
      return speciesIcon;
    }
  }
  return (

    <GoogleMap
      id="marker-example"
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={props?.singleMap ? center1 : center}
      options={{ fullscreenControl: false }}
    >
      {mapData &&
        mapData.length > 0 &&
        mapData.map((item, i) => (
          <>
            <Marker
              icon={{
                url: chooseMarker(item.section),
              }}
              onMouseOver={() => {
                setHoveredCords({
                  lat: +item?.coordinates?.latitude,
                  lng: +item?.coordinates?.longitude,
                });

                setZoom(1.62)
                setShowInfo(true);
              }}
              onMouseOut={() => {
                setHoveredCords({ lat: "", lng: "" });
                setShowInfo(false);
              }}

              onClick={() => {
                handleMarkerClick(item)
              }}
              position={{
                lat: +item?.coordinates?.latitude,
                lng: +item?.coordinates?.longitude,
              }}
            >
              {showInfo &&
                hoveredCords.lat == item?.coordinates?.latitude && (
                  <InfoWindowF className="infoWindow-container">
                    <div className="infoWindow">
                      <p>{item.name}</p>
                      {item.image && <img src={process.env.REACT_APP_MEDIA + item.image} alt="Marker point" />}
                    </div>
                  </InfoWindowF>
                )}
            </Marker>
          </>
        ))}
    </GoogleMap>
  );
};

export default NewMarker;
