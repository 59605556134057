import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";

//Home Page Sidebar dropdown for Species

const SpeciesDropdown = (props) => {
    const renderSpeciesList = (speciesList) => {
        return (
            <ul className="ei-category-list">
                {speciesList.map((item, i) => (
                    <li className="ei-category-item" key={item._id || i}>
                        <Link
                            to={{
                                pathname: `/endangered-species`,
                                id: item?._id,
                                name: item?.name,
                            }}
                            style={{
                                color: props.selectedCategory === item?._id ? "#47AD1D" : "",
                            }}
                            onClick={() => {
                                props.selectData(item);
                                props.setSelectedCategory(item?._id);
                            }}
                            className="ei-category-box cursor-pointer text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item?.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllSpecies ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessSpecies}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.speciesCategories.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickSpecies}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isSpeciesMenu && (
                        <>
                            {props.speciesCategories &&
                                props.speciesCategories.length > 0 && (
                                    <Accordion.Item
                                        className="tab-pane fade show active accordion-item"
                                        id="two-tab-pane"
                                        eventKey="1"
                                    >
                                        <Accordion.Header
                                            className="accordion-header d-lg-block d-none"
                                            id="headingTwo"
                                        >
                                            Endangered Species
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-body">
                                            {renderSpeciesList(
                                                props.handleViewAllSpecies
                                                    ? props.dividedSpeciesData.flat()
                                                    : props.handleViewLessSpecies
                                                        ? props.poppedSpeciesData
                                                        : props.speciesCategories.slice(0, 15)
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.speciesCategories &&
                        props.speciesCategories.length > 0 && (
                            <Accordion.Item
                                className="tab-pane fade accordion-item"
                                id="two-tab-pane"
                                eventKey="1"
                            >
                                <Accordion.Header
                                    className="accordion-header d-lg-block d-none"
                                    id="headingTwo"
                                >
                                    Endangered Species
                                </Accordion.Header>
                                <Accordion.Body className="accordion-body">
                                    {renderSpeciesList(
                                        props.handleViewAllSpecies
                                            ? props.dividedSpeciesData.flat()
                                            : props.handleViewLessSpecies
                                                ? props.poppedSpeciesData
                                                : props.speciesCategories.slice(0, 15)
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                </>
            )}
        </>
    );
};

export default SpeciesDropdown;
