import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import docImage from "../../../include/images/doc-image1.jpg";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { Amplify, Storage, Auth } from "aws-amplify";
import { getMisc, uploadEducationTab, uploadMedia } from "../../../store/misc";
import { connect } from "react-redux";
import AlertModal from "../../feed/modals/alertModal";
import Editor from "../../../common/editor/editor";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import essayIcon from "../../../include/images/Essay 2.svg";
import imgIcon from "../../../include/images/button-img-1.svg";
import videosIcon from "../../../include/images/button-img-2.svg";
import docIcon from "../../../include/images/doc-sm.svg";
import { getProfile } from "../../../utils/localStorageServices";
import RightFeedSidebar from "../post/addPostSelections";
import { addMyPost, getMyFeedData } from "../../../store/users";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const PostUpload = (props) => {
  const history = useHistory();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [screenWidth, setWidth] = useState(0);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [videoLinks, setVideoLinks] = useState([]);
  const [isModeChanged, setIsModeChanged] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [tempType, setTempType] = useState("");
  const [essayText, setEssayText] = useState("");
  const [error, setError] = useState(false);
  const [classtd, setClass] = useState(null);
  const [tempSelectScienceName, setTempSelectScienceName] = useState([]);
  const [tempSelectRegionName, setTempSelectRegionName] = useState([]);
  const [tempSelectOrgName, setTempSelectOrgName] = useState([]);
  const [tempSelectZooName, setTempSelectZooName] = useState([]);
  const [tempSelectEnvName, setTempSelectEnvName] = useState([]);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);
  const [tempSelectScience, setTempSelectScience] = useState([]);
  const [tempSelectRegion, setTempSelectRegion] = useState([]);
  const [tempSelectOrg, setTempSelectOrg] = useState([]);
  const [tempSelectZoo, setTempSelectZoo] = useState([]);
  const [tempSelectWetMarket, setTempSelectWetMarket] = useState([]);
  const [tempSelectWetMarketName, setTempSelectWetMarketName] = useState([]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [showWetMarket, setShowWetMarket] = useState(false);
  const [showScienceAndEducation, setShowScienceAndEducation] = useState(false);
  const [showOrganizationAndZoo, setShowOrganizationAndZoo] = useState(false);
  const profile = getProfile();
  const [data, setData] = useState({
    isNewImage: false,
    img: [],
    caption: "",
    type: "public",
  });
  useEffect(() => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);
  useEffect(() => {
    if (props.section == "species") {
      setTempSelectSpecies([...tempSelectSpecies, props.id]);
      setTempSelectSpeciesName([
        ...tempSelectSpeciesName,
        {
          name: props?.data?.name,
          scientificName: props?.data?.scientificName,
          slug: props?.data?.slug,
          speciesCategory: props?.data?.speciesCategory,
          _id: props?.data?._id,
        },
      ]);
    } else if (props.section == "region") {
      setTempSelectRegion([...tempSelectRegion, props.id]);
      setTempSelectRegionName([
        ...tempSelectRegionName,
        {
          continent: { _id: props.data?.continent, name: props.data?.countryName },
          country: props.data?.country,
          countryName: props.data?.countryName,
          coverImage: props.data?.coverImage,
          name: props.data?.name,
          slug: props.data?.slug,
          speciesCount: props.data?.speciesCount,
          state: props.data?.state,
          _id: props.data?._id,
        },
      ]);
    } else if (props.section == "organization") {
      setTempSelectOrg([...tempSelectOrg, props.id]);
      setTempSelectOrgName([
        ...tempSelectOrgName,
        {
          geoLocation: props.data?.geoLocation,
          coverImage: props.data?.coverImage,
          name: props.data?.name,
          slug: props.data?.slug,
          species: props.data?.species,
          _id: props.data?._id,
        },
      ]);
    } else if (props.section == "zoo") {
      setTempSelectZoo([...tempSelectZoo, props.id]);
      setTempSelectZooName([
        ...tempSelectZooName,
        {
          geoLocation: props.data?.geoLocation,
          coverImage: props.data?.coverImage,
          name: props.data?.name,
          slug: props.data?.slug,
          species: props.data?.species,
          speciesCount: props.data?.speciesCount,
          _id: props.data?._id,
        },
      ]);
    } else if (props.section == "wetMarket") {
      setTempSelectWetMarket([...tempSelectWetMarket, props.id]);
      setTempSelectWetMarketName([
        ...tempSelectWetMarketName,
        {
          geoLocation: props.data?.geoLocation,
          coverImage: props.data?.coverImage,
          name: props.data?.name,
          slug: props.data?.slug,
          species: props.data?.species,
          speciesCount: props.data?.speciesCount,
          _id: props.data?._id,
        },
      ]);
    }
  }, [props.id]);
  const handleUpload = async () => {
    if (data?.caption == "") {
      toast(<AlertError message={"Please Enter Description for this Post"} />);
      return;
    }

    if (
      tempSelectSpeciesName.length > 0 ||
      tempSelectScienceName.length > 0 ||
      tempSelectRegionName.length > 0 ||
      tempSelectOrgName.length > 0 ||
      tempSelectZooName.length > 0 ||
      tempSelectWetMarketName.length > 0
    ) {
      const promiseContainer = [];

      // Handle Image, Video, and Document Uploads
      if (Array.from(data?.img)?.length) {
        for (let i = 0; i < Array.from(data?.img)?.length; i++) {
          setLoading(true);
          props.setloadingPost(true);
          const file = data?.img[i];
          const fSize = Math.round(file.size / 1048576);
          const fType = file.type;
          const ext = file.name?.split(".").pop().toLowerCase();

          // File size check
          if (fSize > 25) {
            setLoading(false);
            props.setloadingPost(false);
            toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
            return;
          }

          // File type check for images, videos, and documents
          else if (
            ![
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/webp",
              "video/MP4",
              "video/mp4",
              "video/webm",
              "video/mpeg",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(fType)
          ) {
            setLoading(false);
            props.setloadingPost(false);
            toast(
              <AlertError message="Media is not of correct format and cannot be uploaded. Valid Media formats are jpeg, jpg, png, webp, MP4, mpeg, webm, pdf, doc, docx." />,
            );
            return;
          } else {
            const fileName = uuidv4() + "." + ext;

            promiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return setLoading(false), toast(<AlertError message={"Something Went Wrong"} />);
                },
              }),
            );
          }
        }

        const allPromise = Promise.all([...promiseContainer]);
        allPromise.then((result) => {
          const payload = {
            files: result?.map((it) => ({
              media: "public/" + it?.key,
              mediaType: ["mp4", "mpeg", "MP4", "webm"].includes(it?.key?.split(".")[1])
                ? "video"
                : ["pdf", "doc", "docx"].includes(it?.key?.split(".")[1])
                ? "document"
                : "image",
            })),
            name: profile?.profile?.firstName + profile?.profile?.lastName,
            description: data?.caption
              ? data?.caption.replace(/\n/g, "<br>").replace(/^( +)/gm, (spaces) => "&nbsp;".repeat(spaces.length))
              : "",
            type: data?.type ? data?.type : "",
            regions: tempSelectRegionName?.map((it) => it?._id),
            species: tempSelectSpeciesName?.map((it) => it?._id),
            speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
            organizations: tempSelectOrgName?.map((it) => it?._id),
            wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
            scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
            zoos: tempSelectZooName?.map((it) => it?._id),
          };

          props?.addMyPost(payload, (res) => {
            if (res && res.status == 200) {
              props.setShowMedia(false);
              props.setloadingPost(false);
              setData({
                isNewImage: false,
                img: [],
                caption: "",
                type: "public",
              });
              toast(<AlertSuccess message="Post Added successfully" />);
              const params = {
                order: "desc",
                sort: "createdAt",
                id: props.id,
                page: 1,
                section: props.section,
              };
              props.setloadingPost(true);
              props.getEducationMedia(params, (res) => {
                if (res && res.status === 200) {
                  props.setloadingPost(false);
                  props.setMedia(res.data);
                }
              });
            } else {
              setLoading(false);
              props.setloadingPost(false);
              toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
            }
          });
        });
      }

      // Handle case where no media is uploaded
      else {
        const payload = {
          name: profile?.profile?.firstName + profile?.profile?.lastName,
          description: data?.caption
            ? data?.caption.replace(/\n/g, "<br>").replace(/^( +)/gm, (spaces) => "&nbsp;".repeat(spaces.length))
            : "",
          type: data?.type ? data?.type : "",
          regions: tempSelectRegionName?.map((it) => it?._id),
          species: tempSelectSpeciesName?.map((it) => it?._id),
          speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
          organizations: tempSelectOrgName?.map((it) => it?._id),
          wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
          scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
          zoos: tempSelectZooName?.map((it) => it?._id),
          files: [],
        };

        props?.addMyPost(payload, (res) => {
          if (res && res.status == 200) {
            props.setShowMedia(false);
            props.setloadingPost(false);
            setData({
              isNewImage: false,
              img: [],
              caption: "",
              type: "public",
            });
            toast(<AlertSuccess message="Post Added successfully" />);
            const params = {
              order: "desc",
              sort: "createdAt",
              id: props.id,
              page: 1,
              section: props.section,
            };
            props.setloadingPost(true);
            props.getEducationMedia(params, (res) => {
              if (res && res.status === 200) {
                props.setloadingPost(false);
                props.setMedia(res.data);
              }
            });
          } else {
            props.setloadingPost(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      }
    } else {
      toast(<AlertError message={"Please Select any one Preferences"} />);
    }
  };

  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const toggleRegion = () => {
    setShowRegion(!showRegion);
  };
  const toggleWetMarket = () => {
    setShowWetMarket(!showWetMarket);
  };

  const toggleScience = () => {
    setShowScienceAndEducation(!showScienceAndEducation);
  };

  const toggleOrgAndZoo = () => {
    setShowOrganizationAndZoo(!showOrganizationAndZoo);
  };
  const getDocImage = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase(); // Get the file extension

    switch (extension) {
      case "pdf":
        return pdf;
      case "doc":
        return doc;
      case "docx":
        return docx;
      case "txt":
        return txt;
      case "xls":
      case "xlsx":
        return xls;
      case "xlxs":
        return xlxs;
      default:
        return doc; // Default to a generic doc icon if no match
    }
  };
  return (
    <>
      <div className="modal-content d-flex flex-column position-relative">
        <div class="panel-body flex-grow-1">
          <div class="widget d-flex flex-wrap add-green-border">
            <div class="widget-content w-100">
              <h6 className="modal-title text-capitalize education-select-text mt-0" style={{ color: "#227300" }}>
                {props.heading}
              </h6>
              <div class="w-content-upper">
                <div class="w-textarea-block">
                  <textarea
                    class="w-textarea add-post-textarea"
                    placeholder="Write something here"
                    onChange={(e) => {
                      const value = { ...data };
                      value.caption = e?.target?.value;
                      setData((prev) => ({
                        ...prev,
                        caption: value?.caption,
                      }));
                    }}
                    value={data?.caption}
                  ></textarea>
                </div>
              </div>
              <div class="w-content-lower">
                <div class="w-button-holder d-flex flex-wrap align-items-center">
                  <div class="w-button position-relative pl-0">
                    <input
                      type="file"
                      multiple
                      id="inputGroupImg"
                      accept=".jpg,.jpeg,.png,.gif,.webp"
                      onChange={(e) => {
                        const value = { ...data };
                        value.isNewImage = true;
                        value.img = e.target.files;
                        setData((prev) => ({
                          ...prev,
                          img: [...Array.from(prev?.img), ...Array.from(value?.img)],
                          isNewImage: value?.isNewImage,
                        }));
                      }}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="inputGroupImg"
                      class="w-button-box d-flex align-items-center justify-content-center "
                    >
                      <img src={imgIcon} alt="image1" />
                    </label>
                  </div>
                  <div class="w-button position-relative">
                    <input
                      type="file"
                      multiple
                      id="inputGroupVideo"
                      accept="video/MP4, video/mp4, video/webm, video/mpeg"
                      onChange={(e) => {
                        const value = { ...data };
                        value.isNewImage = true;
                        value.img = e.target.files;
                        setData((prev) => ({
                          ...prev,
                          img: [...Array.from(prev?.img), ...Array.from(value?.img)],
                          isNewImage: value?.isNewImage,
                        }));
                      }}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="inputGroupVideo"
                      class="w-button-box d-flex align-items-center justify-content-center"
                    >
                      <img src={videosIcon} alt="video" />
                    </label>
                  </div>
                  <div class="w-button position-relative" style={{ paddingLeft: "5px" }}>
                    <input
                      type="file"
                      multiple
                      id="inputGroupDoc"
                      accept=".pdf, application/pdf, application/x-pdf, application/x-download, application/octet-stream"
                      onChange={(e) => {
                        const value = { ...data };
                        value.isNewImage = true;
                        value.img = e.target.files;
                        setData((prev) => ({
                          ...prev,
                          img: [...Array.from(prev?.img), ...Array.from(value?.img)],
                          isNewImage: value?.isNewImage,
                        }));
                      }}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="inputGroupDoc"
                      class="w-button-box d-flex align-items-center justify-content-center"
                    >
                      <img src={docIcon} alt="doc" />
                    </label>
                  </div>
                </div>

                {data?.img && (
                  <div class="w-image-holder mt-3">
                    <div className="d-flex flex-wrap">
                      {Array.from(data?.img).map((item, i) => {
                        const isVideo =
                          (item?.type && ["video/mp4", "video/mpeg", "video/MP4", "video/webm"].includes(item?.type)) ||
                          (!item?.type && ["mp4", "mpeg", "MP4", "webm"].includes(item?.original?.split(".")[1]));

                        const isImage =
                          (item?.type &&
                            ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"].includes(item?.type)) ||
                          (!item?.type &&
                            ["jpg", "jpeg", "png", "gif", "webp"].includes(item?.original?.split(".")[1]));

                        const isFile =
                          (item?.type &&
                            [
                              "application/pdf",
                              "application/msword",
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              "application/vnd.ms-excel",
                            ].includes(item?.type)) ||
                          (!item?.type &&
                            ["pdf", "doc", "docx", "xls", "xlsx"].includes(item?.original?.split(".")[1]));

                        return isVideo ? (
                          <div class="gallery-item gallery-item-education m-1">
                            <video controls width="150" height="100" className="gallery-image">
                              <source
                                src={
                                  data?.img[i]?.name
                                    ? `${URL.createObjectURL(data?.img[i])}`
                                    : process.env.REACT_APP_MEDIA + data?.img[i].original
                                }
                              />
                            </video>
                            <button
                              type="button"
                              class="trash-button-box gallery-remove-btn"
                              onClick={() => {
                                const a = Array.from(data?.img)?.filter((ir) => ir?.name !== item?.name);
                                setData((prev) => ({ ...prev, img: a }));
                              }}
                            >
                              <img src={trash} alt="w volume" />
                            </button>
                          </div>
                        ) : isImage ? (
                          <div class="gallery-item gallery-item-education m-1">
                            <img
                              className="gallery-image"
                              src={
                                data?.img[i]?.name
                                  ? `${URL.createObjectURL(data?.img[i])}`
                                  : process.env.REACT_APP_MEDIA + data?.img[i]?.original
                              }
                              alt=""
                            />
                            <button
                              type="button"
                              class="trash-button-box gallery-remove-btn"
                              onClick={() => {
                                const a = Array.from(data?.img)?.filter((ir) => ir?.name !== item?.name);
                                setData((prev) => ({ ...prev, img: a }));
                              }}
                            >
                              <img src={trash} alt="w volume" />
                            </button>
                          </div>
                        ) : isFile ? (
                          <div class="gallery-item gallery-item-education m-1">
                            <img className="gallery-image" src={getDocImage(data?.img[i]?.name)} alt="File Preview" />
                            <button
                              type="button"
                              class="trash-button-box gallery-remove-btn"
                              onClick={() => {
                                const a = Array.from(data?.img)?.filter((ir) => ir?.name !== item?.name);
                                setData((prev) => ({ ...prev, img: a }));
                              }}
                            >
                              <img src={trash} alt="w volume" />
                            </button>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}
                <div class="col-text-area" style={{ marginTop: "20px" }}>
                  <div class="col-text-item">
                    <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                      <div class="col-text-label">Categories of Species</div>
                      {tempSelectSpeciesName?.length > 0 ? (
                        <button type="button" class="col-select-btn" onClick={() => toggleSpeciesAndCategories()}>
                          {`${tempSelectSpeciesName?.length} Selected`}
                        </button>
                      ) : (
                        <button type="button" class="col-select-btn" onClick={() => toggleSpeciesAndCategories()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  <div class="col-text-item">
                    <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                      <div class="col-text-label">Science & Education</div>
                      {tempSelectScienceName?.length > 0 ? (
                        <button type="button" class="col-select-btn" onClick={() => toggleScience()}>
                          {`${tempSelectScienceName?.length} Selected`}
                        </button>
                      ) : (
                        <button type="button" class="col-select-btn" onClick={() => toggleScience()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  <div class="col-text-item">
                    <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                      <div class="col-text-label">Organizations, Zoos and Wildlife Reserves</div>
                      {tempSelectOrgName?.length > 0 || tempSelectZooName?.length > 0 ? (
                        <button type="button" class="col-select-btn" onClick={() => toggleOrgAndZoo()}>
                          {`${tempSelectOrgName?.length + tempSelectZooName?.length} Selected`}
                        </button>
                      ) : (
                        <button type="button" class="col-select-btn" onClick={() => toggleOrgAndZoo()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  <div class="col-text-item">
                    <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                      <div class="col-text-label">Regions</div>
                      {tempSelectRegionName?.length > 0 ? (
                        <button type="button" class="col-select-btn" onClick={() => toggleRegion()}>
                          {`${tempSelectRegionName?.length} Selected`}
                        </button>
                      ) : (
                        <button type="button" class="col-select-btn" onClick={() => toggleRegion()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  <div class="col-text-item">
                    <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                      <div class="col-text-label">War and Threats Environment</div>
                      {tempSelectWetMarketName?.length > 0 ? (
                        <button type="button" class="col-select-btn" onClick={() => toggleWetMarket()}>
                          {`${tempSelectWetMarketName?.length} Selected`}
                        </button>
                      ) : (
                        <button type="button" class="col-select-btn" onClick={() => toggleWetMarket()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-18">
                      <button className="btn btn-default w-100 h-40" onClick={handleUpload} disabled={uploading}>
                        {uploading ? "Uploading..." : "Post"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightFeedSidebar
        toggleSpeciesAndCategories={toggleSpeciesAndCategories}
        toggleRegion={toggleRegion}
        toggleWetMarket={toggleWetMarket}
        toggleScience={toggleScience}
        toggleOrgAndZoo={toggleOrgAndZoo}
        showRegion={showRegion}
        setShowRegion={setShowRegion}
        showOrganizationAndZoo={showOrganizationAndZoo}
        setShowOrganizationAndZoo={setShowOrganizationAndZoo}
        showScienceAndEducation={showScienceAndEducation}
        setShowScienceAndEducation={setShowScienceAndEducation}
        showWetMarket={showWetMarket}
        setShowWetMarket={setShowWetMarket}
        showSpeciesAndCategories={showSpeciesAndCategories}
        setshowSpeciesAndCategories={setshowSpeciesAndCategories}
        tempSelectEnvName={tempSelectEnvName}
        tempSelectOrgName={tempSelectOrgName}
        tempSelectRegionName={tempSelectRegionName}
        tempSelectScienceName={tempSelectScienceName}
        tempSelectWetMarketName={tempSelectWetMarketName}
        tempSelectZooName={tempSelectZooName}
        setTempSelectEnvName={setTempSelectEnvName}
        setTempSelectOrgName={setTempSelectOrgName}
        setTempSelectRegionName={setTempSelectRegionName}
        setTempSelectScienceName={setTempSelectScienceName}
        setTempSelectWetMarketName={setTempSelectWetMarketName}
        setTempSelectZooName={setTempSelectZooName}
        tempSelectSpeciesName={tempSelectSpeciesName}
        setTempSelectSpeciesName={setTempSelectSpeciesName}
        tempSelectSpeciesCategory={tempSelectSpeciesCategory}
        setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
        postType={""}
        editItem={""}
        tempSelectSpecies={tempSelectSpecies}
        setTempSelectSpecies={setTempSelectSpecies}
        tempSelectScience={tempSelectScience}
        setTempSelectScience={setTempSelectScience}
        tempSelectRegion={tempSelectRegion}
        setTempSelectRegion={setTempSelectRegion}
        setTempSelectOrg={setTempSelectOrg}
        tempSelectOrg={tempSelectOrg}
        tempSelectZoo={tempSelectZoo}
        setTempSelectZoo={setTempSelectZoo}
        tempSelectWetMarket={tempSelectWetMarket}
        setTempSelectWetMarket={setTempSelectWetMarket}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addMyPost: (data, callback) => dispatch(addMyPost(data, callback)),
  getEducationMedia: (params, callback) => dispatch(getMyFeedData(params, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PostUpload));
