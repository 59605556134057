import React from "react";
import { Link, Linkx } from "react-router-dom/cjs/react-router-dom.min";
import eyeImg from "../../include/images/eye-d.svg";
import eyeOff from "../../include/images/eye-d-off.svg";
import { useState, useEffect } from "react";
import { getMisc, getCountry, getUsState, getUsCitiesDropdown, verifyRecaptcha } from "../../store/misc";
import { connect } from "react-redux";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import { getUser, sendOtpForSignup } from "../../store/users";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Header from "./header";
import close from "../../include/images/close-16x16.svg";
import profileImg from "../../include/images/fav-icon.svg";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage, Auth } from "aws-amplify";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import NextButton from "../../common/form/nextButton";
import { setToken } from "../../utils/localStorageServices";
import ReCAPTCHA from "react-google-recaptcha";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const SignupStepOne = (props) => {
  var ref = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [countryDropdown, setCountryDropdown] = useState(null);
  const [usStateDropdown, setUsStateDropdown] = useState(null);
  const [usCityDropdown, setUsCityDropdown] = useState(null);
  const [selectValues, setSelectValues] = useState(null);
  const [selectLocation, setSelectLocation] = useState(null);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [stateId, setStateId] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [ImgSrc, setImgSrc] = useState();
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  //Loaders
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [key, setKey] = useState(0);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const isMobile = useCheckMobileScreen();
  useEffect(() => {
    if (captchaRef.current) {
      captchaRef.current.execute();
    }
  }, []);

  useEffect(() => {
    if (captchaToken && !isVerifying) {
      setIsVerifying(true);
      const captchaPayload = { recaptchaToken: captchaToken };
      props.verifyRecaptcha(captchaPayload, (res) => {
        if (res && res.status === 200) {
          setIsCaptchaVerified(true);
        } else {
          setIsCaptchaVerified(false);
        }
        setIsVerifying(false);
      });
    }
  }, [captchaToken]);

  const handleChange = (token) => {
    setCaptchaToken(token);
  };
  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    setLoadingCountry(true);
    props.getCountry((res) => {
      if (res && res.status === 200) {
        ref && ref.current && ref.current.complete();
        setLoadingCountry(false);
      } else {
        setLoadingCountry(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    setLoadingState(true);
    props.getUsState((res) => {
      if (res && res.status === 200) {
        ref && ref.current && ref.current.complete();
        setLoadingState(false);
      } else {
        setLoadingState(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  const country = (props.getMisc && props.getMisc.Countries && props.getMisc.Countries.data) || {};

  const state = (props.getMisc && props.getMisc.usState && props.getMisc.usState.data) || {};

  const cities = props.getMisc && props.getMisc.usCitiesItems && props.getMisc.usCitiesItems.data;

  useEffect(() => {
    if (country) {
      const data =
        country && country.length > 0
          ? country.map((item) => ({
              name: item.name,
              code: item.code,
              _id: item._id,
            }))
          : [];
      setCountryDropdown(data);
    }

    if (state) {
      const sData =
        state && state.length > 0
          ? state.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setUsStateDropdown(sData);
    }

    if (cities) {
      const cData =
        cities && cities.length > 0
          ? cities.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setUsCityDropdown(cData);
    }
  }, [country, state, cities]);

  const validateHandler = () => {
    const errors = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!selectValues || !selectValues.email) {
      errors.email = "Email is required";
    } else if (!regex.test(selectValues.email)) {
      errors.email = "Invalid Email";
    }

    if (!selectValues || !selectValues.password) {
      errors.password = "Password is required";
    } else if (selectValues.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    if (!selectValues || !selectValues.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!selectValues || !selectValues.lastName) {
      errors.lastName = "Last Name is required";
    }

    if (!selectLocation || !selectLocation.country) {
      errors.country = "Country is required";
    }
    if (!selectLocation || !selectLocation.city) {
      errors.city = "City is required";
    }
    if (!selectLocation || !selectLocation.state) {
      errors.state = "State is required";
    }
    const isEmpty = Object.values(errors).every((x) => x === null || x === "");

    if (!isEmpty) {
      setError(errors);
      return false;
    } else {
      setError(errors);
      return true;
    }
  };
  const sendVerification = (e) => {
    if (validateHandler()) {
      const value = { ...selectValues };
      const lValue = { ...selectLocation };
      setError({});
      e.preventDefault();
      ref && ref.current && ref.current.continuousStart();
      setLoading(true);

      if (selectValues?.profileImage && selectedImage) {
        const file = selectedImage;
        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();

        if (fSize > 25) {
          toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
          setLoading(false);
          return;
        } else if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
          toast(
            <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpg, jpeg, png, and webp." />,
          );
          setLoading(false);
          return;
        } else {
          const fileName = uuidv4() + "." + ext;
          Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              setLoading(false);
              toast(<AlertError message={"Something Went Wrong"} />);
              return;
            },
          }).then((result) => {
            submitFormWithImage(result.key, value, lValue);
          });
        }
      } else {
        submitFormWithImage(null, value, lValue);
      }
    }
  };

  // Helper function to submit the form with or without an image
  const submitFormWithImage = (imageKey = null, value, lValue) => {
    const payload = {
      firstName: value.firstName ? value.firstName : "",
      lastName: value.lastName ? value.lastName : "",
      email: value.email ? value.email : "",
      country: lValue.country ? lValue.country : "",
      password: value.password ? value.password : "",
      website: value.website ? value.website : "",
      state: lValue.state ? lValue.state : "",
      city: lValue.city ? lValue.city : "",
      organizationName: value.organizationName ? value.organizationName : "",
    };
    if (imageKey) {
      payload.profilePicture = "public/" + imageKey;
    }
    if (value?.address) {
      payload.address =  value.address ? value.address : "";
    }
    if (lValue?.zipCode) {
      payload.zipCode =  lValue.zipCode ? lValue.zipCode : "";
    }
    if (props.type !== "normal" && props.type !== "volunteer") {
      payload.userType = props.type;
    }

    props.sendOtpForSignup(payload, (res) => {
      if (res.status === 200) {
        setToken(null, res?.data?.data?.token);
        props.setSteps({
          step: 2,
          data: {
            ...props.steps.data,
            code: res?.data?.data?.code,
            token: res?.data?.data?.token,
            email: value?.email ? value?.email : "",
            profileImage: imageKey ? selectedImage : "",
          },
        });
        ref && ref.current && ref.current.complete();
        setLoading(false);
      } else {
        ref && ref.current && ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),

    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#F5F5F5",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      height: 42,
      minHeight: 42,
      background: "##f5f5f5",
      borderColor: state.isFocused ? "#e4e4e4" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0px #e4e4e4" : "0",
      border: state.isHovered ? "0" : "0px solid #e4e4e4",

      "&:hover": {
        borderColor: state.isFocused ? "#e4e4e4" : "0",
        boxShadow: state.isFocused ? "0 0 0 0px #e4e4e4" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  let reorderedCountryDropdown = [];

  if (Array.isArray(countryDropdown)) {
    reorderedCountryDropdown = [...countryDropdown];
    const usIndex = reorderedCountryDropdown.findIndex((country) => country.name === "United States");

    if (usIndex > -1) {
      const usOption = reorderedCountryDropdown.splice(usIndex, 1)[0];
      reorderedCountryDropdown.unshift(usOption);
    }
  }
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div class="page-outer-wrapper font-family-poppins grey-bg min-vh-100">
        <Header step="step-1" type={props?.type} />
        <div class="registration-flow-container fw-medium">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="rfc-content-holder mx-auto">
                  <div class="rf-head-row d-flex space-between">
                    <h1>Create an Account</h1>
                    <div class="rf-label-box fw-medium">
                      <Link className="btn btn-outline-green btn-sm" to="/login">
                        Login Instead
                      </Link>
                    </div>
                  </div>
                  <div class="rf-form-row-group">
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class="ff-label-box">Profile Picture</div>
                        <div className="ff-image-box d-flex">
                          {ImgSrc && <img className="upload-profile-pic" src={ImgSrc ? ImgSrc : profileImg} alt="" />}
                          {ImgSrc && (
                            <button
                              type="button"
                              className="ff-close-img"
                              onClick={() => {
                                const data = { ...selectValues };
                                data.profilePicture = {};
                                data.profileImage = false;
                                setSelectValues(data);
                                setSelectedImage(null);
                                setImgSrc(null);
                                setKey((prevKey) => prevKey + 1);
                              }}
                            >
                              <img src={close} alt="close arrow" />
                            </button>
                          )}
                          <button type="button" className={"ff-update-link mt-auto position-relative"}>
                            <input
                              key={key}
                              type="file"
                              className="form-control"
                              id="fileInput"
                              aria-describedby="fileHelp"
                              accept=".jpg, .jpeg, .png, .webp"
                              onChange={(e) => {
                                const data = { ...selectValues };
                                data.profileImage = true;
                                setSelectedImage(e.target.files[0]);
                                setSelectValues(data);
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onloadend = () => {
                                  setImgSrc(reader.result);
                                };
                              }}
                            />
                            {!ImgSrc && "Upload"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div
                          class={
                            error && error.firstName
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type="text"
                            class={error && error.firstName ? "is-invalid form-control" : "form-control "}
                            value={selectValues && selectValues.firstName ? selectValues.firstName : ""}
                            id="firstName"
                            placeholder="First name"
                            autocomplete="given-name"
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.firstName = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="firstName">First name</label>
                        </div>
                        <div class="invalid-feedback">{error && error.firstName ? <p>{error.firstName}</p> : ""}</div>
                      </div>
                      <div class="ff-column">
                        <div
                          class={
                            error && error.lastName
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type="text"
                            class={error && error.lastName ? "is-invalid form-control" : "form-control "}
                            id="lastName"
                            value={selectValues && selectValues.lastName ? selectValues.lastName : ""}
                            placeholder="Last name"
                            autocomplete="family-name"
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.lastName = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="lastName">Last name</label>
                        </div>
                        <div class="invalid-feedback">{error && error.lastName ? <p>{error.lastName}</p> : ""}</div>
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div
                          class={
                            error && error.country
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <Select
                            value={
                              selectLocation?.country
                                ? { name: selectLocation?.countryName, value: selectLocation?.country }
                                : { name: "Select", value: "" }
                            }
                            className={
                              error && error.country
                                ? "invalid-border form-select c-select signup-dropdown-fix"
                                : "form-select c-select signup-dropdown-fix"
                            }
                            id="cuntrySelect"
                            placeholder={false}
                            styles={customStyles1}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                            options={[{ name: "Select", _id: "" }, ...reorderedCountryDropdown]}
                            isLoading={loadingCountry}
                            loadingMessage={() => "Loading ..."}
                            name={countryDropdown}
                            onChange={(event) => {
                              const data = { ...selectLocation };
                              data.countryName = event.name;
                              data.country = event.code;
                              setSelectLocation(data);
                            }}
                          />
                          <label for="floatingSelect">Country</label>
                        </div>
                        <div class="invalid-feedback">{error && error.country ? <p>{error.country}</p> : ""}</div>
                      </div>
                      {(selectLocation && selectLocation?.country && selectLocation?.country === "US") ||
                      (selectLocation &&
                        selectLocation?.countryName &&
                        selectLocation?.countryName === "United States") ? (
                        <div class="ff-column">
                          <div
                            class={
                              error && error.state
                                ? "is-invalid form-floating custom-form-floating"
                                : "form-floating custom-form-floating"
                            }
                          >
                            <Select
                              className={
                                error?.state
                                  ? "invalid-border form-select c-select signup-dropdown-fix"
                                  : "form-select c-select signup-dropdown-fix"
                              }
                              id="stateSelect"
                              placeholder={false}
                              styles={customStyles1}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              options={[{ name: "Select", id: "" }, ...usStateDropdown]}
                              isLoading={loadingState}
                              loadingMessage={() => "Loading ..."}
                              name="stateSelect"
                              value={
                                selectLocation.state
                                  ? usStateDropdown.find((option) => option.id === selectLocation.state)
                                  : { name: "Select", id: "" }
                              }
                              onChange={(event) => {
                                const data = { ...selectLocation };
                                data.state = event.name;
                                data.value = event.id;
                                setStateId(event.id);
                                setSelectLocation(data);
                              }}
                            />
                            <label for="floatingSelect">State</label>
                          </div>
                          <div class="invalid-feedback">{error && error.state ? <p>{error.state}</p> : ""}</div>
                        </div>
                      ) : (
                        <div class="ff-column">
                          <div
                            class={
                              error && error.state
                                ? "is-invalid form-floating custom-form-floating"
                                : "form-floating custom-form-floating"
                            }
                          >
                            <input
                              type="text"
                              class={error && error.state ? "invalid-border form-control " : "form-control "}
                              id="state"
                              placeholder="State"
                              value={selectLocation && selectLocation.state ? selectValues?.state : ""}
                              onChange={(e) => {
                                const data = { ...selectLocation };
                                data.state = e.target.value;
                                setSelectLocation(data);
                              }}
                            />
                            <label for="firstName">State</label>
                          </div>
                          <div class="invalid-feedback">{error && error.state ? <p>{error.state}</p> : ""}</div>
                        </div>
                      )}
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div className={`ff-column ${isMobile?"":"w-50"}`}>
                        <div class={"form-floating custom-form-floating"}>
                          <input
                            type="text"
                            class={"form-control "}
                            id="address1"
                            placeholder="Address line 2"
                            value={selectValues && selectValues?.address ? selectValues?.address : ""}
                            autocomplete="address"
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.address = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="address1">Address</label>
                        </div>
                      </div>
                      <div className={`ff-column ${isMobile?"":"w-25"}`}>
                        <div
                          class={
                            error && error.city
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type="text"
                            class={error && error.city ? "invalid-border form-control" : "form-control "}
                            id="city"
                            placeholder="City"
                            value={selectLocation && selectLocation.city ? selectLocation?.city : ""}
                            onChange={(e) => {
                              const data = { ...selectLocation };
                              data.city = e.target.value;
                              setSelectLocation(data);
                            }}
                          />
                          <label for="cityF">City</label>
                        </div>
                        <div class="invalid-feedback">{error && error.city ? <p>{error.city}</p> : ""}</div>
                      </div>
                      <div className={`ff-column ${isMobile?"":"w-25"}`}>
                        <div class={"form-floating custom-form-floating"}>
                          <input
                            type="number"
                            class={"form-control "}
                            id="zipcode"
                            placeholder="Zip Code"
                            value={selectLocation && selectLocation.zipCode ? selectValues?.zipCode : ""}
                            onChange={(e) => {
                              const data = { ...selectLocation };
                              data.zipCode = e.target.value;
                              setSelectLocation(data);
                            }}
                          />
                          <label for="zipcode">Zip Code</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class="form-floating custom-form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="website"
                            placeholder="Website"
                            value={selectValues && selectValues?.website ? selectValues?.website : ""}
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.website = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="website">Website</label>
                        </div>
                      </div>
                      <div class="ff-column">
                        <div class="form-floating custom-form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="organizationName"
                            placeholder="organizationName"
                            value={selectValues && selectValues?.organizationName ? selectValues?.organizationName : ""}
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.organizationName = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="organizationName">Organization</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div
                          class={
                            error && error.email
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type="email"
                            class={error && error.email ? "is-invalid form-control" : "form-control "}
                            id="emailAddress"
                            placeholder="Email Address"
                            value={selectValues && selectValues?.email ? selectValues?.email : ""}
                            autocomplete="email"
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.email = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="emailAddress">Email Address</label>
                        </div>
                        <div class="invalid-feedback">{error && error.email ? <p>{error.email}</p> : ""}</div>
                      </div>
                      <div class="ff-column">
                        <div
                          class={
                            error && error.password
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type={showPassword ? "text" : "password"}
                            class={error && error.password ? "is-invalid form-control" : "form-control"}
                            id="floatingPassword"
                            placeholder="Password"
                            autocomplete="new-password"
                            value={selectValues && selectValues?.password ? selectValues?.password : ""}
                            onChange={(e) => {
                              const data = { ...selectValues };
                              data.password = e.target.value;
                              setSelectValues(data);
                            }}
                          />
                          <label for="floatingPassword">Password</label>

                          {showPassword ? (
                            <button
                              type="button"
                              class="show-password-btn"
                              data-target="floatingPassword"
                              onClick={toggleShowPassword}
                            >
                              <img src={eyeImg} alt="eye d" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="show-password-btn"
                              data-target="floatingPassword"
                              onClick={toggleShowPassword}
                            >
                              <img src={eyeOff} alt="eye d" />
                            </button>
                          )}
                        </div>
                        <div class="invalid-feedback">{error && error.password ? <p>{error.password}</p> : ""}</div>
                      </div>
                    </div>
                    <div class="rf-form-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={(e) => {
                          sendVerification(e);
                        }}
                        customizeMessgae={"Processing..."}
                        loading={loading}
                        classData={`btn btn-default btn-lg ${!isCaptchaVerified ? "disabled" : ""}`}
                        label="Continue"
                        page={"signup"}
                        disabled={loading ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleChange} size="invisible" ref={captchaRef} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountry: (callback) => dispatch(getCountry(callback)),
  getUsState: (callback) => dispatch(getUsState(callback)),
  getUsCitiesDropdown: (data, callback) => dispatch(getUsCitiesDropdown(data, callback)),
  sendOtpForSignup: (data, callback) => dispatch(sendOtpForSignup(data, callback)),
  verifyRecaptcha: (data, callback) => dispatch(verifyRecaptcha(data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SignupStepOne));
