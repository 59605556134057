import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { deleteWetMarkets, getRegion, getWetMarkets, speciesListings, updateWetMarkets } from "../../store/species";
import { getRegionList, getWetMarketList, sideBarApisListings } from "../../store/sidebarApis";
import noRecord from "../../include/images/nrb-img.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HTMLReactParser from "html-react-parser";
import ShowReports from "../common/showMoreModal/showReports";
import {
  removeTags,
  capitalizeContentInBrackets,
  transformDropdownData,
  extractIds,
} from "../../utils/helperFunctions";
import { useEffect } from "react";
import Pagination from "../common/Pagination/pagination";
import Skeleton from "react-loading-skeleton";
import Threats from "./threats";
import trash from "../../include/images/trash-24x24.svg";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import AddModal from "../common/addModal/addModal";
const EnvironmentThreats = (props) => {
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    wetMarket: "",
  });
  const [showAdd, setShowAdd] = useState(false);
  const wetMarketDropdown = transformDropdownData(props.sideBarApisListings?.wetMarket?.data || []);

  useEffect(() => {
    if (!props.sideBarApisListings?.wetMarket?.data) {
      props.getWetMarketList({}, (res) => {});
    }
  }, []);
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      wetMarket: selectedOptions,
    }));
  };
  const handleImageError = () => {
    setImageError(true);
  };
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };
  const wetMarketDetails = props.speciesListings && props.speciesListings.wetMarket;

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
    }
    return description;
  };
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getWetMarkets(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((wetMarketDetails?.totalCount ?? 0) / (wetMarketDetails?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      wetMarketIds: extractIds(selectedPreference?.wetMarket),
    };
    props.updateWetMarkets(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          wetMarket: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getWetMarkets(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      wetMarketIds: [ids],
    };
    props.deleteWetMarkets(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getWetMarkets(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner species-wetmarket">
            {props.editMode && (
              <div className="d-flex justify-content-between mb-4">
                <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                  Add War on the Environment - Threats
                </Link>
                <div class="d-flex flex-wrap justify-content-end w-75">
                  <Select
                    className="basic-single w-50 z-3 text-capitalize"
                    classNamePrefix="select-search"
                    isMulti
                    placeholder="Select"
                    styles={customStyles1}
                    options={wetMarketDropdown}
                    value={selectedPreference.wetMarket}
                    onChange={handleSelectChange}
                  />
                  <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                    Save
                  </Link>
                </div>
              </div>
            )}
            {wetMarketDetails && wetMarketDetails?.data.length == 0 && (
              <ul class="ei-grid-list d-flex flex-wrap justify-content-center">
                <div class="no-records-container d-flex align-items-center justify-content-center">
                  <div class="no-records-box text-center">
                    <div class="nrb-image">
                      <img src={noRecord} alt="" />
                    </div>
                    <h6>No Records Found</h6>
                  </div>
                </div>
              </ul>
            )}
            {loading ? (
              <ul class="tbc-organization-list d-flex flex-wrap">
                {Array.from({ length: 42 }, (item, index) => (
                  <div class=" org-skeleton">
                    <Skeleton borderRadius={"10px"} height="150px" />
                  </div>
                ))}
              </ul>
            ) : (
              <ul class="tbc-organization-list d-flex flex-wrap">
                {wetMarketDetails &&
                  wetMarketDetails?.data.length > 0 &&
                  wetMarketDetails.data.map((item) => {
                    const descp = item.description ? HTMLReactParser(item.description) : "";
                    return (
                      <li class="organisation-item wet-market-list position-relative">
                        {props.editMode && (
                          <button
                            type="button"
                            class="trash-button-box z-1"
                            onClick={(e) => {
                              handleDelete(item?._id);
                            }}
                          >
                            <img src={trash} alt="w volumne" />
                          </button>
                        )}
                        {
                          <div className="organisation-box d-flex flex-wrap " style={{ minHeight: "170px" }}>
                            <div class="organisation-logo ">
                              <Link
                                to={{
                                  pathname: `/war-on-the-environment-threats/${item?.slug}`,
                                  state: { id: item._id },
                                }}
                              >
                                {!imageError ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                    onError={handleImageError}
                                    className="org-img sm-img-fix"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                    alt=""
                                    className="org-img sm-img-fix"
                                  />
                                )}
                              </Link>
                            </div>
                            <div class="organisation-text" style={{ cursor: "default" }}>
                              <h6>
                                {" "}
                                <Link
                                  className="text-transfrom "
                                  to={{
                                    pathname: `/war-on-the-environment-threats/${item?.slug}`,
                                    state: { id: item?._id },
                                  }}
                                >
                                  {`${capitalizeContentInBrackets(item?.name)}`}
                                </Link>
                              </h6>
                              <div class="organisation-text-footer">
                                <small>Description :</small>
                                <p className="caps-text">
                                  {item?.description
                                    ? item?.description?.length > 30
                                      ? dotDesc(HTMLReactParser(removeTags(item?.description)), 180) + "..."
                                      : HTMLReactParser(removeTags(item?.description))
                                    : "Not yet added"}
                                </p>

                                {item?.reports?.length > 0 ? (
                                  <p
                                    class=" map-switch view-all-btn1 fs-12 org-tab-mob"
                                    style={{
                                      minWidth: "100px",
                                      lineHeight: "12px",
                                      marginLeft: "0px",
                                    }}
                                    onClick={() => {
                                      if (item?.reports?.length > 0) {
                                        setSelectedData(item?.reports);
                                        toggleShowMoreModal();
                                      }
                                    }}
                                  >
                                    View Reports
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      </li>
                    );
                  })}
              </ul>
            )}
            {wetMarketDetails && wetMarketDetails?.totalCount > 42 && (
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />
            )}
          </div>
        </div>
        <ShowReports
          show={showMoreModal}
          onHide={toggleShowMoreModal}
          listData={selectedData}
          heading="Wet Market Reports"
          isNonEditable={true}
        />
        <AddModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          heading={"Add War on the Environment - Threats"}
          page="species"
          type="wetMarket"
          id={props.id}
          alert="war on the environment - threats"
        />
      </div>
      {/* <div
        className="d-flex  justify-content-start "
        style={{ marginBottom: "0px" }}
      >
        <div className="marker-content">
          <img src={marker} />
        </div>
        <p className="bold marker-spacing">Organizations</p>
      </div>
      <App data={mapOrganizationData} mapType="zoo" zoom={"zoom"} /> */}
      <Threats editMode={props?.editMode} editState={props?.editState} setEditState={props?.setEditState} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWetMarkets: (params, callback) => dispatch(getWetMarkets(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  updateWetMarkets: (params, data, callback) => dispatch(updateWetMarkets(params, data, callback)),
  deleteWetMarkets: (params, data, callback) => dispatch(deleteWetMarkets(params, data, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EnvironmentThreats));
