import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
    getSpeciesDetails,
    getMedia,
    speciesListings,
} from "../../../store/species";
import { getRegionList, sideBarApisListings } from "../../../store/sidebarApis";
import BulkUploadModal from "../../feed/modals/bulkUploadModal";
import { getRegionMedia, regionListings } from "../../../store/region";
import { getMediaDetails, organizationListing } from "../../../store/organization";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getWetMarketMedia, wetMarketData } from "../../../store/wetMarket";
import Media from "./media";
import Report from "./report";
import { getProfile } from "../../../utils/localStorageServices";

const UserUpload = (props) => {
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [media, setMedia] = useState(null)
    const [mediaReport, setMediaReport] = useState(true);
    const [showUpload, setShowUpload] = useState(false);
    const topElementRef = useRef(null);
    const profile = getProfile();
    const toggleUploadModal = () => {
        setShowUpload(!showUpload)
    }
    const scrollToTop = () => {
        const topElement = topElementRef.current;
        if (topElement) {
            const topPosition = topElement.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: topPosition - 200, // Adjust the value as needed
                behavior: 'smooth',
            });
        }
    };
    return (
        <>
            <div class="container" ref={topElementRef}>
                <div class="row">
                    <div class="col-md-12">
                        <div class="tbc-body-inner">
                            <div className="media-tab-head d-flex align-items-center justify-content-between">
                                <ul id="mediaTabs" className="nav nav-pills d-flex gap-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <Link className={`nav-link ${mediaReport ? 'activeMedia' : ''}`} type="button" role="tab"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMediaReport(true);
                                                setLoadingDetail(true)
                                            }}
                                        >
                                            Media
                                        </Link>
                                    </li>
                                    <li className={`nav-item`} role="presentation">
                                        <Link className={`nav-link ${!mediaReport ? 'activeMedia' : ''}`} type="button" role="tab"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMediaReport(false);
                                                setLoadingDetail(true)
                                            }}
                                        >
                                            Reports
                                        </Link>
                                    </li>
                                </ul>

                                {props.section != "environmentalist" && < div id="tabBtnContainer">
                                    <div id="tabButton1" className="tabs">
                                        <div className="d-flex justify-content-end">
                                            <button onClick={toggleUploadModal} className="btn btn-default">
                                                {mediaReport ? "Add Media" : "Add Reports"}
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                                {props.section == "environmentalist" && profile?.profile._id == props.id && < div id="tabBtnContainer">
                                    <div id="tabButton1" className="tabs">
                                        <div className="d-flex justify-content-end">
                                            <button onClick={toggleUploadModal} className="btn btn-default">
                                                {mediaReport ? "Add Media" : "Add Reports"}
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div class="media-tab-body" style={{ marginTop: "20px" }}>
                                <div class="tabs">
                                    {mediaReport && (
                                        <Media
                                            media={media}
                                            setMedia={setMedia}
                                            section={props.section}
                                            id={props.id}
                                            loading={loadingDetail}
                                            setLoading={setLoadingDetail}
                                            scrollToTop={scrollToTop} />
                                    )}
                                    {!mediaReport &&
                                        <Report
                                            media={media}
                                            section={props.section}
                                            id={props.id}
                                            loading={loadingDetail}
                                            setLoading={setLoadingDetail}
                                            scrollToTop={scrollToTop}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <BulkUploadModal
                show={showUpload}
                onHide={toggleUploadModal}
                heading="Upload Files"
                id={props.id}
                section={props.section}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getMedia: (params, callback) => dispatch(getMedia(params, callback)),
    getSpeciesDetails: (params, callback) =>
        dispatch(getSpeciesDetails(params, callback)),
    getRegionList: (params, callback) =>
        dispatch(getRegionList(params, callback)),
    getWetMarketMedia: (params, callback) =>
        dispatch(getWetMarketMedia(params, callback)),
    getRegionMedia: (params, callback) =>
        dispatch(getRegionMedia(params, callback)),
    getMediaDetails: (params, callback) =>
        dispatch(getMediaDetails(params, callback)),
});


const mapStateToProps = (state) => ({
    speciesListings: speciesListings(state),
    regionListings: regionListings(state),
    organizationListing: organizationListing(state),
    sideBarApisListings: sideBarApisListings(state),
    wetMarketData: wetMarketData(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserUpload));
