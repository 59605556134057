import React, { useEffect, useRef, useState } from "react";
import ListingCard from "../../common/listingCard/listingCard";
import { deleteSpeciesReg, getSpeciesReg, regionListings, updateSpeciesReg } from "../../../store/region";
import { connect } from "react-redux";
import noRecord from "../../../include/images/nrb-img.svg";
import Pagination from "../../common/Pagination/pagination";
import { getSpeciesList, sideBarApisListings } from "../../../store/sidebarApis";
import { capitalizeContentInBrackets, extractIds } from "../../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import AddModal from "../../common/addModal/addModal";
import amphibiansImg from "../../../include/images/Amphibians.png";
import birdsImg from "../../../include/images/Birds.png";
import fishImg from "../../../include/images/Fish.png";
import insectsImg from "../../../include/images/Insects.png";
import mammalsImg from "../../../include/images/Mammals.png";
import reefCoralsImg from "../../../include/images/Reef Corals.png";
import reptilessImg from "../../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../../include/images/Sharks & Rays.png";
import defaultImage from "../../../include/images/default_image_species.svg";
import crustaceansImg from "../../../include/images/Crustaceans.png";
const EndangeredSpecies = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [selectedPreference, setSelectedPreference] = useState({
    species: "",
  });
  const [showAdd, setShowAdd] = useState(false);
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      species: selectedOptions,
    }));
  };
  const specieDetails = props.regionDetails && props.regionDetails.species;
  const [initialRender, setInitialRender] = useState(true);
  const transformDropdownData = (data, labelKey = "scientificName", valueKey = "_id") => {
    return data
      .filter((item) => item[labelKey] || item.displayCommonName)
      .map((item) => ({
        label: capitalizeContentInBrackets(item.displayCommonName || item[labelKey]),
        value: item[valueKey],
      }));
  };
  const speciesListDropdown = transformDropdownData(props.sideBarApisListings?.speciesList?.data || []);

  useEffect(() => {
    if (!props.sideBarApisListings?.speciesList?.data) {
      props.getSpeciesList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getSpeciesReg(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((specieDetails?.totalCount ?? 0) / (specieDetails?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      speciesIds: extractIds(selectedPreference?.species),
    };
    props.updateSpeciesReg(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          species: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getSpeciesReg(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      speciesIds: [ids],
    };
    props.deleteSpeciesReg(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getSpeciesReg(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            {props.editMode && (
              <div className="d-flex justify-content-between mb-4">
                <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                  Add Species
                </Link>
                <div class="d-flex flex-wrap justify-content-end w-75">
                  <Select
                    className="basic-single w-50 z-2 text-capitalize"
                    classNamePrefix="select-search"
                    isMulti
                    placeholder="Select"
                    styles={customStyles1}
                    options={speciesListDropdown}
                    value={selectedPreference.species}
                    onChange={handleSelectChange}
                  />
                  <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                    Save
                  </Link>
                </div>
              </div>
            )}
            {specieDetails && specieDetails?.data?.length == 0 && (
              <ul class="ei-grid-list d-flex flex-wrap justify-content-center">
                <div class="no-records-container d-flex align-items-center justify-content-center">
                  <div class="no-records-box text-center">
                    <div class="nrb-image">
                      <img src={noRecord} alt="" />
                    </div>
                    <h6>No Records Found</h6>
                  </div>
                </div>
              </ul>
            )}
            <ul class="ei-grid-list region-species-list d-flex flex-wrap ei-grid-5">
              {specieDetails &&
                specieDetails.data?.length > 0 &&
                specieDetails.data.map((item, idx) => (
                  <ListingCard
                    key={idx}
                    name={item?.displayCommonName || item?.scientificName}
                    loading={props.loadingDetail || loading}
                    img={item?.coverImage?.medium}
                    img1={item?.coverImage?.large}
                    pawRed={true}
                    pawRedImage={item?.dangerLevel?.coverImage?.medium}
                    id={item?._id}
                    slug={item?.slug}
                    speciesCategoryName={item?.speciesCategoryName}
                    page={"region-species"}
                    editMode={props.editMode}
                    handleDelete={handleDelete}
                    getDefaultImage={getDefaultImage}
                  />
                ))}
            </ul>
            {specieDetails && specieDetails?.totalCount > 50 && (
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />
            )}
          </div>
        </div>
      </div>
      <AddModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        heading={"Add Species"}
        page="region"
        type="species"
        id={props.id}
        alert="species"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSpeciesReg: (params, callback) => dispatch(getSpeciesReg(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  updateSpeciesReg: (params, data, callback) => dispatch(updateSpeciesReg(params, data, callback)),
  deleteSpeciesReg: (params, data, callback) => dispatch(deleteSpeciesReg(params, data, callback)),
});

const mapStateToProps = (state) => ({
  regionDetails: regionListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EndangeredSpecies));
