import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import closeIcon from "../../include/images/ch-close-icon.svg";
import defualtIcon from "../../include/images/feed-Icon.svg";
import CommentCard from "../../components/feed/modals/commentCard";
import { getRelativeTime } from "../../utils/helperFunctions";
import { getProfile } from "../../utils/localStorageServices";
import ImageHolder from "../../common/customStyles/imageHolder";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import liked from "../../include/images/fill-heart-icon.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { v4 as uuidv4 } from "uuid";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { Amplify, Auth, Storage } from "aws-amplify";
import {
  deleteComments,
  getComments,
  getCurrentPost,
  getPosts,
  likesReceived,
  likeUnlikePost,
  postComments,
  postReceived,
  updateComments,
  viewPost,
} from "../../store/post";
import { feedReceived, getMyFeedData, getMyPostData, getUser } from "../../store/users";
import { connect } from "react-redux";
import CommentInput from "../../components/feed/modals/commentInput";
import PostModal from "../../components/feed/modals/postModal";
import ReportComments from "../../components/feed/modals/reportComments";
import DmPosts from "../../components/feed/modals/dmPosts";
import ReportPost from "../../components/feed/modals/reportPost";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";
import ReactPlayer from "react-player";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const Posts = (props) => {
  const [newCommentData, setNewCommentData] = useState({
    text: "",
    media: null,
    isMediaNew: false,
  });
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const profile = getProfile();
  const location = useLocation();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const selectedItem = props.getPosts && props.getPosts.post && props.getPosts.post.data;
  const history = useHistory();
  const initials = `${selectedItem?.createdBy?.firstName?.charAt(0)}${selectedItem?.createdBy?.lastName?.charAt(
    0,
  )}`.toUpperCase();
  const [showReport, setShowReport] = useState(false);
  const [showDmPost, setShowDmPost] = useState(false);
  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const isMobile = useCheckMobileScreen();
  const mediaPrefix = process.env.REACT_APP_MEDIA;
  const toggleShowComments = () => {
    setNewCommentData({
      text: "",
      media: null,
      isMediaNew: false,
    });
  };
  const toggleDmPosts = () => {
    setShowDmPost(!showDmPost);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.pathname.includes("/posts")) {
      setLoading(true);
      props.getCurrentPost(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status === 200) {
          setLoading(false);
        } else {
          setLoading(false);
          toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [currentUrl]);
  const handlePostComment = () => {
    const id = selectedItem?._id;
    const createdId = selectedItem?.createdBy?._id;
    if (newCommentData.isMediaNew) {
      const promiseContainer = [];
      setLoading(true);
      ref?.current?.continuousStart();
      const file = newCommentData.media;
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name?.split(".").pop();
      if (fSize > 25) {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return;
      } else if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
          "video/MP4",
          "video/mp4",
          "video/webm",
          "video/mpeg",
        ].includes(fType)
      ) {
        ref?.current?.complete();
        setLoading(false);
        toast(
          <AlertError message="Media is not of correct format and hence cannot be uploaded. Valid Media formats are jpeg, jpg, png, webp, MP4 ,mpeg and webm." />,
        );
        return;
      } else {
        const fileName = uuidv4() + "." + ext;
        promiseContainer.push(
          Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              return (
                ref?.current?.complete(), setLoading(false), toast(<AlertError message={"Something Went Wrong"} />)
              );
            },
          }),
        );
      }
      const allPromise = Promise.all([...promiseContainer]);
      allPromise.then((result) => {
        const payload = {
          file: "public/" + result[0]?.key,
          content: newCommentData?.text,
        };
        props.postComments(id, payload, (res) => {
          if (res && res.status == 200) {
            setNewCommentData({
              text: "",
              media: null,
              isMediaNew: false,
            });
            setLoading(false);
            const payload = {
              page: 1,
              sort: "createdAt",
              order: "desc",
            };
            if (location?.pathname !== "/profile") {
              props.getCurrentPost(id, (res) => {
                if (res && res.status === 200) {
                  ref?.current?.complete();
                  toggleShowComments();
                  toast(<AlertSuccess message={"Comment Posted Successfully"} />);
                } else {
                  ref?.current?.complete();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            } else {
              props.getMyPostData(payload, (res) => {
                if (res && res.status === 200) {
                  ref?.current?.complete();
                  toggleShowComments();
                  toast(<AlertSuccess message={"Comment Posted Successfully"} />);
                } else {
                  ref?.current?.complete();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            }
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      });
    } else {
      const payload = {
        content: newCommentData?.text,
      };
      setLoading(true);
      ref?.current?.continuousStart();
      props.postComments(id, payload, (res) => {
        if (res && res.status == 200) {
          setNewCommentData({
            text: "",
            media: null,
            isMediaNew: false,
          });
          props.getCurrentPost(id, (res) => {});
          let updatedMyPostData;
          setLoading(false);
          const payload = {
            page: 1,
            sort: "createdAt",
            order: "desc",
          };
          updatedMyPostData = props.myPostData.map((post) => {
            if (post._id === id) {
              return {
                ...post,
                commentCount: post.commentCount + 1,
              };
            }
            return post;
          });
          if (location?.pathname !== "/profile") {
            props.setPostData(updatedMyPostData);
            props.feedReceived({ data: updatedMyPostData });
            ref?.current?.complete();
            toggleShowComments();
            toast(<AlertSuccess message={"Comment Posted Successfully"} />);
          } else {
            props.postReceived({ data: updatedMyPostData });
            ref?.current?.complete();
            toggleShowComments();
            toast(<AlertSuccess message={"Comment Posted Successfully"} />);
          }
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };
  const likeAndUnlikePost = (type, id) => {
    ref?.current?.continuousStart();
    const data = id;
    props?.likeUnlikePost(type, data, (res) => {
      let updatedMyPostData;
      if (res && res.status == 200) {
        if (type === "like") {
          updatedMyPostData = {
            ...selectedItem,
            isLiked: true,
            likeCount: selectedItem.likeCount + 1,
          };
        } else if (type === "unlike") {
          updatedMyPostData = {
            ...selectedItem,
            isLiked: false,
            likeCount: selectedItem.likeCount > 0 ? selectedItem.likeCount - 1 : 0,
          };
        }
        props.postReceived({ data: updatedMyPostData });
        ref?.current?.complete();
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };
  return (
    <Modal
      className="modal fade full-width-modal all-comment-model font-family-poppins custom-view-modal"
      id="allCommentsModal"
      dialogClassName="modal-dialog modal-fullscreen"
      show={location?.pathname.includes("/posts")}
      onHide={() => history.push("/home")}
    >
      <Helmet>
        <meta property="og:url" content={getShareUrl(selectedItem?.slug)} />
        <meta property="og:type" content="post" />
        <meta property="og:title" content={"Environmental Inclusion"} />
        <meta property="og:description" content={selectedItem?.description} />
        <meta property="og:image" content={process.env.REACT_APP_MEDIA + selectedItem?.files[0]?.original} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Environmental Inclusion"} />
        <meta name="twitter:description" content={selectedItem?.description} />
        <meta name="twitter:image" content={process.env.REACT_APP_MEDIA + selectedItem?.files[0]?.original} />
      </Helmet>
      <div class="fwm-content-box h-100 d-flex flex-wrap">
        <div class="fwm-content-left h-100 d-flex align-items-center justify-content-center" data-bs-dismiss="modal">
          <div class="fwm-close-btn d-flex align-items-center" data-bs-dismiss="modal">
            <img
              className="cursor-pointer"
              src={closeIcon}
              alt=""
              onClick={() => {
                history.push("/home");
              }}
            />
          </div>
          <div class="fit-width-image-wrap w-100 h-100 d-flex align-items-center justify-content-center">
            {selectedItem?.files?.length > 0 && (
              <React.Fragment>
                {selectedItem.files.length > 1 ? (
                  <AwesomeSlider bullets={false}>
                    {selectedItem.files.map((file, index) => (
                      <div key={index} className="d-flex justify-content-center">
                        {["mp4", "mpeg", "MP4", "webm", "video"].includes(file.mediaType) ? (
                          <video controls style={{ width: "90%", height: "100%", objectFit: "contain" }}>
                            <source src={process.env.REACT_APP_MEDIA + file.original} />
                          </video>
                        ) : (
                          <img
                            src={process.env.REACT_APP_MEDIA + file.original}
                            alt=""
                            style={{ width: "100%", height: "100%", objectFit: "contain" }}
                          />
                        )}
                      </div>
                    ))}
                  </AwesomeSlider>
                ) : (
                  <>
                    {selectedItem.files.map((file, index) => (
                      <div key={index} className="fwiw-img">
                         {(file?.mediaType === "image" ||
                          file?.mediaType === "sketch" ||
                          file?.mediaType === "essay") && (
                          <img
                            className="w-100 h-100"
                            src={process.env.REACT_APP_MEDIA + file?.original}
                            alt=""
                          />
                        )}
                        {file?.mediaType === "video" && (
                          <>
                            {file?.original && file?.original.startsWith("https") ? (
                              <ReactPlayer
                                className={`react-player-iframe`}
                                url={file?.original}
                                controls={true}
                                style={{ marginTop: "20%" }}
                              />
                            ) : null}
                            {file?.original && !file?.original.startsWith("https") ? (
                              <video controls className="post-video-tag" style={{ width: "90%", height: "100%", objectFit: "contain" }}>
                                <source src={process.env.REACT_APP_MEDIA + file?.original} />
                              </video>
                            ) : null}
                          </>
                        )}
                        {file?.mediaType === "document" && file?.original && (
                          <a
                            href={process.env.REACT_APP_MEDIA + file?.original}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="h-100 object-fit-contain p-2"
                            style={{marginTop:"20%"}}
                          >
                            <div className="h-100 react-player-iframe">
                              <iframe
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  process.env.REACT_APP_MEDIA + file?.original,
                                )}&embedded=true`}
                                className="pdf-preview-view"
                                title="PDF Preview"
                              />
                            </div>
                          </a>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <div class="fwm-content-right position-relative d-flex flex-column h-100">
          <div class="fwm-head-content overflow-scroll">
            <div class="widget d-flex flex-wrap">
              <div class="widget-icon">
                {selectedItem?.createdByAdmin != null ? (
                  <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                    <img src={defualtIcon} name={selectedItem?.name?.slice(0, 1)} alt={selectedItem?.name} />
                  </div>
                ) : (
                  <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                    {selectedItem?.createdBy?.profilePicture ? (
                      <ImageHolder
                        image={
                          selectedItem?.createdBy ? selectedItem?.createdBy?.profilePicture?.original : profilePicture
                        }
                        name={selectedItem?.name?.slice(0, 1)}
                      />
                    ) : (
                      <div className="profile-picture-name">{initials}</div>
                    )}
                  </div>
                )}
              </div>
              <PostModal
                selectedItem={selectedItem}
                likeAndUnlikePost={likeAndUnlikePost}
                toggleShowReport={toggleShowReport}
                toggleDmPosts={toggleDmPosts}
                location={location}
              />
            </div>
          </div>
          <div class="fwm-body-content flex-grow-1 d-flex flex-column overflow-auto">
            <div class="fwm-header">
              <div class="fwm-title">All Comments</div>
            </div>
            {loading ? (
              <div className="fwm-body flex-grow-1 hide-scrollbar">
                <Skeleton borderRadius={"10px"} height={"80vh"} width={"100%"} />
              </div>
            ) : (
              <>
                {selectedItem?.comments && selectedItem?.comments.length > 0 ? (
                  <div className="fwm-body flex-grow-1 hide-scrollbar mb-3">
                    {selectedItem?.comments?.map((item) => (
                      <CommentCard item={item} id={selectedItem._id} />
                    ))}
                  </div>
                ) : (
                  <div className="fwm-body flex-grow-1 hide-scrollbar d-flex justify-content-center">
                    <div className="no-comments-message" style={isMobile && profile?.profile ?{height:"60px"}:{}}>No Comments</div>
                  </div>
                )}
              </>
            )}
            {profile?.profile && (
              <CommentInput
                selectedItem={selectedItem}
                newCommentData={newCommentData}
                setNewCommentData={setNewCommentData}
                profilePicture={profilePicture}
                handleComment={handlePostComment}
              />
            )}
          </div>
        </div>
      </div>
      <ReportPost show={showReport} onHide={toggleShowReport} selectedItem={selectedItem} page="viewpost" />
      <DmPosts show={showDmPost} onHide={toggleDmPosts} selectedItem={selectedItem} page="viewpost" />
    </Modal>
  );
};
const mapDispatchToProps = (dispatch) => ({
  postComments: (id, data, callback) => dispatch(postComments(id, data, callback)),
  getComments: (id, callback) => dispatch(getComments(id, callback)),
  updateComments: (id1, id2, data, callback) => dispatch(updateComments(id1, id2, data, callback)),
  deleteComments: (id1, id2, callback) => dispatch(deleteComments(id1, id2, callback)),
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)),
  getMyFeedData: (params, callback) => dispatch(getMyFeedData(params, callback)),
  getMyPostData: (params, callback) => dispatch(getMyPostData(params, callback)),
  viewPost: (id, callback) => dispatch(viewPost(id, callback)),
  feedReceived: (payload) => dispatch(feedReceived(payload)),
  postReceived: (payload) => dispatch(postReceived(payload)),
  likesReceived: (payload) => dispatch(likesReceived(payload)),
  getCurrentPost: (id, callback) => dispatch(getCurrentPost(id, callback)),
});

const mapStateToProps = (state) => ({
  getPosts: getPosts(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Posts));
