import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import close1 from "../../../include/images/x-close-24x24.svg";

//Modal to show the tour video of Home page
const TourModal = (props) => {
  return (
    <Modal
      className="modal fade signup-modal font-family-poppins"
      id="viewmodal"
      show={props.show}
      onHide={props.onHide}
      style={{ marginTop: "4%" }}
    >
      <div className="modal-dialog custom-tourmodal modal-dialog-centered position-relative">
        <div className="modal-content custom-tour-modal">
          <iframe
            src="https://www.youtube.com/embed/GvDKvXoU7dA"
            title="Salvex EI"
            width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="position-absolute top-0 end-0" data-bs-dismiss="modal" onClick={props.onHide}>
        <img src={close1} alt="x close icon" />
      </div>
    </Modal>
  );
};
export default TourModal;
