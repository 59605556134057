import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import close from "../../../include/images/close.svg";
import download from "../../../include/images/download.svg";
import { dotDesc, removeTags } from "../../../utils/helperFunctions";

const ShowMoreModal = (props) => {
  const [value, setValue] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [textSearched, setTextSearched] = useState("");
  useEffect(() => {
    if (props.show) {
      if (props.selectedData) {
        const data = [...props.selectedData];
        setValue(data);
      }

      setFilteredValues(props.listData);
    }
  }, [props.show]);
  const [screenWidth, setWidth] = useState(0);
  useEffect(() => {
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);

  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="modal-header pb-0 modal-head view-all-header">
          <h5 class="modal-title text-capitalize">{props.heading}</h5>
          <div>
            <button
              type="button"
              class="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
              onClick={() => props.onHide()}
            >
              <img src={close} alt="" className="modal-close-btn" />
            </button>
          </div>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ paddingLeft: "30px" }}>
          <div class="two-row-column d-flex flex-wrap">
            <div class="tr-column  position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <table class="table table-borderless align-middle table-left-align">
                  <tbody>
                    <tr className={"d-flex flex-wrap flex-column"}>
                      {filteredValues &&
                        filteredValues.length > 0 &&
                        filteredValues.map((item, idx) => {
                          const descp = item.description
                            ? HTMLReactParser(item.description)
                            : "";
                          return (
                            <div className="w-100 d-flex justify-content-start reports-flex-width">
                              <div
                                className="img-container"
                                style={{ minWidth: "60px" }}
                              >
                                <a
                                  href={
                                    process.env.REACT_APP_MEDIA +
                                    item?.file?.original
                                  }
                                  target="_blank"
                                  download
                                >
                                  <img src={download} />
                                </a>
                              </div>
                              <div className="report-description ml-20">
                                {HTMLReactParser(removeTags(item?.description))}
                              </div>
                            </div>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row row-form-button justify-content-end">
            <div class="col-sm-6 ps-sm-0">
              {!props.isNonEditable && (
                <button
                  type="submit"
                  class="btn btn-default btn-block"
                  onClick={() => {
                    const data = [...value];
                    props.setSelectedData(data);
                    props.onHide();
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default React.memo(ShowMoreModal);
