import React from "react";
import { Modal } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";


//Gallery View Component for home page post
const HomeImageGallery = ({ show, handleClose, items, startIndex, title }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      id="photosModal"
      className="modal fade "
      dialogClassName="modal-dialog custom-modal-dialog"
      contentClassName="mis-content"
      backdrop={true}
      centered
    >
      <div className="modal-content-header position-relative mis-head">
        <button
          type="button"
          className="btn-close btn-close-gallery"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <Modal.Body bsPrefix="modal-content-body mis-body">
        <ImageGallery
          lazyLoad={true}
          items={items?.map((item) => ({
            renderItem: (props) => (
              <div className="image-gallery-image home-page-image">
                {item.file.mediaType === "image" ? (
                  <img src={item.file.original} alt="gallery" style={{ width: "100%", height: "100%" }} />
                ) : item.file.mediaType === "sketch" ? (
                  <img src={item.file.original} alt="gallery" style={{ width: "100%", height: "100%" }} />
                ) : item.file.mediaType === "essay" ? (
                  <img src={item.file.original} alt="gallery" style={{ width: "100%", height: "100%" }} />
                ) : item.file.mediaType === "video" && item.file.original.startsWith("https") ? (
                  <ReactPlayer url={item.file.original} controls width="100%" height="100%" />
                ) : item.file.mediaType === "video" ? (
                  <video controls style={{ width: "100%", height: "100%" }}>
                    <source src={process.env.REACT_APP_MEDIA + item.file.original} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </div>
            ),
          }))}
          showPlayButton={false}
          startIndex={startIndex}
          showNav={true}
          showIndex={false}
        />
      </Modal.Body>
    </Modal>
  );
};

export default HomeImageGallery;
