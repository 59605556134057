import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import trash from "../../../include/images/trash-24x24.svg";
const ListingCardReg = (props) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };
    return (
        <li class="ei-grid-item position-relative">
            {props.editMode &&
                <button
                    type="button"
                    class="trash-button-box z-1"
                    onClick={(e) => {
                        props.handleDelete(props.id)
                    }}
                >
                    <img src={trash} alt="w volumne" />
                </button>
            }
            {props.loading ? (
                <div className="ei-grid-box">
                    <Skeleton borderRadius={"10px"} height={"250px"} />
                </div>
            ) : (
                <Link
                    to={
                        {
                            pathname:
                                props.country === "US"
                                    ? `/regions/united-states/${props.slug}`
                                    : `/regions/${props.slug}`,
                            state: { id: props.id },
                        }

                    }
                    className="ei-grid-box"
                >
                    <div class="ei-grid-box-image list-card-image">
                        {!imageError ? <img src={process.env.REACT_APP_MEDIA + props.img} onError={handleImageError}
                            alt="" /> : <img src={process.env.REACT_APP_MEDIA + props.img1} alt="" />}
                    </div>
                    <div
                        class={
                            "ei-grid-box-overlay"
                        }
                    >
                        <p className="text-transfrom ">
                            {props.item &&
                                props.item?.countryName === "United States"
                                ? `${props.item?.state}, ${props.item?.countryName}`
                                : props.item?.countryName}
                        </p>
                        {props.pawRed && props.pawRedImage && (
                            <img
                                src={process.env.REACT_APP_MEDIA + props.pawRedImage}
                                class="ei-grid-box-icon"
                                alt=""
                            />
                        )}
                    </div>
                </Link>
            )}
        </li>
    );
};

export default ListingCardReg;
