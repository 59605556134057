import React from 'react'
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions"
//Home Page Sidebar dropdown for Regions

const RegionsDropdown = (props) => {
    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isRegionMenu && (
                        <>
                            {props.regions && props.regions.length > 0 && (
                                <Accordion.Item
                                    className="tab-pane fade show active accordion-item"
                                    id="two-tab-pane"
                                    eventKey="2"
                                >
                                    <Accordion.Header
                                        className="accordion-header d-lg-block d-none"
                                        id="headingTwo"
                                    >
                                        Regions
                                    </Accordion.Header>
                                    {Object.entries(
                                        props.regions?.reduce((acc, region) => {
                                            if (!region.continent) {
                                                acc['other'] = acc['other'] || [];
                                                acc['other'].push(region);
                                            } else {
                                                acc[region.continent.name] = acc[region.continent.name] || [];
                                                acc[region.continent.name].push(region);
                                            }
                                            return acc;
                                        }, {})
                                    ).sort(([a], [b]) => {
                                        if (a === 'United States') return -1;
                                        if (b === 'United States') return 1;
                                        return a.localeCompare(b);
                                    }).map(([continentName, continentRegions]) => (
                                        <Accordion.Body className="accordion-body">
                                            {props.isRegionMenu && (
                                                <span className="accor-font-us">{continentName === 'other' ? 'Other Regions' : continentName}</span>
                                            )}
                                            <ul class="ei-category-list">
                                                <Accordion>
                                                    <Accordion.Item
                                                        eventKey="999"
                                                        className="ei-sideregion"
                                                    >
                                                        <Accordion.Header
                                                            bsPrefix="accor-font-us"
                                                            className="accordion-header d-lg-block d-none "
                                                        >
                                                            <span className="accor-font-us">
                                                                United States
                                                            </span>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="accordion-body padding-us-states">
                                                            {continentRegions.map((item, i) => (
                                                                <li class="ei-category-item">
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/regions/${item.countryName.toLowerCase() === 'united states' ? `united-states/${item.slug}` : `${item.slug}`}`,
                                                                            state: { id: item._id },
                                                                        }}
                                                                        class="ei-category-box text-capitalize"
                                                                    >
                                                                        {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                            <li class="ei-category-item"></li>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </ul>
                                        </Accordion.Body>
                                    ))}
                                </Accordion.Item>
                            )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.regions && props.regions.length > 0 && (
                        <Accordion.Item
                            className="tab-pane fade accordion-item"
                            id="two-tab-pane"
                            eventKey="2"
                        >
                            <Accordion.Header
                                className="accordion-header d-lg-block d-none"
                                id="headingTwo"
                            >
                                Regions
                            </Accordion.Header>

                            <Accordion.Body className="accordion-body">
                                <ul class="ei-category-list">
                                    <Accordion>
                                        {Object.entries(
                                            props.regions?.reduce((acc, region) => {
                                                if (!region.continent) {
                                                    acc['other'] = acc['other'] || [];
                                                    acc['other'].push(region);
                                                } else {
                                                    acc[region.continent.name] = acc[region.continent.name] || [];
                                                    acc[region.continent.name].push(region);
                                                }
                                                return acc;
                                            }, {})
                                        ).sort(([a], [b]) => {
                                            if (a === 'United States') return -1;
                                            if (b === 'United States') return 1;
                                            return a.localeCompare(b);
                                        }).map(([continentName, continentRegions]) => (
                                            <Accordion.Item
                                                key={continentName}
                                                className="ei-sideregion"
                                                id={`${continentName}-tab-pane`}
                                                eventKey={continentName}
                                            >
                                                <Accordion.Header
                                                    bsPrefix="accor-font-us"
                                                    className="accordion-header d-lg-block d-none "
                                                    id={`${continentName}-heading`}
                                                >
                                                    <span className="accor-font-us">
                                                        {continentName === 'other' ? 'Other Regions' : continentName}
                                                    </span>
                                                </Accordion.Header>

                                                <Accordion.Body className="accordion-body padding-us-states">
                                                    {/* Render regions for this continent */}
                                                    {continentRegions.map((region) => (
                                                        <li className="ei-category-item" key={region._id}>
                                                            <Link
                                                                to={{
                                                                    pathname: `/regions/${region.countryName.toLowerCase() === 'united states' ? `united-states/${region.slug}` : `${region.slug}`}`,
                                                                    state: { id: region._id },
                                                                }}
                                                                className="ei-category-box text-capitalize"
                                                            >
                                                                {`${capitalizeContentInBrackets(region.name)} (${region.speciesCount})`}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </>
            )}
        </>
    )
}
export default RegionsDropdown;
