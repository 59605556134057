import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { getUser, resendOtpAndSignup, verifyOtpAndSignup, volunteerPreference } from "../../store/users";
import { toast } from "react-toastify";
import Header from "./header";
import {
  getRedirectUrl,
  removeKey,
  setToken,
  setProfile,
  getToken,
  getVolunteerPreference,
  removeKeySession,
} from "../../utils/localStorageServices";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import NextButton from "../../common/form/nextButton";
const SignupStepTwo = (props) => {
  var ref = useRef();
  const history = useHistory();
  const textBoxOne = useRef(null);
  const textBoxTwo = useRef(null);
  const textBoxThree = useRef(null);
  const textBoxFour = useRef(null);
  const textBoxFive = useRef(null);
  const textBoxSix = useRef(null);

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({
    first: null,
    second: null,
    third: null,
    fourth: null,
    fifth: null,
    six: null,
  });
  const [error, setError] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => setTimeLeft(30), []);

  const resendOtp = () => {
    const token = getToken("auth");
    if (token) {
      ref && ref.current && ref.current.continuousStart();
      setLoading(true);
      const token = getToken("auth");

      props.resendOtpAndSignup({ token }, (res) => {
        if (res && res?.status == 200) {
          const newData = { ...props?.steps?.data };
          newData.token = res?.data?.data?.token;
          props.setSteps({ step: 2, data: newData });
          setTimeLeft(30);
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertSuccess message={"Your 6 digit code has been resent "} />);
        } else {
          ref && ref.current && ref.current.complete();
          setLoading(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      props.setSteps({
        step: 1,
        data: {},
      });
    }
  };
  const extractIds = (selectedOptions) => {
    return selectedOptions.map((option) => option.value);
  };
  const verifyCode = (e) => {
    if (code?.first && code?.second && code?.third && code?.fourth && code?.fifth && code?.six) {
      setError({});
      e.preventDefault();
      ref && ref.current && ref.current.continuousStart();
      setLoading(true);
      const payload = {
        code: `${code?.first}${code?.second}${code?.third}${code?.fourth}${code?.fifth}${code?.six}`,
        token: props?.steps && props?.steps?.data.token ? props?.steps?.data.token : "",
      };

      props.verifyOtpAndSignup(payload, (res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const { accessToken, refreshToken, profile } = data;
          setToken(refreshToken, accessToken);
          if (props?.type == "volunteer") {
            props.setSteps({
              step: 10,
              data: {
                id: profile?._id,
              },
            });
          } else {
            ref && ref.current && ref.current.complete();
            setLoading(false);
            localStorage.removeItem("data");
            localStorage.removeItem("x-auth-token");
            localStorage.removeItem("x-refresh-token");
            localStorage.removeItem("signupStep");
            history.push("/greeting");
          }
        } else {
          ref && ref.current && ref.current.complete();
          setLoading(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      const errors = { ...error };
      errors.message = "Please enter 6 digit verification code to continue";
      setError(errors);
    }
  };

  const codeChangeHandler = (event) => {
    const element = event.target;
    const nextSibling = event?.target?.value ? element.nextElementSibling : element.previousElementSibling;
    nextSibling ? nextSibling.focus() : element.blur();
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const pastedValues = pastedText.split("").slice(0, 6);

    const data = {
      first: pastedValues[0] || "",
      second: pastedValues[1] || "",
      third: pastedValues[2] || "",
      fourth: pastedValues[3] || "",
      fifth: pastedValues[4] || "",
      six: pastedValues[5] || "",
    };
    setCode(data);
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div class="page-outer-wrapper font-family-poppins grey-bg min-vh-100 min-vh-90">
        <Header data={props?.steps?.data} step="step-2"   type={props?.type}/>
        <div class="registration-flow-container fw-medium">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="rfc-content-holder mx-auto">
                  <div class="rf-head-row">
                    <h1>Verify Account</h1>
                    <div class="rf-label-box email-label-box">
                      Enter Verification Code sent to{" "}
                      <a class="fw-medium">
                        {props.steps && props?.steps && props?.steps?.data && props?.steps?.data?.email}
                      </a>
                      . Your verification code is valid for next 10 Minutes.
                    </div>
                  </div>
                  <form class="rf-form-row-group">
                    <div class="otp-input-fields">
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.first}
                        ref={textBoxOne}
                        onChange={(e) => {
                          const data = { ...code };
                          data.first = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.second}
                        ref={textBoxTwo}
                        onChange={(e) => {
                          const data = { ...code };
                          data.second = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.third}
                        ref={textBoxThree}
                        onChange={(e) => {
                          const data = { ...code };
                          data.third = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.fourth}
                        ref={textBoxFour}
                        onChange={(e) => {
                          const data = { ...code };
                          data.fourth = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.fifth}
                        ref={textBoxFive}
                        onChange={(e) => {
                          const data = { ...code };
                          data.fifth = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                      <input
                        type="number"
                        class="otp-box"
                        required
                        maxLength={1}
                        min="0"
                        max="9"
                        value={code?.six}
                        ref={textBoxSix}
                        onChange={(e) => {
                          const data = { ...code };
                          data.six = e.target.value.slice(0, 1);
                          setCode(data);
                          codeChangeHandler(e);
                        }}
                        onPaste={(e) => handlePaste(e)}
                      />
                    </div>
                    {error.message ? (
                      <p style={{ color: "red", fontSize: "14px", paddingTop: "10px" }}>{error.message}</p>
                    ) : (
                      ""
                    )}
                    {timeLeft && (
                      <p className={timeLeft ? "otp-resend " : ""}>You can resend code in {timeLeft} seconds</p>
                    )}

                    <div class="rf-form-btn d-flex align-items-center space-between resend-container">
                      <p
                        className={`link-text cursor-pointer mb-0 ${!timeLeft ? "" : "disable-resend-otp"}`}
                        onClick={() => {
                          if (timeLeft) return;
                          resendOtp();
                        }}
                      >
                        Resend Code
                      </p>
                      <NextButton
                        handleSubmit={(e) => {
                          verifyCode(e);
                        }}
                        loading={loading}
                        classData={"btn btn-default btn-lg"}
                        label="Continue"
                        disabled={loading}
                        page={"signup"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  verifyOtpAndSignup: (data, callback) => dispatch(verifyOtpAndSignup(data, callback)),
  resendOtpAndSignup: (data, callback) => dispatch(resendOtpAndSignup(data, callback)),
  volunteerPreference: (params, data, callback) => dispatch(volunteerPreference(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SignupStepTwo));
