import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getEnvironmentalistDetails,
  environmentalistListings,
  getOrganizationEnv,
  updateOrganizationEnv,
  deleteOrganizationEnv,
} from "../../store/environmentalist";
import App from "../../common/maps/map";
import {
  capitalizeFirstLetter,
  capitalizeContentInBrackets,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";

import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ShowMoreModal from "../common/showMoreModal/showMoreModal";
import marker from "../../include/images/marker.svg";
import Pagination from "../common/Pagination/pagination";
import Select from "react-select";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import { sideBarApisListings } from "../../store/sidebarApis";
import { updateOrganizationDetails } from "../../store/organization";
import AddModal from "../common/addModal/addModal";
import trash from "../../include/images/trash-24x24.svg";
import defaultOrgImg from "../../include/images/organisation.png"
const Organizations = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [mapOrganizationData, setOrganizationData] = useState([]);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const organizationDropdown = transformDropdownData(props.sideBarApisListings?.organizationList?.data || []);
  const [selectedPreference, setSelectedPreference] = useState({
    organization: "",
  });
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      organization: selectedOptions,
    }));
  };
  const handleImageError = () => {
    setImageError(true);
  };
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getOrganizationEnv(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const details = props.environmentalistListings && props.environmentalistListings.organization;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };
  const addMore = () => {
    setLoading(true);
    let payload = {
      organizationIds: extractIds(selectedPreference?.organization),
    };
    props.updateOrganizationEnv(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          organization: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getOrganizationEnv(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      organizationIds: [ids],
    };
    props.deleteOrganizationEnv(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getOrganizationEnv({ regions: props.id }, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {props.editMode && (
                <div className="d-flex justify-content-between mb-4">
                  <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                    Add Organization
                  </Link>
                  <div class="d-flex flex-wrap justify-content-end w-75">
                    <Select
                      className="basic-single w-50 text-capitalize"
                      classNamePrefix="select-search"
                      isMulti
                      placeholder="Select"
                      styles={customStyles1}
                      options={organizationDropdown}
                      value={selectedPreference.organization}
                      onChange={handleSelectChange}
                    />
                    <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                      Save
                    </Link>
                  </div>{" "}
                </div>
              )}
              {loading ? (
                <ul class="tbc-organization-list d-flex flex-wrap">
                  {Array.from({ length: 48 }, (item, index) => (
                    <div class=" org-skeleton">
                      <Skeleton borderRadius={"10px"} height="150px" />
                    </div>
                  ))}
                </ul>
              ) : (
                <>
                  <ul class="tbc-organization-list  d-flex flex-wrap">
                    {details && details.data && details?.data?.length
                      ? details?.data?.map((item) => {
                          const donationUrl = item?.donationUrl?.split("//").pop();
                          return (
                            <li className="organisation-item org-card position-relative">
                              {props.editMode && (
                                <button
                                  type="button"
                                  class="trash-button-box z-1"
                                  onClick={(e) => {
                                    handleDelete(props.id);
                                  }}
                                >
                                  <img src={trash} alt="w volumne" />
                                </button>
                              )}
                              {
                                <div className="organisation-box d-flex flex-wrap">
                                  <div class="organisation-logo ">
                                    <Link
                                      to={{
                                        pathname: `/organizations/${item?.slug}`,
                                        state: { id: item._id },
                                      }}
                                    >
                                      {!imageError && item?.coverImage?.small ? (
                                        <img
                                          src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                          onError={handleImageError}
                                          className="org-img sm-img-fix"
                                          alt=""
                                        />
                                      ) : !imageError && item?.coverImage?.medium ? (
                                        <img
                                          src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                          alt=""
                                          className="org-img sm-img-fix"
                                        />
                                      ) : (
                                        <img src={defaultOrgImg} alt="" className="org-img sm-img-fix" />
                                      )}
                                    </Link>
                                  </div>
                                  <div class="organisation-text">
                                    <h6 className="text-transfrom " style={{ marginBottom: "0px" }}>
                                      {" "}
                                      <Link
                                        to={{
                                          pathname: `/organizations/${item?.slug}`,
                                          state: { id: item?._id },
                                        }}
                                      >
                                        {`${capitalizeContentInBrackets(item?.name)}`}
                                      </Link>
                                    </h6>
                                    <div class="organisation-text-footer" style={{ marginTop: "0px" }}>
                                      <div className="d-flex space-between">
                                        <small>Species :</small>
                                        {item?.species?.length > 0 && (
                                          <span
                                            class="map-switch view-all-btn1 fs-11 org-tab-mob link-text hover-underline"
                                            style={{
                                              marginTop: "3px",
                                              marginLeft: "10px",
                                            }}
                                            onClick={() => {
                                              if (item?.species?.length > 0) {
                                                setSelectedData(item?.species);
                                                setSelectedName(item?.name);
                                                toggleShowMoreModal();
                                              }
                                            }}
                                          >
                                            View All
                                          </span>
                                        )}
                                      </div>

                                      <p className="caps-text d-flex align-items-center space-between">
                                        <span>
                                          {item?.species && item?.species.length > 0
                                            ? item?.species.filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                                .length > 0 // Filter species that have a scientific name
                                              ? item?.species
                                                  .filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                                  .map((ie, r, filteredSpecies) =>
                                                    r < 2 ? (
                                                      <span key={r}>
                                                        {ie?.displayCommonName || ie?.scientificName}
                                                        {r + 1 < filteredSpecies.length && r < 1 ? ", " : ""}
                                                        {r === 1 && filteredSpecies.length > 2 ? " ..." : ""}
                                                      </span>
                                                    ) : null,
                                                  )
                                              : "No scientific names available"
                                            : "Not yet added"}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  {donationUrl ? (
                                    <a
                                      href={`https://${donationUrl}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="btn btn-default position-absolute"
                                      style={{
                                        minWidth: "80px",
                                        lineHeight: "12px",
                                        bottom: "10px",
                                        left: "25%",
                                      }}
                                    >
                                      Donate Direct
                                    </a>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              }
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                  {details && details?.totalCount > 48 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      currentPage={currentPage}
                      pageLinks={pageLinks}
                      totalPages={pageLinks.length}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex  justify-content-start " style={{ marginBottom: "0px" }}>
          <div className="marker-content">
            <img src={marker} />
          </div>
          <p className="bold marker-spacing">Organizations</p>
        </div>
        <App data={mapOrganizationData} mapType="zoo" zoom={"zoom"} />
        <ShowMoreModal
          show={showMoreModal}
          onHide={toggleShowMoreModal}
          listData={selectedData}
          heading={`Species Supported by ${selectedName ? capitalizeContentInBrackets(selectedName) : ""}`}
          isNonEditable={true}
        />
        <AddModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          heading={"Add Organization"}
          page="environmentalist"
          type="organization"
          id={props.id}
          alert="organization"
        />
      </div>
    </>
  );
};
// Saber-Toothed Tiger
const mapDispatchToProps = (dispatch) => ({
  getOrganizationEnv: (params, callback) => dispatch(getOrganizationEnv(params, callback)),
  updateOrganizationEnv: (params, data, callback) => dispatch(updateOrganizationEnv(params, data, callback)),
  deleteOrganizationEnv: (params, data, callback) => dispatch(deleteOrganizationEnv(params, data, callback)),
});

const mapStateToProps = (state) => ({
  environmentalistListings: environmentalistListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Organizations));
