import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getScienceAndEducationDetail,
  getScienceAndEducationMedia,
  getScienceAndEducationReports,
  scienceAndEducationListing,
} from "../../store/scienceAndEducation";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import parse from "html-react-parser";
import LargeImageGalleryModal from "../../components/common/imageGallery/largeImageGallery";
import { capitalizeContentInBrackets, capitalizeFirstLetterWord, cleanAndFormatHtml } from "../../utils/helperFunctions"

import download from "../../include/images/download.svg";
import { getMisc, getSetting } from "../../store/misc";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import ShareModal from "../../components/common/sharemodal/shareModal";
import shareSvg from "../../include/images/share.svg"

const ScienceAndEducations = (props) => {
  const location = useLocation();
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [id, setId] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;

  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [])
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split('/');
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      setLoadingDetail(true);
      props.getScienceAndEducationDetail(id, (res) => {
        if (res && res.status === 200) {
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getScienceAndEducationDetail(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status === 200) {
          setId(res?.data?.data?._id);
          setLoadingDetail(false);
        }
      });
    }
  }, [location?.state?.id]);
  Useauthredirect();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      props.getScienceAndEducationMedia(id, (res) => {
        if (res && res.status === 200) {
        }
      });

      props.getScienceAndEducationReports(id, (res) => {
        if (res && res.status === 200) {
        }
      });
    } else if (id) {
      props.getScienceAndEducationMedia(id, (res) => {
        if (res && res.status === 200) {
        }
      });

      props.getScienceAndEducationReports(id, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [location?.state?.id, id]);

  const details =
    props.scienceAndEducationListing &&
    props.scienceAndEducationListing.scienceAndEducationDetail?.data;

  const media =
    props.scienceAndEducationListing &&
    props.scienceAndEducationListing.media?.data;

  const reports =
    props.scienceAndEducationListing &&
    props.scienceAndEducationListing.reports?.data;

  return (
    <PageLayout >
      <main id="main">
        {/* <!-- content-container start --> */}
        <div class="content-container pt-40">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="single-page-details d-flex flex-wrap">
                  <div class="spd-content">
                    <div class="breadcrumb-row d-flex flex-wrap">
                      <div class="breadcrumb-box">
                        {" "}
                        <Link to="/home">Home</Link>
                      </div>
                      <div class="breadcrumb-box">
                        <Link to="/science-education">
                          Science & Educations
                        </Link>
                      </div>
                      <div class="breadcrumb-box">
                        {!loadingDetail ? (
                          <a className="caps-text text-transform">
                            {details && details.name}
                          </a>
                        ) : (
                          <Skeleton borderRadius={"10px"} width="100px" height="20px" />
                        )}
                      </div>
                    </div>
                    <div class="spd-head">
                      {!loadingDetail ? (
                        <>
                          <div class="spd-title text-green caps-text text-transform">
                            {details &&
                              details.scienceAndEducationCategory ?
                              `${capitalizeContentInBrackets(details.scienceAndEducationCategory.name)}` : ""}
                          </div>
                          <div class="ei-heading">
                            <h1 className="text-transform d-flex text-capitalize">
                              {details && details.name ? `${capitalizeContentInBrackets(details.name)}` : ""}
                              {/* <div className="ml-20"> <Link onClick={() => setOpenShareModal(true)}><img
                                src={shareSvg} alt="" /></Link></div> */}
                            </h1>
                          </div>
                        </>
                      ) : (
                        <>
                          <Skeleton borderRadius={"10px"} width="100px" height="20px" />
                        </>
                      )}

                      <div class="publisher-footer d-flex">
                        {!loadingDetail ? (
                          <>
                            <div class="publisher-details">
                              <div class="pd-box">
                                {moment(details && details.createdAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </div>
                              {/* <div class="pd-box caps-text">
                                {details &&
                                  details.author &&
                                  `By ` + (details && details.author)}
                              </div> */}
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Skeleton borderRadius={"10px"} width="100px" height="20px" />
                          </>
                        )}
                      </div>

                      <div class="spd-text-icon d-flex">
                        {" "}
                        {/* <a href="#!"  style={{pointerEvents:"none"}}>
                          <img src={share} alt="" />
                        </a> */}
                      </div>
                    </div>

                    <div class="spd-separator"></div>
                    <div class="spd-image-inner d-lg-none d-block">
                      {!loadingDetail ? (
                        <div class="spd-image-inner">
                          <img
                            src={
                              details?.coverImage?.original
                                ? process.env.REACT_APP_MEDIA +
                                details?.coverImage?.original
                                : ""
                            }
                            alt=""
                          />
                        </div>
                      ) : (
                        <Skeleton borderRadius={"10px"} width="600px" height="400px" />
                      )}
                    </div>

                    {!loadingDetail ? (
                      <div class="spd-body">
                        {details && details.description
                          ? parse(details.description)
                          : ""}
                      </div>
                    ) : (
                      <Skeleton borderRadius={"10px"} width="600px" height="400px" />
                    )}
                  </div>
                  <div class="spd-image d-none d-lg-block">
                    {!loadingDetail ? (
                      <div class="spd-image-inner">
                        <img
                          src={
                            details?.coverImage?.original
                              ? process.env.REACT_APP_MEDIA +
                              details?.coverImage?.original
                              : ""
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <Skeleton borderRadius={"10px"} width="600px" height="400px" />
                    )}
                  </div>
                </div>
                {media && media.length > 0 && (
                  <div className="w-100 mt-20">
                    <h6 className="media-sub-heading">Media</h6>
                    <ul
                      class="media-list d-flex flex-wrap"
                      style={{ marginTop: "0px" }}
                    >
                      {media &&
                        media.length > 0 &&
                        media.map((item, i) => {
                          const ext = item?.file?.original.split(".").pop();
                          return (
                            <li className="media-item">
                              <div class="media-box">
                                <div
                                  class="media-box-inner"
                                  onClick={() => {
                                    setStartIndex(i);
                                    toggleShowMoreModal();
                                  }}
                                >
                                  {item && item?.file?.mediaType === "image" ? (
                                    <img
                                      class=""
                                      src={
                                        process.env.REACT_APP_MEDIA +
                                        item.file.original
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <video
                                      className=""
                                      type={`${item?.file?.mediaType}/${ext}`}
                                      controls
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_MEDIA +
                                          item.file.original
                                        }
                                      />
                                    </video>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
                {reports && reports.length > 0 && (
                  <div className="w-33 mt-20">
                    <h6 className="media-sub-heading">Reports</h6>
                    <ul class="report-list">
                      {reports && reports.length > 0
                        ? reports?.map((item) => {
                          return (
                            <li class="report-item">
                              <div class="report-box">
                                <h4 className="text-capitalize text-transfrom ">
                                  {`${capitalizeContentInBrackets(item?.name)}`}
                                  <small className="text-capitalize text-transfrom ">
                                    {item?.reportedBy}
                                  </small>
                                </h4>
                                <a
                                  class="report-box-download"
                                  href={`${process.env.REACT_APP_MEDIA}${item?.file?.original}`}
                                  download
                                  target="_blank"
                                >
                                  <img src={download} alt="" />
                                </a>
                              </div>
                            </li>
                          );
                        })
                        : ""}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showMoreModal && (
            <LargeImageGalleryModal
              show={showMoreModal}
              handleClose={toggleShowMoreModal}
              items={media.map((item) => {
                const mediaType = item?.file?.mediaType;
                const original =
                  process.env.REACT_APP_MEDIA + item.file.original;
                const ext = item?.file?.original.split(".").pop();
                if (mediaType === "image") {
                  return {
                    original: original,
                    renderItem: () => (
                      <img
                        className="image-gallery-image"
                        src={original}
                        alt="Image1"
                      />
                    ),
                  };
                } else if (mediaType === "video") {
                  return {
                    original: original,
                    renderItem: () => (
                      <video controls className="image-gallery-image">
                        <source src={original} type={`${mediaType}/${ext}`} />
                        Your browser does not support the video tag.
                      </video>
                    ),
                  };
                }
                return null;
              })}
              startIndex={startIndex}
            />
          )}
        </div>
        <ShareModal
          show={openShareModal}
          onHide={() => setOpenShareModal(!openShareModal)}
          name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.name))}
          id={details?._id}
          category="scienceAndEducation"
        />
        <div class="container custom-citation">
          <div className="custom-citation-text">Citations</div>
          <div className="custom-citation-text" dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}></div>
        </div>
      </main>
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getScienceAndEducationDetail: (params, callback) =>
    dispatch(getScienceAndEducationDetail(params, callback)),
  getScienceAndEducationMedia: (params, callback) =>
    dispatch(getScienceAndEducationMedia(params, callback)),
  getScienceAndEducationReports: (params, callback) =>
    dispatch(getScienceAndEducationReports(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
});

const mapStateToProps = (state) => ({
  scienceAndEducationListing: scienceAndEducationListing(state),
  getMisc: getMisc(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ScienceAndEducations));
