import React, { useEffect, useState, useRef } from "react";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Skeleton from "react-loading-skeleton";
import noRecord from "../../../include/images/noPostImg.svg";
import LargeImageGalleryModal from "../imageGallery/largeImageGallery";
import Pagination from "../Pagination/pagination";
import { connect } from "react-redux";
import { getEducationMedia, getMisc } from "../../../store/misc";
import { capitalizeContentInBrackets, dotDesc, getRelativeTime, removeTags } from "../../../utils/helperFunctions";
import ReactPlayer from "react-player";
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import essayIcon from "../../../include/images/Essay 2.svg";
import HTMLReactParser from "html-react-parser";
import ViewModal from "./viewModal";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sideBarApisListings } from "../../../store/sidebarApis";
import { getProfile } from "../../../utils/localStorageServices";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import ImageHolder from "../../../common/customStyles/imageHolder";
import ViewPost from "../post/viewPost";
import PostCard from "./post";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import Education from "./education";
import EducationPostCard from "./educationPost";

const Listcard = (props) => {
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [galleryItems, setGalleryItems] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const sectionsArray = [
    { name: "regions", path: "/regions" },
    { name: "species", path: "/endangered-species" },
    { name: "organizations", path: "/organizations" },
    { name: "wetMarkets", path: "/war-on-the-environment-threats" },
    { name: "zoos", path: "/zoos-and-wildlife-reserves" },
    { name: "scienceAndEducations", path: "/science-education" },
    { name: "environmentalists", path: "/environmentalists" },
  ];
  const profile = getProfile();
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const isMobile = useCheckMobileScreen();
  const regionsDropdown =
    props.sideBarApisListings && props.sideBarApisListings?.regionList && props.sideBarApisListings?.regionList?.data;
  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  useEffect(() => {
    let mediaItems;
    mediaItems =
      props.media &&
      props.media?.data?.length &&
      props.media?.data.map((item) => {
        const mediaType = item?.file?.mediaType;
        const original = process.env.REACT_APP_MEDIA + item?.file?.original;

        if (mediaType === "document" && item?.section) {
          return {
            file: {
              mediaType: mediaType,
              original: item?.file?.original,
              firstName: item?.firstName ? item?.firstName : "",
              class: item?.class ? item?.class : "",
              name: item?.file?.name,
              school: item?.school,
              teacherName: item?.teacherName,
              description: item?.description,
              ...item,
            },
          };
        } else if (item?.section && (mediaType === "sketch" || mediaType === "video" || mediaType === "image")) {
          return {
            file: {
              mediaType: mediaType,
              original:
                item?.file?.original?.startsWith("https") && mediaType === "video" ? item?.file?.original : original,
              firstName: item?.firstName ? item?.firstName : "",
              class: item?.class ? item?.class : "",
              school: item?.school,
              teacherName: item?.teacherName,
              description: item?.description,
              ...item,
            },
          };
        } else if (item?.section) {
          return {
            file: {
              mediaType: mediaType ? mediaType : "essay1",
              original: item?.file?.original ? original : essayIcon,
              firstName: item?.firstName ? item?.firstName : "",
              class: item?.class ? item?.class : "",
              essay: item?.essay,
              school: item?.school,
              teacherName: item?.teacherName,
              description: item?.description,
              ...item,
            },
          };
        } else {
          const allSectionItems = sectionsArray.reduce((acc, sectionName) => {
            if (item[sectionName.name]) {
              const sectionItems = item[sectionName.name].map((sectionItem) => ({
                ...sectionItem,
                path: `${sectionName.path}/${sectionItem.slug}`,
                id: sectionItem._id,
              }));
              return [...acc, ...sectionItems];
            }
            return acc;
          }, []);
          return {
            file: {
              type1: "post",
              mediaType: item?.files[0]?.mediaType,
              original: process.env.REACT_APP_MEDIA + item?.files[0]?.original,
              firstName: item?.createdBy?.firstName ? item?.createdBy?.firstName : "",
              description: item?.description,
              allSectionItems: allSectionItems,
              ...item,
            },
          };
        }
      });
    if (mediaItems == 0) {
      setGalleryItems([]);
    } else {
      setGalleryItems(mediaItems && mediaItems.filter(Boolean));
    }
  }, [props.media]);
  const pageLinks = Array.from(
    { length: Math.ceil((props.media?.totalCount ?? 0) / (props.media?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const [readMore, setReadMore] = useState(false);
  const [loadedItems, setLoadedItems] = useState(0);

  const handleMediaLoad = () => {
    setLoadedItems((prevLoadedItems) => prevLoadedItems + 1);
  };

  useEffect(() => {
    setLoadedItems(0);
  }, [props.media]);
  useEffect(() => {
    fetchFeeds(currentPage, false);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (currentPage > 1) {
      fetchFeeds(currentPage, true);
    }
  }, [currentPage]);

  const getDefaultDocIcon = (extension) => {
    switch (extension) {
      case "docx":
        return docx;
      case "pdf":
        return pdf;
      case "txt":
        return txt;
      case "xls":
        return xls;
      case "xlsx":
        return xlxs;
      default:
        return doc;
    }
  };

  const handleClick = (item) => {
    setViewData(item);
    toggleViewModal();
  };

  const renderDescription = (description) => {
    if (!description) return "";
    const characterLimit = isMobile ? 200 : 400;
    if (description.length <= characterLimit) {
      return HTMLReactParser(description);
    }

    const shortDescription = description.substring(0, characterLimit) + "... ";
    return (
      <>
        {HTMLReactParser(readMore ? description : shortDescription)}
        <p onClick={toggleReadMore} className="read-more-detail p-0">
          {readMore ? "Read Less" : "Read More"}
        </p>
      </>
    );
  };
  const toggleReadMore = () => {
    setReadMore(!readMore);
  };
  const fetchFeeds = (page, append = true) => {
    const totalRecords = props.media?.totalCount ?? 0;
    const recordsPerPage = props.media?.recordsPerPage ?? 10;
    const maxPages = Math.ceil(totalRecords / recordsPerPage);

    if (page > maxPages) {
      return;
    }

    const params = {
      order: "desc",
      created: "createdAt",
      id: props.id,
      section: props.section,
      page: page,
    };

    if (!append) {
      props.setLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    props.getEducationMedia(params, (res) => {
      if (res && res.status === 200) {
        const newMediaData = res.data.data;
        const newMetadata = {
          filteredCount: res.data.filteredCount,
          recordsPerPage: res.data.recordsPerPage,
          totalCount: res.data.totalCount,
        };

        if (append) {
          props.setMedia((prevMedia) => {
            const previousData = Array.isArray(prevMedia.data) ? prevMedia.data : [];
            return {
              ...newMetadata,
              data: [...previousData, ...newMediaData],
            };
          });
        } else {
          // Set new data as initial load
          props.setMedia({
            ...newMetadata,
            data: newMediaData,
          });
        }
        props.setLoading(false);
        setIsLoadingMore(false);
      } else {
        props.setLoading(false);
        setIsLoadingMore(false);
        toast(<AlertError message={res?.data?.message || "Something Went Wrong"} />);
      }
    });
  };

  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;

    const isSmallScreen = window.innerWidth <= 768;
    const threshold = isSmallScreen ? 1000 : 50;
    if (scrollTop + clientHeight >= scrollHeight - threshold && !isLoadingMore && !props.loadingPost) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  return (
    <div className={`tab-content ${isMobile ? "w-100 mt-3" : "education-body"}`} id="myTabContent">
      <div className="tab-pane fade show active" id="map-tab-pane1">
        <div
          id="collapseOne"
          className="accordion-collapse collapse show d-lg-block"
          aria-labelledby="headingOne"
          data-bs-parent="#myTabContent"
        >
          <div className="accordion-body">
            <div className={`grid ${galleryItems?.length > 0 ? "" : "h-auto"}`}>
              <div className="grid-sizer"></div>

              {/* Show skeleton loaders if the post is still loading */}
              {!props.loadingPost && galleryItems?.length > 0 ? (
                galleryItems.map((item, idx) => {
                  const mediaType = item?.file?.mediaType;
                  const imageOriginal = item?.file?.original;
                  const ext = item?.file?.original?.match(/\.([^.]+)$/)?.[1];
                  const defaultDocIcon = mediaType === "document" ? getDefaultDocIcon(ext) : "";
                  const nameDoc = mediaType === "document" ? item?.file?.name : "";
                  return item.file.type1 == "post" ? (
                    <PostCard
                      id={idx}
                      item={item}
                      page={props?.page}
                      renderDescription={renderDescription}
                      galleryItems={galleryItems}
                      setGalleryItems={setGalleryItems}
                      media={props.media}
                      setMedia={props.setMedia}
                      type="post"
                    />
                  ) : (
                    <PostCard
                      id={idx}
                      item={item}
                      page={props?.page}
                      renderDescription={renderDescription}
                      galleryItems={galleryItems}
                      setGalleryItems={setGalleryItems}
                      mediaType={mediaType}
                      nameDoc={nameDoc}
                      media={props.media}
                      setMedia={props.setMedia}
                      type="education"
                    />
                  );
                })
              ) : !props.loadingPost && galleryItems?.length === 0 ? (
                <div className="text-center">
                  <div className="nrb-image">
                    <img src={noRecord} alt="" />
                  </div>
                </div>
              ) : (
                /* Show skeletons while loading */
                <>
                  {[1, 2, 3, 4].map((skeletonItem) => (
                    <div className="widget d-flex flex-wrap" key={skeletonItem}>
                      <div className="widget-icon z-0">
                        <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
                      </div>
                      <div className="widget-content z-0">
                        <div className="widget-text-holder">
                          <div className="w-heading-block position-relative">
                            <div className="w-heading-box d-flex align-items-center flex-wrap">
                              <div className="w-label-box fw-semibold text-capitalize">
                                <Skeleton borderRadius={"10px"} height={45} width={150} />
                              </div>
                            </div>
                          </div>
                          <div className="w-image-holder mt-35 z-0">
                            <div className="d-flex flex-wrap">
                              <div className="position-relative" style={{ width: "100%" }}>
                                <Skeleton borderRadius={"10px"} height={"40vh"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {/* Infinite scroll loading skeletons */}
              {isLoadingMore &&
                [...Array(4)].map((_, skeletonItem) => (
                  <div className="widget d-flex flex-wrap" key={skeletonItem}>
                    <div className="widget-icon z-0">
                      <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
                    </div>
                    <div className="widget-content z-0">
                      <div className="widget-text-holder">
                        <div className="w-heading-block position-relative">
                          <div className="w-heading-box d-flex align-items-center flex-wrap">
                            <div className="w-label-box fw-semibold text-capitalize">
                              <Skeleton borderRadius={"10px"} height={45} width={150} />
                            </div>
                          </div>
                        </div>
                        <div className="w-image-holder mt-35 z-0">
                          <div className="d-flex flex-wrap">
                            <div className="position-relative" style={{ width: "100%" }}>
                              <Skeleton borderRadius={"10px"} height={"40vh"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <ViewModal
                show={viewModal}
                onHide={toggleViewModal}
                heading="Essay"
                id={props.id}
                section={props.section}
                data={viewData}
                sectionsArray={sectionsArray}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEducationMedia: (params, callback) => dispatch(getEducationMedia(params, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Listcard));
