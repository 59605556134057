import React from "react";
import trash from "../../../include/images/trash-24x24.svg";
import button from "../../../include/images/button-img-1.svg";
import button2 from "../../../include/images/button-img-2.svg";
import ImageHolder from "../../../common/customStyles/imageHolder";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const CommentInput = (props) => {
 const {selectedItem,newCommentData,setNewCommentData,profilePicture,handleComment} = props;
  return (
    <div class="modal-widet-row">
      <div class="widget d-flex flex-wrap">
        <div class="widget-icon">
          <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
            <ImageHolder
              image={profilePicture}
              name={selectedItem?.name?.slice(0, 1)}
            />
          </div>
        </div>
        <div class="widget-content">
          <div class="w-content-upper">
            {newCommentData?.media && (
              <div class="w-image-holder">
                {["video/mp4", "video/mpeg", "video/MP4", "video/webm"]?.find(
                  (i) => i == newCommentData?.media?.type,
                ) ? (
                  <div
                    class="m-10 position-relative"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <video
                      controls
                      style={{
                        width: "100%",
                        height: "260px",
                        objectFit: "cover",
                      }}
                    >
                      <source src={`${URL.createObjectURL(newCommentData?.media)}`} />
                    </video>
                    <button
                      type="button"
                      class="trash-button-box"
                      onClick={(e) => {
                        setNewCommentData((prev) => ({
                          ...prev,
                          media: null,
                        }));
                      }}
                    >
                      <img src={trash} alt="w volumne" />
                    </button>
                  </div>
                ) : (
                  <div
                    class="m-10 position-relative"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "260px",
                        objectFit: "cover",
                      }}
                      src={`${URL.createObjectURL(newCommentData?.media)}`}
                      alt=""
                    />
                    <button
                      type="button"
                      class="trash-button-box"
                      onClick={(e) => {
                        setNewCommentData((prev) => ({
                          ...prev,
                          media: null,
                        }));
                      }}
                    >
                      <img src={trash} alt="w volumne" />
                    </button>
                  </div>
                )}
              </div>
            )}
            <div class="w-textarea-block">
              <textarea
                class="w-textarea"
                placeholder="Type Your Comment"
                onChange={(e) => {
                  const data = { ...newCommentData };
                  data.text = e.target.value;
                  setNewCommentData(data);
                }}
                value={newCommentData?.text}
              ></textarea>
            </div>
          </div>
          <div class="w-content-lower d-flex flex-wrap align-items-center justify-content-between">
            <div class="w-button-holder d-flex flex-wrap align-items-center">
              <div class="w-button position-relative">
                <input
                  type="file"
                  id="inputGroupImg1"
                  accept=".jpg,.jpeg,.png,.gif,.webp"
                  onChange={(e) => {
                    const value = { ...newCommentData };
                    value.isMediaNew = true;
                    value.media = e.target.files[0];
                    setNewCommentData(value);
                  }}
                />
                <label type="button" class="w-button-box d-flex align-items-center justify-content-center">
                  <img src={button} alt="image1" />
                </label>
              </div>
              <div class="w-button position-relative">
                <input
                  type="file"
                  id="inputGroupImg2"
                  accept="video/MP4,
                          video/mp4,
                          video/webm,
                          video/mpeg"
                  onChange={(e) => {
                    const value = { ...newCommentData };
                    value.isMediaNew = true;
                    value.media = e.target.files[0];
                    setNewCommentData(value);
                  }}
                />
                <label type="button" class="w-button-box d-flex align-items-center justify-content-center">
                  <img src={button2} alt="video" />
                </label>
              </div>
            </div>
            <div class="w-btn-row">
              <Link
                class={`${
                  props?.loading || !newCommentData?.text ? "btn btn-default btn-sm disabled" : "btn btn-default btn-sm"
                }`}
                onClick={handleComment}
              >
                Comment
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentInput;
