import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import docImage from "../../../include/images/doc-image.webp";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { Amplify, Storage, Auth } from "aws-amplify";
import { getMisc, uploadMedia } from "../../../store/misc";
import { connect } from "react-redux";
import AlertModal from "./alertModal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const BulkUploadModal = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [screenWidth, setWidth] = useState(0);
  const [selectedFileType, setSelectedFileType] = useState("image");
  const [videoLinks, setVideoLinks] = useState([]);
  const [isModeChanged, setIsModeChanged] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [tempType, setTempType] = useState("");
  const handleDocumentFileChange = (event, index) => {
    const newDocumentFiles = [...documentFiles];
    const file = event.target.files[0];

    if (file && file.size <= 10 * 1024 * 1024) {
      newDocumentFiles[index] = file;
      setDocumentFiles(newDocumentFiles);
    } else {
      toast(<AlertError message={"Total size of documents should be <= 10MB."} />);
    }
  };

  const handleDocumentNameChange = (event, index) => {
    const newDocumentNames = [...documentNames];
    const newName = event.target.value;
    newDocumentNames[index] = newName;
    setDocumentNames(newDocumentNames);
  };
  useEffect(() => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const totalFiles = [...selectedFiles, ...files];

    if (
      (selectedFileType === "image" &&
        totalFiles.length <= 20 &&
        totalFiles.every((file) => file.size <= 2 * 1024 * 1024)) ||
      (selectedFileType === "video" &&
        totalFiles.length <= 2 &&
        totalFiles.reduce((acc, file) => acc + file.size, 0) <= 30 * 1024 * 1024 &&
        videoLinks.length <= 5)
    ) {
      setSelectedFiles(totalFiles);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } else {
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
      const errorMessage =
        selectedFileType === "image"
          ? "Maximum 20 images allowed. Each image should be <= 2MB."
          : selectedFileType === "video"
          ? "Maximum 2 videos allowed. Total size <= 30MB and max 5 video links are allowed."
          : "Invalid file selection.";

      toast(<AlertError message={errorMessage} />);
    }
  };

  const handleUpload = async () => {
    if (
      Array.from(selectedFiles)?.length ||
      videoLinks.some((item) => item !== null) ||
      documentFiles.some((file) => file !== null)
    ) {
      setUploading(true);
      try {
        if (selectedFileType === "image") {
          // Handle image uploads
          const imagePromiseContainer = [];

          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;

            imagePromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }),
            );
          }

          const imageResult = await Promise.all([...imagePromiseContainer]);

          const imagePayload = {
            section: props.section,
            id: props.id,
            files: imageResult?.map((it) => ({
              media: "public/" + it?.key,
              mediaType: "image",
              mediaSource: "s3",
            })),
          };
          props?.uploadMedia(imagePayload, async (res) => {
            if (res && res.status === 200) {
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            } else {
            }
          });
        } else if (selectedFileType === "video") {
          const videoPromiseContainer = [];

          // Process user-uploaded videos
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];

            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;

            await Storage.put(fileName, file, {
              completeCallback: (event) => {},
              progressCallback: (progress) => {},
              errorCallback: (err) => {
                return toast(<AlertError message={"Something Went Wrong"} />);
              },
            });

            videoPromiseContainer.push({
              media: `public/${fileName}`,
              mediaType: "video",
              mediaSource: "s3", // Marking as S3 source
            });
          }

          // Process video links
          for (let i = 0; i < videoLinks.length; i++) {
            const item = videoLinks[i];

            if (item !== null) {
              videoPromiseContainer.push({
                media: item,
                mediaType: "video",
                mediaSource: "url", // Marking as URL source
              });
            }
          }

          const videoResult = await Promise.all(videoPromiseContainer);

          const videoPayload = {
            section: props.section,
            id: props.id,
            files: videoResult?.map((it) => ({
              media: it.mediaSource === "s3" ? it.media : it.media,
              mediaType: "video",
              mediaSource: it.mediaSource,
            })),
          };
          await props?.uploadMedia(videoPayload, async (res) => {
            if (res && res.status === 200) {
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            } else {
              return toast(<AlertError message={"Something Went Wrong"} />);
            }
          });
        } else if (selectedFileType === "document") {
          const documentPromiseContainer = [];
          const uploadedDocumentIndexes = [];
          const documentNamesArray = [];
          for (let i = 0; i < documentFiles.length; i++) {
            const file = documentFiles[i];

            if (file !== null) {
              uploadedDocumentIndexes.push(i);

              const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;

              documentPromiseContainer.push(
                Storage.put(fileName, file, {
                  completeCallback: (event) => {},
                  progressCallback: (progress) => {},
                  errorCallback: (err) => {
                    return toast(<AlertError message={"Something Went Wrong"} />);
                  },
                }),
              );
              const documentName = documentNames[i].trim();

              // Check for duplicate document names
              if (documentNamesArray.includes(documentName)) {
                setUploading(false);
                return toast(<AlertError message={`Duplicate document name: ${documentName}`} />);
              }

              documentNamesArray.push(documentName);
            }
          }

          if (uploadedDocumentIndexes.length > 0) {
            const missingNames = uploadedDocumentIndexes.filter((index) => documentNames[index].trim() === "");

            if (missingNames.length > 0) {
              setUploading(false);
              return toast(<AlertError message={`Please provide names for document`} />);
            }
          }

          try {
            const documentResult = await Promise.all([...documentPromiseContainer]);

            const documentPayload = {
              section: props.section,
              id: props.id,
              files: documentResult?.map((it, index) => ({
                media: "public/" + it?.key,
                mediaType: "document",
                name: documentNames[index],
                mediaSource: "s3",
              })),
            };
            await props?.uploadMedia(documentPayload, async (res) => {
              if (res && res.status === 200) {
                toast(
                  <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
                );
              } else {
                return toast(<AlertError message={"Something Went Wrong"} />);
              }
            });
          } catch (error) {
            toast(
              <AlertError
                message={error && error.data && error.data.message ? error.data.message : "Error uploading documents"}
              />,
            );
          }
        }
      } catch (error) {
        setUploading(false);
        return toast(
          <AlertError
            message={error && error.data && error.data.message ? error.data.message : "Something went wrong."}
          />,
        );
      }
    } else {
      return toast(<AlertError message={"Nothing to upload"} />);
    }
    setSelectedFiles([]);
    setDocumentFiles([]);
    setVideoLinks([]);
    setDocumentNames([]);
    setUploading(false);
    props.onHide();
  };

  const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const handleVideoLinkChange = (index, link) => {
    const newVideoLinks = [...videoLinks];
    newVideoLinks[index - 1] = link;
    setVideoLinks(newVideoLinks);
  };
  const handleModeChange = (newMode) => {
    if (selectedFiles.length > 0 || videoLinks.length > 0 || documentFiles.length > 0) {
      setShowChangeModal(true);
      setTempType(newMode);
    } else {
      setIsModeChanged(true);
      setSelectedFileType(newMode);
    }
  };
  const handleChangeMode = () => {
    setIsModeChanged(true);
    setSelectedFiles([]);
    setVideoLinks([]);
    setDocumentFiles([]);
    setDocumentNames([]);
    setSelectedFileType(tempType);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    setShowChangeModal(false);
  };
  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall media-upload-modal"
      show={props.show}
      enforceFocus={false}
    >
      <div className="modal-content d-flex flex-column position-relative">
        <div className="modal-header pb-0  pt-0 modal-head view-all-header">
          <h5 className="modal-title text-capitalize">{props.heading}</h5>
          <div>
            <button
              type="button"
              className="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
              onClick={() => props.onHide()}
            >
              <img src={close} alt="" className="modal-close-btn" />
            </button>
          </div>
        </div>
        <Modal.Body
          className="custom-modal-body flex-grow-1 overflow-auto w-100 pb-0"
          style={selectedFiles.length > 0 ? { height: "auto" } : { height: "500px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="file-type-radio">
                  <label>
                    <input
                      type="radio"
                      value="image"
                      checked={selectedFileType === "image"}
                      onChange={() => handleModeChange("image")}
                    />
                    Images {selectedFileType === "image" && <small> {`(Max 20 images and each image <= 2MB)`}</small>}
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="video"
                      checked={selectedFileType === "video"}
                      onChange={() => handleModeChange("video")}
                    />
                    Videos{" "}
                    {selectedFileType === "video" && (
                      <small> {`(Max 2 videos, each video <= 30MB and max 5 video links)`}</small>
                    )}
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="document"
                      checked={selectedFileType === "document"}
                      onChange={() => handleModeChange("document")}
                    />
                    Documents{" "}
                    {selectedFileType === "document" && <small> {`(Document size <= 20MB)`}</small>}
                  </label>
                </div>
              </div>
            </div>

            {selectedFileType !== "document" && (
              <div className="row">
                <div className="col-md-12">
                  <label className="btn pl-0">
                    <input
                      type="file"
                      multiple
                      accept={
                        selectedFileType === "image"
                          ? "image/*"
                          : selectedFileType === "video"
                          ? "video/*"
                          : selectedFileType === "document"
                          ? ".pdf,.doc,.docx"
                          : ""
                      }
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            )}
            {selectedFileType === "video" && (
              <div className="row">
                {[1, 2, 3, 4, 5].map((index) => (
                  <div key={index} className="col-md-6 mt-2">
                    <input
                      type="text"
                      id={`videoLink${index}`}
                      className="form-control"
                      placeholder={`Enter video link ${index}`}
                      value={videoLinks[index - 1]}
                      onChange={(e) => handleVideoLinkChange(index, e.target.value)}
                    />
                  </div>
                ))}
              </div>
            )}
            {selectedFileType === "document" && (
              <div className="row mb-5">
                {[...Array(5).keys()].map((index) => (
                  <div key={index} className="col-md-12 mt-2 mb-2">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="btn" style={{ padding: "0px" }}>
                          <input
                            type="file"
                            id={`documentInput${index}`}
                            accept=".pdf,.doc,.docx"
                            onChange={(e) => handleDocumentFileChange(e, index)}
                            className="mb-2"
                          />
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          id={`documentName${index}`}
                          className="form-control"
                          placeholder={`Enter document name ${index + 1}`}
                          value={documentNames[index]}
                          onChange={(e) => handleDocumentNameChange(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal.Body>
        {selectedFiles.length > 0 && (
          <div className="modal-header pt-0">
            <div className="col-md-14 bulk-upload-container">
              <h5>Selected Files:</h5>
              <div className="container d-flex  flex-wrap">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="gallery-item m-1">
                    {file.type.startsWith("image/") && (
                      <img src={URL.createObjectURL(file)} alt={`Preview ${file.name}`} className="gallery-image" />
                    )}
                    {file.type.startsWith("video/") && (
                      <video controls width="150" height="100" className="gallery-video">
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {file.type.startsWith("application/") && (
                      <div className="gallery-document">
                        <div className="document-files">
                          {documentFiles.map((file, index) => (
                            <div key={index} className="document-file">
                              <img src={docImage} alt="document" />
                              <input
                                type="text"
                                placeholder="Document name"
                                className="form-control"
                                value={documentNames[index] || ""}
                                onChange={(e) => handleDocumentNameChange(e, index)}
                              />
                              <button onClick={() => handleFileRemove(index)}>
                                <img src={trash} alt="remove" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <button type="button" className="gallery-remove-btn" onClick={() => handleFileRemove(index)}>
                      <img src={trash} alt="Remove" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="modal-header view-all-header">
          <button className={`btn btn-default btn-bulk-upload ${uploading ? "disabled" : ""}`} onClick={handleUpload}>
            {uploading ? "Uploading..." : "Upload"}
          </button>
        </div>
      </div>
      <AlertModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
        loadingDel={uploading}
        deleteHandler={handleChangeMode}
        message="Changing to another option will result in the loss of your current data. Are you sure you want to proceed?"
        type={"Confirm"}
      />
    </Modal>
  );
};
const mapDispatchToProps = (dispatch) => ({
  uploadMedia: (data, callback) => dispatch(uploadMedia(data, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BulkUploadModal));
