import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../alerts/alertError";

const containerStyle = {
  width: "100%",
  height: "700px",
  borderRadius: "10px"
};

const WorldMap = (props) => {
  const history = useHistory();
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [worldGeometries, setWorldGeometries] = useState([]);
  const center1 = props.showAllMap ? { lat: 5, lng: 30 } : props.coordinates;

  useEffect(() => {
    // Fetch world geometries from the JSON file
    fetch("/feature.json")
      .then((response) => response.json())
      .then((data) => {
        if (data.objects && data.objects.world && data.objects.world.geometries) {
          setWorldGeometries(data.objects.world.geometries);
        } else {
          throw new Error("Invalid JSON format");
        }
      })
      .catch((error) => {
        console.error("Error fetching world geometries:", error);
        toast(<AlertError message="Failed to load world geometries. Please try again later." />);
      })
      .finally(() => {
        setScriptLoaded(true);
      });
  }, []);

  const handleRegionClick = (regionName) => {
    const regionData = props.data.find((entry) => entry.state === regionName);

    if (regionData) {
      history.push(`/regions/${regionData.slug}`, {
        id: regionData._id,
      });
    } else {
      toast(<AlertError message="Sorry, but data for this region is currently unavailable. We are working diligently to update our information. Please check back soon for the latest updates. Thank you for your understanding" />);
    }
  };

  const convertArcsToCoordinates = (geometry) => {
    const { type, arcs } = geometry;
    const coordinates = [];

    if (type === "Polygon") {
      // Single Polygon
      arcs.forEach(arc => {
        arc.forEach(pointIndex => {
          coordinates.push({ lat: pointIndex[1], lng: pointIndex[0] });
        });
      });
    } else if (type === "MultiPolygon") {
      // MultiPolygon
      arcs.forEach(polygon => {
        polygon.forEach(innerArc => {
          innerArc.forEach(pointIndex => {
            coordinates.push({ lat: pointIndex[1], lng: pointIndex[0] });
          });
        });
      });
    }

    return coordinates;
  };
  const mapOptions = {
    disableDefaultUI: false, // Remove all default UI controls
    gestureHandling: "auto", // Disable gesture handling (double-click and scroll zoom)
    // styles: [
    //   {
    //     featureType: "administrative",
    //     elementType: "labels",
    //     stylers: [{ visibility: "off" }],
    //   },
    // ],
  };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center1}
      zoom={props.showAllMap ? 2 : 5}
      mapTypeId="terrain"
      options={mapOptions}
    >
      {/* Render world regions based on worldGeometries */}
      {worldGeometries.map((geometry, index) => (
        <Polygon
          key={index}
          paths={convertArcsToCoordinates(geometry)}
          onClick={() => handleRegionClick(geometry.properties.name)}
        />
      ))}

      {/* Render marker if props.name exists */}
      {props.name && (
        <Marker
          position={{
            lat: props.coordinates?.lat || 0,
            lng: props.coordinates?.lng || 0,
          }}
        />
      )}
    </GoogleMap>
  );
};

export default WorldMap;
