import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import HTMLReactParser from 'html-react-parser';
import { connect } from 'react-redux';
import { getWetMarketMedia, wetMarketData } from '../../../store/wetMarket';
import { getMediaDetails, organizationListing } from '../../../store/organization';
import { getRegionMedia, regionListings } from '../../../store/region';
import { getMedia, getSpeciesDetails, speciesListings } from '../../../store/species';
import { getRegionList } from '../../../store/sidebarApis';
import Pagination from '../Pagination/pagination';
import noRecord from "../../../include/images/nrb-img.svg";
import { removeTags } from '../../../utils/helperFunctions';
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import { getEnvMedia } from '../../../store/environmentalist';

const Report = (props) => {
    const [media, setMedia] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getDefaultDocIcon = (extension) => {
        switch (extension) {
            case 'docx':
                return docx;
            case 'pdf':
                return pdf;
            case 'txt':
                return txt;
            case 'xls':
                return xls;
            case 'xlsx':
                return xlxs;
            default:
                return doc;
        }
    };

    const dotDesc = (text, limit) => {
        const dots = "...";
        if (text && text.length > limit) {
            text = text.substring(0, limit) + dots;
        }
        return text;
    };

    const fetchData = (params) => {
        props.setLoading(true);

        const fetchDataFunction = (() => {
            switch (props.section) {
                case "species":
                    return props.getMedia;
                case "wetMarket":
                    return props.getWetMarketMedia;
                case "organization":
                    return props.getMediaDetails;
                case "region":
                    return props.getRegionMedia;
                case "environmentalist":
                    return props.getEnvMedia;
                default:
                    return null;
            }
        })();

        if (fetchDataFunction) {
            fetchDataFunction(params, (res) => {
                if (res && res.status === 200) {
                    props.setLoading(false);
                    setMedia(res.data);
                }
            });
        }
    };

    useEffect(() => {
        fetchData({ id: props.id, page: currentPage, mediaType: "document" });
        props.scrollToTop();
    }, [props.id, currentPage]);


    const pageLinks = Array.from({ length: Math.ceil((media?.filteredCount || 0) / (media?.recordsPerPage || 1)) }, (_, i) => i + 1);

    return (
        <>
            < >
                {props.loading ? (
                    <ul className="media-list d-flex flex-wrap" style={{ marginTop: "0px" }}>
                        {Array.from({ length: 18 }, (_, index) => (
                            <li className="media-item media-item-skeleton" key={index}>
                                <div className="media-box">
                                    <div className="media-box-inner">
                                        <Skeleton borderRadius={"10px"} width="100%" height={"215px"} />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className="media-list d-flex flex-wrap reports-list" style={{ marginTop: "0px" }}>
                        {media?.data?.length > 0 ? (
                            media.data.map((item, idx) => {
                                const ext = (props.section === "species" ? item?.media?.original : item?.file?.original)?.match(/\.([^.]+)$/)?.[1];
                                const mediaType = props.section === "species" ? item?.media?.mediaType : item?.file?.mediaType;
                                const imageOriginal = props.section === "species" ? item?.media?.original : item?.file?.original;
                                const nameDoc = props.section === "species" ? item?.media?.name : item?.file?.name;

                                if (mediaType === "document" && imageOriginal) {
                                    const defaultDocIcon = getDefaultDocIcon(ext);
                                    return (
                                        <li className="media-item" key={idx}>
                                            <a
                                                href={process.env.REACT_APP_MEDIA + imageOriginal}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ display: "block" }}
                                            >
                                                <div className="media-box">
                                                    <div className="media-box-inner">
                                                        {item && mediaType === "document" && (
                                                            <img src={defaultDocIcon} alt="Default Document Icon" />
                                                        )}
                                                    </div>
                                                    <div className="media-text-box mt-3 text-center text-capitalize" title={nameDoc || ""}>
                                                        {nameDoc !== undefined ? dotDesc(HTMLReactParser(removeTags(nameDoc)), 15) : "\u00A0"}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    );
                                }

                                return null;
                            })
                        ) : (
                            <ul className="blog-list d-flex flex-wrap justify-content-center">
                                <div className="no-records-container d-flex align-items-center justify-content-center" style={{ height: "auto" }}>
                                    <div className="no-records-box text-center">
                                        <div className="nrb-image">
                                            <img src={noRecord} alt="" />
                                        </div>
                                        <h6>No Records Found</h6>
                                    </div>
                                </div>
                            </ul>
                        )}

                    </ul>
                )}
            </>
            {media && media?.filteredCount > 48 && <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
            />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getMedia: (params, callback) => dispatch(getMedia(params, callback)),
    getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
    getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
    getWetMarketMedia: (params, callback) => dispatch(getWetMarketMedia(params, callback)),
    getRegionMedia: (params, callback) => dispatch(getRegionMedia(params, callback)),
    getMediaDetails: (params, callback) => dispatch(getMediaDetails(params, callback)),
    getEnvMedia: (params, callback) => dispatch(getEnvMedia(params, callback)),
});

const mapStateToProps = (state) => ({
    speciesListings: speciesListings(state),
    regionListings: regionListings(state),
    organizationListing: organizationListing(state),
    wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Report));
