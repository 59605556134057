import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "post",
  initialState: {
    reports: {},
    comments: {},
    media: {},
    likes: {},
    post:{},
    loading: false,
    publicMedia:{},
    publicPost:{},
  },
  reducers: {
    reportsRequested: (misc, action) => {
      misc.loading = true;
    },
    reportsReceived: (misc, action) => {
      misc.reports = action.payload;
      misc.loading = false;
    },
    reportsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    mediaRequested: (misc, action) => {
      misc.loading = true;
    },
    mediaReceived: (misc, action) => {
      misc.media = action.payload;
      misc.loading = false;
    },
    mediaRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    likesRequested: (misc, action) => {
      misc.loading = true;
    },
    likesReceived: (misc, action) => {
      misc.likes = action.payload;
      misc.loading = false;
    },
    likesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    commentsRequested: (misc, action) => {
      misc.loading = true;
    },
    commentsReceived: (misc, action) => {
      misc.comments = action.payload;
      misc.loading = false;
    },
    commentsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    guestPostRequested: (misc, action) => {
      misc.loading = true;
    },
    guestPostReceived: (misc, action) => {
      misc.comments = action.payload;
      misc.loading = false;
    },
    guestPostRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    postRequested: (misc, action) => {
      misc.loading = true;
    },
    postReceived: (misc, action) => {
      misc.post = action.payload;
      misc.loading = false;
    },
    postRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    publicPostRequested: (misc, action) => {
      misc.loading = true;
    },
    publicPostReceived: (misc, action) => {
      misc.publicPost = action.payload;
      misc.loading = false;
    },
    publicPostFailed: (misc, action) => {
      misc.loading = false;
    },
    publicMediaRequested: (misc, action) => {
      misc.loading = true;
    },
    publicMediaReceived: (misc, action) => {
      misc.publicMedia = action.payload;
      misc.loading = false;
    },
    publicMediaFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  reportsRequested,
  reportsReceived,
  reportsRequestFailed,
  commentsReceived,
  commentsRequestFailed,
  commentsRequested,
  guestPostReceived,
  guestPostRequestFailed,
  guestPostRequested,
  mediaRequested,
  mediaReceived,
  mediaRequestFailed,
  likesRequested,
  likesReceived,
  likesRequestFailed,
  postReceived,
  postRequestFailed,
  postRequested,
  publicPostRequested,
  publicPostReceived,
  publicPostFailed,
  publicMediaRequested,
  publicMediaReceived,
  publicMediaFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "post";

export const getReports = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/reports/${id}`,
      method: "get",
      callback,
      onStart: reportsRequested.type,
      onSuccess: reportsReceived.type,
      onError: reportsRequestFailed.type,
    })
  );
};

export const getMedia = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/media`,
      method: "get",
      callback,
      onStart: mediaRequested.type,
      onSuccess: mediaReceived.type,
      onError: mediaRequestFailed.type,
    })
  );
};

export const getLiked = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/liked`,
      method: "get",
      callback,
      onStart: likesRequested.type,
      onSuccess: likesReceived.type,
      onError: likesRequestFailed.type,
    })
  );
};

export const getComments = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/${id}`,
      method: "get",
      callback,
      onStart: commentsRequested.type,
      onSuccess: commentsReceived.type,
      onError: commentsRequestFailed.type,
    })
  );
};

export const postComments = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};

export const likeUnlikePost = (type, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${type == "like" ? "like" : "unlike"}/${id}`,
      method: "post",
      callback,
    })
  );
};

export const updateComments =
  (postId, commentid, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/comment/${postId}/${commentid}`,
        method: "post",
        data,
        callback,
      })
    );
  };

export const deleteComments = (postId, commentid, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/${postId}/${commentid}`,
      method: "delete",
      callback,
    })
  );
};

export const postReports = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/report/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};
//API for post to get guest user
export const getPostFeedsGuestUser = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/feeds?latitude=${params.lat}&longitude=${params.long}`,
      method: "get",
      callback,
      onStart: guestPostRequested.type,
      onSuccess: guestPostReceived.type,
      onError: guestPostRequestFailed.type,
    })
  );
};
//View Post post/view/6542448a1b668832ebedfcb2
export const viewPost = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/view/${id}`,
      method: "post",
      callback,
    })
  );
};

//Send post Dm to users
export const sendPostDM = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/share/user/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};

//Report Comments
export const commentReports = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/report/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};
//Like unlike comment
export const likeUnlikeComment = (type, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/${type == "like" ? "like" : "unlike"}/${id}`,
      method: "post",
      callback,
    })
  );
};
//Send comment Dm to users
export const sendCommentDM = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/comment/share/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};
export const getCurrentPost = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "get",
      callback,
      onStart: postRequested.type,
      onSuccess: postReceived.type,
      onError: postRequestFailed.type,
    })
  );
};
//Get Public post
export const getPublicPost = (data,callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/publicPosts/${data}`,
      method: "get",
      callback,
      onStart: publicPostRequested.type,
      onSuccess: publicPostReceived.type,
      onError: publicPostFailed.type,
    })
  );
};
//Get Public Media
export const getPublicMedia = (data,callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/publicPosts/media/${data}`,
      method: "get",
      callback,
      onStart: publicMediaRequested.type,
      onSuccess: publicMediaReceived.type,
      onError: publicMediaFailed.type,
    })
  );
};

export const getPosts = createSelector(
  (state) => state.entities.post,
  (post) => post
);
