import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import Editor from "../../../common/editor/editor";

const AddReference = (props) => {
  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall media-upload-modal"
      show={props.show}
      enforceFocus={false}
      onHide={props.onHide}
      centered
    >
      <div className="modal-content d-flex flex-column position-relative">
        <div className="modal-header pb-0  pt-0 modal-head view-all-header">
          <h5 className="modal-title text-capitalize">{props.heading}</h5>
          <div>
            <button
              type="button"
              className="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
              onClick={() => props.onHide()}
            >
              <img src={close} alt="" className="modal-close-btn" />
            </button>
          </div>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 overflow-auto w-100 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="upload-section reference-add">
                  <Editor
                    id="textarea"
                    name="reference"
                    editorLoaded={true}
                    placeholder="Reference"
                    value={props.description}
                    className="w-100 mt-2"
                    onChange={(event) => {
                      props.setDescription(event.target.value);
                    }}
                    type="reference"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-5">
          <button className="btn btn-default" onClick={props.handleAdd}>
            Save
          </button>
          
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddReference;
