import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Correct location import
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { updateYourProfile } from "../../store/users"; // Assuming the action exists
import { getSpeciesDetails } from "../../store/species";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
import { createsponsor, createStory, getSubscription } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import SpeciesAndCategoryEditModal from "../../components/common/feedEditModals/speciesAndCategoryEditModal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const CreateStory = (props) => {
  const location = useLocation(); // To access location.state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    logo: null,
  });

  const [id, setId] = useState(null);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [follow, setFollow] = useState(false);
  const [speciesData, setSpeciesData] = useState(null);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [speciesCatData, setSpeciesCatData] = useState(null);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);
  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    if (location?.state?.id) {
      setId(location?.state?.id);
      const speciesId = location.state.id;
      fetchSpeciesData(speciesId);
    }
  }, [location]);

  const fetchSpeciesData = (speciesId) => {
    setLoadingDetail(true);
    props.getSpeciesDetails(speciesId, (res) => {
      if (res && res.status === 200) {
        setLoadingDetail(false);
        setSpeciesData(res?.data?.data);
      }
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file (logo) upload
  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, logo: e.target.files[0] }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingDetail(true);
    if (tempSelectSpeciesName?.length > 1) {
      toast(<AlertError message="You can select only one Species." />);
      return;
    }
    if (!location?.state?.id) {
      sessionStorage.setItem("tempSelectSpecies", JSON.stringify(tempSelectSpecies));
    }
    const { logo } = formData;
    const file = logo;
    const fSize = Math.round(file.size / 1048576); // Size in MB
    const fType = file.type;
    const ext = file.name.split(".").pop();

    if (fSize > 25) {
      toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
      return setLoadingDetail(false);
    } else if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpg, jpeg, png and webp." />,
      );
      return setLoadingDetail(false);
    } else {
      const fileName = uuidv4() + "." + ext;

      Storage.put(fileName, file, {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {
          setLoadingDetail(false);
          toast(<AlertError message={"Something Went Wrong"} />);
        },
      })
        .then((result) => {
          const payload = {
            logo: "public/" + result.key,
            name: formData?.name,
            description: formData?.description,
            return_url: location?.state?.id
              ? `${window.location.origin}/endangered-species/${speciesData?.slug}`
              : `${window.location.origin}/profile`,
            cancel_url: location?.state?.id
              ? `${window.location.origin}/endangered-species/${speciesData?.slug}`
              : `${window.location.origin}/profile`,
          };
          props.createStory(location?.state?.id ? id : tempSelectSpecies[0], payload, (res) => {
            if (res && res.status === 200) {
              setLoadingDetail(false);
              const redirectUrl = res.data.data?.url;
              if (redirectUrl) {
                window.location.href = redirectUrl;
              } else {
                toast(<AlertError message="No redirection URL provided" />);
              }
            } else {
              setLoadingDetail(false);
              toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
            }
          });
        })
        .catch((err) => {
          setLoadingDetail(false);
          toast(<AlertError message={"Something Went Wrong"} />);
        });
    }
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  return (
    <PageLayout>
      <div className="page-panel-container font-family-poppins sponsored-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Create A Story</h1>
            </div>
          </div>
          <div className="row g-4 g-lg-0">
            <div className="col-lg-6">
              <div className="sponsored-text-box">
                {location?.state?.id && (
                  <div className="sponsored-details d-flex flex-column flex-sm-row">
                    <div className="sd-img-box flex-shrink-0">
                      {speciesData?.coverImage?.original ? (
                        <img src={process.env.REACT_APP_MEDIA + speciesData?.coverImage?.original} alt="" />
                      ) : (
                        <img src={getDefaultImage(speciesData?.speciesCategoryName)} alt="" />
                      )}
                    </div>
                    <div className="sd-text-box flex-grow-1 text-capitalize">
                      <h2>{speciesData?.dangerLevelName}</h2>
                      <h3 className="d-flex">
                        {speciesData?.displayCommonName || speciesData?.scientificName}{" "}
                        <img src={process.env.REACT_APP_MEDIA + speciesData?.dangerLevelImage?.small} alt=""></img>
                      </h3>
                      <div className="badge-holder d-flex align-items-center flex-wrap">
                        {speciesData?.regions?.map((region, index) => (
                          <div key={index} className="badge green-badge">
                            {region.countryName === "United States" && region.state
                              ? `${region.state}, ${region.name}`
                              : region.name}
                          </div>
                        ))}
                        {speciesData?.regions?.length > 3 && (
                          <div className="badge green-badge">+{speciesData.regions.length - 3}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <p className="sponsor-content mt-2">
                  Environmental Inclusion has developed the Environmental Press Corp, a global network for stringer
                  reporters, who write stories about endangered animals.
                </p>
                <p>
                  The base cost is <strong>$600</strong> which is used to hire a reporter in the field and the editor to
                  produce a story, These stories are designed to expose each species challenges causing extinction,
                  local issues impacting the habitats and profile the environmental super hero's protecting them.{" "}
                </p>
                <p>
                  Give us your feedback, concerns and ideas and we will convey them to the reporter. This creates
                  original professional content, designed to bring awareness the challenges each species faces. Your
                  name and organization will be included next to the reporter, as the sponsor of the story. Please click
                  hear and dispatch a reporter.{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-6 ps-lg-5">
              <div className="sponsor-form-box position-relative">
                <div class="text-sponsor fw-bold d-flex justify-content-end">$600</div>
                <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={formData.name}
                        name="name"
                        onChange={handleInputChange}
                        placeholder="First name"
                      />
                      <label htmlFor="firstName">Name</label>
                    </div>
                  </div>
                  {/* <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        value={formData.email}
                        name="email"
                        onChange={handleInputChange}
                        placeholder="Email Address"
                      />
                      <label htmlFor="emailAddress">Email</label>
                    </div>
                  </div> */}
                </div>

                <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="text"
                        className="form-control"
                        value={formData.logo ? formData.logo.name : ""}
                        readOnly
                        onClick={() => document.getElementById("floatingfile").click()}
                        placeholder="Click here to upload a logo"
                      />
                      <label htmlFor="floatingfile">Upload Logo</label>

                      <input type="file" id="floatingfile" onChange={handleFileChange} style={{ display: "none" }} />

                      <button
                        type="button"
                        className="ff-change-link"
                        onClick={() => document.getElementById("floatingfile").click()}
                      >
                        Browse
                      </button>
                    </div>
                  </div>
                </div>

                {formData.logo && (
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="ffr-logo-area">
                        <img
                          src={URL.createObjectURL(formData.logo)}
                          alt="Uploaded Preview"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!location?.state?.id && (
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control"
                          value="Categories of Species"
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories();
                          }}
                          placeholder="Categories of Species"
                        />
                        {tempSelectSpeciesName?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              if (tempSelectSpeciesName?.length > 1) {
                                toast(<AlertError message="You can select only one species." />);
                              } else {
                                toggleSpeciesAndCategories();
                              }
                            }}
                          >
                            {`${tempSelectSpeciesName?.length} Selected`}
                          </button>
                        ) : (
                          <button type="button" className="ff-change-link" onClick={() => toggleSpeciesAndCategories()}>
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Describe any particular things that interest you regarding this species."
                        id="floatingTextarea2"
                        style={{ height: "115px" }}
                        value={formData.description}
                        name="description"
                        onChange={handleInputChange}
                      />
                      <label htmlFor="floatingTextarea2">Describe any particular things that interest you regarding this species.</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating-btn text-end">
                  <button type="submit" onClick={handleSubmit} className="btn btn-default btn-md">
                    Dispatch a Reporter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SpeciesAndCategoryEditModal
          show={showSpeciesAndCategories}
          onHide={toggleSpeciesAndCategories}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
          tempSelectSpecies={tempSelectSpecies}
          setTempSelectSpecies={setTempSelectSpecies}
          tempSelectSpeciesName={tempSelectSpeciesName}
          setTempSelectSpeciesName={setTempSelectSpeciesName}
          isPost={true}
          postType={props?.postType}
        />
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  createStory: (params, data, callback) => {
    dispatch(createStory(params, data, callback));
  },
});
const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateStory));
