import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getProgramDetails,
  organizationListing,
  getOrganizationDetails,
} from "../../store/organization";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { removeTags, capitalizeContentInBrackets } from "../../utils/helperFunctions";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination/pagination";
const Programs = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const details =
    props.organizationListing && props.organizationListing.programDetail;
  const [initialRender, setInitialRender] = useState(true); //
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true)
      props.getProgramDetails(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false)
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: 'smooth',
      });
    }
  };
  const pageLinks = Array.from({ length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) }, (_, i) => i + 1);
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <>
                {loading ? (
                  <ul class="blog-list blog-list-4 d-flex flex-wrap">
                    {Array.from({ length: 52 }, (item, index) => (
                      <li class="blog-item">
                        <Skeleton borderRadius={"10px"} height="280px" />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <ul class="blog-list blog-list-4 d-flex flex-wrap">
                      {details && details.data && details.data.length
                        ? details?.data.map((item) => {
                          return (
                            <li class="blog-item">
                              <Link
                                to={{
                                  pathname: `/organizations/${item?.slug
                                    }/program`,
                                  state: {
                                    id: props.id,
                                    name: props?.name,
                                    item: item,
                                  },
                                }}
                                class="blog-box blog-sm"
                              >
                                <div class="blog-box-image">

                                  {!imageError ? <img src={process.env.REACT_APP_MEDIA + item?.coverImage?.small} onError={handleImageError}
                                    alt="" /> : <img src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium} alt="" />}
                                </div>
                                <div class="blog-box-content">
                                  <div class="bbc-body">
                                    <h6>
                                      {dotDesc(
                                        capitalizeContentInBrackets(
                                          (item.name)
                                        ),
                                        55
                                      )}
                                    </h6>
                                    <p>
                                      {item.description
                                        ? dotDesc(
                                          HTMLReactParser(
                                            removeTags(item.description)
                                          ),
                                          330
                                        )
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div class="bbc-footer">
                                  <div class="bbc-footer-inner">
                                    <p>
                                      {moment(item && item.createdAt).format(
                                        "MMM DD, YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          );
                        })
                        : ""}

                    </ul>
                  </>
                )}
                {details && details?.totalCount > 52 && (<Pagination
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  pageLinks={pageLinks}
                  totalPages={pageLinks.length}
                />)}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) =>
    dispatch(getOrganizationDetails(params, callback)),
  getProgramDetails: (params, callback) =>
    dispatch(getProgramDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Programs));
