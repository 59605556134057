import React from "react";

import iconImage from "../../../include/images/Icon-image-01.jpg";
import cornerUpLeft from "../../../include/images/corner-up-left.svg";
import heart from "../../../include/images/heart.svg";
import wIcon from "../../../include/images/w-icon-1.svg";
import wIcon2 from "../../../include/images/w-icon-2.svg";
import wIcon3 from "../../../include/images/w-icon-3.svg";
import liked from "../../../include/images/fill-heart-icon.svg";
import wIcon4 from "../../../include/images/w-icon-4.svg";
import wIcon5 from "../../../include/images/w-icon-5.svg";
import { getRelativeTime } from "../../../utils/helperFunctions";
import { getProfile } from "../../../utils/localStorageServices";
import ImageHolder from "../../../common/customStyles/imageHolder";
import { Dropdown } from "react-bootstrap";
import editIcon from "../../../include/images/heart.svg";
import ReportComments from "./reportComments";
import { useState } from "react";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import { commentsReceived, getCurrentPost, getPosts, likeUnlikeComment, postReceived } from "../../../store/post";
import { connect } from "react-redux";
import DmPosts from "./dmPosts";

const CommentCard = (props) => {
  const profile = getProfile();
  const commentsData = props.getPosts && props.getPosts.post && props.getPosts.post.data ;
  const selectedItem = commentsData.comments.find((item)=>item._id == props.item._id )
  const initials = `${selectedItem?.createdBy?.firstName.charAt(0)}${selectedItem?.createdBy?.lastName.charAt(
    0,
  )}`.toUpperCase();
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const [showReport, setShowReport] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
 
  const [showDmPost, setShowDmPost] = useState(false);
  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const likeAndUnlikeComment = (type, id) => {
    const data = id;
    props?.likeUnlikeComment(type, data, (res) => {
      if (res && res?.status == 200) {
        props.getCurrentPost(props.id, (res1) => {
        })
      } else {
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  const toggleDmPosts = () => {
    setShowDmPost(!showDmPost);
  };
  return (
    <div class="fwm-widget">
      <div class="widget-outer">
        <div class="wo-widget">
          <div class="wo-body">
            <div class="widget d-flex flex-wrap">
              <div class="widget-icon">
                <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                  {selectedItem?.createdBy?.profilePicture ? (
                    <ImageHolder
                      image={selectedItem?.createdBy ? selectedItem?.createdBy?.profilePicture?.medium : profilePicture}
                      name={selectedItem?.name?.slice(0, 1)}
                    />
                  ) : (
                    <div className="profile-picture-name">{initials}</div>
                  )}
                </div>
              </div>
              <div class="widget-content">
                <div class="widget-text-holder">
                  <div class="w-heading-block position-relative">
                    <div class="w-heading-box d-flex align-items-center flex-wrap">
                      <div class="w-label-box fw-semibold text-capitalize">
                        {selectedItem?.createdBy
                          ? `${selectedItem?.createdBy.firstName} ${selectedItem?.createdBy.lastName}`
                          : ""}
                      </div>
                      <span class="w-dot-box"></span>
                      <div class="w-time-box d-flex align-items-center">
                        <span class="w-time-count"> {getRelativeTime(selectedItem?.createdAt)}</span>
                      </div>
                    </div>
                    <div class="w-h-button-box d-flex align-items-center justify-content-center">
                      <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
                        <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle ">
                          <img src={editIcon} alt="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                          <Dropdown.Item
                            className="dropdown-item delete"
                            onClick={() => {
                              setSelectedComment(selectedItem);
                              toggleShowReport();
                            }}
                            eventKey={"2"}
                          >
                            Report Comment
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div class="w-text-box">
                    <p>{selectedItem?.content}</p>
                  </div>
                  {selectedItem?.file && (
                    <div className="w-image-holder">
                      {selectedItem?.file?.toLowerCase().endsWith(".mp4") ? (
                        <video
                          controls
                          style={{
                            width: "100%",
                            height: "260px",
                            objectFit: "cover",
                          }}
                        >
                          <source src={process.env.REACT_APP_MEDIA + selectedItem?.file} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={process.env.REACT_APP_MEDIA + selectedItem?.file}
                          alt="w_image1"
                        />
                      )}
                    </div>
                  )}
                  <div class="w-iconlist d-flex flex-wrap align-items-center justify-content-between">
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div
                          class="w-iconl-box"
                          onClick={() => {
                            setSelectedComment(selectedItem);
                            toggleShowReport();
                          }}
                        >
                          <img src={wIcon} alt="w icon 1" />
                        </div>
                      </div>
                    </div>
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div
                          class="w-iconl-box"
                          onClick={() => {
                            likeAndUnlikeComment(selectedItem?.isLiked ? "unlike" : "like", selectedItem?._id);
                          }}
                        >
                          <img src={selectedItem?.isLiked ? liked : wIcon3} alt="w icon 3" />
                        </div>
                        <div class="w-textl-box">
                          {selectedItem?.commentLikeCount == 0 ? "" : selectedItem?.commentLikeCount}
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-iconlist-item"
                      onClick={(e) => {
                        toggleDmPosts();
                      }}
                    >
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div class="w-iconl-box">
                          <img src={wIcon5} alt="w icon 5" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportComments show={showReport} onHide={toggleShowReport} selectedItem={selectedComment} page="viewpost"  />
      <DmPosts show={showDmPost} onHide={toggleDmPosts} selectedItem={selectedItem} comments={true} page="viewpost" />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  likeUnlikeComment: (type, id, callback) => dispatch(likeUnlikeComment(type, id, callback)),
  commentsReceived: (payload) => dispatch(commentsReceived(payload)),
  postReceived: (payload) => dispatch(postReceived(payload)),
  getCurrentPost: (id, callback) => dispatch(getCurrentPost(id, callback)),
});
const mapStateToProps = (state) => ({
  getPosts: getPosts(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CommentCard));
