import React from "react";

const Pagination = (props) => {
    const { handlePageClick, currentPage, totalPages, pageLinks, setCurrentPage } = props;
    return (
        <div className="table-pagination">
            <ul className="pagination justify-content-end">
                <li className="page-item">
                    <a
                        className={currentPage === 1 ? "page-link disabled _cursor" : "page-link _cursor"}
                        aria-label="Previous"
                        onClick={() => {
                            handlePageClick(currentPage - 1)
                        }}
                    >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </a>
                </li>
                {pageLinks?.map((pageNumber) => (
                    <li key={pageNumber} className={pageNumber == currentPage ? "page-item active" : "page-item"}>
                        <a className="page-link _cursor" onClick={() => handlePageClick(pageNumber)}>
                            {pageNumber}
                        </a>
                    </li>
                ))}

                <li className="page-item">
                    <a
                        className={currentPage === totalPages ? "page-link disabled _cursor" : "page-link _cursor"}
                        aria-label="Next"
                        onClick={() => {
                            handlePageClick(currentPage + 1);
                        }}
                    >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
