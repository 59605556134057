import React, { useEffect, useRef, useState } from "react";
import ListingCardType from "../../common/listingCard/listingCardType2";
import {
  deleteScienceAndEducationReg,
  getScienceEducation,
  regionListings,
  updateScienceAndEducationReg,
} from "../../../store/region";
import { connect } from "react-redux";
import noRecord from "../../../include/images/nrb-img.svg";
import Pagination from "../../common/Pagination/pagination";
import { getScienceAndEducationList, sideBarApisListings } from "../../../store/sidebarApis";
import { extractIds, transformDropdownData } from "../../../utils/helperFunctions";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";

const ScienceEducation = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    science: "",
  });

  const scienceAndEducationDropdown = transformDropdownData(props.sideBarApisListings?.scienceAndEducation?.data || []);

  useEffect(() => {
    if (!props.sideBarApisListings?.scienceAndEducation?.data) {
      props.getScienceAndEducationList({}, (res) => {});
    }
  }, []);
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      science: selectedOptions,
    }));
  };
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getScienceEducation(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      // Set initial render flag to false after the first render
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const scienceEducationDetails =
    props.regionDetails && props.regionDetails.scienceAndEducation && props.regionDetails.scienceAndEducation;
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((scienceEducationDetails?.totalCount ?? 0) / (scienceEducationDetails?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const addMore = () => {
    setLoading(true);
    let payload = {
      scienceAndEducationIds: extractIds(selectedPreference?.science),
    };
    props.updateScienceAndEducation(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          science: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getScienceEducation(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      scienceAndEducationIds: [ids],
    };
    props.deleteScienceAndEducation(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getScienceEducation(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            {props.editMode && (
              <div class="d-flex flex-wrap justify-content-end">
                <div class="d-flex flex-wrap justify-content-end w-75 mb-4">
                  <Select
                    className="basic-single w-50 text-capitalize"
                    classNamePrefix="select-search"
                    isMulti
                    placeholder="Select"
                    styles={customStyles1}
                    options={scienceAndEducationDropdown}
                    value={selectedPreference.science}
                    onChange={handleSelectChange}
                  />
                  <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                    Save
                  </Link>
                </div>
              </div>
            )}
            {scienceEducationDetails && scienceEducationDetails?.data.length == 0 && (
              <ul class="ei-grid-list d-flex flex-wrap justify-content-center">
                <div class="no-records-container d-flex align-items-center justify-content-center">
                  <div class="no-records-box text-center">
                    <div class="nrb-image">
                      <img src={noRecord} alt="" />
                    </div>
                    <h6>No Records Found</h6>
                  </div>
                </div>
              </ul>
            )}
            <ul class="ei-grid-list d-flex flex-wrap">
              {scienceEducationDetails &&
                scienceEducationDetails?.data.length > 0 &&
                scienceEducationDetails?.data.map((item) => (
                  <ListingCardType
                    loading={loading}
                    name={item.name}
                    img={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                    img1={process.env.REACT_APP_MEDIA + item?.coverImage?.large}
                    page="Science And Education"
                    id={item?._id}
                    slug={item?.slug}
                    editMode={props.editMode}
                    handleDelete={handleDelete}
                  />
                ))}
            </ul>
            {scienceEducationDetails && scienceEducationDetails?.totalCount > 52 && (
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getScienceEducation: (params, callback) => dispatch(getScienceEducation(params, callback)),
  getScienceAndEducationList: (params, callback) => dispatch(getScienceAndEducationList(params, callback)),
  updateScienceAndEducation: (params, data, callback) => dispatch(updateScienceAndEducationReg(params, data, callback)),
  deleteScienceAndEducation: (params, data, callback) => dispatch(deleteScienceAndEducationReg(params, data, callback)),
});

const mapStateToProps = (state) => ({
  regionDetails: regionListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ScienceEducation));
