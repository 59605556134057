import React, { useState } from "react";
import heroImg1 from "../include/images/page-hero-1.png";
import heroImg2 from "../include/images/page-hero-2.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import intro from "../include/images/page-intro-img.png";
import pageCard1 from "../include/images/page-card-img-1.png";
import pageCard2 from "../include/images/page-card-img-2.png";
import pageCard3 from "../include/images/page-card-img-3.png";
import pageCard4 from "../include/images/page-card-img-4.png";
import pageCard5 from "../include/images/page-card-img-6.png";
import pageCard6 from "../include/images/page-card-img-7.png";
import pageCard7 from "../include/images/page-card-img-8.png";
import pageCard8 from "../include/images/page-card-img-9.png";
import ricImage from "../include/images/ric-img.jpg";
import shape1 from "../include/images/shape-01.png";
import shape2 from "../include/images/shape-bg-page.jpg";
import ptcImg1 from "../include/images/ptc-img-1.png";
import comingSoonBg from "../include/images/cooming-soon-bg.jpg";
import eggImg from "../include/images/egg-0.png";
import logo from "../include/images/logo.svg";
import TourModal from "../components/common/tourModal/tourModal";
import ReactPlayer from "react-player";
import useCheckMobileScreen from "../common/customHooks/useCheckMobileScreen";

const HomeIndex = () => {
  const [showTourModal, setShowTourModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  return (
    <div className="page-holder-wrapper font-poppins bg-gray">
      <header id="header">
        <nav className="navbar navbar-expand">
          <div className="container pt-4 pb-4">
            <div className="nav-inside d-flex align-items-center justify-content-between">
              <Link to="/" className="navbar-brand">
                <img style={isMobile ? {} : { height: "50px", maxWidth: "none" }} src={logo} alt="Logo" />
              </Link>
              <div className="nav-item">
                <a
                  href="/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`btn btn-default cursor-pointer ${isMobile?"splash-login-btn":""}`}
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className={`page-hero-container position-relative overflow-hidden ${isMobile?"pb-2":""}`}>
        <img className="page-hero-img-1 d-none d-lg-block" src={heroImg1} alt="" />
        <img className="page-hero-img-2 d-none d-lg-block" src={heroImg2} alt="" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-10 col-xl-7 mx-auto">
              <div className="page-hero-content text-center mx-auto">
                <h5>Help Save the Worlds Endangered Species</h5>
                <p>
                  {" "}
                  Environmental Inclusion is a global environmental platform for environmental scientists, biologists,
                  educators, repurposing professionals, volunteers, environmental journalists, activists, non-profits
                  and impact investors go to collaborate ideas, information, education programs, socialize, to develop
                  real time solutions. The mission is simple, to save as many endangered animals as possible.
                </p>
                <div className="page-hero-btn-wrap d-flex flex-wrap align-items-sm-center justify-content-sm-center">
                  <div className="phbw-item">
                    <a href="/signup?type=normal" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                      Register for the Launch
                    </a>
                  </div>
                  <div className="phbw-item">
                    <a
                      href="/signup?type=volunteer"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-dark-green"
                    >
                      Register as Volunteer & Interns
                    </a>
                  </div>
                  <div className="phbw-item">
                    <a
                      href="/signup?type=impactInvestor"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-green"
                    >
                      Register as Impact Investor
                    </a>
                  </div>
                  <div className="phbw-item">
                    <a
                      href="/signup?type=journalist"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-green"
                    >
                      Register for Environmental Press Corp
                    </a>
                  </div>
                  <div className="phbw-item">
                    <a
                      href="/signup?type=scientistAndEducator"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-dark-green"
                    >
                      Register as Scientist & Educator
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-intro-container pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 home-page-intro1">
              <div className="page-intro-image">
                <iframe
                  src="https://www.youtube.com/embed/GvDKvXoU7dA"
                  title="Salvex EI"
                  width="100%"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6 home-page-intro2">
              <div className="page-inro-txt-wrap w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="page-inro-text">
                  <h4 className="page-title custom-page-title">About Environmental Inclusion</h4>
                  <p>
                    Environmental Inclusion is the first global website dedicated to saving endangered animals. Our
                    mission is straightforward: to rescue as many endangered animals as possible worldwide. We are also
                    committed to raising awareness about the threats these animals face.
                  </p>
                  <div className="page-btn">
                    <Link
                      onClick={() => {
                        setShowTourModal(true);
                      }}
                      className="btn btn-default btn-xs"
                    >
                      Take a Tour
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-card-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <ul className="page-card-list">
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Endangered Species</h4>
                      <p>
                        Over 16,000 species of endangered animals worldwide are at risk of extinction. In order to save
                        these animals we need to support that thousands of scientists, biologists, activists,
                        volunteers, and non-profit people who are on the front lines fighting the war on the
                        environment.
                      </p>
                      <p>
                        Pick an animal and save it, is our motto. There are many things you can do to get involved and
                        Environmental Inclusion is a good place to start.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Volunteer for Species
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard1} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Global Regions</h4>
                      <p>
                        We work in all regions of the world identifying endangered species and the habitats they live.
                        When you help the endangered animals, you always help other animals who may soon be threatened
                        by habitat depletion. Look for animal in your region and pick an animal in your region and save
                        it.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Volunteer for Regions
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard2} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Environmental Organizations & Non-Profits</h4>
                      <p>
                        Environmental Inclusion focuses on which organizations work directly in the field, helping the
                        endangered species, and fighting on the front lines to save animals from extinction. These
                        people represent the last line of defense from complete extinction for many species.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Register for Organizations
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard3} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Scientists, Biologists, and Environmental Heroes</h4>
                      <p>
                        These people are environmental super heroes who put in thousands of hours of research and
                        conservation work. Their educatoin and sacrifice is what the drives all the knowledge required
                        to make educated conservation decisions.
                      </p>
                      <p>
                        We appreciate their papers, videos, research and educational materials as a collaborative
                        scientific bodies of work that will save the planet one battle at a time.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs "
                          style={{ lineHeight: "normal" }}
                        >
                          Register for Scientists, Biologists, or Environmentalists
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard4} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Zoos & Wildlife Reserves</h4>
                      <p>
                        Animals need a place to live and keeping a healthy population is the goal of all conservation
                        efforts. The people who manage and run these oases of habitat need critical resources that are
                        custom to every situation.
                      </p>
                      <p>
                        Commercial ingress, developers, pollutions, poachers, feed costs, testing and land management
                        are examples of challenges zoos and wildlife reserve are faced with every day. All global
                        regions have pockets of critical habitats that desperately need conservation resources to carry
                        out strategic programs.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Register for Zoos & Wildlife Reserves
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard5} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">
                        Environmental Press Corp fights for the animals in a war against the environment
                      </h4>
                      <p>
                        Environmental Inclusion has created an Environmental Press Corp that is built by experiences
                        journalist who organize global stringer reports to write and video in dept journalism creating
                        new content and updated status of where we are with many important environmental topics. In
                        order to best understand where resources need to be allocated, we need to better understand the
                        threats posed by climate change, industrialization, pollution, poaching, disease, and many other
                        factors. We consider the situation as War on the Environment and the best way expose these
                        atrocities is to have journalists write investigative stories about the many desperate
                        situations.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=journalist"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                          style={{ lineHeight: "normal" }}
                        >
                          Register as Link Journalist to join Environmental Press Corp
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard6} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Environmental Educational Programs</h4>
                      <p>
                        All great environmental movements need public support and participation, which is based on
                        science and awareness. All ages are welcome to participate in education programs K-12 to teach
                        kids to pick and animal and save it.
                      </p>
                      <p>They can participate through research, study, essays and animal art any age can publish.</p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=normal"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Publish your research, art and essays
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard7} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="page-card-item">
                  <div className="page-card-row d-flex flex-wrap align-items-center">
                    <div className="page-card-text">
                      <h4 className="page-title">Volunteer and Interns</h4>
                      <p>
                        Volunteers and Interns are the driving force behind content input and management of
                        Environmental Inclusion. College students can study scientific information, new papers, videos,
                        life feeds, and threats on the environment for each endangered species. Environmental students,
                        teachers, classes, and departments can apply be develop a program that will add to the movement
                        on species level and publishing their work and ideas.
                      </p>
                      <div className="page-btn">
                        <a
                          href="/signup?type=volunteer"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-default btn-xs"
                        >
                          Register as Volunteer/Interns
                        </a>
                      </div>
                    </div>
                    <div className="page-card-image">
                      <div className="page-card-img">
                        <img src={pageCard8} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="right-image-container position-relative overflow-hidden">
        <div className="ric-image-box">
          <div className="ric-image w-100 h-100">
            <img className="w-100 h-100 object-fit-cover" src={ricImage} alt="ricImg" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="ric-box d-flex flex-wrap align-items-center">
                <div className="ric-content-box">
                  <h6 className="text-uppercase">Become the solution not the problem</h6>
                  <h4>Impact Investor</h4>
                  <p>
                    Environmental Inclusion was launched from funds raised through reproposing of donated commercial
                    assets. Environmental Inclusion is not a non-profit, so we do not accept tax deductions directly,
                    but help recommend organizations that are having the greatest impact to receive cash donations. We
                    do consider funds from impact investors who want to see different programs succeed and be a part of
                    the first environmental platform designed to save endangered animals through collaboration,
                    information, education and action.
                  </p>
                  <div className="ric-btn">
                    <a
                      href="/signup?type=impactInvestor"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-dark-green btn-xs"
                    >
                      Register as Impact Investor
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="left-image-container position-relative overflow-hidden">
        <div className="lic-image-box">
          <div className="lic-image-hold">
            <img className="shape-1-bg" src={shape1} alt="" />
            <img className="shape-2-bg" src={shape2} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="lic-box d-flex flex-wrap align-items-center justify-content-end">
                <div className="lic-content-box">
                  <h6 className="text-uppercase">Intern program</h6>
                  <h4>Social Media</h4>
                  <p>
                    Environmental Inclusion is designed to be a serious place to discuss science and education as the
                    main focus. We are also inclusive to anyone who wants to show pictures, share experiences, offer
                    opinions and spend time thinkings about global problems that exist. The social interactions create
                    interest, that can lead to activism and then to action which is desperately needed. They programs
                    created by so many environmentalists require long term solutions, so introducing the issues to our
                    young people is an investment critical to the success of ALL long-term solutions.
                  </p>
                  <div className="page-btn">
                    <a
                      href="/signup?type=volunteer"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-default btn-xs"
                    >
                      Register as Volunteer/Interns
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-text-container position-relative overflow-hidden">
        <div className="container position-relative z-1">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="ptc-box d-flex flex-wrap align-items-center">
                <div className="ptc-content-box">
                  <h6 className="text-uppercase">Save Animals</h6>
                  <h4>Donate Commercial Assets to Save Animals</h4>
                  <p>
                    The Salvex.Com Platform Is Designed To Buy, Sell, And Repurpose Commercial Assets For Corporations.
                    In Partnership With Environmental Inclusion, Companies Can Now Donate Surplus Assets, Old
                    Inventories And Used Equipment To Endangered Animals.
                  </p>
                  {/* <div className="ptc-btn">
                    <a href="#" className="btn btn-dark-green btn-xs">
                      Post an Asset to Donate
                    </a>
                  </div> */}
                </div>
                <div className="ptc-image-box">
                  <div className="ptc-image">
                    <img className="w-100 h-100 object-fit-cover" src={ptcImg1} alt="ptcImg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-coming-soon-container overflow-hidden" style={{ backgroundImage: `url(${comingSoonBg})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="pcsc-row d-flex flex-column flex-lg-row flex-wrap">
                <div className="pcsc-text-box">
                  <div className="pcsc-inside">
                    <h4>Coming Soon</h4>
                    <p>
                      Environmental Inclusion is a platform for Scientists, Journalists, Educators, Volunteers, Impact
                      Investors, Non-profits, and Enthusiasts to collaborate on the world's first global environmental
                      platform designed to save endangered animals.
                    </p>
                    <p>
                      <strong>on Earth Day 2025 (April 22nd, 2025)</strong>
                    </p>
                    <div className="pcsc-btn">
                      <a
                        href="/signup?type=normal"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-green-white btn-xs"
                      >
                        Sign Up for the Launch
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pcsc-img-box">
                  <div className="eggie-img">
                    <img src={eggImg} alt="Egg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer2" className={`d-flex align-items-center justify-content-center ${isMobile?"mt-2":""}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-md-flex align-items-md-center justify-content-md-center">
              <ul className="footer-nav-list d-flex justify-content-center justify-content-md-start align-items-center">
                <li>
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
              <div className="copyright-box">
                <img src="include/images/copyright.svg" alt="" />
                &copy; Environmental Inclusion. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
      <TourModal show={showTourModal} onHide={() => setShowTourModal(false)} />
    </div>
  );
};

export default HomeIndex;
