import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import trash from "../../../include/images/trash-24x24.svg";
const ListingCardType = (props) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return props.loading ? (
    Array.from({ length: 10 }, (_, index) => (
      <li class="ei-grid-item">
        <div class="ei-grid-box">
          <Skeleton borderRadius={"10px"} height="270px" />
        </div>
      </li>
    ))
  ) : props.page == "Science And Education" ? (
    <li class="ei-grid-item position-relative">
      {props.editMode &&
        <button
          type="button"
          class="trash-button-box z-1"
          onClick={(e) => {
            props?.handleDelete(props?.id)
          }}
        >
          <img src={trash} alt="w volumne" />
        </button>
      }
      <Link
        class="ei-grid-box"
        to={{
          pathname: `/science-education/${props?.slug}`,
          state: { id: props?.id },
        }}
      >
        <div class="ei-grid-box-image">
          {!imageError ? <img src={props.img} onError={handleImageError}
            alt="" /> : <img src={props.img1} alt="" />}
        </div>
        <div class="ei-grid-box-overlay text-transfrom ">
          <p>{`${capitalizeContentInBrackets(props.name)}`}</p>
        </div>
      </Link>
    </li>
  ) : (
    <li class="ei-grid-item">
      <Link
        className="ei-grid-box pointer-event-none"
        style={{ cursor: "default" }}
      >
        <div class="ei-grid-box-image">
          {!imageError ? <img src={props.img} onError={handleImageError}
            alt="" /> : <img src={props.img1} alt="" />}
        </div>
        <div class="ei-grid-box-overlay text-transfrom ">
          <p>{`${capitalizeContentInBrackets(props.name)}`}</p>
        </div>
      </Link>
    </li>
  );
};

export default ListingCardType;
