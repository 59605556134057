import React, { useEffect, useState } from "react";
import logo from "../../include/images/logo.svg";
import leftArrow from "../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../include/images/pagination-right-arrow-green.svg";
import logoutImg from "../../include/images/log-out__header.svg";
import user from "../../include/images/user__header.svg";
import noRecord from "../../include/images/nrb-img.svg";
import { Link, useHistory } from "react-router-dom";
import { getProfileInfo, getUser, userLogout } from "../../store/users";
import { connect } from "react-redux";
import { getProfile, logout, removeKey, setProfile } from "../../utils/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { Dropdown } from "react-bootstrap";
import { getMisc, getSetting, searchAll } from "../../store/misc";
import PageInfoSidebar from "../common/pageInfoSidebar/pageInfoSidebar";
import { getNews, getZoo, regionListings } from "../../store/region";
import { getSpeciesCategory, speciesListings } from "../../store/species";
import { blogsListings, getBlogs } from "../../store/blog";
import { newsListings } from "../../store/news";
import { getAllZoo, zooListings } from "../../store/zoo";
import { getRegionList, sideBarApisListings } from "../../store/sidebarApis";
import SearchListCard from "../common/listingCard/searchListCard";
import searchClose from "../../include/images/search-close-18x18.svg";
import GoogleTranslateWidget from "../../common/googleTranslate";
import ShowRegModal from "../common/showMoreModal/showRegModal";
import useCheckMediumScreen from "../../common/customHooks/useCheckForMediumScreen";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";

const Header = (props) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const isLoggedIn = !!localStorage.getItem("data");
  const [page, setPage] = useState(1);
  const USRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country === "US");
  const otherRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country !== "US");
  const blogCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.blogCategoryList &&
    props?.sideBarApisListings?.blogCategoryList.data;
  // const [blogCategory, setBlogCategory] = useState([]); scienceAndEducationCategory
  const newsCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.newsCategoryList &&
    props?.sideBarApisListings?.newsCategoryList.data;
  const speciesCategories =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.speciesCategory &&
    props?.sideBarApisListings?.speciesCategory.data;
  const scienceAndEducationCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.scienceAndEducationCategory &&
    props?.sideBarApisListings?.scienceAndEducationCategory.data;
  const organizationList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.organizationList &&
    props?.sideBarApisListings?.organizationList.data;
  const environmentalistList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.environmentalist &&
    props?.sideBarApisListings?.environmentalist.data;
  const zooAndParksList =
    props?.sideBarApisListings && props?.sideBarApisListings?.zoo && props?.sideBarApisListings?.zoo.data;
  const wetmarketList =
    props?.sideBarApisListings && props?.sideBarApisListings?.wetMarket && props?.sideBarApisListings?.wetMarket.data;
  const details = props?.misc && props?.misc?.searchAllData && props?.misc?.searchAllData?.data;
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const [detail, setDetail] = useState("");
  const [loadingMain, setLoadingMain] = useState(false);
  const [textSearched, setTextSearched] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showRegModal, setShoweRegModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const isMediumScreen = useCheckMediumScreen();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");

  // Find the index of "search" in the path segments
  const searchIndex = pathSegments.indexOf("search");

  useEffect(() => {
    if (searchIndex !== -1) {
      const lastElement = pathSegments[pathSegments.length - 1];
      props.setIsTextSearched(true);
      setTextSearched(lastElement);
    }
  }, [currentUrl]);
  useEffect(() => {
    if (!props?.sideBarApisListings?.regionList.data) {
      props.getRegionList({}, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
    if (isLoggedIn) {
      props.getProfileInfo((res) => {
        if (res && res.status === 200) {
          let tempData = { ...profile };
          tempData.profile = res?.data?.data;
          setProfile(tempData);
        }
      });
    }
  }, []);
  // Step 3: Create a function to toggle the modal visibility
  const toggleModal = () => {
    setShoweRegModal(!showRegModal);
  };
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };
  const handleLogout = () => {
    props.userLogout((res) => {
      if (res && res.status === 200) {
        const logoutUrl = logout();
        if (logoutUrl) {
          removeKey("logoutUrl");
          window.location.assign(`${logoutUrl}`);
        } else {
          history.push("/login");
        }
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const profile = getProfile();
  const profilePic = profile?.profile?.profilePicture?.original;
  useEffect(() => {
    if (!textSearched && typeof props.setIsTextSearched === "function") {
      props.setIsTextSearched(false);
    }
  }, [textSearched, props.setIsTextSearched]);
  const searchText = () => {
    const payload = textSearched
      ? {
          keyword: textSearched,
          page: page,
        }
      : "";
    props.setIsTextSearched(true);
    setLoadingMain(true);
    if (textSearched) {
      props.searchAll(payload, (res) => {
        if (res && res?.status === 200) {
          history.push(`/search/${textSearched}`);
          let tempDetail;
          if (selectedCategory == "Species") {
            tempDetail = res.data.data?.filter((item) => item.type == "species");
          } else if (selectedCategory == "Region") {
            tempDetail = res.data.data?.filter((item) => item.type == "region");
          } else if (selectedCategory == "Organization") {
            tempDetail = res.data.data?.filter((item) => item.type == "organization");
          } else if (selectedCategory == "Zoos & Wildlife Reserves") {
            tempDetail = res.data.data?.filter((item) => item.type == "zoo wetMarket");
          } else if (selectedCategory == "War on the Environment- Threats") {
            tempDetail = res.data.data?.filter((item) => item.type == "wetMarket");
          } else {
            tempDetail = res.data.data;
          }
          setDetail(tempDetail);

          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  };

  const textRemove = () => {
    props.setIsTextSearched(false);
    setTextSearched("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchText();
    }
  };

  const handleSearchTab = () => {
    props.setIsTextSearched(false);
  };
  const totalPages = (props?.misc?.searchAllData && props?.misc?.searchAllData?.totalCount) || 0;

  const dataPerPage = (props?.misc?.searchAllData && props?.misc?.searchAllData?.recordsPerPage) || 0;

  const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;
  return (
    <>
      <header id="header" className="header-ei">
        <nav class="navbar navbar-expand-xl">
          <div class="container">
            <div class="nav-inside d-flex align-items-center justify-content-between">
              <Link to="/home" class="navbar-brand">
                <img src={logo} alt="" />
              </Link>
              <button
                class={showMenu ? "navbar-toggler" : "navbar-toggler collapsed"}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNav"
                aria-controls="mainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setShowMenu(!showMenu)}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class={
                  showMenu
                    ? "collapse navbar-collapse justify-content-end show "
                    : "collapse navbar-collapse justify-content-end"
                }
                id="mainNav"
              >
                <div class="navbar-inside ml-auto">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link
                        to="/home"
                        className={`nav-link ${history?.location.pathname == "/home" ? "active-nav" : ""}`}
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        Home
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to="/about"
                        className={`nav-link ${history?.location.pathname == "/about" ? "active-nav" : ""}`}
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        About Us
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to="/endangered-species"
                        className={`nav-link ${
                          history?.location.pathname == "/endangered-species" ? "active-nav" : ""
                        }`}
                      >
                        Endangered Species
                      </Link>
                    </li>
                    <li class="nav-item">
                      {isMobile ? (
                        <Link
                          className={`nav-link ${history?.location.pathname == "/regions" ? "active-nav" : ""}`}
                          to="/regions"
                        >
                          Regions
                        </Link>
                      ) : (
                        <Link
                          className={`nav-link ${history?.location.pathname == "/regions" ? "active-nav" : ""}`}
                          onClick={toggleModal}
                        >
                          Regions
                        </Link>
                      )}
                    </li>
                    <li class="nav-item">
                      <Link
                        to="/organizations"
                        className={`nav-link ${history?.location.pathname == "/organizations" ? "active-nav" : ""}`}
                      >
                        Organizations
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to="/live-feeds"
                        className={`nav-link ${history?.location.pathname == "/live-feeds" ? "active-nav" : ""}`}
                      >
                        Live Feeds
                      </Link>
                    </li>
                    {!isMediumScreen && (
                      <li className=" nav-item view-all-btn " style={{ lineHeight: "1.2" }}>
                        {!isLoggedIn && (
                          <Link to="/login" className="lg-btn cursor-pointer">
                            Login
                          </Link>
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li className=" nav-item">
                        {!isLoggedIn && (
                          <Link
                            to="/login"
                            className={`nav-link ${history?.location.pathname == "/login" ? "active-nav" : ""}`}
                          >
                            Login
                          </Link>
                        )}
                      </li>
                    )}
                    {isMediumScreen && (
                      <li className=" nav-item">
                        <Link to={isLoggedIn ? "/volunteer" : "/signup?type=volunteer"} className={`nav-link`}>
                          {isLoggedIn && profile?.profile?.isVolunteer ? "Update Volunteer" : "Volunteer"}
                        </Link>
                      </li>
                    )}
                    {isMediumScreen && (
                      <li className=" nav-item">
                        <div class="nav-link">
                          <GoogleTranslateWidget />
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                {!isLoggedIn && !isMediumScreen && (
                  <div class="header-right d-none d-md-block me-xl-2">
                    <Link to="/register/user-type" class="btn btn-default header-btn cursor-pointer">
                      Sign Up
                    </Link>
                  </div>
                )}
                {!isLoggedIn && (
                  <div class="header-right d-block contact-now-display d-lg-none contact-us-sticky">
                    <Link to="/register/user-type" class="btn btn-block btn-dark-green contact-now-btn">
                      Sign Up
                    </Link>
                  </div>
                )}
                {!isMediumScreen && (
                  <a
                    href={isLoggedIn ? "/volunteer" : "/signup?type=volunteer"}
                    className={`btn btn-default btn-volunteer header-btn cursor-pointer ${isLoggedIn ? "me-xl-2" : ""}`}
                  >
                    {isLoggedIn && profile?.profile?.isVolunteer ? "Update Volunteer" : "Volunteer"}
                  </a>
                )}
                {!isMediumScreen && isLoggedIn && (
                  <div class="header-right">
                    <Dropdown className="header-menu-area dropdown">
                      <Dropdown.Toggle
                        as={"a"}
                        className="dropdown-toggle d-flex align-items-center"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div class="header-profile-img d-flex align-items-center justify-content-center">
                          <img src={process.env.REACT_APP_MEDIA + profilePic} alt="profilePic" />
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                        <Dropdown.Item className="dropdown-menu-items">
                          <Link to="/profile" className="dropdown-item d-flex align-items-center">
                            <div className="dropdown-item-icon d-flex align-items-center justify-content-center">
                              <img src={user} alt="user" />
                            </div>
                            <div className="dropdown-item-text">Profile</div>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-menu-items" onClick={handleLogout}>
                          <Link class="dropdown-item d-flex align-items-center">
                            <div class="dropdown-item-icon d-flex align-items-center justify-content-center">
                              <img src={logoutImg} alt="logout" />
                            </div>
                            <div class="dropdown-item-text">Logout</div>
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              {isMediumScreen && !showMenu && isLoggedIn && (
                <div class="header-right mt-2 me-4">
                  <Dropdown className="header-menu-area dropdown me-3">
                    <Dropdown.Toggle
                      as={"a"}
                      className="dropdown-toggle d-flex align-items-center"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div class="header-profile-img d-flex align-items-center justify-content-center">
                        <img src={process.env.REACT_APP_MEDIA + profilePic} alt="profilePic" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                      <Dropdown.Item className="dropdown-menu-items">
                        <Link to="/profile" className="dropdown-item d-flex align-items-center">
                          <div className="dropdown-item-icon d-flex align-items-center justify-content-center">
                            <img src={user} alt="user" />
                          </div>
                          <div className="dropdown-item-text">Profile</div>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-menu-items" onClick={handleLogout}>
                        <Link class="dropdown-item d-flex align-items-center">
                          <div class="dropdown-item-icon d-flex align-items-center justify-content-center">
                            <img src={logoutImg} alt="logout" />
                          </div>
                          <div class="dropdown-item-text">Logout</div>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
      <div className="full-width-search-row sticky-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flex-grow-1 dropdown position-relative">
                <div className="fws-search-box position-relative w-100 d-flex align-items-center">
                  <div className="input-group header-input">
                    {!isMobile && (
                      <div className="header-search-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.6">
                            <path
                              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.5 17.5L13.875 13.875"
                              stroke="black"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>
                      </div>
                    )}
                    <input
                      type="text"
                      className="form-control fws-search-input"
                      placeholder={isMobile ? "Search" : "Search Species, Regions, Organizations etc"}
                      onChange={(e) => setTextSearched(e.target.value)}
                      value={textSearched}
                      onKeyPress={handleKeyPress}
                    />
                    {textSearched && (
                      <button
                        type="button"
                        className="modal-search-close-icon header-search-close-icon"
                        onClick={() => textRemove()}
                      >
                        <img src={searchClose} alt="alt close" />
                      </button>
                    )}
                  </div>
                  <div className="search-category-filter">
                    <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer header-category-dropdown">
                      <Dropdown.Toggle variant="secondary" id="categoryDropdown">
                        {selectedCategory || "All"} {selectedCategory == "All" && <span class="chevron"></span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-light">
                        <Dropdown.Item onClick={() => handleCategorySelect("All")}>All Categories</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCategorySelect("Species")}>Species</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCategorySelect("Region")}>Regions</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCategorySelect("Organization")}>
                          Organizations
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCategorySelect("Zoos & Wildlife Reserves")}>
                          Zoos & Wildlife Reserves
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCategorySelect("War on the Environment- Threats")}>
                          War on the Environment - Threats
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {!isMobile && (
                    <button onClick={() => searchText()} className="btn btn-light btn-search-header">
                      Search
                    </button>
                  )}
                  {isMobile && (
                    <button onClick={() => searchText()} className="btn btn-light btn-search-header-icon">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                          <path
                            d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17.5 17.5L13.875 13.875"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                    </button>
                  )}
                  {!isMediumScreen && (
                    <div class="ml-10 position-relative google-element" style={{ right: "0px" }}>
                      <GoogleTranslateWidget />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props?.textSearched && (
        <div>
          <div class="content-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="ei-category-container d-flex flex-wrap">
                    <div class="ei-category-left">
                      <PageInfoSidebar
                        page={page}
                        setPage={setPage}
                        USRegions={USRegion}
                        otherRegion={otherRegion}
                        blogCategories={blogCategory}
                        newsCategory={newsCategory}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        speciesCategories={speciesCategories}
                        zooAndParksList={zooAndParksList}
                        organizationList={organizationList}
                        environmentalistList={environmentalistList}
                        wetmarketList={wetmarketList}
                        scienceAndEducationCategory={scienceAndEducationCategory}
                        selectSpeciesCategory={selectSpeciesCategory}
                        setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                        setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                        selectSpeciesCategoryName={selectSpeciesCategoryName}
                      />
                    </div>
                    <div class="ei-category-right">
                      <div class="ei-category-details">
                        <>
                          <>
                            <div class="ei-heading">
                              <h4>
                                Showing results for "{textSearched}" ({totalPages})
                              </h4>
                            </div>
                            {detail?.length !== 0 && (
                              <ul class="ei-grid-list d-flex flex-wrap">
                                {loadingMain
                                  ? Array.from({ length: 52 }, (item, index) => (
                                      <SearchListCard
                                        img={item?.coverImage?.medium}
                                        name={item?.name}
                                        id={item?._id}
                                        loading={loadingMain}
                                        slug={item?.slug}
                                        type={item?.type}
                                      />
                                    ))
                                  : detail &&
                                    detail &&
                                    detail.length > 0 &&
                                    detail.map((item, idx) => (
                                      <SearchListCard
                                        img={item?.coverImage?.medium}
                                        name={item.name}
                                        page={"home"}
                                        id={item._id}
                                        loading={loadingMain}
                                        slug={item?.slug}
                                        type={item?.type}
                                        handleClick={handleSearchTab}
                                      />
                                    ))}
                              </ul>
                            )}
                          </>
                          {detail?.length === 0 && !loadingMain ? (
                            <ul class="blog-list d-flex flex-wrap justify-content-center">
                              <div class="no-records-container d-flex align-items-center justify-content-center">
                                <div class="no-records-box text-center">
                                  <div class="nrb-image">
                                    <img src={noRecord} alt="" />
                                  </div>
                                  <h6>No Records Found</h6>
                                </div>
                              </div>
                            </ul>
                          ) : (
                            <>
                              {detail && noOfPage > 1 && (
                                <div class="pagination-row d-flex justify-content-between">
                                  <div class="pagination-arrow">
                                    <Link
                                      class={`pagination-arrow-box d-flex align-items-center  ${
                                        page === 1 && "no-pointer"
                                      }`}
                                      onClick={() => {
                                        if (page > 1) {
                                          setPage(page - 1);
                                        }
                                      }}
                                    >
                                      <img src={page === 1 ? leftArrow : leftArrowGreen} alt="" />
                                      <span className="hover-link">Previous</span>
                                    </Link>
                                  </div>
                                  <div class="pagination-count d-flex flex-wrap">
                                    {Array.from({ length: noOfPage }).map((_, i) => {
                                      const pageNumber = i + 1;
                                      const isCurrentPage = pageNumber === page;

                                      return (
                                        <Link
                                          key={pageNumber}
                                          class={`pagination-count-box ${isCurrentPage ? "active" : ""}`}
                                          onClick={() => setPage(pageNumber)}
                                        >
                                          <h6>{pageNumber}</h6>
                                        </Link>
                                      );
                                    })}
                                  </div>
                                  <div class="pagination-arrow">
                                    <Link
                                      class={`pagination-arrow-box d-flex align-items-center ${
                                        page === noOfPage && "no-pointer"
                                      }`}
                                      onClick={() => {
                                        if (page < noOfPage) {
                                          setPage(page + 1);
                                        }
                                      }}
                                    >
                                      <span className="hover-link">Next</span>
                                      <img src={page === noOfPage ? rightArrow : rightArrowGreen} alt="" />
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShowRegModal show={showRegModal} onHide={toggleModal} regions={props?.sideBarApisListings?.regionList?.data} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userLogout: (callback) => dispatch(userLogout(callback)),
  searchAll: (params, callback) => {
    dispatch(searchAll(params, callback));
  },
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesCategory: (params, callback) => dispatch(getSpeciesCategory(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getNews: (params, callback) => dispatch(getNews(params, callback)),
  getBlogs: (params, callback) => dispatch(getBlogs(params, callback)),
  getZoo: (params, callback) => dispatch(getZoo(params, callback)),
  getAllZoo: (params, callback) => dispatch(getAllZoo(params, callback)),
  getProfileInfo: (params, callback) => dispatch(getProfileInfo(params, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  regionLists: regionListings(state),
  speciesList: speciesListings(state),
  blogsListings: blogsListings(state),
  newsListings: newsListings(state),
  zooListings: zooListings(state),
  sideBarApisListings: sideBarApisListings(state),
  misc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
