import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getEnvironmentalist,
  getBlog,
  speciesListings,
  updateEnvironmentalist,
  deleteEnvironmentalist,
} from "../../store/species";
import noRecord from "../../include/images/nrb-img.svg";
import { useState } from "react";
import ListBlogsOnRegion from "../common/listingCard/listBlogsOnRegion";
import PeopleListCard from "../common/listingCard/peopleListCard";
import Pagination from "../common/Pagination/pagination";
import HTMLReactParser from "html-react-parser";
import {
  removeTags,
  capitalizeContentInBrackets,
  transformDropdownData,
  extractIds,
} from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import trash from "../../include/images/trash-24x24.svg";
import Select from "react-select";
import { getEnvironmentalistList, sideBarApisListings } from "../../store/sidebarApis";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import Skeleton from "react-loading-skeleton";
import defaultEnvImg from "../../include/images/defaultEnvImg.png";

const People = (props) => {
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [initialRender, setInitialRender] = useState(true);
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  const [selectedPreference, setSelectedPreference] = useState({
    environmentalist: "",
  });
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      environmentalist: selectedOptions,
    }));
  };

  const environmentalistDropdown = transformDropdownData(props.sideBarApisListings?.environmentalist?.data || []);
  useEffect(() => {
    if (!props.sideBarApisListings?.environmentalist?.data) {
      props.getEnvironmentalistList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getEnvironmentalist(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const peopleDetails = props.speciesListings && props.speciesListings.environmentalist;

  const blog = props.speciesListings && props.speciesListings.blog && props.speciesListings.blog.data;
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((peopleDetails?.totalCount ?? 0) / (peopleDetails?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
    }
    return description;
  };
  const addMore = () => {
    setLoading(true);
    let payload = {
      environmentalistIds: extractIds(selectedPreference?.environmentalist),
    };
    props.updateEnvironmentalist(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          environmentalist: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getEnvironmentalist(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      environmentalistIds: [ids],
    };
    props.deleteEnvironmentalist(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getEnvironmentalist(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {peopleDetails && peopleDetails?.data?.length === 0 && (
                <>
                  {props.editMode && (
                    <div class="d-flex flex-wrap justify-content-end">
                      <div class="d-flex flex-wrap justify-content-end w-75 me-2">
                        <Select
                          className="basic-single w-50 text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={environmentalistDropdown}
                          value={selectedPreference.environmentalist}
                          onChange={handleSelectChange}
                        />
                        <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                          Save
                        </Link>
                      </div>
                    </div>
                  )}
                  <ul class="ei-grid-list d-flex flex-wrap justify-content-center">
                    <div class="no-records-container d-flex align-items-center justify-content-center">
                      <div class="no-records-box text-center">
                        <div class="nrb-image">
                          <img src={noRecord} alt="" />
                        </div>
                        <h6>No Records Found</h6>
                      </div>
                    </div>
                  </ul>
                </>
              )}

              {peopleDetails && peopleDetails?.data?.length > 0 && (
                <>
                  {props.editMode && (
                    <div class="d-flex flex-wrap justify-content-end">
                      <div class="d-flex flex-wrap justify-content-end w-75 mb-4 ">
                        <Select
                          className="basic-single w-50 text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={environmentalistDropdown}
                          value={selectedPreference.environmentalist}
                          onChange={handleSelectChange}
                        />
                        <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                          Save
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              )}
              {loading ? (
                <ul class="ei-grid-list d-flex flex-wrap">
                  {Array.from({ length: 42 }, (item, index) => (
                    <div class="organisation-item wet-market-list">
                      <Skeleton borderRadius={"10px"} height="200px" />
                    </div>
                  ))}
                </ul>
              ) : (
                <ul class="tbc-organization-list d-flex flex-wrap">
                  {peopleDetails &&
                    peopleDetails?.data?.length > 0 &&
                    peopleDetails.data.map((item) => {
                      const descp = item.description ? HTMLReactParser(item.description) : "";
                      return (
                        <li class="organisation-item wet-market-list position-relative">
                          {props.editMode && (
                            <button
                              type="button"
                              class="trash-button-box"
                              onClick={(e) => {
                                handleDelete(item?._id);
                              }}
                            >
                              <img src={trash} alt="w volumne" />
                            </button>
                          )}
                          {
                            <div className="organisation-box d-flex flex-wrap " style={{ minHeight: "170px" }}>
                              <div class="organisation-logo ">
                                <Link
                                  to={{
                                    pathname: `/environmentalists/${item?.slug}`,
                                    state: { id: item._id },
                                  }}
                                >
                                  {!imageError && item?.coverImage?.small ? (
                                    <img
                                      src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                      onError={handleImageError}
                                      className="org-img sm-img-fix"
                                      alt=""
                                    />
                                  ) : !imageError && item?.coverImage?.medium ? (
                                    <img
                                      src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                      alt=""
                                      className="org-img sm-img-fix"
                                    />
                                  ) : (
                                    <img src={defaultEnvImg} alt="" className="org-img sm-img-fix" />
                                  )}
                                </Link>
                              </div>
                              <div class="organisation-text" style={{ cursor: "default" }}>
                                <h6>
                                  {" "}
                                  <Link
                                    className="text-transfrom "
                                    to={{
                                      pathname: `/environmentalists/${item?.slug}`,
                                      state: { id: item?._id },
                                    }}
                                  >
                                    {`${capitalizeContentInBrackets(item?.name)}`}
                                  </Link>
                                </h6>
                                <div class="organisation-text-footer">
                                  <small>Description :</small>
                                  <p className="caps-text">
                                    {item?.description
                                      ? item?.description?.length > 30
                                        ? dotDesc(HTMLReactParser(removeTags(item?.description)), 180) + "..."
                                        : HTMLReactParser(removeTags(item?.description))
                                      : "Not yet added"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                        </li>
                      );
                    })}
                </ul>
              )}
              {/* {blog && blog.length > 0 && (
                <>
                  <div class="ei-heading d-flex flex-wrap justify-content-between align-items-center">
                    <h4>Blogs</h4>{" "}
                    <a href="#!" class="btn btn-default">
                      Join
                    </a>
                  </div>
                   <ul class="blog-list d-flex flex-wrap">
                    {loading
                      ? [1, 2, 3].map((item) => (
                          <ListBlogsOnRegion
                            img={item?.coverImage?.medium}
                            name={item.name}
                            description={item?.description}
                            page={"blog-listing-page-species"}
                            id={item._id}
                            createdAt={item?.createdAt}
                            loading={loading}
                            blogCategoryName={item?.blogCategoryName}
                          />
                        ))
                      : blog &&
                        blog.length > 0 &&
                        blog.map(
                          (item, idx) =>
                            idx < 3 && (
                              <ListBlogsOnRegion
                                img={item?.coverImage?.medium}
                                name={item.name}
                                createdAt={item?.createdAt}
                                description={item?.description}
                                page={"blog-listing-page-species"}
                                id={item._id}
                                blogCategoryName={item?.blogCategoryName}
                              />
                            )
                        )}
                  </ul>
                </>
              )} */}
              {peopleDetails && peopleDetails?.totalCount > 50 && (
                <Pagination
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  pageLinks={pageLinks}
                  totalPages={pageLinks.length}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEnvironmentalist: (params, callback) => dispatch(getEnvironmentalist(params, callback)),
  getBlog: (params, callback) => dispatch(getBlog(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  updateEnvironmentalist: (params, data, callback) => dispatch(updateEnvironmentalist(params, data, callback)),
  deleteEnvironmentalist: (params, data, callback) => dispatch(deleteEnvironmentalist(params, data, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(People));
