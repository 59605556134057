import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from '../../../utils/helperFunctions';

//Home Page Sidebar dropdown for Zoos And Wildlife

const ZooDropdown = (props) => {
    const renderZooList = (zooList) => {
        return (
            <ul className="ei-category-list">
                {zooList.map((item, i) => (
                    <li className="ei-category-item" key={item._id || i}>
                        <Link
                            to={{
                                pathname: `/zoos-and-wildlife-reserves/${item?.slug}`,
                                state: { id: item?._id },
                            }}
                            className="ei-category-box text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllZoo ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessZoo}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.zooAndParksList.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickZoo}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isZooMenu && (
                        <>
                            {props.zooAndParksList &&
                                props.zooAndParksList.length > 0 && (
                                    <Accordion.Item
                                        className="tab-pane fade show active accordion-item"
                                        id="two-tab-pane"
                                        eventKey="5"
                                    >
                                        <Accordion.Header
                                            className="accordion-header d-lg-block d-none"
                                            id="headingTwo"
                                        >
                                            Zoos & Wildlife Reserves
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-body">
                                            {renderZooList(
                                                props.handleViewAllZoo
                                                    ? props.dividedZooData.flat()
                                                    : props.handleViewLessZoo
                                                        ? props.poppedZooData
                                                        : props.zooAndParksList.slice(0, 15)
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.zooAndParksList && props.zooAndParksList.length > 0 && (
                        <Accordion.Item
                            className="tab-pane fade accordion-item"
                            id="two-tab-pane"
                            eventKey="5"
                        >
                            <Accordion.Header
                                className="accordion-header d-lg-block d-none"
                                id="headingTwo"
                            >
                                Zoos & Wildlife Reserves
                            </Accordion.Header>
                            <Accordion.Body className="accordion-body">
                                {renderZooList(
                                    props.handleViewAllZoo
                                        ? props.dividedZooData.flat()
                                        : props.handleViewLessZoo
                                            ? props.poppedZooData
                                            : props.zooAndParksList.slice(0, 15)
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </>
            )}
        </>
    );
};

export default ZooDropdown;
