import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getEnvironmentalistDetails,
  environmentalistListings,
  getRegionEnv,
  updateRegionEnv,
  deleteRegionEnv,
} from "../../store/environmentalist";
import { extractIds, removeTags, transformDropdownData } from "../../utils/helperFunctions";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import WorldMap from "../../common/maps/regionDetail/worldMap";
import USMap from "../../common/maps/regionDetail/usMap";
import { sideBarApisListings } from "../../store/sidebarApis";
import ShowMoreModal from "../common/showMoreModal/showMoreModal";
import ListingCardReg from "../common/listingCard/listingCardReg";
import Pagination from "../common/Pagination/pagination";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import Select from "react-select";

const Regions = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [type, setType] = useState("world");
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };
  const [selectedPreference, setSelectedPreference] = useState({
    region: "",
  });
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      region: selectedOptions,
    }));
  };

  const regionListDropdown = transformDropdownData(props.sideBarApisListings?.regionList?.data || []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getRegionEnv(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const details = props.environmentalistListings && props.environmentalistListings.region;

  const allRegionDetails = props?.sideBarApisListings?.regionList?.data;

  const usRegions =
    allRegionDetails && allRegionDetails.length > 0 && allRegionDetails.filter((item) => item.country === "US");

  const usState =
    details &&
    details.data &&
    details.data.length &&
    details.data.filter((item) => item.countryName === "United States");
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      regionIds: extractIds(selectedPreference?.region),
    };
    props.updateRegionEnv(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          region: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getRegionEnv(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      regionIds: [ids],
    };
    props.deleteRegionEnv(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getRegionEnv(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <>
                {props.editMode && (
                  <div class="d-flex flex-wrap justify-content-end">
                    <div class="d-flex flex-wrap justify-content-end w-75 mb-4">
                      <Select
                        className="basic-single w-50 z-2 text-capitalize"
                        classNamePrefix="select-search"
                        isMulti
                        placeholder="Select"
                        styles={customStyles1}
                        options={regionListDropdown}
                        value={selectedPreference.region}
                        onChange={handleSelectChange}
                      />
                      <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                        Save
                      </Link>
                    </div>
                  </div>
                )}
                <ul class="ei-grid-list region-species-list d-flex flex-wrap ei-grid-5">
                  {details && details.data && details?.data?.length
                    ? details?.data?.map((item) => {
                        return (
                          <ListingCardReg
                            img={item?.coverImage?.medium}
                            img1={item?.coverImage?.large}
                            name={item.name}
                            page={"region-listing-page"}
                            id={item._id}
                            country={item?.country}
                            slug={item.slug}
                            item={item}
                            loading={loading}
                            handleDelete={handleDelete}
                            editMode={props?.editMode}
                          />
                        );
                      })
                    : ""}
                </ul>
                {details && details?.totalCount > 50 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    totalPages={pageLinks.length}
                  />
                )}
              </>
            </div>
          </div>
        </div>
        <div className="d-flex space-between species-region-text">
          <p className="map-switch cursor-pointer" onClick={() => toggleShowMoreModal()}>
            View All Regions Name
          </p>

          {usState?.length > 0 && (
            <p
              className="map-switch cursor-pointer"
              onClick={() => {
                if (type == "us") {
                  setType("world");
                } else if (type == "world") {
                  setType("us");
                }
              }}
            >
              Switch to {type === "us" ? "All " : "US"} region map
            </p>
          )}
        </div>
        <div class="tbc-map-full">
          <div class="map-hero-image">
            {(details &&
              details.data &&
              details.data.length === 1 &&
              details.data[0]?.countryName === "United States") ||
            type == "us" ? (
              <USMap
                name={
                  details && details.data && (details.data?.regions?.length === 1 || type == "us")
                    ? usState?.map((item) => item?.state)
                    : usState?.map((item) => item?.countryName)
                }
                data={usRegions}
                setSelectedId={props?.setSelectedId}
                page="species"
                isNotClickable={false}
              />
            ) : (
              <WorldMap
                name={details && details.data && details.data?.regions?.map((item) => item?.countryName)}
                data={allRegionDetails}
                setSelectedId={props?.setSelectedId}
                page="species"
                isNotClickable={true}
                setType={setType}
                showAllMap={true}
              />
            )}
          </div>
          <ShowMoreModal
            show={showMoreModal}
            onHide={toggleShowMoreModal}
            listData={details?.data}
            heading={`${
              details?.data?.length > 0 ? "Species found in these regions" : "No Species found in these regions"
            } `}
            isNonEditable={true}
            type="regions"
          />
        </div>
      </div>
    </>
  );
};
// Saber-Toothed Tiger
const mapDispatchToProps = (dispatch) => ({
  getRegionEnv: (params, callback) => dispatch(getRegionEnv(params, callback)),
  updateRegionEnv: (params, data, callback) => dispatch(updateRegionEnv(params, data, callback)),
  deleteRegionEnv:(params, data, callback) => dispatch(deleteRegionEnv(params, data, callback)),
});

const mapStateToProps = (state) => ({
  environmentalistListings: environmentalistListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Regions));
