import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from '../../../utils/helperFunctions';

//Home Page Sidebar dropdown for Science & Education
const ScienceDropdown = (props) => {
    const renderScienceList = (scienceList) => {
        return (
            <ul className="ei-category-list">
                {scienceList.map((item, i) => (
                    <li className="ei-category-item" key={i}>
                        <Link
                            to={{
                                pathname: `/science-education`,
                                id: item?._id,
                                name: item.name,
                            }}
                            className="ei-category-box text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllScience ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessScience}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.scienceAndEducationCategory.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickScience}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isScienceMenu && (
                        <>
                            {props.scienceAndEducationCategory &&
                                props.scienceAndEducationCategory.length > 0 && (
                                    <Accordion.Item
                                        className="tab-pane fade show active accordion-item"
                                        id="two-tab-pane"
                                        eventKey="6"
                                    >
                                        <Accordion.Header
                                            className="accordion-header d-lg-block d-none"
                                            id="headingTwo"
                                        >
                                            Science & Education
                                        </Accordion.Header>

                                        <Accordion.Body className="accordion-body">
                                            {renderScienceList(
                                                props.handleViewAllScience
                                                    ? props.dividedScienceData.flat()
                                                    : props.handleViewLessScience
                                                        ? props.poppedScienceData
                                                        : props.scienceAndEducationCategory.slice(0, 15)
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.scienceAndEducationCategory &&
                        props.scienceAndEducationCategory.length > 0 && (
                            <Accordion.Item
                                className="tab-pane fade accordion-item"
                                id="two-tab-pane"
                                eventKey="6"
                            >
                                <Accordion.Header
                                    className="accordion-header d-lg-block d-none"
                                    id="headingTwo"
                                >
                                    Science & Education
                                </Accordion.Header>

                                <Accordion.Body className="accordion-body">
                                    {renderScienceList(
                                        props.handleViewAllScience
                                            ? props.dividedScienceData.flat()
                                            : props.handleViewLessScience
                                                ? props.poppedScienceData
                                                : props.scienceAndEducationCategory.slice(0, 15)
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                </>
            )}
        </>
    );
};

export default ScienceDropdown;
