import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions"

//Home Page Sidebar dropdown for Organization

const OrganizationDropdown = (props) => {
    const renderOrganizationList = (organizationList) => {
        return (
            <ul className="ei-category-list">
                {organizationList.map((item, i) => (
                    <li className="ei-category-item" key={item._id || i}>
                        <Link
                            to={{
                                pathname: `/organizations/${item?.slug}`,
                                state: { id: item?._id },
                            }}
                            className="ei-category-box text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllOrganization ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessOrganization}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.organizationList.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickOrganization}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isOrgMenu && (
                        <>
                            {props.organizationList &&
                                props.organizationList.length > 0 && (
                                    <Accordion.Item
                                        className="tab-pane fade show active accordion-item d-block d-lg-none"
                                        id="two-tab-pane"
                                        eventKey="3"
                                    >
                                        <Accordion.Header
                                            className="accordion-header d-lg-block d-none"
                                            id="headingTwo"
                                        >
                                            Organizations
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-body">
                                            {renderOrganizationList(
                                                props.handleViewAllOrganization
                                                    ? props.dividedOrganizationData.flat()
                                                    : props.handleViewLessOrganization
                                                        ? props.poppedOrganizationData
                                                        : props.organizationList.slice(0, 15)
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.organizationList && props.organizationList.length > 0 && (
                        <Accordion.Item
                            className="tab-pane fade accordion-item d-lg-block d-none"
                            id="two-tab-pane"
                            eventKey="3"
                        >
                            <Accordion.Header
                                className="accordion-header"
                                id="headingTwo"
                            >
                                Organizations
                            </Accordion.Header>

                            <Accordion.Body className="accordion-body">
                                {renderOrganizationList(
                                    props.handleViewAllOrganization
                                        ? props.dividedOrganizationData.flat()
                                        : props.handleViewLessOrganization
                                            ? props.poppedOrganizationData
                                            : props.organizationList.slice(0, 15)
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </>
            )}
        </>
    );
};

export default OrganizationDropdown;
