import React from "react";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  organizationListing,
} from "../../store/organization";
import parse from "html-react-parser";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
const Details = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const details =
    props.organizationListing && props.organizationListing.organizationDetail;

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {loading ? (
                <>
                  <div class="tbc-details d-flex flex-wrap region-details-main ">
                    <div class="tbc-details-right detail-right-tbc" style={{ fontSize: "14px" }}>
                      <Skeleton borderRadius={"10px"} width="500px" height="250px" />
                    </div>

                    <Skeleton borderRadius={"10px"} width="500px" height="500px" />
                  </div>
                </>
              ) : (
                <>
                  <div class="tbc-details region-details-main ">
                  {props.editMode ? (
                        <textarea
                          name="description"
                          className="w-100"
                          value={props?.stripHtmlTags(props?.editState?.description)}
                          onChange={props.handleChange}
                          rows="10"
                          cols="50"
                        />
                      ) : (
                    <div class="" style={{ fontSize: "20px", lineHeight: "36px" }}>
                      {details?.data?.description
                        ? parse(details?.data?.description)
                        : ""}
                    </div>)}
                    {/* <div class="tbc-details-left r-image">
                      {!imageError ? <img src={
                        details?.data?.detailTabImage?.large
                          ? process.env.REACT_APP_MEDIA +
                          details?.data?.detailTabImage?.large
                          : ""
                      }
                        onError={handleImageError}
                        alt="" /> : <img src={
                          details?.data?.detailTabImage?.original
                            ? process.env.REACT_APP_MEDIA +
                            details?.data?.detailTabImage?.original
                            : ""
                        }
                          alt="" />}
                    </div> */}
                  </div>
                  {/* <div class="tbc-details-bg">
                    {!imageError ? <img src={
                      details?.data?.detailTabImage?.large
                        ? process.env.REACT_APP_MEDIA +
                        details?.data?.detailTabImage?.large
                        : ""
                    }
                      onError={handleImageError}
                      alt="" /> : <img src={
                        details?.data?.detailTabImage?.original
                          ? process.env.REACT_APP_MEDIA +
                          details?.data?.detailTabImage?.original
                          : ""
                      }
                        alt="" />}
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// Saber-Toothed Tiger
const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) =>
    dispatch(getOrganizationDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Details));
