import React, { useRef, useState } from "react";
import { deleteOrganizations, getOrganizations, speciesListings, updateOrganizations } from "../../store/species";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noSponsor from "../../include/images/sponsor.svg";
import noSponsorSmall from "../../include/images/sponsorsmall.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import { getsponsor, getstory, getSubscription } from "../../store/subscription";
import { getProfile } from "../../utils/localStorageServices";

const Sponsors = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const isMobile = useCheckMobileScreen();
  const sponsorData = props?.getSubscription?.sponsor?.data;
  const storyData = props?.getSubscription?.story?.data;
  const profile = getProfile();

  const handleImageError = () => {
    setImageError(true);
  };

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
        isActive: true,
      };
      setLoading(true);
      props.getsponsor(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const organizationDetail = props.speciesListings && props.speciesListings.organization;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const pageLinks = Array.from(
    { length: Math.ceil((organizationDetail?.totalCount ?? 0) / (organizationDetail?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }
    return description;
  };

  return (
    <div className="container ms-0 ps-0 mt-3" ref={topElementRef}>
      <div className="row">
        <div className="col-md-12">
          {sponsorData && sponsorData.length > 0 && <div className="ei-heading d-flex justify-content-between align-items-center">
            <h4>Sponsored By</h4>
          </div>}
          <div className={`${!sponsorData?.length && storyData?.length ? "" : "pt-3"} pb-0`}>
            {loading ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                {Array.from({ length: 42 }, (item, index) => (
                  <div key={index} className="org-skeleton">
                    <Skeleton borderRadius="10px" height="150px" />
                  </div>
                ))}
              </ul>
            ) : sponsorData && sponsorData.length > 0 ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                {sponsorData.map((item, index) => (
                  <li key={index} className="organisation-item org-card position-relative">
                    <div className="organisation-box d-flex flex-wrap">
                      <div className="organisation-logo">
                        <Link>
                          <img
                            src={`${process.env.REACT_APP_MEDIA}${imageError ? item?.logo?.medium : item?.logo?.small}`}
                            onError={handleImageError}
                            className="org-img sm-img-fix"
                            alt="Organization Logo"
                          />
                        </Link>
                      </div>
                      <div className="organisation-text">
                        <h6 className="text-transform text-capitalize">
                          <Link>{capitalizeContentInBrackets(item?.sponsorName)}</Link>
                        </h6>
                        <div className="organisation-text-footer">
                          <div className="d-flex justify-content-between">
                            <small>Location:</small>
                          </div>
                          <p className="caps-text d-flex align-items-center justify-content-between">
                            <span>{item?.location}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : !sponsorData?.length && !storyData?.length ? (
              isMobile ? (
                <div className="d-flex justify-content-center"></div>
              ) : (
                <div className="d-flex justify-content-between"></div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsor: (params, callback) => dispatch(getsponsor(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sponsors));
