import React, { useRef, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { deleteRegion, getRegion, speciesListings, updateRegion, updateSpeciesDetails } from "../../store/species";
import { getRegionList, sideBarApisListings } from "../../store/sidebarApis";
import { useEffect } from "react";
import ListingCardReg from "../common/listingCard/listingCardReg";
import Pagination from "../common/Pagination/pagination";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import { extractIds, transformDropdownData } from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Region = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    region: "",
  });
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      region: selectedOptions,
    }));
  };

  const regionListDropdown = transformDropdownData(props.sideBarApisListings?.regionList?.data || []);
  useEffect(() => {
    if (!props.sideBarApisListings?.regionList?.data) {
      props.getRegionList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getRegion(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const details = props.speciesListings && props.speciesListings?.region;
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const addMore = () => {
    setLoading(true);
    let payload = {
      regionIds: extractIds(selectedPreference?.region),
    };
    props.updateRegion(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          region: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getRegion(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      regionIds: [ids],
    };
    props.deleteRegion(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getRegion(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            <>
              {props.editMode && (
                <div class="d-flex flex-wrap justify-content-end">
                  <div class="d-flex flex-wrap justify-content-end w-75 mb-4">
                    <Select
                      className="basic-single w-50 z-2 text-capitalize"
                      classNamePrefix="select-search"
                      isMulti
                      placeholder="Select"
                      styles={customStyles1}
                      options={regionListDropdown}
                      value={selectedPreference.region}
                      onChange={handleSelectChange}
                    />
                    <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                      Save
                    </Link>
                  </div>
                </div>
              )}
              <ul class="ei-grid-list region-species-list d-flex flex-wrap ei-grid-5">
                {details && details.data && details?.data.length
                  ? details?.data.map((item) => {
                      return (
                        <ListingCardReg
                          img={item?.coverImage?.medium}
                          img1={item?.coverImage?.large}
                          name={item.name}
                          page={"region-listing-page"}
                          id={item._id}
                          country={item?.country}
                          slug={item.slug}
                          item={item}
                          loading={loading}
                          editMode={props.editMode}
                          handleDelete={handleDelete}
                        />
                      );
                    })
                  : ""}
              </ul>
              {details && details?.totalCount > 50 && (
                <Pagination
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  pageLinks={pageLinks}
                  totalPages={pageLinks.length}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRegion: (params, callback) => dispatch(getRegion(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  updateRegion: (params, data, callback) => dispatch(updateRegion(params, data, callback)),
  deleteRegion: (params, data, callback) => dispatch(deleteRegion(params, data, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Region));
