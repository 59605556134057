import React, { useEffect, useRef, useState } from "react";
import {
  getMyPostData,
  getUsersPreferences,
  getUser,
  deleteMyPost,
  getGuestProfile,
  followUnfollowFriends,
} from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import profileImage from "../../include/images/profile-w-img.jpg";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import location from "../../include/images/w-page-link-0.svg";
import link from "../../include/images/w-page-link-1.svg";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Feeds from "../../components/feed/feeds";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import LoadingBar from "react-top-loading-bar";
import Media from "../../components/feed/media";
import { getLiked, getMedia, getPosts, getPublicMedia, getPublicPost } from "../../store/post";
import Skeleton from "react-loading-skeleton";
import organizationSvg from "../../include/images/organization.svg";
import emailsvg from "../../include/images/email.svg";
import banner from "../../include/images/feed-Icon.svg";
import lockIcon from "../../include/images/lock.svg";
//Guest Profile View page
const GuestProfile = (props) => {
  var ref = useRef(null);
  const [loadingMain, setLoadingMain] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sort, setSort] = useState("createdAt");
  const [tab, setTab] = useState("post");
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [loadingLike, setLoadingLike] = useState(false);
  const [id, setId] = useState(null);
  const myPostData = props.getPosts && props.getPosts.publicPost && props.getPosts.publicPost.data;
  const userProfile = props?.getUser?.guestProfile?.data;
  const mediaData = props?.getPosts?.publicMedia;
  const likeData = props?.getPosts?.likes?.data;
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingMain(true);
    props.getGuestProfile(pathSegments[pathSegments.length - 1], (res) => {
      if (res && res.status == 200) {
        setId(res?.data?.data?._id);
        setLoadingMain(false);
      }
    });
  }, []);
  useEffect(() => {
    setLoadingMain(true);
    window.scrollTo(0, 0);
    const payload = {
      keyword: "",
      page: page,
      sort,
      order,
    };
    props.getMyPostData(pathSegments[pathSegments.length - 1], (res) => {
      if (res && res.status === 200) {
        setPage(1);
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
      }
    });
  }, [sort, order, page]);

  useEffect(() => {
    if (tab == "media") {
      setLoadingMedia(true);
      props?.getMedia(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setLoadingMedia(false);
        } else {
          setTab("post");
          setLoadingMedia(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  }, [tab]);

  // useEffect(() => {
  //   if (tab == "like") {
  //     setLoadingLike(true);
  //     props?.getLiked((res) => {
  //       if (res && res.status == 200) {
  //         setLoadingLike(false);
  //       } else {
  //         setTab("post");
  //         setLoadingLike(false);
  //         toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
  //       }
  //     });
  //   }
  // }, [tab]);
  const deleteHandler = (item) => {
    ref?.current?.continuousStart();
    setLoadingMain(true);
    const id = item?._id;
    props.deleteMyPost(id, (res) => {
      if (res && res.status === 200) {
        const payload = {
          keyword: "",
          page: page,
          sort,
          order,
        };
        props.getMyPostData(pathSegments[pathSegments.length - 1], (res) => {
          if (res && res.status === 200) {
            ref?.current?.complete();
            setLoadingMain(false);
            toast(<AlertSuccess message={"Post Deleted Successfully"} />);
          }
        });
      } else {
        ref?.current?.complete();
        setLoadingMain(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleFollow = () => {
    ref?.current?.continuousStart();
    props?.followUnfollowFriends(id, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        props.getGuestProfile(pathSegments[pathSegments.length - 1], (res) => {
          if (res && res.status == 200) {
            setId(res?.data?.data?._id);
          }
        });
      } else {
        ref?.current?.complete();
        <AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />;
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <ProfileLayout page={"guestProfile"} loading={loadingMain}>
        <div class="panel-main-content d-flex flex-column">
          {userProfile?.isPrivate ? (
            <div class="panel-body flex-grow-1">
              <div class="widget-profile-block">
                <div class="widget-profile-wrapper">
                  {loadingMain ? <Skeleton borderRadius={"10px"} height={40} /> : <div class="wpw-title">Profile</div>}
                  <div class="wpw-content-row ">
                    {loadingMain ? (
                      <Skeleton borderRadius={"10px"} height={200} width={"100%"} />
                    ) : (
                      <div className="wpw-content-img-box">
                        <img
                          src={
                            userProfile?.coverPicture?.original
                              ? process.env.REACT_APP_MEDIA + userProfile?.coverPicture?.original
                              : banner
                          }
                          alt="profile cover"
                          className="banner-image"
                        />
                      </div>
                    )}
                    <div class="wpw-content">
                      <div class="wpw-content-head d-flex align-items-center justify-content-between">
                        {loadingMain ? (
                          <Skeleton borderRadius={"10px"} height={110} width={100} />
                        ) : (
                          <div class="wpw-content-head-icon d-flex  align-items-center justify-content-center">
                            <img
                              src={
                                userProfile?.profilePicture?.original
                                  ? process.env.REACT_APP_MEDIA + userProfile?.profilePicture?.original
                                  : ""
                              }
                              alt="profile w img"
                              className="h-100 object-fit-contain"
                            />
                          </div>
                        )}
                        {loadingMain ? (
                          <Skeleton borderRadius={"10px"} height={30} width={300} />
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="wpw-content-head-btn mr-3">
                              <Link className="btn btn-default btn-block" onClick={handleFollow}>
                                {userProfile?.isFollowing ? "Unfollow" : "Follow"}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="wpw-content-body">
                        <div class="w-body-content pb-2">
                          {loadingMain ? (
                            <div className="loading-skeleton">
                              <Skeleton borderRadius={"10px"} height={30} width={200} />
                            </div>
                          ) : (
                            <h4 className="caps-text">
                              {userProfile?.firstName} {userProfile?.lastName}
                            </h4>
                          )}
                          <div class="private-user-block d-flex flex-nowrap gap-3">
                            <div class="pub-icon-box d-flex align-items-center justify-content-center flex-shrink-0">
                              <img src={lockIcon} alt="Lock icon" />
                            </div>
                            <div class="pub-text-box flex-grow-1 d-flex flex-column gap-1">
                              <h6>User has chosen to keep their profile private.</h6>
                              <p>
                                Information is not available for viewing. Follow the user to request access and see more
                                details.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="panel-body flex-grow-1">
              <div class="widget-profile-block">
                <div class="widget-profile-wrapper">
                  {loadingMain ? <Skeleton borderRadius={"10px"} height={40} /> : <div class="wpw-title">Profile</div>}
                  <div class="wpw-content-row ">
                    {loadingMain ? (
                      <Skeleton borderRadius={"10px"} height={200} width={"100%"} />
                    ) : (
                      <div className="wpw-content-img-box">
                        <img
                          src={
                            userProfile?.coverPicture?.original
                              ? process.env.REACT_APP_MEDIA + userProfile?.coverPicture?.original
                              : banner
                          }
                          alt="profile cover"
                          className="banner-image"
                        />
                      </div>
                    )}
                    <div class="wpw-content">
                      <div class="wpw-content-head d-flex align-items-center justify-content-between">
                        {loadingMain ? (
                          <Skeleton borderRadius={"10px"} height={110} width={100} />
                        ) : (
                          <div class="wpw-content-head-icon d-flex  align-items-center justify-content-center">
                            <img
                              src={
                                userProfile?.profilePicture?.original
                                  ? process.env.REACT_APP_MEDIA + userProfile?.profilePicture?.original
                                  : ""
                              }
                              alt="profile w img"
                              className="h-100 object-fit-contain"
                            />
                          </div>
                        )}
                        {loadingMain ? (
                          <Skeleton borderRadius={"10px"} height={30} width={300} />
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="wpw-content-head-btn mr-3">
                              <Link className="btn btn-default btn-block" onClick={handleFollow}>
                                {userProfile?.isFollowing ? "Unfollow" : "Follow"}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="wpw-content-body">
                        <div class="w-body-content">
                          {loadingMain ? (
                            <div className="loading-skeleton">
                              <Skeleton borderRadius={"10px"} height={30} width={200} />
                            </div>
                          ) : (
                            <h4 className="caps-text">
                              {userProfile?.firstName} {userProfile?.lastName}
                            </h4>
                          )}
                          <div class="w-page-link-list">
                            {loadingMain ? (
                              <div className="w-page-link-item">
                                <div className="loading-skeleton">
                                  <Skeleton borderRadius={"10px"} height={20} width={100} />
                                </div>
                              </div>
                            ) : (
                              userProfile?.location && (
                                <div className="w-page-link-item">
                                  <div className="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                    <div className="w-page-link-icon">
                                      <img src={location} alt="w page link" />
                                    </div>
                                    <div className="w-page-link text-capitalize">
                                      {userProfile?.location?.stateName && userProfile?.location?.countryName
                                        ? `${userProfile.location.stateName}, ${userProfile.location.countryName}`
                                        : userProfile?.location?.countryName || ""}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {loadingMain ? (
                              <div class="w-page-link-item">
                                <div className="loading-skeleton">
                                  <Skeleton borderRadius={"10px"} height={20} width={100} />
                                </div>
                              </div>
                            ) : (
                              userProfile?.email && (
                                <div class="w-page-link-item">
                                  <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                    <div class="w-page-link-icon">
                                      <img src={emailsvg} alt="w page link" />
                                    </div>
                                    <div class="w-page-link">
                                      <a href="#" target="_blank">
                                        {userProfile && userProfile?.email}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {loadingMain ? (
                              <div class="w-page-link-item">
                                <div className="loading-skeleton">
                                  <Skeleton borderRadius={"10px"} height={20} width={100} />
                                </div>
                              </div>
                            ) : (
                              userProfile?.organizationName && (
                                <div class="w-page-link-item">
                                  <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                    <div class="w-page-link-icon">
                                      <img src={organizationSvg} alt="w page link" />
                                    </div>
                                    <div class="w-page-link text-capitalize">
                                      {userProfile && userProfile?.organizationName
                                        ? userProfile?.organizationName
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {loadingMain ? (
                              <div class="w-page-link-item">
                                <div className="loading-skeleton">
                                  <Skeleton borderRadius={"10px"} height={20} width={100} />
                                </div>
                              </div>
                            ) : (
                              userProfile?.website && (
                                <div class="w-page-link-item">
                                  <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                    <div class="w-page-link-icon">
                                      <img src={link} alt="w page link" />
                                    </div>
                                    <div class="w-page-link">
                                      <a href="#" target="_blank">
                                        {userProfile && userProfile?.website}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div class="ww-text-list d-flex flex-wrap align-items-center">
                            <div class="ww-text-item">
                              <div class="ww-text-box">
                                <span>{userProfile?.followingCount}</span>
                                {/* to={`${pathSegments[pathSegments.length - 1]}/followers`} */}
                                <Link>Following</Link>
                              </div>
                            </div>
                            <div class="ww-text-item">
                              <div class="ww-text-box">
                                <span>{userProfile?.followerCount}</span>
                                <Link>Followers</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="profile-page-link d-flex flex-wrap align-items-center">
                          <div class={`p-p-item ${tab == "post" ? "active" : ""}`}>
                            <a href="#" class="p-p-box" onClick={() => setTab("post")}>
                              Posts
                            </a>
                          </div>
                          <div class={`p-p-item ms-4 ${tab == "media" ? "active" : ""}`}>
                            <a href="#" class="p-p-box" onClick={() => setTab("media")}>
                              Media
                            </a>
                          </div>
                          {/* <div class={`p-p-item ${tab == "like" ? "active" : ""}`}>
                          <a href="#" class="p-p-box" onClick={() => setTab("like")}>
                            Likes
                          </a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(tab == "post" || tab == "like") && (
                <Feeds
                  myPostData={tab == "like" ? likeData : myPostData}
                  userProfile={userProfile}
                  deleteHandler={deleteHandler}
                  likedPosts={tab}
                  page="profile"
                  loading={loadingMain}
                />
              )}
              {tab == "media" && <Media mediaData={mediaData} />}
            </div>
          )}
        </div>
      </ProfileLayout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMyPostData: (params, callback) => dispatch(getPublicPost(params, callback)),
  getUsersPreferences: (params, callback) => dispatch(getUsersPreferences(params, callback)),
  deleteMyPost: (params, callback) => dispatch(deleteMyPost(params, callback)),
  getMedia: (data, callback) => dispatch(getPublicMedia(data, callback)),
  getLiked: (callback) => dispatch(getLiked(callback)),
  getGuestProfile: (data, callback) => dispatch(getGuestProfile(data, callback)),
  followUnfollowFriends: (data, callback) => dispatch(followUnfollowFriends(data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getPosts: getPosts(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GuestProfile));
