import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "scienceAndEducation",
  initialState: {
    scienceAndEducation: {},
    scienceAndEducationDetail: {},
    loading: false,
    media: {},
    reports: {},
  },
  reducers: {
    scienceAndEducationRequested: (misc, action) => {
      misc.loading = true;
    },
    scienceAndEducationReceived: (misc, action) => {
      misc.scienceAndEducation = action.payload;
      misc.loading = false;
    },
    scienceAndEducationRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    scienceAndEducationDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    scienceAndEducationDetailReceived: (misc, action) => {
      misc.scienceAndEducationDetail = action.payload;
      misc.loading = false;
    },
    scienceAndEducationDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    mediaRequested: (misc, action) => {
      misc.loading = true;
    },
    mediaReceived: (misc, action) => {
      misc.media = action.payload;
      misc.loading = false;
    },
    mediaRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    reportsRequested: (misc, action) => {
      misc.loading = true;
    },
    reportsReceived: (misc, action) => {
      misc.reports = action.payload;
      misc.loading = false;
    },
    reportsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  scienceAndEducationRequested,
  scienceAndEducationReceived,
  scienceAndEducationRequestFailed,
  scienceAndEducationDetailRequested,
  scienceAndEducationDetailReceived,
  scienceAndEducationDetailRequestFailed,
  mediaRequested,
  mediaReceived,
  mediaRequestFailed,
  reportsRequested,
  reportsReceived,
  reportsRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "scienceAndEducation";

export const getScienceAndEducation = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "get",
      params,
      callback,
      onStart: scienceAndEducationRequested.type,
      onSuccess: scienceAndEducationReceived.type,
      onError: scienceAndEducationRequestFailed.type,
    })
  );
};
export const getScienceAndEducationDetail =
  (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/${params}`,
        method: "get",
        callback,
        onStart: scienceAndEducationDetailRequested.type,
        onSuccess: scienceAndEducationDetailReceived.type,
        onError: scienceAndEducationDetailRequestFailed.type,
      })
    );
  };

export const getScienceAndEducationMedia = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/media/${params}`,
      method: "get",
      callback,
      onStart: mediaRequested.type,
      onSuccess: mediaReceived.type,
      onError: mediaRequestFailed.type,
    })
  );
};

export const getScienceAndEducationReports =
  (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/report/${params}`,
        method: "get",
        callback,
        onStart: reportsRequested.type,
        onSuccess: reportsReceived.type,
        onError: reportsRequestFailed.type,
      })
    );
  };

export const scienceAndEducationListing = createSelector(
  (state) => state.entities.scienceAndEducation,
  (scienceAndEducation) => scienceAndEducation
);
