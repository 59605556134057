import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import HTMLReactParser from "html-react-parser";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import defaultOrgImg from "../../../include/images/organisation.png";
const OrganizationListCard = (props) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const donationUrl = props?.donationUrl?.split("//").pop();
  const handleLink = () => {
    if (!props?.description) {
      toast(
        <AlertError message="Sorry, but data for this organization is currently unavailable.We are working diligently to update our information.Please check back soon for the latest updates.Thank you for your understanding." />,
      );
    }
  };
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };
  return (
    <li class="organisation-item">
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton borderRadius={"10px"} height={"200px"} />
        </div>
      ) : (
        <div className="organisation-box d-flex flex-wrap" style={{ height: "200px" }}>
          <div class="organisation-logo ">
            <Link
              to={
                props?.description
                  ? {
                      pathname: `/organizations/${props?.slug}`,
                      state: { id: props.id },
                    }
                  : "#"
              }
              onClick={() => handleLink()}
            >
              {!imageError && props.img ? (
                <img
                  className="org-img sm-img-fix"
                  src={process.env.REACT_APP_MEDIA + props.img}
                  onError={handleImageError}
                  alt=""
                />
              ) : !imageError && props.img1 ? (
                <img className="org-img sm-img-fix" src={process.env.REACT_APP_MEDIA + props.img1} alt="" />
              ) : (
                <img className="org-img sm-img-fix" src={defaultOrgImg} alt="" />
              )}
            </Link>
          </div>
          <div class="organisation-text">
            <h6>
              {" "}
              <Link
                to={
                  props?.description
                    ? {
                        pathname: `/organizations/${props?.slug}`,
                        state: { id: props.id },
                      }
                    : "#"
                }
                onClick={() => handleLink()}
                className="text-capitalize"
              >
                {`${capitalizeContentInBrackets(props?.name)}`}
              </Link>
            </h6>
            <div class="organisation-text-footer">
              {props?.city ? (
                <>
                  <small>Address :</small>
                  <p className="caps-text" style={{ minHeight: "40px" }}>
                    {props?.city}
                    {", "} {props?.state}
                    {"- "}
                    {props?.country}
                  </p>
                </>
              ) : (
                ""
              )}
              {props?.isAddedToDonation === true ? (
                <a
                  href={`https://${donationUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-default"
                  style={{
                    minWidth: "100px",
                    lineHeight: "12px",
                  }}
                >
                  Donate
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default OrganizationListCard;
