import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import img from "../../include/images/zoo-front-bg-2.jpg";
import { useState } from "react";
import { zooListings } from "../../store/zoo";
const ContactInfo = (props) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const details = props.zooListings && props.zooListings.zooDetail.data;
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <div class="tbc-details d-flex flex-wrap lg-tbc-details">
                <div class="tbc-details-right">
                  <div class="tbc-contact-list">
                    <div class="tbc-contact-item">
                      Phone{" "}
                      <span class="d-block fw-medium">
                        {details?.mobileNumber1 || details?.mobileNumber2 ? (
                          <>
                            {details?.mobileNumber1 && (
                              <a href={`tel:${details.mobileNumber1}`}>{details.mobileNumber1}</a>
                            )}
                            {details?.mobileNumber2 && (
                              <>
                                {details?.mobileNumber1 && " / "}
                                <a href={`tel:${details.mobileNumber2}`}>{details.mobileNumber2}</a>
                              </>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                    <div class="tbc-contact-item">
                      Address
                      <address class="d-block fw-medium">
                        {details.address}, {details.location.countryName}
                      </address>
                    </div>
                  </div>
                </div>
                <div class="tbc-details-left r-image">
                  {!imageError ? <img src={
                    details?.coverImage?.large
                      ? process.env.REACT_APP_MEDIA +
                      details?.coverImage?.large
                      : ""
                  } onError={handleImageError}
                    alt="" style={{ objectFit: "contain" }} /> : <img src={
                      details?.coverImage?.original
                        ? process.env.REACT_APP_MEDIA +
                        details?.coverImage?.original
                        : ""
                    } alt="" style={{ objectFit: "contain" }} />}
                </div>
              </div>
              <div class="tbc-details-bg">
                {!imageError ? <img src={
                  details?.coverImage?.large
                    ? process.env.REACT_APP_MEDIA +
                    details?.coverImage?.large
                    : ""
                } onError={handleImageError}
                  alt="" /> : <img src={
                    details?.coverImage?.original
                      ? process.env.REACT_APP_MEDIA +
                      details?.coverImage?.original
                      : ""
                  } alt="" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //   getRegionDetails: (params, callback) =>
  //     dispatch(getRegionDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  zooListings: zooListings(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ContactInfo));
