import React, { useEffect, useRef, useState } from "react";
import logo from "../../include/images/logo.svg";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUser, userLogin, volunteerPreference } from "../../store/users";
import { connect } from "react-redux";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import eyeImg from "../../include/images/eye-d.svg";
import eyeOff from "../../include/images/eye-d-off.svg";
import {
  getRedirectUrl,
  setProfile,
  setToken,
  removeKey,
  getVolunteerPreference,
  removeKeySession,
} from "../../utils/localStorageServices";
import LoadingBar from "react-top-loading-bar";
import NextButton from "../../common/form/nextButton";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyRecaptcha } from "../../store/misc";

const Login = ({ userLogin, verifyRecaptcha, volunteerPreference, props }) => {
  var ref = useRef(null);
  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [error, setError] = useState({});
  const [captchaToken, setCaptchaToken] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    removeKey("signupStep");
  }, []);

  const handleChange = (token) => {
    setCaptchaToken(token);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateHandler = () => {
    const errors = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!loginData.email) {
      errors.email = "Email is required";
    } else if (!regex.test(loginData.email)) {
      errors.email = "Invalid Email";
    }

    if (!loginData.password) {
      errors.password = "Password is required";
    }

    const isEmpty = Object.values(errors).every((x) => x === null || x === "");

    if (!isEmpty) {
      setError(errors);
      return false;
    } else {
      setError(errors);
      return true;
    }
  };

  Useauthredirect();

  const extractIds = (selectedOptions) => {
    return selectedOptions.map((option) => option.value);
  };

  const sendLogin = () => {
    if (validateHandler()) {
      if (!isCaptchaVerified) {
        // Trigger the captcha verification first
        captchaRef.current.execute();
      } else {
        performLogin();
      }
    }
  };

  const performLogin = () => {
    setError({});
    ref && ref.current && ref.current.continuousStart();
    const value = { ...loginData };
    setLoading(true);
    const payload = {
      email: value.email,
      password: value.password,
    };

    userLogin(payload, (res) => {
      if (res.status === 200) {
        const data = res.data.data;
        const { accessToken, refreshToken, profile } = data;
        if (profile.loginAccess === "approved") {
          setToken(refreshToken, accessToken);
          setProfile(data);
          let volunteerP = getVolunteerPreference();
          if (volunteerP) {
            let volunteerPayload = {
              species: extractIds(volunteerP.species),
              organizations: extractIds(volunteerP.organization),
              regions: extractIds(volunteerP.region),
              zoos: extractIds(volunteerP.zoo),
              wetMarkets: extractIds(volunteerP.wetMarket),
              environmentalists: extractIds(volunteerP.environmentalist),
            };
            volunteerPreference(profile?._id, volunteerPayload, (res1) => {
              if (res1.status === 200) {
                removeKeySession("preferences");
              }
            });
          }
          const redirectUrl = getRedirectUrl();
          if (redirectUrl) {
            removeKey("redirectUrl");
            window.location.assign(`${redirectUrl}`);
          } else {
            history.push("/home");
          }
          setLoading(false);
          ref && ref.current && ref.current.complete();
        } else if (profile.loginAccess === "denied") {
          toast(<AlertError message="Access denied by Admin" />);
          setLoading(false);
          ref && ref.current && ref.current.complete();
        } else if (profile.loginAccess === "pending") {
          toast(
            <AlertError message="Your account is not yet approved by the admin. Please wait for approval before logging in." />,
          );
          setLoading(false);
          ref && ref.current && ref.current.complete();
        }
      } else {
        setLoading(false);
        ref && ref.current && ref.current.complete();
        setError({ ...error, loginRes: res?.data?.message });
      }
    });
  };

  useEffect(() => {
    if (captchaToken && !isVerifying) {
      setIsVerifying(true);
      const captchaPayload = { recaptchaToken: captchaToken };
      verifyRecaptcha(captchaPayload, (res) => {
        if (res && res.status === 200) {
          setIsCaptchaVerified(true);
          performLogin();
        } else {
          setIsCaptchaVerified(false);
          toast(<AlertError message="CAPTCHA verification failed" />);
        }
        setIsVerifying(false);
      });
    }
  }, [captchaToken]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div className="page-outer-wrapper font-family-poppins grey-bg">
        <header id="header">
          <nav className="navbar navbar-expand-xl">
            <div className="container">
              <div className="nav-inside d-flex align-items-center justify-content-center">
                <Link to={"/home"} className="navbar-brand">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <div className="login-container fw-medium">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-content-holder mx-auto">
                  <div className="login-head-row">
                    <h1>Member Login</h1>
                  </div>
                  <div className="invalid-feedback">{error.loginRes && <p>{error.loginRes}</p>}</div>
                  <div className="login-form-row-group">
                    <div className="form-floating-row d-flex flex-wrap">
                      <div className="ff-column">
                        <div
                          className={
                            error.email
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type="email"
                            className={error.email ? "is-invalid form-control" : "form-control"}
                            id="emailAddress"
                            placeholder="Email Address"
                            value={loginData.email || ""}
                            onChange={(e) => {
                              const data = { ...loginData };
                              data.email = e.target.value;
                              setLoginData(data);
                            }}
                          />
                          <label htmlFor="emailAddress">Email Address</label>
                        </div>
                        <div className="invalid-feedback">{error.email && <p>{error.email}</p>}</div>
                      </div>
                    </div>
                    <div className="form-floating-row d-flex flex-wrap">
                      <div className="ff-column">
                        <div
                          className={
                            error.password
                              ? "is-invalid form-floating custom-form-floating"
                              : "form-floating custom-form-floating"
                          }
                        >
                          <input
                            type={showPassword ? "text" : "password"}
                            className={error.password ? "is-invalid form-control" : "form-control"}
                            id="floatingPassword"
                            placeholder="Password"
                            value={loginData.password || ""}
                            onChange={(e) => {
                              const data = { ...loginData };
                              data.password = e.target.value;
                              setLoginData(data);
                            }}
                          />
                          <label htmlFor="floatingPassword">Password</label>

                          <button
                            type="button"
                            className="show-password-btn"
                            data-target="floatingPassword"
                            onClick={toggleShowPassword}
                          >
                            <img src={showPassword ? eyeImg : eyeOff} alt="eye toggle" />
                          </button>
                        </div>
                        <div className="invalid-feedback">{error.password && <p>{error.password}</p>}</div>
                      </div>
                    </div>
                    <div className="form-floating-row remember-me-row d-sm-flex flex-wrap align-items-center justify-content-between">
                      <Link to="/forgot-password">Forgot your password?</Link>
                    </div>
                    <div className="login-form-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={sendLogin}
                        loading={loading}
                        classData={`btn btn-default`}
                        label="Login"
                      />
                    </div>
                    <div className="login-form-btn d-flex align-items-center justify-content-end mt-2 ">
                      <Link className="btn btn-outline-green" to="/register/user-type">
                        Not a Member? Register
                      </Link>
                    </div>
                    <div className="login-link">
                      <Link to="/home">Back to Home</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="footer2" className="d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 d-md-flex align-items-md-center justify-content-md-center">
                <ul className="footer-nav-list d-flex justify-content-center justify-content-md-start align-items-center">
                  <li>
                    <a href="#!">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#!">Privacy Policy</a>
                  </li>
                </ul>
                <div className="copyright-box">
                  <img src="include/images/copyright.svg" alt="" />
                  &copy; Environmental Inclusion. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleChange} size="invisible" ref={captchaRef} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userLogin: (data, callback) => dispatch(userLogin(data, callback)),
  verifyRecaptcha: (data, callback) => dispatch(verifyRecaptcha(data, callback)),
  volunteerPreference: (params, data, callback) => dispatch(volunteerPreference(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Login));
