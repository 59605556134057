import React from "react";
import Skeleton from "react-loading-skeleton";
import Details from "../../components/Region/regionDetailsPage/details";
import ListingCard from "../../components/common/listingCard/listingCard";
import EndangeredSpecies from "../../components/Region/regionDetailsPage/endangeredSpecies";
import EnvironmentThreats from "../../components/Region/regionDetailsPage/environmentThreats";
import Zoo from "../../components/Region/regionDetailsPage/zoo";
import Organizations from "../../components/Region/regionDetailsPage/organizations";
import ScienceEducation from "../../components/Region/regionDetailsPage/scienceEducation";
import Environmentalist from "../../components/Region/regionDetailsPage/environmentalist";
import UserUpload from "../../components/common/userUpload/userUpload";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import App from "../../common/maps/map";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import { cleanAndFormatHtml } from "../../utils/helperFunctions";

//Region Detail Edit Modal
const  EditRegionDetail = ({
  loadingDetail,
  menuItem,
  details,
  id,
  handleMenuItemClick,
  setEditMode,
  isEdit,
  items,
  combinedData,
  splashText,
  setMenuItem,
  environmentalist,
  setSelectedId,
  regionMapData,
  addTargetBlankToLinks,
  miscSettingCitation,
  editState,
  editMode,
  setEditState,
  allRegionDetails,
}) => {
  const indexesToRemove = [7, 8];
  const filteredItems = items.filter((item, index) => !indexesToRemove.includes(index));
  const date = new Date(details?.data?.updatedAtVolunteer);
  return (
    <main id="main">
      <Details
        isEdit={isEdit}
        setEditState={setEditState}
        setEditMode={setEditMode}
        editState={editState}
        loading={loadingDetail}
        menuItem={menuItem}
        id={id}
        editMode={editMode}
        allRegionDetails={allRegionDetails}
      />
      {loadingDetail ? (
        <div class="tabs-block-container">
          <div class="tbc-head ">
            <Skeleton
              borderRadius={"10px"}
              height={"7.5vh"}
              baseColor="var(--dark-green-color)"
              highlightColor="#0cb40c"
            />
          </div>
          <div class="container mt-60">
            <ul class="ei-grid-list region-species-list d-flex flex-wrap ei-grid-5">
              {Array.from({ length: 50 }, (item, index) => (
                <ListingCard id={item?._id} loading={loadingDetail} />
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div class="tabs-block-container new-tabs-block-container">
          <div className="tbc-head">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <form className="d-lg-none">
                    <select
                      className="form-select"
                      id="tabSelector"
                      onChange={(e) => handleMenuItemClick(e.target.value)}
                      value={menuItem}
                    >
                      {filteredItems.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </option>
                      ))}
                    </select>
                  </form>
                  <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                    {filteredItems.map((item) => (
                      <li className="nav-item" key={item.id} role="presentation">
                        <div
                          className={`nav-link ${menuItem === item.id && "active"}`}
                          id={`pills-${item.id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${item.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${item.id}`}
                          aria-selected={menuItem === item.id}
                          onClick={() => handleMenuItemClick(item.id)}
                        >
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tbc-body overflow-hidden">
            <div class="tab-content tab-content-slider" id="pills-tabContent">
              {menuItem == "1" && (
                <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                  <EndangeredSpecies menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "3" && (
                <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                  <EnvironmentThreats menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "4" && (
                <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                  <Zoo menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}

              {menuItem == "5" && (
                <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                  <Organizations
                    menuItem={menuItem}
                    id={id}
                    splashData={splashText}
                    setMenuItem={setMenuItem}
                    editMode={editMode}
                  />
                </div>
              )}
              {menuItem == "6" && (
                <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                  <ScienceEducation menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "7" && (
                <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                  <Environmentalist
                    menuItem={menuItem}
                    id={id}
                    environmentalist={environmentalist}
                    editMode={editMode}
                  />
                </div>
              )}
              {menuItem == "8" && (
                <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                  <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="region" />
                </div>
              )}
              {menuItem == "9" && (
                <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                  <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="region" />
                </div>
              )}
              {menuItem == "10" && (
                <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                  {loadingDetail ? (
                    <div className="container">
                      {" "}
                      <Skeleton
                        borderRadius={"10px"}
                        height={"80vh"}
                        width={"100%"}
                        style={{ margin: "auto", display: "flex" }}
                      />
                    </div>
                  ) : (
                    <div className="container">
                      <div class="tbc-map-full detail-page-map region-detail-map">
                        <div class="map-hero-image map-wet-market">
                          {combinedData && (
                            <App
                              data={combinedData}
                              data1={regionMapData}
                              mapType="detail-page"
                              isMultiMarker={true}
                              zoom={"zoom"}
                              singleMap={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {menuItem == "11" && (
                <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                  <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="region" />
                </div>
              )}
              {menuItem == "12" && (
                <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                  <References
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    section="region"
                    isEdit={editMode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div class="container custom-citation">
        <div className="custom-citation-text">Citations</div>
        <div
          className="custom-citation-text"
          dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
        ></div>
        {details?.updatedByVolunteer?.firstName && (
          <div className="last-edited">
            {" "}
            Last edited on {date.toLocaleString()} by{" "}
            <span className="text-capitalize">
              {details?.updatedByVolunteer?.firstName} {details?.updatedByVolunteer?.lastName}{" "}
            </span>
          </div>
        )}
      </div>
    </main>
  );
};

export default EditRegionDetail;
