import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { capitalizeContentInBrackets, capitalizeFirstLetterWord, cleanAndFormatHtml } from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import parse from "html-react-parser";
import Details from "../../components/organizationDetail/details";
import Works from "../../components/organizationDetail/works";
import Teams from "../../components/organizationDetail/teams";
import Partners from "../../components/organizationDetail/partners";
import Programs from "../../components/organizationDetail/programs";
import Species from "../../components/environmentalistDetail/species";
import Reports from "../../components/organizationDetail/reports";
import UserUpload from "../../components/common/userUpload/userUpload";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import App from "../../common/maps/map";
import ShareModal from "../../components/common/sharemodal/shareModal";
import editIcon2 from "../../include/images/edit-2.svg";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";

//Edit details modal for organization
const EditOrganization = ({
  details,
  loadingDetail,
  items,
  isEditable,
  editMode,
  id,
  setSelectedId,
  menuItem,
  openShareModal,
  setOpenShareModal,
  donationUrl,
  handleMenuItemClick,
  combinedData,
  orgMapData,
  addTargetBlankToLinks,
  miscSettingCitation,
  editState,
  setEditState,
}) => {
  const indexesToRemove = [];
  const filteredItems = items.filter((item, index) => !indexesToRemove.includes(index));
  const [imgSrc, setImgSrc] = useState(null);
  const [detailImgSrc, setDetailImgSrc] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e, img) => {
    const file = e.target.files[0];
    if (file) {
      setEditState((prevState) => ({
        ...prevState,
        [img]: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        if (img == "coverImage") {
          setImgSrc(reader.result);
        } else if (img == "detailTabImage") {
          setDetailImgSrc(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <main id="main">
      {/* <div class="org-hero-container">
           {loadingDetail ? <div class="org-hero-bg org-hero-bg1"><Skeleton borderRadius={"10px"} height={"100%"} /> </div> : <div class="org-hero-bg org-hero-bg1">
            <img
              src={
                details?.data?.bannerImage?.original ? process.env.REACT_APP_MEDIA +
                  details?.data?.bannerImage?.original : defaultcover
              }
              alt=""
            />
          </div>}
      </div> */}
      <div class="org-detail-container">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="org-detail-main d-flex flex-wrap align-items-start mt-2">
                <div class="od-logo">
                  {loadingDetail ? (
                    <div class="od-logo-inner">
                      <Skeleton borderRadius={"10px"} height={"100%"} />{" "}
                    </div>
                  ) : (
                    <div class="od-logo-inner">
                      <img
                        src={
                          imgSrc?.startsWith("data:") ? imgSrc : `${process.env.REACT_APP_MEDIA}${editState.coverImage}`
                        }
                        alt="Species"
                      />
                      <Link className="edit-btn species-detailsedit-btn" onClick={triggerFileInput}>
                        <img src={editIcon2} alt="Edit" />
                      </Link>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, "coverImage")}
                      />
                    </div>
                  )}
                  <ul class="hero-social-media-list d-flex flex-wrap">
                    {details?.data?.websiteUrl ? (
                      <li class="ltb-icon-item">
                        <a
                          href={details?.data?.websiteUrl}
                          target="_blank"
                          rel="noreferrer"
                          class="ltb-icon-box custom-icon"
                        >
                          <svg
                            width="40"
                            height="1em"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 21C17.9706 21 22 16.9706 22 12C22 7.02944 17.9706 3 13 3C8.02944 3 4 7.02944 4 12C4 16.9706 8.02944 21 13 21Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 12H22"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.9984 3C15.2496 5.46452 16.5289 8.66283 16.5984 12C16.5289 15.3372 15.2496 18.5355 12.9984 21C10.7473 18.5355 9.46796 15.3372 9.39844 12C9.46796 8.66283 10.7473 5.46452 12.9984 3Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {details?.data?.socialMediaLink?.facebook ? (
                      <li class="ltb-icon-item">
                        <a
                          href={details?.data?.socialMediaLink?.facebook}
                          target="_blank"
                          class="ltb-icon-box custom-icon"
                          rel="noreferrer"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {details?.data?.socialMediaLink?.twitter ? (
                      <li class="ltb-icon-item">
                        <a
                          href={details?.data?.socialMediaLink?.twitter}
                          target="_blank"
                          class="ltb-icon-box custom-icon"
                          rel="noreferrer"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                          </svg>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {details?.data?.socialMediaLink?.instagram ? (
                      <li class="ltb-icon-item">
                        <a
                          href={details?.data?.socialMediaLink?.instagram}
                          target="_blank"
                          class="ltb-icon-box custom-icon"
                          rel="noreferrer"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                <div class="od-content">
                  <span class="text-title text-green">Organizations</span>
                  <div class="od-content-heading d-flex">
                    {loadingDetail ? (
                      <Skeleton borderRadius={"10px"} height={40} width={200} />
                    ) : (
                      <h1 className="caps-text ">
                        <div className="form-group species-detail-name edit-org-name">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={editState.name}
                            onChange={handleChange}
                            className="text-capitalize d-flex"
                          />
                        </div>
                      </h1>
                    )}
                    {details?.data?.isAddedToDonation === true ? (
                      <a
                        href={donationUrl ? `https://${donationUrl}` : "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn btn-default btn-donate-org ${!donationUrl ? "disabled" : ""}`}
                      >
                        Donate Direct
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {loadingDetail ? (
                    <ul class="w-100 mt-4">
                      <Skeleton count={3} borderRadius={"10px"} width={"100%"} />
                    </ul>
                  ) : (
                    <ul class="page-detail-head-list ml-0">
                      <textarea
                        name="introduction"
                        className="w-100"
                        value={stripHtmlTags(editState.introduction)}
                        onChange={handleChange}
                        rows="10"
                        cols="50"
                      />
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingDetail ? (
        <div class="tabs-block-container">
          <div class="tbc-head ">
            <Skeleton
              borderRadius={"10px"}
              height={"7.5vh"}
              baseColor="var(--dark-green-color)"
              highlightColor="#0cb40c"
            />
          </div>
          <div class="container">
            {" "}
            <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
          </div>
        </div>
      ) : (
        <div class="tabs-block-container new-tabs-block-container">
          <div class="tbc-head ">
            <div class="container">
              <div class="row">
                <div className="col-md-12">
                  <form className="d-lg-none">
                    <select
                      className="form-select"
                      id="tabSelector"
                      onChange={(e) => handleMenuItemClick(e.target.value)}
                      value={menuItem}
                    >
                      {items.map(
                        (item) =>
                          item.data?.length > 0 && (
                            <option key={item.id} value={item.id}>
                              {item.label} {item.count > 0 && `(${item.count})`}
                            </option>
                          ),
                      )}
                    </select>
                  </form>
                  <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                    {items.map(
                      (item) =>
                        item.data?.length > 0 && (
                          <li className="nav-item" key={item.id} role="presentation">
                            <div
                              className={`nav-link ${menuItem === item.id && "active"}`}
                              id={`pills-${item.id}-tab`}
                              data-bs-toggle="pill"
                              data-bs-target={`#pills-${item.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`pills-${item.id}`}
                              aria-selected={menuItem === item.id}
                              onClick={() => handleMenuItemClick(item.id)}
                            >
                              {item.label} {item.count > 0 && `(${item.count})`}
                            </div>
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tbc-body overflow-hidden">
            <div class="tab-content tab-content-slider" id="pills-tabContent">
              {menuItem == "1" && (
                <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                  <Details
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    editMode={editMode}
                    editState={editState}
                    handleChange={handleChange}
                    stripHtmlTags={stripHtmlTags}
                  />
                </div>
              )}
              {menuItem == "2" && (
                <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                  <Works menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                </div>
              )}

              {menuItem == "3" && (
                <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                  <Teams menuItem={menuItem} id={id} />
                </div>
              )}

              {menuItem == "4" && (
                <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                  <Partners menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                </div>
              )}

              {menuItem == "5" && (
                <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                  <Programs menuItem={menuItem} id={id} name={details?.data?.name} setSelectedId={setSelectedId} />
                </div>
              )}

              {menuItem == "6" && (
                <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                  <Species
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    page="organization"
                    editMode={editMode}
                  />
                </div>
              )}
              {menuItem == "7" && (
                <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                  <Reports menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                </div>
              )}
              {menuItem == "8" && (
                <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                  <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" />
                </div>
              )}
              {menuItem == "9" && (
                <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                  <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" />
                </div>
              )}
              {menuItem == "10" && (
                <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                  <div className="container">
                    <div class="tbc-map-full detail-page-map region-detail-map">
                      <div class="map-hero-image map-wet-market">
                        {combinedData && (
                          <App
                            data={combinedData}
                            data1={orgMapData}
                            mapType="detail-page"
                            isMultiMarker={true}
                            zoom={"zoom"}
                            singleMap={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {menuItem == "11" && (
                <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                  <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" />
                </div>
              )}
              {menuItem == "12" && (
                <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                  <References
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    section="organization"
                    isEdit={editMode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ShareModal
        show={openShareModal}
        onHide={() => setOpenShareModal(!openShareModal)}
        name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
        id={details?.data?._id}
        category="organization"
      />
      <div class="container custom-citation">
        <div className="custom-citation-text">Citations</div>
        <div
          className="custom-citation-text"
          dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
        ></div>
      </div>
    </main>
  );
};

export default EditOrganization;
