import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    InfoWindowF,
    Marker,
} from "@react-google-maps/api";
import { ultraLightTheme } from "./ultraLightTheme";
import marker from "../../include/images/marker.svg";
import marker1 from "../../include/images/marker1.svg";

const NewMarkerZoom = (props) => {
    const [zoom, setZoom] = useState(parseFloat(props.zoom) || 12);
    const [center, setCenter] = useState({ lat: 0.745, lng: 0.745 });
    const [showInfo, setShowInfo] = useState(false);
    const [hoveredCords, setHoveredCords] = useState({ lat: "", lng: "" });


    useEffect(() => {
        if (props.singleMap && props.data && props.data.length > 0) {
            const firstDataItem = props.data[0];
            const initialCenter = {
                lat: +firstDataItem.coordinates?.latitude,
                lng: +firstDataItem.coordinates?.longitude,
            };
            setCenter(initialCenter);
            if (props.page == "wetMarket") {
                setZoom(16)
            } else {
                setZoom(parseFloat(props.zoom) || 12);
            }
        }
    }, [props.singleMap, props.data, props.zoom]);

    const mapContainerStyle = {

        height: "400px",
        width: "100%",
    };

    const mapData =
        props.data &&
        props.data.map((item) => ({
            name: item.name,
            coordinates: item.coordinates,
        }));

    const mapData1 =
        (props.data1 &&
            props.data1?.map((item) => ({
                name: item.name,
                coordinates: item.coordinates,
            }))) ||
        [];

    return (

        <GoogleMap
            id="marker-example"
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
            options={{ styles: ultraLightTheme }}
        >
            {props.isMultiMarker &&
                mapData1 &&
                mapData1.length > 0 &&
                mapData1.map((item, i) => (
                    <Marker
                        key={i}
                        icon={{
                            url: marker1,
                        }}
                        onMouseOver={() => {
                            setHoveredCords({
                                lat: +item?.coordinates?.latitude,
                                lng: +item?.coordinates?.longitude,
                            });
                            setShowInfo(true);
                        }}
                        onMouseOut={() => {
                            setHoveredCords({ lat: "", lng: "" });
                            setShowInfo(false);
                        }}
                        onClick={() => {
                            if (
                                zoom > 18 &&
                                center.lat === +item?.coordinates?.latitude
                            ) {
                                setZoom(12); // Set an appropriate default zoom level
                                setCenter({ lat: 0.745, lng: 0.745 });
                            } else {
                                setZoom(parseFloat(zoom) + 4);
                                setCenter({
                                    lat: +item?.coordinates?.latitude,
                                    lng: +item?.coordinates?.longitude,
                                });
                            }
                        }}
                        position={{
                            lat: +item?.coordinates?.latitude,
                            lng: +item?.coordinates?.longitude,
                        }}
                    >
                        {showInfo &&
                            hoveredCords.lat === +item?.coordinates?.latitude && (
                                <InfoWindowF className="infoWindow-container">
                                    <div className="infoWindow">
                                        <p>{item.name}</p>
                                    </div>
                                </InfoWindowF>
                            )}
                    </Marker>
                ))}

            {mapData &&
                mapData.length > 0 &&
                mapData.map((item, i) => (
                    <Marker
                        key={i}
                        icon={{
                            url: marker,
                        }}
                        onMouseOver={() => {
                            setHoveredCords({
                                lat: +item?.coordinates?.latitude,
                                lng: +item?.coordinates?.longitude,
                            });
                            setShowInfo(true);
                        }}
                        onMouseOut={() => {
                            setHoveredCords({ lat: "", lng: "" });
                            setShowInfo(false);
                        }}

                        onClick={() => {
                            if (
                                zoom > 18 &&
                                center.lat === +item?.coordinates?.latitude
                            ) {
                                setZoom(12); // Set an appropriate default zoom level
                                setCenter({ lat: 0.745, lng: 0.745 });
                            } else {
                                setZoom(parseFloat(zoom) + 4);
                                setCenter({
                                    lat: +item?.coordinates?.latitude,
                                    lng: +item?.coordinates?.longitude,
                                });
                            }
                        }}
                        position={{
                            lat: +item?.coordinates?.latitude,
                            lng: +item?.coordinates?.longitude,
                        }}
                    >
                        {showInfo &&
                            hoveredCords.lat === +item?.coordinates?.latitude && (
                                <InfoWindowF className="infoWindow-container">
                                    <div className="infoWindow">
                                        <p>{item.name}</p>
                                    </div>
                                </InfoWindowF>
                            )}
                    </Marker>
                ))}
        </GoogleMap>

    );
};

export default NewMarkerZoom;
