import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  getWorkDetails,
  organizationListing,
} from "../../store/organization";
import { removeTags, capitalizeContentInBrackets } from "../../utils/helperFunctions";
import Skeleton from "react-loading-skeleton";
import HTMLReactParser from "html-react-parser";
import Pagination from "../common/Pagination/pagination";

const Works = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedItemIndex, setExpandedItemIndex] = useState(null); // State to control which item's description is expanded
  const topElementRef = useRef(null);
  const details = props.organizationListing && props.organizationListing.workDetail;
  const [initialRender, setInitialRender] = useState(true); // State to track initial render

  useEffect(() => {
    // Check if it's the initial render
    if (!initialRender) {
      // Define a function to fetch data from the API
      const fetchData = async () => {
        try {
          const params = {
            id: props.id,
            page: currentPage,
          };
          setLoading(true);
          // Make the API call using props.getWorkDetails
          const res = await props.getWorkDetails(params);
          if (res && res.status === 200) {
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };

      // Call the fetchData function only when currentPage changes
      fetchData();
    } else {
      setInitialRender(false);
    }
  }, [currentPage, props.id]);

  const dotDesc = (description, limit, index) => {
    const dots = "...";
    if (description && description.length > limit && expandedItemIndex !== index) {
      return description.substring(0, limit) + dots;
    }
    return description;
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const handleToggleShowMore = (index) => {
    if (expandedItemIndex === index) {
      setExpandedItemIndex(null);
    } else {
      setExpandedItemIndex(index);
    }
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: 'smooth',
      });
    }
  };

  const pageLinks = Array.from({ length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) }, (_, i) => i + 1);

  return (
    <div className="container" ref={topElementRef}>
      <div className="row">
        <div className="col-md-12">
          <div className="tbc-body-inner">
            <>
              {loading ? (
                <ul className="tbc-grid-list org-ul d-flex flex-wrap">
                  {Array.from({ length: 20 }, (item, index) => (
                    <li className="tbc-grid-item" key={index}>
                      <div className="tbc-grid-box">
                        <h4 className="text-capitalize">
                          <Skeleton borderRadius={"10px"} width="560px" height="33px" />
                        </h4>
                        <p>
                          <Skeleton borderRadius={"10px"} width="560px" height="119px" />
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tbc-grid-list  org-ul d-flex flex-wrap">
                    {details && details.data && details.data.length
                      ? details.data.map((item, index) => {
                        return (
                          <li className="tbc-grid-item" key={index}>
                            <div className="tbc-grid-box">
                              <h4 className="text-capitalize">
                                {HTMLReactParser(capitalizeContentInBrackets(removeTags(item.name)))}
                              </h4>
                              <p>
                                {" "}
                                {item.description
                                  ? dotDesc(
                                    HTMLReactParser(removeTags(item.description)),
                                    300,
                                    index // Show only 300 characters
                                  )
                                  : ""}
                                {item.description && item.description.length > 300 && (
                                  <p className="map-switch" onClick={() => handleToggleShowMore(index)}>
                                    {expandedItemIndex === index ? "Less" : "More"}
                                  </p>
                                )}
                              </p>

                            </div>
                          </li>
                        );
                      })
                      : ""}
                  </ul>
                </>
              )}
              {details && details?.totalCount > 20 && (<Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />)}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) =>
    dispatch(getOrganizationDetails(params, callback)),
  getWorkDetails: (params, callback) =>
    dispatch(getWorkDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Works));
