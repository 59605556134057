import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "wetMarket",
  initialState: {
    wetMarket: {},
    details: {},
    reports: {},
    media: {},
    species: {},
    liveFeeds: {},
    loading: false,
  },
  reducers: {
    wetmarketRequested: (misc, action) => {
      misc.loading = true;
    },
    wetmarketReceived: (misc, action) => {
      misc.wetMarket = action.payload;
      misc.loading = false;
    },
    wetmarketRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    detailsRequested: (misc, action) => {
      misc.loading = true;
    },
    detailsReceived: (misc, action) => {
      misc.details = action.payload;
      misc.loading = false;
    },
    detailsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    reportRequested: (misc, action) => {
      misc.loading = true;
    },
    reportReceived: (misc, action) => {
      misc.reports = action.payload;
      misc.loading = false;
    },
    reportRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    mediaRequested: (misc, action) => {
      misc.loading = true;
    },
    mediaReceived: (misc, action) => {
      misc.media = action.payload;
      misc.loading = false;
    },
    mediaFailed: (misc, action) => {
      misc.loading = false;
    },
    liveFeedsRequested: (misc, action) => {
      misc.loading = true;
    },
    liveFeedsReceived: (misc, action) => {
      misc.liveFeeds = action.payload;
      misc.loading = false;
    },
    liveFeedsFailed: (misc, action) => {
      misc.loading = false;
    },
    speciesRequested: (misc, action) => {
      misc.loading = true;
    },
    speciesReceived: (misc, action) => {
      misc.species = action.payload;
      misc.loading = false;
    },
    speciesFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  wetmarketRequested,
  wetmarketReceived,
  wetmarketRequestFailed,
  detailsRequested,
  detailsReceived,
  detailsRequestFailed,
  reportRequested,
  reportReceived,
  reportRequestFailed,
  mediaRequested,
  mediaReceived,
  mediaFailed,
  liveFeedsRequested,
  liveFeedsReceived,
  liveFeedsFailed,
  speciesRequested,
  speciesReceived,
  speciesFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "wetMarket";

export const getwetmarket = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "get",
      params,
      callback,
      onStart: wetmarketRequested.type,
      onSuccess: wetmarketReceived.type,
      onError: wetmarketRequestFailed.type,
    })
  );
};

export const getwetmarketDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/details/${params}`,
      method: "get",
      callback,
      onStart: detailsRequested.type,
      onSuccess: detailsReceived.type,
      onError: detailsRequestFailed.type,
    })
  );
};
export const getwetmarketReports = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/report/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: reportRequested.type,
      onSuccess: reportReceived.type,
      onError: reportRequestFailed.type,
    })
  );
};
export const getWetMarketMedia = (params, callback) => (dispatch) => {
  const mediaType = params.mediaType || "media";
  const url = `${baseUrl}/media/${mediaType === "document" ? "doc/" : ""}${params.id}?page=${params.page}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: mediaRequested.type,
      onSuccess: mediaReceived.type,
      onError: mediaFailed.type,
    })
  );
};
export const getWetMarketLiveFeeds = (params, callback) => (dispatch) => {
  const url = `${baseUrl}/liveFeed/${params.id}?page=${params.page}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: liveFeedsRequested.type,
      onSuccess: liveFeedsReceived.type,
      onError: liveFeedsFailed.type,
    })
  );
};
export const getSpeciesWetMarket = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: speciesRequested.type,
      onSuccess: speciesReceived.type,
      onError: speciesFailed.type,
    })
  );
};
export const updateSpeciesWetMarket = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};
export const deleteSpeciesWetMarket = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "delete",
      data,
      callback,
    })
  );
};
export const wetMarketData = createSelector(
  (state) => state.entities.wetMarket,
  (wetMarket) => wetMarket
);
