import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";
import noRecord from "../../../include/images/nrb-img.svg";
import { addReference, getMisc, getReferences, updateReference } from "../../../store/misc";
import { connect } from "react-redux";
import AddReference from "./addReference";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";

const References = (props) => {
  const [loading, setLoading] = useState(false);
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const [description, setDescription] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleAdd = () => {
    setLoading(true);
    setOpenAddModal(false);
    const payload = {
      section: props.section,
      id: props.id,
      description: description,
    };
    props.addReference(payload, (res) => {
      if (res && res.status == 200) {
        setDescription("")
        const params = {
          order: "desc",
          created: "createdAt",
          id: props.id,
          page: currentPage,
          section: props.section,
        };
        props.getReferences(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
            toast(<AlertSuccess message={"Reference Added"} />);
          }
        });
      } else {
        setLoading(false);
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });

    return doc.body.innerHTML;
  };
  return (
    <div className="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            {props.isEdit && (
              <div class="d-flex flex-wrap justify-content-end mb-4">
                <Link onClick={() => setOpenAddModal(true)} class="btn btn-default ms-2 add-more-btn-edit-tab">
                  Add
                </Link>
              </div>
            )}
            {loading ? (
              <ul className="media-list w-100" style={{ marginTop: "0px" }}>
                {Array.from({ length: 6 }, (_, index) => (
                  <li className="media-item w-100" key={index}>
                    <div className="widget-outer">
                      <Skeleton borderRadius={"10px"} width="100%" height={"150px"} />
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {references && references.length > 0 ? (
                  <ol className="w-100 ps-3" style={{ marginTop: "0px" }}>
                    {references?.map((item, idx) => (
                      <li className="reference-item w-text-box pt-2" key={idx}>
                        <p dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(item.description) }}></p>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <ul className="blog-list d-flex flex-wrap justify-content-center">
                    <div
                      className="no-records-container d-flex align-items-center justify-content-center"
                      style={{ height: "auto" }}
                    >
                      <div className="no-records-box text-center">
                        <div className="nrb-image">
                          <img src={noRecord} alt="" />
                        </div>
                        <h6>No Records Found</h6>
                      </div>
                    </div>
                  </ul>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <AddReference
        show={openAddModal}
        onHide={() => {
          setOpenAddModal(false);
        }}
        heading={"Add References"}
        handleAdd={handleAdd}
        description={description}
        setDescription={setDescription}
      />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
  addReference: (data, callback) => dispatch(addReference(data, callback)),
  updateReference: (params, data, callback) => dispatch(updateReference(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(References));
