import React from "react";
import Layout from "../../components/forgotPassword/layout";
import tickCircle from "../../include/images/tick-circle.svg";
import { Link } from "react-router-dom";

const ResetPasswordSuccessful = () => {
  return (
    <Layout>
      <div class="login-container fw-medium">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="login-content-holder mx-auto">
                <div class="sucess-messgae-block text-center">
                  <div class="block-icon-box">
                    <img src={tickCircle} alt="" />
                  </div>
                  <p>Password has been successfully changed</p>
                  <Link to="/login" class="btn btn-default">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPasswordSuccessful;
