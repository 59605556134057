import React, { useEffect, useState } from "react";
import {
    forgotPassword,
    getUser,
    resetPassword,
    verifyCodeForgotPassword,
} from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import {
    getMisc,
} from "../../store/misc";
import _ from "lodash";
import { useRef } from "react";
import AlertSuccess from "../../common/alerts/alertSuccess";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import NextButton from "../../common/form/nextButton";
import Joi from "joi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { validateErrorMessage } from "../../common/validation/validation";
import eyeImg from "../../include/images/eye-d.svg";
import eyeOff from "../../include/images/eye-d-off.svg";
import tickCircle from "../../include/images/tick-circle.svg";

//Modal to change password
const ChangePassword = (props) => {
    var ref = useRef();
    const [step, setStep] = useState("1");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [forgetPasId, setForgetPasId] = useState(null)
    const [data, setData] = useState({ pwd: "", pwd1: "" });
    const [showEyes, setShowEyes] = useState({ eye1: false, eye2: false });

    const [error, setError] = useState({});

    const textBoxOne = useRef(null);
    const textBoxTwo = useRef(null);
    const textBoxThree = useRef(null);
    const textBoxFour = useRef(null);
    const textBoxFive = useRef(null);
    const textBoxSix = useRef(null);

    const [code, setCode] = useState({
        first: null,
        second: null,
        third: null,
        fourth: null,
        fifth: null,
        six: null,
    });
    const [timeLeft, setTimeLeft] = useState(null);

    const schema = Joi.object({
        pwd: Joi.string()
            .label("Password")
            .min(6)
            .required()
            .error((errors) => validateErrorMessage(errors)),
        pwd1: Joi.string()
            .label("Confirm Password")
            .valid(Joi.ref("pwd"))
            .required()
            .error((errors) => validateErrorMessage(errors)),
    });

    const validate = () => {
        const error = schema.validate(data, { abortEarly: false });
        if (error.error && error.error.details) {
            const errors = {};
            error.error.details.forEach((element) => {
                errors[element.context.key] = element.message;
            });
            setError(errors);
            return false;
        }
        setError("");
        return true;
    };

    const handleResetPassword = () => {
        if (!validate()) return;
        setLoading(true);
        ref?.current?.continuousStart();
        props?.resetPassword(forgetPasId, { newPassword: data?.pwd }, (res) => {
            if (res && res?.status == 200) {
                setLoading(false);
                ref?.current?.complete();
                setStep("4")
            } else {
                setLoading(false);
                ref?.current?.complete();
                toast(
                    <AlertError
                        message={
                            res?.data?.message ? res.data.message : "Something went wrong"
                        }
                    />
                );
            }
        });
    };

    const handleSendCode = () => {
        setLoading(true);
        ref?.current?.continuousStart();
        props?.forgotPassword({ email }, (res) => {
            if (res && res.status == 200) {
                setLoading(false);
                ref?.current?.complete();
                setStep("2");
                toast(
                    <AlertSuccess
                        message={
                            res?.data?.message
                                ? res?.data?.data?.message
                                : "Code Sent Successfully"
                        }
                    />
                );
            } else {
                setLoading(false);
                ref?.current?.complete();
                toast(
                    <AlertError
                        message={
                            res?.data?.message ? res?.data?.message : "Something went wrong"
                        }
                    />
                );
            }
        });
    };

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null);
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);
    useEffect(() => setTimeLeft(30), []);

    const resendOtp = () => {
        ref && ref.current && ref.current.continuousStart();
        setLoading(true);
        props.forgotPassword({ email }, (res) => {
            if (res && res?.status == 200) {
                setStep("2");
                setTimeLeft(30);
                setLoading(false);
                ref?.current?.complete();
                toast(
                    <AlertSuccess message={"Your 6 digit code has been resent "} />
                );
            } else {
                ref && ref.current && ref.current.complete();
                setLoading(false);
                toast(
                    <AlertError
                        message={
                            res && res.data && res.data.message
                                ? res.data.message
                                : "Something Went Wrong"
                        }
                    />
                );
            }
        });
    };

    const verifyCode = (e) => {
        if (
            code?.first &&
            code?.second &&
            code?.third &&
            code?.fourth &&
            code?.fifth &&
            code?.six
        ) {
            setError({});
            e.preventDefault();
            ref && ref.current && ref.current.continuousStart();
            setLoading(true);
            const payload = {
                code: `${code?.first}${code?.second}${code?.third}${code?.fourth}${code?.fifth}${code?.six}`,
                email: email
            };
            props.verifyCodeForgotPassword(payload, (res) => {
                if (res.status === 200) {
                    setForgetPasId(res?.data?.data?.id)
                    setStep("3")
                    ref && ref.current && ref.current.complete();
                    setLoading(false);
                } else {
                    ref && ref.current && ref.current.complete();
                    setLoading(false);
                    toast(
                        <AlertError
                            message={
                                res && res.data && res.data.message
                                    ? res.data.message
                                    : "Something Went Wrong"
                            }
                        />
                    );
                }
            });
        } else {
            const errors = { ...error };
            errors.message = "Please enter 6 digit verification code to continue";
            setError(errors);
        }
    };

    const codeChangeHandler = (event) => {
        const element = event.target;
        const nextSibling = event?.target?.value
            ? element.nextElementSibling
            : element.previousElementSibling;
        nextSibling ? nextSibling.focus() : element.blur();
    };
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        const pastedValues = pastedText.split('').slice(0, 6);

        const data = {
            first: pastedValues[0] || '',
            second: pastedValues[1] || '',
            third: pastedValues[2] || '',
            fourth: pastedValues[3] || '',
            fifth: pastedValues[4] || '',
            six: pastedValues[5] || ''
        };
        setCode(data);
    };
    return (
        <>
            <ProfileLayout >
                <div class="panel-main-content d-flex flex-column">
                    <div class="panel-head">
                        <div class="breadcrumb-row d-flex flex-wrap">

                        </div>
                        <h1>Change Password</h1>
                    </div>
                    {step == "1" && (
                        <div class="login-content-holder mx-auto p-md-2">
                            <div class="login-head-row">
                                <p>
                                    Simply enter your email address and you'll receive an email to
                                    reset your password shortly.
                                </p>
                            </div>
                            <form class="login-form-row-group">
                                <div class="form-floating-row d-flex flex-wrap">
                                    <div class="ff-column">
                                        <div class="form-floating custom-form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="emailAddress"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label for="emailAddress">Email Address</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-end">
                                    <Link
                                        class={`btn btn-default btn-lg ${loading || !email ? "disabled" : ""
                                            }`}
                                        onClick={() => handleSendCode()}
                                    >
                                        Submit
                                    </Link>
                                </div>
                            </form>
                        </div>
                    )}
                    {step == "2" && (
                        <div class="rfc-content-holder mx-auto p-md-2">
                            <div class="rf-head-row">
                                <div class="rf-label-box email-label-box">
                                    Enter Verification Code sent to{" "}
                                    <Link class="fw-medium">
                                        {email}
                                    </Link>
                                </div>
                            </div>
                            <form class="rf-form-row-group">
                                <div class="otp-input-fields">
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.first}
                                        ref={textBoxOne}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.first = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.second}
                                        ref={textBoxTwo}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.second = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.third}
                                        ref={textBoxThree}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.third = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.fourth}
                                        ref={textBoxFour}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.fourth = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.fifth}
                                        ref={textBoxFive}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.fifth = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                    <input
                                        type="number"
                                        class="otp-box"
                                        required
                                        maxLength={1}
                                        min="0"
                                        max="9"
                                        value={code?.six}
                                        ref={textBoxSix}
                                        onChange={(e) => {
                                            const data = { ...code };
                                            data.six = e.target.value.slice(0, 1);
                                            setCode(data);
                                            codeChangeHandler(e);
                                        }}
                                        onPaste={(e) => handlePaste(e)}
                                    />
                                </div>
                                {error.message ? (
                                    <p style={{ color: "red", fontSize: "14px", paddingTop: "10px" }}>
                                        {error.message}
                                    </p>
                                ) : (
                                    ""
                                )}
                                {timeLeft && (
                                    <p className={timeLeft ? "otp-resend " : ""}>
                                        You can resend code in {timeLeft} seconds
                                    </p>
                                )}

                                <div class="rf-form-btn d-flex align-items-center space-between resend-container">
                                    <p
                                        className={`link-text cursor-pointer mb-0 ${!timeLeft ? "" : "disable-resend-otp"
                                            }`}
                                        onClick={() => {
                                            if (timeLeft) return;
                                            resendOtp();
                                        }}
                                    >
                                        Resend Code
                                    </p>
                                    <NextButton
                                        handleSubmit={(e) => {
                                            verifyCode(e);
                                        }}
                                        loading={loading}
                                        classData={"btn btn-default btn-lg"}
                                        label="Continue"
                                        disabled={loading}
                                        page={"signup"}
                                    />
                                </div>
                            </form>
                        </div>

                    )}

                    {step == "3" &&
                        <form class="login-form-row-group">
                            <div class="form-floating-row d-flex flex-wrap">
                                <div class="ff-column">
                                    <div class="form-floating custom-form-floating">
                                        <input
                                            type={showEyes?.eye1 ? "text" : "password"}
                                            class="form-control"
                                            id="floatingPassword1"
                                            placeholder="Enter Password"
                                            value={data?.pwd}
                                            onChange={(e) => {
                                                const value = { ...data };
                                                value.pwd = e.target.value;
                                                setData(value);
                                            }}
                                        />
                                        <label for="floatingPassword">New Password</label>
                                        <button
                                            type="button"
                                            class="show-password-btn"
                                            data-target="floatingPassword1"
                                            onClick={() => {
                                                const data = { ...showEyes };
                                                data.eye1 = !showEyes?.eye1;
                                                setShowEyes(data);
                                            }}
                                        >
                                            <img
                                                src={showEyes?.eye1 ? eyeImg : eyeOff}
                                                alt="eye d"
                                            />
                                        </button>
                                    </div>
                                    {error && error?.pwd ? (
                                        <p style={{ color: "red", fontSize: "12px" }}>
                                            {error?.pwd}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div class="form-floating-row d-flex flex-wrap">
                                <div class="ff-column">
                                    <div class="form-floating custom-form-floating">
                                        <input
                                            type={showEyes?.eye2 ? "text" : "password"}
                                            class="form-control"
                                            id="floatingPassword2"
                                            placeholder="Re-enter Password"
                                            value={data?.pwd1}
                                            onChange={(e) => {
                                                const value = { ...data };
                                                value.pwd1 = e.target.value;
                                                setData(value);
                                            }}
                                        />
                                        <label for="floatingPassword">
                                            Re-enter New Password
                                        </label>
                                        <button
                                            type="button"
                                            class="show-password-btn"
                                            data-target="floatingPassword2"
                                            onClick={() => {
                                                const data = { ...showEyes };
                                                data.eye2 = !showEyes?.eye2;
                                                setShowEyes(data);
                                            }}
                                        >
                                            <img
                                                src={showEyes?.eye2 ? eyeImg : eyeOff}
                                                alt="eye d"
                                            />
                                        </button>
                                    </div>
                                    {error && error?.pwd1 ? (
                                        <p style={{ color: "red", fontSize: "12px" }}>
                                            {error?.pwd1}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-end">
                                <Link
                                    class={`btn btn-default btn-lg ${loading ? "disabled" : ""}`}
                                    onClick={() => handleResetPassword()}
                                >
                                    Submit
                                </Link>
                            </div>
                        </form>}
                    {step == "4" &&
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="login-content-holder mx-auto">
                                        <div class="sucess-messgae-block text-center">
                                            <div class="block-icon-box">
                                                <img src={tickCircle} alt="" />
                                            </div>
                                            <p>Password has been successfully changed</p>
                                            <Link to="/profile" class="btn btn-default">
                                                Back to Profile
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ProfileLayout>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (data, callback) => dispatch(forgotPassword(data, callback)),
    verifyCodeForgotPassword: (data, callback) =>
        dispatch(verifyCodeForgotPassword(data, callback)),
    resetPassword: (id, data, callback) =>
        dispatch(resetPassword(id, data, callback)),
});

const mapStateToProps = (state) => ({
    getMisc: getMisc(state),
    getUser: getUser(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(ChangePassword));
