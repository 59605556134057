import Joi from "joi";
import React from "react";
import { validateErrorMessage } from "../../common/validation/validation";
import eyeImg from "../../include/images/eye-d.svg";
import eyeOff from "../../include/images/eye-d-off.svg";
import { useState } from "react";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Layout from "./layout";
import { connect } from "react-redux";
import { resetPassword } from "../../store/users";
import { getProfile, logout } from "../../utils/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ResetPassword = (props) => {
  const history = useHistory();
  var ref = useRef();
  const [data, setData] = useState({ pwd: "", pwd1: "" });
  const [showEyes, setShowEyes] = useState({ eye1: false, eye2: false });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const schema = Joi.object({
    pwd: Joi.string()
      .label("Password")
      .min(6)
      .required()
      .error((errors) => validateErrorMessage(errors)),
    pwd1: Joi.string()
      .label("Confirm Password")
      .valid(Joi.ref("pwd"))
      .required()
      .error((errors) => validateErrorMessage(errors)),
  });

  // useEffect(() => {
  //   if (!getProfile()) {
  //     window.location.assign("/login");
  //   }
  // });

  const validate = () => {
    const error = schema.validate(data, { abortEarly: false });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setError(errors);
      return false;
    }
    setError("");
    return true;
  };

  const handleResetPassword = () => {
    if (!validate()) return;
    setLoading(true);
    ref?.current?.continuousStart();
    props?.resetPassword(props?.forgetPasId, { newPassword: data?.pwd }, (res) => {
      if (res && res?.status == 200) {
        setLoading(false);
        logout();
        ref?.current?.complete();
        history.push("/reset-password-successful");
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(
          <AlertError
            message={
              res?.data?.message ? res.data.message : "Something went wrong"
            }
          />
        );
      }
    });
  };

  console.log("errors", error);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Layout>
        <div class="login-container fw-medium">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="login-content-holder mx-auto">
                  <div class="login-head-row">
                    <h1>Reset password</h1>

                  </div>
                  <form class="login-form-row-group">
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class="form-floating custom-form-floating">
                          <input
                            type={showEyes?.eye1 ? "text" : "password"}
                            class="form-control"
                            id="floatingPassword1"
                            placeholder="Enter Password"
                            value={data?.pwd}
                            onChange={(e) => {
                              const value = { ...data };
                              value.pwd = e.target.value;
                              setData(value);
                            }}
                          />
                          <label for="floatingPassword">New Password</label>
                          <button
                            type="button"
                            class="show-password-btn"
                            data-target="floatingPassword1"
                            onClick={() => {
                              const data = { ...showEyes };
                              data.eye1 = !showEyes?.eye1;
                              setShowEyes(data);
                            }}
                          >
                            <img
                              src={showEyes?.eye1 ? eyeImg : eyeOff}
                              alt="eye d"
                            />
                          </button>
                        </div>
                        {error && error?.pwd ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {error?.pwd}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class="form-floating custom-form-floating">
                          <input
                            type={showEyes?.eye2 ? "text" : "password"}
                            class="form-control"
                            id="floatingPassword2"
                            placeholder="Re-enter Password"
                            value={data?.pwd1}
                            onChange={(e) => {
                              const value = { ...data };
                              value.pwd1 = e.target.value;
                              setData(value);
                            }}
                          />
                          <label for="floatingPassword">
                            Re-enter New Password
                          </label>
                          <button
                            type="button"
                            class="show-password-btn"
                            data-target="floatingPassword2"
                            onClick={() => {
                              const data = { ...showEyes };
                              data.eye2 = !showEyes?.eye2;
                              setShowEyes(data);
                            }}
                          >
                            <img
                              src={showEyes?.eye2 ? eyeImg : eyeOff}
                              alt="eye d"
                            />
                          </button>
                        </div>
                        {error && error?.pwd1 ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {error?.pwd1}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="login-form-btn d-flex align-items-center justify-content-end">
                      <a
                        href="#"
                        class={`btn btn-default ${loading ? "disabled" : ""}`}
                        onClick={() => handleResetPassword()}
                      >
                        Submit
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (id, data, callback) =>
    dispatch(resetPassword(id, data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
