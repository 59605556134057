import React, { useEffect } from 'react';

const GoogleTranslateWidget = () => {
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: 'en',
                autoDisplay: false,
            },
            'google_translate_element',
        );

        // Hide extra select elements after the widget initializes
        const selects = document.querySelectorAll('#google_translate_element select');
        
        // Keep the first select, hide all others
        selects.forEach((select, index) => {
            if (index > 0) {
                select.style.display = 'none';
            }
        });
    };

    useEffect(() => {
        const addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    return (
        <div id="google_translate_element"></div>
    );
};

export default GoogleTranslateWidget;
