import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import {
  followUnfollowFriends,
  getFollowersList,
  getFollowingList,
  getUser,
} from "../../store/users";
import FollowersAndFollowingList from "../../components/profile/followersAndFollowingList";
import AlertError from "../../common/alerts/alertError";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";

const UserProfile = (props) => {
  const ref = useRef();
  const [tab, setTab] = useState("followers");
  const [loading, setLoading] = useState(false);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [page, setPage] = useState(1);
  const followersCount = props?.getFollowers?.data?.length;
  const followingCount = props?.getFollowings?.data?.length;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      props.getFollowersList({ page }),
      props.getFollowingList({ page }),
    ]).then((responses) => {
      setLoading(false);
    }).catch(error => {
      console.error("Error fetching followers and following:", error);
      setLoading(false);
    });
  }, [page]);
  Useauthredirect();
  const handleFollow = (id) => {
    ref?.current?.continuousStart();
    setLoadingFollow(true);
    props?.followUnfollowFriends(id, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingFollow(false);
      } else {
        ref?.current?.complete();
        setLoadingFollow(false);
        <AlertError
          message={
            res?.data?.message ? res?.data?.message : "Something went wrong"
          }
        />;
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <ProfileLayout>
        <div class="panel-main-content d-flex flex-column">
          <div class="panel-body flex-grow-1">
            <div class="widget-tab-wrapper">
              <div class="wpw-title"></div>
              <div class="widget-tab-nav">
                <div class="nav nav-tabs" id="myTab" role="tablist">
                  <div class="nav-item" role="presentation">
                    <a
                      class={`nav-link ${tab === "followers" ? "active" : ""}`}
                      id="widgetPane1"
                      data-bs-toggle="tab"
                      href="#!"
                      onClick={() => setTab("followers")}
                    >
                      Followers ({followersCount})
                    </a>
                  </div>
                  <div class="nav-item" role="presentation">
                    <a
                      class={`nav-link ${tab === "following" ? "active" : ""}`}
                      id="widgetPane2"
                      data-bs-toggle="tab"
                      href="#!"
                      onClick={() => setTab("following")}
                    >
                      Following ({followingCount})
                    </a>
                  </div>
                </div>
              </div>
              <FollowersAndFollowingList
                data={tab === "followers" ? props?.getFollowers?.data : props?.getFollowings?.data}
                tab={tab}
                loading={loading}
                totalPages={tab === "followers" ? Math.ceil(props?.getFollowers?.filteredCount / 20) : Math.ceil(props?.getFollowings?.filteredCount / 20)}
                page={page}
                setPage={setPage}
                handleFollow={handleFollow}
              />
            </div>
          </div>
        </div>
      </ProfileLayout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getFollowersList: (params, callback) =>
    dispatch(getFollowersList(params, callback)),
  getFollowingList: (params, callback) =>
    dispatch(getFollowingList(params, callback)),
  followUnfollowFriends: (data, callback) =>
    dispatch(followUnfollowFriends(data, callback)),
});

const mapStateToProps = (state) => ({
  getFollowers: getUser(state)?.followers,
  getFollowings: getUser(state)?.following,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserProfile));
