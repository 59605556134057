import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRegionDetails, regionListings } from "../../../store/region";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetterWord,
  transformDropdownData,
} from "../../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import shareSvg from "../../../include/images/share.svg";
import ShareModal from "../../common/sharemodal/shareModal";
import editIcon from "../../../include/images/edit.svg";
import editIcon2 from "../../../include/images/edit-2.svg";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import Editor from "../../../common/editor/editor";
import { getProfile, setProfile } from "../../../utils/localStorageServices";
import { getProfileInfo, updateYourProfile } from "../../../store/users";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";

const Details = (props) => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const details = props.regionDetails && props.regionDetails.regionDetails;
  const [imgSrc, setImgSrc] = useState(null);
  const profile = getProfile();

  const regionsDropdown = transformDropdownData(props?.allRegionDetails || []);
  const handleSelectChange = (selectedOptions) => {
    props.setEditState((prevState) => ({
      ...prevState,
      region: selectedOptions,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    props.setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleImageChange = (e, img) => {
    const file = e.target.files[0];
    if (file) {
      props.setEditState((prevState) => ({
        ...prevState,
        [img]: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const extractLabel = useCallback((interestedArray, dropdownArray) => {
    return interestedArray
      .map((id) => {
        const foundObject = dropdownArray.find((obj) => obj?.value === id);
        return foundObject ? { value: foundObject.value, label: foundObject.label } : null;
      })
      .filter((item) => item);
  }, []);
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
    }
    return description;
  };
  const handleRegionsFollow = (e) => {
    e.preventDefault();
    const payload = {
      preferredRegions: [details?._id, ...(profile?.profile?.preferredRegions || [])],
    };
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        props.getProfileInfo((res) => {
          if (res && res.status === 200) {
            let tempData = { ...profile };
            tempData.profile = res?.data?.data;
            setProfile(tempData);
          }
        });
        toast(<AlertSuccess message="You are now following this Region." />);
        props.setFollow(true);
      } else {
        toast(<AlertError message={res?.data?.message || "Something went wrong. Please try again later."} />);
      }
    });
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="listing-details-container d-flex flex-wrap justify-content-between environmentalist-details-container"
              style={{ padding: "30px 0px 30px 0px" }}
            >
              {props.loading ? (
                <div className="ldc-image mb-4">
                  <Skeleton className="detail-img-skeleton" />
                </div>
              ) : (
                <div className="ldc-image ldc-image-2 position-relative">
                  {props.editMode ? (
                    <div className="environmentalist-details-img">
                      <img
                        src={
                          imgSrc?.startsWith("data:")
                            ? imgSrc
                            : `${process.env.REACT_APP_MEDIA}${props.editState?.coverImage}`
                        }
                        alt="Region"
                      />
                      {/* <Link className="edit-btn region-detail-edit-img" onClick={triggerFileInput}>
                        <img src={editIcon2} alt="Edit" />
                      </Link>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, "coverImage")}
                      /> */}
                    </div>
                  ) : (
                    <div className="environmentalist-details-img">
                      <img
                        src={
                          details?.detailPageImage?.original
                            ? process.env.REACT_APP_MEDIA + details?.detailPageImage?.original
                            : process.env.REACT_APP_MEDIA + details?.coverImage?.original
                        }
                        alt=""
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="ldc-text-2">
                <div className="ldc-text-head pb-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <Link to={"/regions"} className="text-title text-green">
                      Region
                    </Link>
                    {profile?.profile && (
                      <div className="ms-5">
                        <button
                          onClick={!props.follow ? handleRegionsFollow : undefined}
                          className="btn btn-default h-40"
                        >
                          {props.follow ? "Following" : "Follow"}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="ldc-text-main">
                    {props.loading ? (
                      <React.Fragment>
                        <div>
                          <Skeleton borderRadius={"10px"} height={"40px"} />
                        </div>
                        <div>
                          <Skeleton borderRadius={"10px"} />
                        </div>
                      </React.Fragment>
                    ) : (
                      <div>
                        <h1>
                          {capitalizeContentInBrackets(details?.state ? details?.state : "")}
                          <div className="ml-20">
                            <Link onClick={() => setOpenShareModal(true)}>
                              <img src={shareSvg} alt="" />
                            </Link>{" "}
                            {profile?.profile?.isVolunteer && props.isEdit && !props.editMode && (
                              <Link
                                onClick={() => {
                                  props.setEditMode(true);
                                }}
                              >
                                <img src={editIcon} alt="" />
                              </Link>
                            )}
                          </div>
                        </h1>
                        <h6>{details.subTitle ? details.subTitle : ""}</h6>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ldc-text-body-2 region-edit">
                  {props.loading ? (
                    <Skeleton count={3} borderRadius={"10px"} />
                  ) : (
                    <>
                      {showFullDescription
                        ? parse(details?.description)
                        : parse(dotDesc(stripHtmlTags(details?.description), 800))}
                      {details?.description?.length > 800 && (
                        <span className="read-more-detail" onClick={() => setShowFullDescription(!showFullDescription)}>
                          {showFullDescription ? " Show less" : " Read more"}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ShareModal
            show={openShareModal}
            onHide={() => setOpenShareModal(!openShareModal)}
            name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.state))}
            id={details._id}
            category="region"
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRegionDetails: (params, callback) => dispatch(getRegionDetails(params, callback)),
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getProfileInfo: (params, callback) => dispatch(getProfileInfo(params, callback)),
});

const mapStateToProps = (state) => ({
  regionDetails: regionListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Details));
