import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import docImage from "../../../include/images/doc-image1.jpg";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { Amplify, Storage, Auth } from "aws-amplify";
import { getMisc, uploadEducationTab, uploadMedia } from "../../../store/misc";
import { connect } from "react-redux";
import AlertModal from "../../feed/modals/alertModal";
import Editor from "../../../common/editor/editor";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import essayIcon from "../../../include/images/Essay 2.svg";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const UploadModal = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [screenWidth, setWidth] = useState(0);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [videoLinks, setVideoLinks] = useState([]);
  const [isModeChanged, setIsModeChanged] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [tempType, setTempType] = useState("");
  const [essayText, setEssayText] = useState("");
  const [error, setError] = useState(false);
  const [classtd, setClass] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    class: "",
    teacherName: "",
    school: "",
    description: "",
  });
  useEffect(() => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);
  const handleFileChange = (event) => {
    const files = event.target.files;
    const totalFiles = [...selectedFiles, ...files];

    if (
      (selectedFileType === "image" || selectedFileType === "sketch" || selectedFileType === "essay") &&
      totalFiles.length <= 20 &&
      totalFiles.every((file) => file.size <= 2 * 1024 * 1024)
    ) {
      setSelectedFiles(totalFiles);
    } else if (
      selectedFileType === "video" &&
      totalFiles.length <= 2 &&
      totalFiles.reduce((acc, file) => acc + file.size, 0) <= 30 * 1024 * 1024 &&
      videoLinks.length <= 5
    ) {
      setSelectedFiles(totalFiles);
    } else {
      const errorMessage =
        selectedFileType === "image" || selectedFileType === "sketch" || selectedFileType === "essay"
          ? "Maximum 20 images/sketches allowed. Each should be <= 2MB."
          : selectedFileType === "video"
          ? "Maximum 2 videos allowed. Total size <= 30MB and max 5 video links are allowed."
          : "Invalid file selection.";
      toast(<AlertError message={errorMessage} />);
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDocumentFileChange = (event, index) => {
    const newDocumentFiles = [...documentFiles];
    const file = event.target.files[0];

    if (file && file.size <= 10 * 1024 * 1024) {
      newDocumentFiles[index] = file;
      setDocumentFiles(newDocumentFiles);
    } else {
      toast(<AlertError message={"Total size of documents should be <= 10MB."} />);
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDocumentNameChange = (event, index) => {
    const newDocumentNames = [...documentNames];
    const newName = event.target.value;
    newDocumentNames[index] = newName;
    setDocumentNames(newDocumentNames);
  };

  const handleDocRemove = (index) => {
    const newDocumentFiles = [...documentFiles];
    const newDocumentNames = [...documentNames];

    newDocumentFiles.splice(index, 1);
    newDocumentNames.splice(index, 1);

    setDocumentFiles(newDocumentFiles);
    setDocumentNames(newDocumentNames);
  };
  const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleVideoLinkChange = (index, link) => {
    const newVideoLinks = [...videoLinks];
    newVideoLinks[index - 1] = link;
    setVideoLinks(newVideoLinks);
  };

  const handleModeChange = (newMode) => {
    if (selectedFiles.length > 0 || videoLinks.length > 0 || documentFiles.length > 0) {
      setShowChangeModal(true);
      setTempType(newMode);
    } else {
      setIsModeChanged(true);
      setSelectedFileType(newMode);
    }
  };

  const handleChangeMode = () => {
    setIsModeChanged(true);
    setSelectedFiles([]);
    setVideoLinks([]);
    setDocumentFiles([]);
    setDocumentNames([]);
    setData({
      firstName: "",
      class: "",
      teacherName: "",
      school: "",
      description: "",
    });
    setSelectedFileType(tempType);
    setShowChangeModal(false);
  };

  const handleUpload = async () => {
    if (!data?.firstName || data.firstName.trim() === "") {
      setError(true);
      return;
    }
    if (
      Array.from(selectedFiles)?.length ||
      videoLinks.some((item) => item !== null) ||
      documentFiles.some((file) => file !== null) ||
      essayText.trim()
    ) {
      setUploading(true);
      try {
        const createPayload = (type, files) => {
          const payload = {
            section: props.section,
            id: props.id,
          };
          if (data?.firstName) payload.firstName = data.firstName;
          if (data?.class) payload.class = data.class;
          if (data?.school) payload.school = data.school;
          if (data?.teacherName) payload.teacherName = data.teacherName;
          if (data?.description && data.description.trim() !== "") {
            payload.description = data.description.trim();
          }
          payload.files = files;
          return payload;
        };

        if (selectedFileType === "image" || selectedFileType === "sketch") {
          const imagePromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            imagePromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: selectedFileType,
                mediaSource: "s3",
              })),
            );
          }
          const imageResult = await Promise.all([...imagePromiseContainer]);
          const imagePayload = createPayload(selectedFileType, imageResult);
          props.uploadMedia(imagePayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              props.setShowMedia(false);
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              setError(false);
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        } else if (selectedFileType === "video") {
          const videoPromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            videoPromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: "video",
                mediaSource: "s3",
              })),
            );
          }
          for (let i = 0; i < videoLinks.length; i++) {
            const item = videoLinks[i];
            if (item !== null) {
              videoPromiseContainer.push(
                Promise.resolve({
                  media: item,
                  mediaType: "video",
                  mediaSource: "url",
                }),
              );
            }
          }
          const videoResult = await Promise.all(videoPromiseContainer);
          const videoPayload = createPayload("video", videoResult);
          await props.uploadMedia(videoPayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              props.setShowMedia(false);
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              setError(false);
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        } else if (selectedFileType === "document") {
          if (documentFiles.length > documentNames.length) {
            setUploading(false);
            return toast(<AlertError message={`Please provide names for document`} />);
          }
          const documentPromiseContainer = [];
          const uploadedDocumentIndexes = [];
          const documentNamesArray = [];
          for (let i = 0; i < documentFiles.length; i++) {
            const file = documentFiles[i];
            if (file !== null) {
              uploadedDocumentIndexes.push(i);
              const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
              documentPromiseContainer.push(
                Storage.put(fileName, file, {
                  completeCallback: (event) => {},
                  progressCallback: (progress) => {},
                  errorCallback: (err) => {
                    return toast(<AlertError message={"Something Went Wrong"} />);
                  },
                }).then((result) => ({
                  media: `public/${result.key}`,
                  mediaType: "document",
                  mediaSource: "s3",
                  name: documentNames[i],
                })),
              );
              const documentName = documentNames[i].trim();
              if (documentNamesArray.includes(documentName)) {
                setUploading(false);
                return toast(<AlertError message={`Duplicate document name: ${documentName}`} />);
              }
              documentNamesArray.push(documentName);
            }
          }
          if (uploadedDocumentIndexes.length > 0) {
            const missingNames = uploadedDocumentIndexes.filter((index) => documentNames[index].trim() === "");
            if (missingNames.length > 0) {
              setUploading(false);
              return toast(<AlertError message={`Please provide names for document`} />);
            }
          }
          try {
            const documentResult = await Promise.all([...documentPromiseContainer]);
            const documentPayload = createPayload("document", documentResult);
            await props.uploadMedia(documentPayload, (res) => {
              if (res && res.status === 200) {
                setSelectedFileType("");
                props.setShowMedia(false);
                setData({
                  firstName: "",
                  class: "",
                  teacherName: "",
                  school: "",
                  description: "",
                });
                setError(false);
                toast(
                  <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
                );
              }
            });
          } catch (error) {
            toast(<AlertError message={"Error uploading documents"} />);
          }
        } else if (selectedFileType === "essay") {
          const imagePromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            imagePromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: "essay",
                mediaSource: "s3",
              })),
            );
          }
          const imageResult = await Promise.all([...imagePromiseContainer]);
          const essayPayload = createPayload("essay", imageResult);
          await props.uploadMedia(essayPayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              props.setShowMedia(false);
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              setError(false);
              toast(
                <AlertSuccess message="Thank you for your submission! Your essay has been successfully uploaded. It is currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        }
      } catch (error) {
        setUploading(false);
        return toast(<AlertError message="Something went wrong." />);
      }
    } else {
      return toast(<AlertError message={"Nothing to upload"} />);
    }
    setSelectedFiles([]);
    setDocumentFiles([]);
    setVideoLinks([]);
    setDocumentNames([]);
    setData({
      firstName: "",
      class: "",
      teacherName: "",
      school: "",
      description: "",
    });
    setUploading(false);
    props.onHide();
  };

  const options = [
    { value: "image", label: "Images" },
    { value: "video", label: "Videos" },
    { value: "document", label: "Research Papers" },
    { value: "sketch", label: "Animal Art" },
    { value: "essay", label: "Essay" },
  ];
  const handleChange = (e) => {
    let data1 = { ...data };
    let { name, value } = e.target;
    setData({
      ...data1,
      [name]: value,
    });
  };
  const getDocImage = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase(); // Get the file extension

    switch (extension) {
      case "pdf":
        return pdf;
      case "doc":
        return doc;
      case "docx":
        return docx;
      case "txt":
        return txt;
      case "xls":
      case "xlsx":
        return xls;
      case "xlxs":
        return xlxs;
      default:
        return doc; // Default to a generic doc icon if no match
    }
  };

  return (
    <>
      <div className="modal-content d-flex flex-column position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="modal-header pb-2  pt-0 modal-head view-all-header mb-2">
                <h6 className="modal-title text-capitalize education-select-text mt-0" style={{ color: "#227300" }}>
                  {props.heading}
                </h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="file-type-select">
                <Select
                  className="basic-single text-capitalize"
                  classNamePrefix="select-search"
                  value={options.find((option) => option.value === selectedFileType)}
                  onChange={(item) => handleModeChange(item?.value)}
                  options={options}
                  styles={customStyles1}
                  placeholder="Select file type"
                />
              </div>
              <div className="file-type-alert ps-1">
                <p className="upload-limit-text">
                  {selectedFileType === "image" && "(Max 20 images and each image size <= 2MB)"}
                  {selectedFileType === "video" && "(Max 2 videos, each video <= 30MB and max 5 video links)"}
                  {selectedFileType === "document" && "(Total file size <= 10MB)"}
                  {selectedFileType === "sketch" && "(Max 20 animal art and each image <= 2MB)"}
                  {selectedFileType === "essay" && "(50-100 words or Essay image)"}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="form-control mb-2"
                value={data?.firstName}
                onChange={handleChange}
              />
              {error ? (
                <div className="file-type-alert ps-1">
                  <p className="upload-limit-text" style={{ color: "red" }}>
                    First name is required.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col-md-12">
              <input
                type="text"
                name="class"
                placeholder="Grade"
                className="form-control mb-2"
                value={data?.class}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12">
              <input
                type="text"
                placeholder="School"
                name="school"
                className="form-control mb-2"
                value={data?.school}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12">
              <input
                type="text"
                placeholder="Teacher Name"
                name="teacherName"
                className="form-control mb-2"
                value={data?.teacherName}
                onChange={handleChange}
              />
            </div> */}
            <div className="col-md-12 mb-2">
              <Editor
                id="textarea"
                name="description"
                placeholder="Description"
                className="w-100"
                onChange={(item) => {
                  handleChange(item);
                }}
                editorLoaded={true}
                style={{ height: "300px" }}
                value={data?.description}
              />
            </div>
          </div>
          {selectedFileType === "image" || selectedFileType === "sketch" ? (
            <div className="row mt-2">
              <div className="col-md-12">
                <label className="btn btn-default h-40 d-flex align-items-center justify-content-center w-25">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  Choose Files
                </label>
              </div>
            </div>
          ) : selectedFileType === "video" ? (
            <div className="row mt-2 mb-2">
              <div className="col-md-12">
                <label className="btn btn-default h-40 d-flex align-items-center justify-content-center w-25">
                  <input
                    type="file"
                    accept="video/*"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                  Choose Files
                </label>
              </div>
              {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
                <div className="col-md-12 mt-2">
                  <input
                    key={num}
                    type="text"
                    className="form-control"
                    placeholder={`Video link ${num}`}
                    value={videoLinks[num - 1] || ""}
                    onChange={(e) => handleVideoLinkChange(num, e.target.value)}
                  />
                </div>
              ))}
            </div>
          ) : selectedFileType === "document" ? (
            <div className="row mt-2">
              <div className="col-md-12">
                <label className="btn btn-default h-40 d-flex align-items-center justify-content-center w-25">
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => handleDocumentFileChange(e, documentFiles.length)}
                  />
                  Choose Files
                </label>
              </div>
              {documentFiles.length > 0 && (
                <div className="col-md-12">
                  <h5 className="mb-2 education-select-text">Selected Files:</h5>
                </div>
              )}
              <div className="col-md-14 document-files">
                {documentFiles.map((file, index) => (
                  <div key={index} className="document-file d-flex justify-content-start align-items-center">
                    <img src={getDocImage(file.name)} className="doc-image mt-0" alt="document" />
                    <input
                      type="text"
                      className="form-control w-75"
                      placeholder="Document name"
                      value={documentNames[index] || ""}
                      onChange={(e) => handleDocumentNameChange(e, index)}
                    />
                    <button
                      className="ms-2 gallery-remove-btn position-relative top-0 right-auto"
                      onClick={() => handleDocRemove(index)}
                    >
                      <img src={trash} alt="remove" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ) : selectedFileType === "essay" ? (
            <div className="row mt-2">
              <div className="col-md-12 upload-section">
                <label className="btn btn-default h-40 d-flex align-items-center justify-content-center w-25">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                  Choose Files
                </label>
              </div>
            </div>
          ) : null}
          {selectedFiles.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <h5 className="mb-1 education-select-text">Selected Files:</h5>
              </div>
              <div className="col-md-14 d-flex flex-wrap">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="gallery-item gallery-item-education m-1">
                    {file.type.startsWith("image/") && (
                      <img src={URL.createObjectURL(file)} className="gallery-image" alt="selected" />
                    )}
                    {file.type.startsWith("video/") && (
                      <video controls width="150" height="100" className="gallery-image">
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <button className="gallery-remove-btn" onClick={() => handleFileRemove(index)}>
                      <img src={trash} alt="remove" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col-md-18">
              <button className="btn btn-default w-100 h-40" onClick={handleUpload} disabled={uploading}>
                {uploading ? "Uploading..." : "Add"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
        deleteHandler={handleChangeMode}
        title="Change Upload Type"
        type={"Confirm"}
        message="Changing the upload type will clear your current selection. Are you sure you want to proceed?"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadMedia: (data, callback) => dispatch(uploadEducationTab(data, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UploadModal));
