import moment from "moment";
import React from "react";
import { Dropdown } from "react-bootstrap";
import editIcon from "../../include/images/heart.svg";
import wIcon from "../../include/images/w-icon-1.svg";
import wIcon2 from "../../include/images/w-icon-2.svg";
import wIcon3 from "../../include/images/w-icon-3.svg";
import liked from "../../include/images/fill-heart-icon.svg";
import wIcon4 from "../../include/images/w-icon-4.svg";
import wIcon5 from "../../include/images/w-icon-5.svg";
import volume from "../../include/images/w-volume-2.svg";
import { useState } from "react";
import Comments from "./modals/comments";
import ImageHolder from "../../common/customStyles/imageHolder";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getRelativeTime } from "../../utils/helperFunctions";
import ReportPost from "./modals/reportPost";
import {
  addPostToViewedDuringSession,
  getProfile,
  hasPostBeenViewedDuringSession,
} from "../../utils/localStorageServices";
import LoadingBar from "react-top-loading-bar";
import PostComment from "./modals/postComment";
import { connect } from "react-redux";
import noPost from "../../include/images/pew-feed-icon.svg";
import {
  deleteComments,
  getComments,
  getPosts,
  likeUnlikePost,
  likesReceived,
  postComments,
  updateComments,
  viewPost,
} from "../../store/post";
import { useRef } from "react";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { useEffect } from "react";
import { feedReceived, getMyFeedData, getMyPostData, getUser, postReceived } from "../../store/users";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DmPosts from "./modals/dmPosts";
import Posts from "../../pages/feed/post";

import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage, Auth } from "aws-amplify";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import PostGalleryModal from "./postGallery";
import ViewPost from "../common/post/viewPost";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const Post = ({ myPostData, userProfile, deleteHandler, ...props }) => {
  const ref = useRef();
  const location = useLocation();
  const [showComments, setShowComments] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newCommentData, setNewCommentData] = useState({
    text: "",
    media: null,
    isMediaNew: false,
  });
  const [showDmPost, setShowDmPost] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const profile = getProfile();
  const observedIds = new Set();
  const [showPost,setShowPost]=useState(false);

  useEffect(() => {
    if (selectedItem && showAllComments) {
      setShowComments(false);
      const id = selectedItem?._id;
      setLoading(true);
      props?.getComments(id, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        } else {
          setLoading(false);
          <AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />;
        }
      });
    }
  }, [showAllComments]);
  useEffect(() => {
    if (myPostData) {
      const options = {
        root: null,
        rootMargin: "10px 0px",
        threshold: 1,
      };
      const handleIntersect = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const postId = entry.target.dataset.postId;
            if (!observedIds.has(postId)) {
              observedIds.add(postId);
              sendPostViewData(postId);
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersect, options);

      myPostData.forEach((post) => {
        const target = document.getElementById(post._id);
        if (target) {
          observer.observe(target);
        }
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [myPostData]);

  const sendPostViewData = async (postId) => {
    if (profile?.profile && location?.pathname !== "/profile") {
      // Check if the post has been viewed during the session
      const postHasBeenViewed = hasPostBeenViewedDuringSession(postId);
      // If the post hasn't been viewed during the session, mark it as viewed
      if (!postHasBeenViewed) {
        addPostToViewedDuringSession(postId);
        props.viewPost(postId, (res) => {});
      }
    }
  };
  const toggleShowComments = () => {
    setShowComments(!showComments);
    setNewCommentData({
      text: "",
      media: null,
      isMediaNew: false,
    });
  };

  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const toggleDmPosts = () => {
    setShowDmPost(!showDmPost);
  };
  const toggleAllComments = () => {
    setShowAllComments(!showAllComments);
  };

  const handlePostComment = () => {
    const id = selectedItem?._id;
    const createdId = selectedItem?.createdBy?._id;
    if (newCommentData.isMediaNew) {
      const promiseContainer = [];
      setLoading(true);
      ref?.current?.continuousStart();
      const file = newCommentData.media;
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name?.split(".").pop();
      if (fSize > 25) {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return;
      } else if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
          "video/MP4",
          "video/mp4",
          "video/webm",
          "video/mpeg",
        ].includes(fType)
      ) {
        ref?.current?.complete();
        setLoading(false);
        toast(
          <AlertError message="Media is not of correct format and hence cannot be uploaded. Valid Media formats are jpeg, jpg, png, webp, MP4 ,mpeg and webm." />,
        );
        return;
      } else {
        const fileName = uuidv4() + "." + ext;
        promiseContainer.push(
          Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              return (
                ref?.current?.complete(), setLoading(false), toast(<AlertError message={"Something Went Wrong"} />)
              );
            },
          }),
        );
      }
      const allPromise = Promise.all([...promiseContainer]);
      allPromise.then((result) => {
        const payload = {
          file: "public/" + result[0]?.key,
          content: newCommentData?.text,
        };
        props?.postComments(id, payload, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
            const payload = {
              page: 1,
              sort: "createdAt",
              order: "desc",
            };
            if (location?.pathname !== "/profile") {
              props.getMyFeedData(payload, (res) => {
                if (res && res.status === 200) {
                  ref?.current?.complete();
                  toggleShowComments();
                  toast(<AlertSuccess message={"Comment Posted Successfully"} />);
                } else {
                  ref?.current?.complete();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            } else {
              props.getMyPostData(payload, (res) => {
                if (res && res.status === 200) {
                  ref?.current?.complete();
                  toggleShowComments();
                  toast(<AlertSuccess message={"Comment Posted Successfully"} />);
                } else {
                  ref?.current?.complete();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            }
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      });
    } else {
      const payload = {
        content: newCommentData?.text,
      };
      setLoading(true);
      ref?.current?.continuousStart();
      props?.postComments(id, payload, (res) => {
        if (res && res.status == 200) {
          let updatedMyPostData;
          setLoading(false);
          const payload = {
            page: 1,
            sort: "createdAt",
            order: "desc",
          };
          updatedMyPostData = myPostData.map((post) => {
            if (post._id === id) {
              return {
                ...post,
                commentCount: post.commentCount + 1,
              };
            }
            return post;
          });
          if (location?.pathname !== "/profile") {
            props.setPostData(updatedMyPostData);
            props.feedReceived({ data: updatedMyPostData });
            ref?.current?.complete();
            toggleShowComments();
            toast(<AlertSuccess message={"Comment Posted Successfully"} />);
          } else {
            props.postReceived({ data: updatedMyPostData });
            ref?.current?.complete();
            toggleShowComments();
            toast(<AlertSuccess message={"Comment Posted Successfully"} />);
          }
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  const likeAndUnlikePost = (type, id) => {
    ref?.current?.continuousStart();
    const data = id;
    props?.likeUnlikePost(type, data, (res) => {
      if (res && res.status == 200) {
        let updatedMyPostData;
        if (props?.likedPosts == "like") {
          updatedMyPostData = myPostData.filter((post) => {
            return post._id !== id;
          });
        } else {
          updatedMyPostData = myPostData.map((post) => {
            if (post._id === id) {
              if (type === "like") {
                return {
                  ...post,
                  isLiked: true,
                  likeCount: post.likeCount + 1,
                };
              } else if (type === "unlike") {
                return {
                  ...post,
                  isLiked: false,
                  likeCount: post.likeCount > 0 ? post.likeCount - 1 : 0,
                };
              }
            }
            return post;
          });
        }

        if (location?.pathname !== "/profile") {
          props.setPostData(updatedMyPostData);
          props.feedReceived({ data: updatedMyPostData });
        } else {
          if (props?.likedPosts == "like") {
            props?.likesReceived({ data: updatedMyPostData });
          } else {
            props.postReceived({ data: updatedMyPostData });
          }
        }
        ref?.current?.complete();
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const commentsData = props?.getPosts?.comments;
  const togglePost=()=>{
    setShowPost(!showPost)
  }
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <>
        {myPostData && !props?.loading ? (
          myPostData?.length > 0 ? (
            myPostData.map((item,idx) => {
              const mediaType = item?.files?.original ? item?.files[0]?.original?.split(".")[1] : ".jpg";
              return (
                <PostGalleryModal
                  key={idx}
                  item={item}
                  likedPosts={props?.likedPosts}
                  location={location}
                  deleteHandler={deleteHandler}
                  setSelectedItem={setSelectedItem}
                  toggleDmPosts={toggleDmPosts}
                  toggleShowReport={toggleShowReport}
                  toggleShowComments={toggleShowComments}
                  likeAndUnlikePost={likeAndUnlikePost}
                  togglePost={togglePost}
                  page={props?.page}
                />
              );
            })
          ) : (
            <>
              {props.likedPosts == "post" ? (
                <div class="panel-body flex-grow-1">
                  <div class="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
                    <div class="pew-icon icon-lg">
                      <img src={noPost} alt="pew feed icon" />
                    </div>
                    <h5>No Posts to Show</h5>
                    <p className="mb-2">
                      Oops! It seems like you don't have any posts to view at the moment. Why not create some amazing
                      content to share with your followers?
                    </p>
                    <Link to={"/add-post"}>
                      <button className="btn btn-default btn-block">Add Post</button>
                    </Link>
                  </div>
                </div>
              ) : props.likedPosts == "like" ? (
                <div class="panel-body flex-grow-1">
                  <div class="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
                    <div class="pew-icon icon-lg">
                      <img src={noPost} alt="pew feed icon" />
                    </div>
                    <h5>No Liked Posts</h5>
                    <p className="mb-2">
                      Looks like you haven't liked any posts yet. Feel free to explore and engage with content that
                      interests you. Your likes help support the community and let others know what resonates with you.
                      Happy browsing!
                    </p>
                    <Link to={"/post"}>
                      <button className="btn btn-default btn-block">Go To Posts</button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div class="panel-body flex-grow-1">
                  <div class="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto">
                    <div class="pew-icon icon-lg">
                      <img src={noPost} alt="pew feed icon" />
                    </div>
                    <h5>No Posts to Show</h5>
                    <p>
                      Oops! It seems like you don't have any posts to view at the moment. Why not create some amazing
                      content to share with your followers? Click on "Add Post" to create a post.
                    </p>
                  </div>
                </div>
              )}
            </>
          )
        ) : (
          [1, 2, 3, 4].map((skeletonItem) => (
            <div class="widget d-flex flex-wrap" key={skeletonItem}>
              <div class="widget-icon z-0">
                <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
              </div>
              <div class="widget-content z-0">
                <div class="widget-text-holder">
                  <div class="w-heading-block position-relative">
                    <div class="w-heading-box d-flex align-items-center flex-wrap ">
                      <div className="w-label-box fw-semibold text-capitalize">
                        <Skeleton borderRadius={"10px"} height={45} width={150} />
                      </div>
                    </div>
                  </div>
                  <div className="w-image-holder mt-35 z-0">
                    <div className="d-flex flex-wrap">
                      <div
                        className=" position-relative"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Skeleton borderRadius={"10px"} height={"40vh"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </>
      <PostComment
        show={showComments}
        onHide={toggleShowComments}
        selectedItem={selectedItem}
        setNewCommentData={setNewCommentData}
        newCommentData={newCommentData}
        loading={loading}
        handlePostComment={handlePostComment}
        toggleAllComments={toggleAllComments}
        likeUnlikePost={likeAndUnlikePost}
        myPostData={myPostData}
        toggleDmPosts={toggleDmPosts}
      />
      {/* <Comments
        show={showAllComments}
        onHide={toggleAllComments}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        loading={loading}
        commentsData={commentsData?.data}
        deleteHandler={deleteHandler}
        toggleShowReport={toggleShowReport}
        likeUnlikePost={likeAndUnlikePost}
        toggleDmPosts={toggleDmPosts}
        myPostData={myPostData}
      /> */}
      <ViewPost 
        show={showPost}
        onHide={togglePost}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        myPostData={myPostData} 
        setPostData={props.setPostData}
      />
      <Posts
        show={showAllComments}
        onHide={toggleAllComments}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        loading={loading}
        commentsData={commentsData?.data}
        deleteHandler={deleteHandler}
        toggleShowReport={toggleShowReport}
        likeUnlikePost={likeAndUnlikePost}
        toggleDmPosts={toggleDmPosts}
        myPostData={myPostData}
      />

      <ReportPost show={showReport} onHide={toggleShowReport} selectedItem={selectedItem} />
      <DmPosts show={showDmPost} onHide={toggleDmPosts} selectedItem={selectedItem} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  postComments: (id, data, callback) => dispatch(postComments(id, data, callback)),
  getComments: (id, callback) => dispatch(getComments(id, callback)),
  updateComments: (id1, id2, data, callback) => dispatch(updateComments(id1, id2, data, callback)),
  deleteComments: (id1, id2, callback) => dispatch(deleteComments(id1, id2, callback)),
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)),
  getMyFeedData: (params, callback) => dispatch(getMyFeedData(params, callback)),
  getMyPostData: (params, callback) => dispatch(getMyPostData(params, callback)),
  viewPost: (id, callback) => dispatch(viewPost(id, callback)),
  feedReceived: (payload) => dispatch(feedReceived(payload)),
  postReceived: (payload) => dispatch(postReceived(payload)),
  likesReceived: (payload) => dispatch(likesReceived(payload)),
});

const mapStateToProps = (state) => ({
  getPosts: getPosts(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Post));
