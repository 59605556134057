import React from "react";
import logo from "../../include/images/logo.svg";
// import copyRight from "../../include/images/copyright.svg";

const Layout = ({ children }) => {
  return (
    <div class="page-outer-wrapper font-family-poppins grey-bg">
      <header id="header">
        <nav class="navbar navbar-expand-xl">
          <div class="container">
            <div class="nav-inside d-flex align-items-center justify-content-center">
              <a class="navbar-brand" href="/home">
                <img src={logo} alt="" />
              </a>
            </div>
          </div>
        </nav>
      </header>
      {children}
      <div
        id="footer2"
        class="d-flex align-items-center justify-content-center"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 d-md-flex align-items-md-center justify-content-md-center">
              <ul class="footer-nav-list d-flex justify-content-center justify-content-md-start align-items-center">
                <li>
                  <a href="#!">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#!">Privacy Policy</a>
                </li>
              </ul>
              <div class="copyright-box">
                &copy; Environmental Inclusion. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
