import React, { useRef, useState } from "react";
import logo from "../../include/images/logo.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


//Greetings modal when registration is complete
const Greetings = () => {
  return (
    <>
      <div className="page-outer-wrapper font-family-poppins grey-bg">
        <header id="header">
          <nav className="navbar navbar-expand-xl">
            <div className="container">
              <div className="nav-inside d-flex align-items-center justify-content-center">
                <Link to={"/"} className="navbar-brand">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <div className="login-container fw-medium">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-content-holder mx-auto">
                  <div className="login-head-row text-center">
                    <h1>Congratulations!</h1>
                  </div>
                  <div className="form-group register-user-form fw-normal text-center">
                    <h6 className="fw-normal">
                      Your registration is successful. Your account is now pending for admin approval. You will be
                      notified once your account has been approved.
                    </h6>
                  </div>

                  <div className="login-form-btn d-flex align-items-center justify-content-end">
                    <Link className="btn btn-default" to="/login">
                      Login
                    </Link>
                  </div>

                  <div className="login-link">
                    <Link to="/">Back to Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer2" className="d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-md-flex align-items-md-center justify-content-md-center">
              <ul className="footer-nav-list d-flex justify-content-center justify-content-md-start align-items-center">
                <li>
                  <a href="#!">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="#!">Privacy Policy</a>
                </li>
              </ul>
              <div className="copyright-box">
                <img src="include/images/copyright.svg" alt="" />
                &copy; Environmental Inclusion. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Greetings);
