import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "zoo",
  initialState: {
    zoo: {},
    allZoo: [],
    loading: false,
    zooDetail: {},
    zooProgram: {},
    species: {},
    liveFeeds: {},
  },
  reducers: {
    zooRequested: (misc, action) => {
      misc.loading = true;
    },
    zooReceived: (misc, action) => {
      misc.zoo = action.payload;
      misc.loading = false;
    },
    zooRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    allZooRequested: (misc, action) => {
      misc.loading = true;
    },
    allZooReceived: (misc, action) => {
      misc.allZoo = action.payload?.data;
      misc.loading = false;
    },
    allZooRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    zooDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    zooDetailReceived: (misc, action) => {
      misc.zooDetail = action.payload;
      misc.loading = false;
    },
    zooDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    zooProgramRequested: (misc, action) => {
      misc.loading = true;
    },
    zooProgramReceived: (misc, action) => {
      misc.zooProgram = action.payload;
      misc.loading = false;
    },
    zooProgramRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    liveFeedsRequested: (misc, action) => {
      misc.loading = true;
    },
    liveFeedsReceived: (misc, action) => {
      misc.liveFeeds = action.payload;
      misc.loading = false;
    },
    liveFeedsFailed: (misc, action) => {
      misc.loading = false;
    },
    speciesRequested: (misc, action) => {
      misc.loading = true;
    },
    speciesReceived: (misc, action) => {
      misc.species = action.payload;
      misc.loading = false;
    },
    speciesFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  zooRequested,
  zooReceived,
  zooRequestFailed,
  allZooRequested,
  allZooReceived,
  allZooRequestFailed,
  zooDetailRequested,
  zooDetailReceived,
  zooDetailRequestFailed,
  zooProgramRequested,
  zooProgramReceived,
  zooProgramRequestFailed,
  liveFeedsRequested,
  liveFeedsReceived,
  liveFeedsFailed,
  speciesRequested,
  speciesReceived,
  speciesFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "zoo";

export const getZoo = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "get",
      params,
      callback,
      onStart: zooRequested.type,
      onSuccess: zooReceived.type,
      onError: zooRequestFailed.type,
    })
  );
};

export const getAllZoo = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown`,
      params,
      method: "get",
      callback,
      onStart: allZooRequested.type,
      onSuccess: allZooReceived.type,
      onError: allZooRequestFailed.type,
    })
  );
};
export const getZooDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "get",
      callback,
      onStart: zooDetailRequested.type,
      onSuccess: zooDetailReceived.type,
      onError: zooDetailRequestFailed.type,
    })
  )
}
export const getZooPrograms = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/program/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: zooProgramRequested.type,
      onSuccess: zooProgramReceived.type,
      onError: zooProgramRequestFailed.type,
    })
  )
}
export const getZooLiveFeeds = (params, callback) => (dispatch) => {
  const url = `${baseUrl}/liveFeed/${params.id}?page=${params.page}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: liveFeedsRequested.type,
      onSuccess: liveFeedsReceived.type,
      onError: liveFeedsFailed.type,
    })
  );
};
export const getSpeciesZoo = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: speciesRequested.type,
      onSuccess: speciesReceived.type,
      onError: speciesFailed.type,
    })
  );
};
export const updateSpeciesZoo = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};
export const deleteSpeciesZoo = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "delete",
      data,
      callback,
    })
  );
};
export const zooListings = createSelector(
  (state) => state.entities.zoo,
  (zoo) => zoo
);
