import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editor = ({ onChange, editorLoaded, name,placeholder, value, ...props }) => {
  const editorRef = useRef();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // Only set the editor as loaded once it's fully initialized
    if (editorLoaded) {
      setLoaded(true);
    }
  }, [editorLoaded]);

  return (
    <div>
      {props.type == "reference" ? (
        <>
          {loaded ? (
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: ["link"],
              }}
              data={value}
              onChange={(event, editor) => {
                const data = editor.getData();
                let target = { name: "description", value: data };
                onChange({ target });
              }}
              style={{ height: "250px", overflowY: "auto" }}
            />
          ) : (
            <div>Editor loading</div>
          )}
        </>
      ) : (
        <>
          {loaded ? (
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "numberedList",
                  "bulletedList",
                  "|",                  
                  // "undo",
                  // "redo",
                  ...(props.type !== "threats" ? ["link"] : []),
                ],
                placeholder: placeholder || "",
              }}
              data={value}
              onChange={(event, editor) => {
                const data = editor.getData();
                let target = { name: "description", value: data };
                onChange({ target });
              }}
              style={{ height: "250px", overflowY: "auto" }}
            />
          ) : (
            <div>Editor loading</div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(Editor);
