import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';
import noRecord from "../../../include/images/nrb-img.svg";
import LargeImageGalleryModal from "../../common/imageGallery/largeImageGallery";
import Pagination from '../Pagination/pagination';
import { getWetMarketMedia, wetMarketData } from '../../../store/wetMarket';
import { getMediaDetails, organizationListing } from '../../../store/organization';
import { getRegionMedia, regionListings } from '../../../store/region';
import { getMedia, getSpeciesDetails, speciesListings } from '../../../store/species';
import { getRegionList } from '../../../store/sidebarApis';

import { connect } from 'react-redux';
import { getEnvMedia } from '../../../store/environmentalist';

const Media = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [galleryItems, setGalleryItems] = useState([]);
    const openModal = (startIndex) => {
        setStartIndex(startIndex);
        setShowModal(!showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.scrollToTop();
    };

    const fetchData = (params, callback) => {
        props.setLoading(true);
        const fetchDataFunction = (() => {
            switch (props.section) {
                case "species":
                    return props.getMedia;
                case "wetMarket":
                    return props.getWetMarketMedia;
                case "organization":
                    return props.getMediaDetails;
                case "region":
                    return props.getRegionMedia;
                case "environmentalist":
                    return props.getEnvMedia;
                default:
                    return null;
            }
        })();
        if (fetchDataFunction) {
            fetchDataFunction(params, (res) => {
                if (res && res.status === 200) {
                    props.setLoading(false);
                    props.setMedia(res.data)
                    if (callback) {
                        callback(res);
                    }
                }
            });
        }
    };


    useEffect(() => {
        const params = {
            id: props.id,
            page: currentPage,
        };
        fetchData(params, (res) => {
        });
    }, [props.id, currentPage]);

    useEffect(() => {
        let mediaItems;

        if (props.section === "species") {
            mediaItems =
                props.media &&
                props.media?.data?.length &&
                props.media?.data.map((item) => {
                    const mediaType = item?.media?.mediaType;
                    const original = process.env.REACT_APP_MEDIA + item?.media?.original;
                    const ext = item?.media?.original.split(".").pop();

                    if (mediaType === "image" || mediaType === "video") {
                        return {
                            file: {
                                mediaType: mediaType,
                                original: original,
                            }
                        };
                    }

                    return null;
                });
        } else {
            mediaItems =
                props.media &&
                props.media?.data?.length &&
                props.media?.data.map((item) => {
                    const mediaType = item?.file?.mediaType;
                    const original = process.env.REACT_APP_MEDIA + item?.file?.original;
                    const ext = item?.file?.original.split(".").pop();

                    if (mediaType === "image" || mediaType === "video") {
                        return {
                            file: {
                                mediaType: mediaType,
                                original: original,
                            }
                        };
                    }
                    return null;
                });
        }
        setGalleryItems(mediaItems && mediaItems.filter(Boolean));

    }, [props.media]);

    const pageLinks = Array.from({ length: Math.ceil((props.media?.filteredCount ?? 0) / (props.media?.recordsPerPage ?? 1)) }, (_, i) => i + 1);
    const [loadedItems, setLoadedItems] = useState(0);

    const handleMediaLoad = () => {
        setLoadedItems((prevLoadedItems) => prevLoadedItems + 1);
    };

    useEffect(() => {
        setLoadedItems(0);
    }, [props.media]);
    return (
        <>
            <ul className="media-list d-flex flex-wrap" style={{ marginTop: "0px" }}>
                {props.loading ? (
                    // Display the loading skeleton
                    Array.from({ length: 18 }, (_, index) => (
                        <li className="media-item media-item-skeleton" key={index}>
                            <div className="media-box">
                                <div className="media-box-inner">
                                    <Skeleton borderRadius={"10px"} width="100%" height={"215px"} />
                                    <div
                                        className="transparent-overlay"
                                        onClick={() => openModal(index)}
                                    />
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <>
                        {galleryItems && galleryItems.length > 0 ? (
                            galleryItems?.map((item, idx) => {
                                const ext = item?.file.original.split(".").pop();
                                const mediaType = item?.file.mediaType;
                                const imageOriginal = item?.file.original;

                                return (
                                    <li className="media-item" key={idx}>
                                        <div className="media-box">
                                            <div className="media-box-inner">
                                                {/* Render actual media content */}
                                                {(mediaType === "image" || mediaType === "video") && (
                                                    <>
                                                        {mediaType === "image" && (
                                                            <img
                                                                className=""
                                                                src={imageOriginal}
                                                                alt=""
                                                                onLoad={handleMediaLoad}
                                                            />
                                                        )}
                                                        {mediaType === "video" && (
                                                            <>
                                                                {imageOriginal && imageOriginal.startsWith("https") ? (
                                                                    <ReactPlayer
                                                                        className="media-link-video"
                                                                        url={imageOriginal}
                                                                        controls={false}
                                                                        onReady={handleMediaLoad}
                                                                    />
                                                                ) : null}
                                                                {imageOriginal && !imageOriginal.startsWith("https") ? (
                                                                    <video
                                                                        style={{ maxWidth: "100%", height: "auto" }}
                                                                        onLoad={handleMediaLoad}
                                                                    >
                                                                        <source
                                                                            src={process.env.REACT_APP_MEDIA + imageOriginal}
                                                                        />
                                                                    </video>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {/* Transparent overlay for interaction */}
                                                <div
                                                    className="transparent-overlay"
                                                    onClick={() => openModal(idx)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            // No records found
                            <ul className="blog-list d-flex flex-wrap justify-content-center">
                                <div
                                    className="no-records-container d-flex align-items-center justify-content-center"
                                    style={{ height: "auto" }}
                                >
                                    <div className="no-records-box text-center">
                                        <div className="nrb-image">
                                            <img src={noRecord} alt="" />
                                        </div>
                                        <h6>No Records Found</h6>
                                    </div>
                                </div>
                            </ul>
                        )}
                        {showModal && (
                            // Display modal for large image gallery
                            <LargeImageGalleryModal
                                show={showModal}
                                handleClose={closeModal}
                                items={galleryItems}
                                startIndex={startIndex}
                            />
                        )}
                    </>
                )}
            </ul>
            {/* Display pagination */}
            {props.media && props.media?.filteredCount > 48 && < Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
            />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getMedia: (params, callback) => dispatch(getMedia(params, callback)),
    getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
    getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
    getWetMarketMedia: (params, callback) => dispatch(getWetMarketMedia(params, callback)),
    getRegionMedia: (params, callback) => dispatch(getRegionMedia(params, callback)),
    getMediaDetails: (params, callback) => dispatch(getMediaDetails(params, callback)),
    getEnvMedia: (params, callback) => dispatch(getEnvMedia(params, callback)),
});

const mapStateToProps = (state) => ({
    speciesListings: speciesListings(state),
    regionListings: regionListings(state),
    organizationListing: organizationListing(state),
    wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Media));
