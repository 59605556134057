import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import leftArrow from "../../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../../include/images/pagination-right-arrow-green.svg";
import _ from "lodash";
const ListingPagination = (props) => {
  const { noOfPage, setPage, page } = props;
  let pages = _.range(page - 5 < 0 ? 0 : page - 5, noOfPage > 10 ? page + 5 : noOfPage);
  return (
    <>
      <div class="pagination-arrow">
        <Link
          class={`pagination-arrow-box d-flex align-items-center  ${page === 1 && "no-pointer"}`}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        >
          <img src={page === 1 ? leftArrow : leftArrowGreen} alt="" />
          <span className="hover-link">Previous</span>
        </Link>
      </div>
      <div class="pagination-count d-flex flex-wrap">
        {page + 1 > 6 ? (
          <Link
            onClick={(e) => {
              setPage(1);
            }}
            key={1}
            class={`pagination-count-box ${1 === page ? "active" : ""}`}
          >
            <h6>1</h6>
          </Link>
        ) : (
          ""
        )}
        {page + 1 > 7 ? (
          <Link
            onClick={(e) => {
              setPage(2);
            }}
            key={2}
            class={`pagination-count-box ${2 === page ? "active" : ""}`}
          >
            <h6>2</h6>
          </Link>
        ) : (
          ""
        )}
        {page + 1 > 8 ? (
          <Link
            onClick={(e) => {
              setPage(3);
            }}
            key={3}
            class={`pagination-count-box ${3 === page ? "active" : ""}`}
          >
            <h6>3</h6>
          </Link>
        ) : (
          ""
        )}
        {page > 5 ? <Link className={"pagination-count-box"}>...</Link> : ""}
        {pages?.map(
          (i) =>
            i + 1 <= noOfPage && (
              <Link
                key={i + 1}
                class={`pagination-count-box ${page == i + 1 ? "active" : ""}`}
                onClick={() => setPage(i + 1)}
              >
                <h6>{i + 1}</h6>
              </Link>
            ),
        )}
        {page < noOfPage - 5 && noOfPage > 10 ? <Link className={"pagination-count-box"}>...</Link> : ""}
        {page < noOfPage - 7 && noOfPage > 10 ? (
          <Link
            onClick={(e) => {
              setPage(noOfPage - 2);
            }}
            className={"pagination-count-box"}
          >
            <h6>{noOfPage - 2}</h6>
          </Link>
        ) : (
          ""
        )}
        {page < noOfPage - 6 && noOfPage > 10 ? (
          <Link
            onClick={(e) => {
              setPage(noOfPage - 1);
            }}
            className={"pagination-count-box"}
          >
            <h6>{noOfPage - 1}</h6>
          </Link>
        ) : (
          ""
        )}
        {page + 5 < noOfPage && noOfPage > 10 ? (
          <Link
            onClick={(e) => {
              setPage(noOfPage);
            }}
            className={"pagination-count-box"}
          >
            <h6>{noOfPage}</h6>
          </Link>
        ) : (
          ""
        )}
      </div>
      <div class="pagination-arrow">
        <Link
          class={`pagination-arrow-box d-flex align-items-center ${page === noOfPage && "no-pointer"}`}
          onClick={() => {
            if (page < noOfPage) {
              setPage(page + 1);
            }
          }}
        >
          <span className="hover-link">Next</span>
          <img src={page === noOfPage ? rightArrow : rightArrowGreen} alt="" />
        </Link>
      </div>
    </>
  );
};

export default ListingPagination;
