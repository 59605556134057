import React, { useRef, useState } from "react";
import defualtIcon from "../../../include/images/feed-Icon.svg";
import ImageHolder from "../../../common/customStyles/imageHolder";
import { getProfile } from "../../../utils/localStorageServices";
import { capitalizeContentInBrackets, dotDesc, getRelativeTime, removeTags } from "../../../utils/helperFunctions";
import ViewPost from "../post/viewPost";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "react-bootstrap";
import ReportPost from "../../feed/modals/reportPost";
import DmPosts from "../../feed/modals/dmPosts";
import wIcon from "../../../include/images/w-icon-1.svg";
import wIcon2 from "../../../include/images/w-icon-2.svg";
import wIcon3 from "../../../include/images/w-icon-3.svg";
import liked from "../../../include/images/fill-heart-icon.svg";
import wIcon4 from "../../../include/images/w-icon-4.svg";
import wIcon5 from "../../../include/images/w-icon-5.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { feedReceived, getUser, postReceived } from "../../../store/users";
import { getPosts, likesReceived, likeUnlikePost, viewPost } from "../../../store/post";
import AlertError from "../../../common/alerts/alertError";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import ReactPlayer from "react-player";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import HTMLReactParser from "html-react-parser";

const PostCard = (props) => {
  const { item, id, renderDescription, setGalleryItems, galleryItems, mediaType, nameDoc } = props;
  const profile = getProfile();
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPost, setShowPost] = useState(false);
  const history = useHistory();
  const [showReport, setShowReport] = useState(false);
  const [showDmPost, setShowDmPost] = useState(false);
  const ref = useRef();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const toggleDmPosts = () => {
    setShowDmPost(!showDmPost);
  };
  const togglePost = () => {
    setShowPost(!showPost);
  };
  const renderFiles = (files, item) => {
    if (files?.length > 1) {
      const firstFile = files[0];
      const remainingCount = files.length - 1;
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(firstFile.mediaType);
      return (
        <>
          {" "}
          <div
            className="w-image-holder position-relative cursor-pointer"
            onClick={() => {
              setSelectedItem(item);
              togglePost();
            }}
          >
            {isVideo ? (
              <video controls>
                <source src={process.env.REACT_APP_MEDIA + firstFile.original} />
              </video>
            ) : (
              <img src={process.env.REACT_APP_MEDIA + firstFile.original} alt="" />
            )}
            <div
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                background: "rgba(0,0,0,0.5)",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedItem(item);
                togglePost();
              }}
            >
              (+{remainingCount}) View More
            </div>
          </div>
        </>
      );
    } else if (files?.length === 1) {
      const file = files[0];
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(file.mediaType);
      return (
        <div
          className={`w-image-holder position-relative cursor-pointer ${
            file.mediaType == "document" && !isMobile ? "d-flex justify-content-center align-items-center" : ""
          }`}
          onClick={() => {
            setSelectedItem(item);
            togglePost();
          }}
        >
          {(file?.mediaType === "image" || file?.mediaType === "sketch" || file?.mediaType === "essay") && (
            <img className="w-100 h-100 object-fit-cover" src={process.env.REACT_APP_MEDIA + file?.original} alt="" />
          )}
          {file?.mediaType === "video" && (
            <>
              {file?.original && file?.original.startsWith("https") ? (
                <ReactPlayer className={`w-100 react-player-iframe`} url={file?.original} controls={true} />
              ) : null}
              {file?.original && !file?.original.startsWith("https") ? (
                <video className="post-video-tag">
                  <source src={process.env.REACT_APP_MEDIA + file?.original} />
                </video>
              ) : null}
            </>
          )}
          {file?.mediaType === "document" && file?.original && (
            <div className="h-100 react-player-iframe">
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                  process.env.REACT_APP_MEDIA + file?.original,
                )}&embedded=true`}
                className="w-100 pdf-preview"
                title="PDF Preview"
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };
  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };

  const likeAndUnlikePost = (type, id) => {
    ref?.current?.continuousStart();
    const data = id;
    props?.likeUnlikePost(type, data, (res) => {
      if (res && res.status === 200) {
        let updatedMyPostData;
        updatedMyPostData = galleryItems?.map((post) => {
          if (post?.file._id === id) {
            if (type === "like") {
              return {
                ...post,
                file: {
                  ...post.file,
                  isLiked: true,
                  likeCount: post?.file?.likeCount + 1,
                },
              };
            } else if (type === "unlike") {
              return {
                ...post,
                file: {
                  ...post.file,
                  isLiked: false,
                  likeCount: post?.file.likeCount > 0 ? post?.file.likeCount - 1 : 0,
                },
              };
            }
          }
          return post;
        });
        setGalleryItems(updatedMyPostData);
        ref?.current?.complete();
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  return (
    <div className="widget d-flex flex-wrap" key={id}>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div className="widget-icon">
        <div className="w-icon d-flex align-items-center justify-content-center overflow-hidden">
          {item?.file?.createdByAdmin != null ? (
            <img src={defualtIcon} name={item?.file?.name?.slice(0, 1)} alt={item?.file?.name} />
          ) : (
            <ImageHolder
              image={
                item?.file?.createdBy
                  ? item?.file?.createdBy?.profilePicture?.medium
                    ? item?.file?.createdBy?.profilePicture?.medium
                    : profilePicture
                  : null
              }
              name={item?.file?.name?.slice(0, 1)}
            />
          )}
        </div>
      </div>
      <div className="widget-content">
        <div className="widget-text-holder">
          <div className="w-heading-block position-relative ">
            <div className="w-heading-box d-flex align-items-center flex-wrap ">
              {item?.file?.createdByAdmin != null ? (
                <Link
                  className="w-label-box fw-semibold text-capitalize post-title-name cursor-pointer"
                  style={{ fontSize: "14px" }}
                >
                  Environmental Inclusion
                </Link>
              ) : item?.file?.isAddedFromMedia ? (
                item?.file?.createdBy ? (
                  <Link
                    to={
                      profile?.profile?._id == item?.file?.createdBy?._id
                        ? {
                            pathname: `/profile`,
                          }
                        : {
                            pathname: `/profile/${item?.file?.createdBy?.slug}`,
                            state: { id: item?.file?.createdBy?._id },
                          }
                    }
                    className="w-label-box fw-semibold text-capitalize post-title-name hover-underline cursor-pointer"
                    style={{ fontSize: "14px" }}
                  >
                    {`${item?.file?.createdBy?.firstName
                      ?.charAt(0)
                      ?.toUpperCase()}${item?.file?.createdBy?.firstName?.slice(1)} 
                    ${item?.file?.createdBy?.lastName
                      ?.charAt(0)
                      ?.toUpperCase()}${item?.file?.createdBy?.lastName?.slice(1)}`}
                  </Link>
                ) : (
                  <div className="w-label-box fw-semibold text-capitalize post-title-name" style={{ fontSize: "14px" }}>
                    {item?.file?.name && item?.file?.name}
                  </div>
                )
              ) : props?.page != "profile" ? (
                <Link
                  to={
                    profile?.profile?._id == item?.file?.createdBy?._id
                      ? {
                          pathname: `/profile`,
                        }
                      : {
                          pathname: `/profile/${item?.file?.createdBy?.slug}`,
                          state: { id: item?.file?.createdBy?._id },
                        }
                  }
                  className="w-label-box fw-semibold text-capitalize post-title-name hover-underline cursor-pointer"
                  style={{ fontSize: "14px" }}
                >
                  {item?.file?.createdBy ? `${item?.file?.createdBy.firstName} ${item?.file?.createdBy.lastName}` : ""}
                </Link>
              ) : (
                <div className="w-label-box fw-semibold text-capitalize post-title-name" style={{ fontSize: "14px" }}>
                  {item?.file?.createdBy ? `${item?.file?.createdBy.firstName} ${item?.file?.createdBy.lastName}` : ""}
                </div>
              )}
              <span className="w-dot-box"></span>
              <div className="w-time-box d-flex align-items-center">
                <span className="w-time-count">{getRelativeTime(item && item?.file.createdAt)}</span>{" "}
              </div>
              {/* <div className="w-time-box d-flex align-items-center position-absolute end-0">
                <span className="w-time-count custom-pill-post rounded-pill">
                  {item?.file?.isAddedFromMedia ? "Education And Media" : "Post"}
                </span>{" "}
              </div> */}
            </div>
          </div>
          {item?.file?.isAddedFromMedia ? (
            <>
              <div className="w-text-box w-text-read-more text-capitalize">
                {" "}
                {renderDescription(item?.file?.description)}
                {item?.file?.class && <p>Grade : {item?.file?.class}</p>}
                {item?.file?.school && <p>School : {item?.file?.school}</p>}
                {item?.file?.teacherName && <p>Teacher Name : {item?.file?.teacherName}</p>}
                {mediaType === "document" && (
                  <div className="media-text-box text-capitalize" title={nameDoc || ""}>
                    Document Name :{" "}
                    {nameDoc !== undefined ? dotDesc(HTMLReactParser(removeTags(nameDoc)), 15) : "\u00A0"}
                  </div>
                )}
                {mediaType === "essay1" && <>{renderDescription(item?.file?.essay?.content)}</>}
              </div>{" "}
            </>
          ) : (
            <div className="w-text-box w-text-read-more"> {renderDescription(item?.file?.description)}</div>
          )}

          <div className="eap-list d-flex flex-wrap mt-3 mb-2">
            {item.file?.allSectionItems?.slice(0, 18).map((sectionItem, idx) => (
              <div className="eap-item text-capitalize" key={idx}>
                <Link
                  to={{
                    pathname: sectionItem.path,
                    state: { id: sectionItem.id },
                  }}
                >
                  #
                  {capitalizeContentInBrackets(
                    sectionItem?.displayCommonName
                      ? sectionItem?.displayCommonName
                      : sectionItem?.scientificName || sectionItem?.name,
                  )}
                </Link>
              </div>
            ))}
            {item.file?.allSectionItems?.length > 20 && (
              <div className="eap-item  text-capitalize">
                <Link>...</Link>
              </div>
            )}
          </div>
          {renderFiles(item?.file?.files, item?.file)}
          <div
            className={`w-iconlist d-flex flex-wrap align-items-center justify-content-between ${
              item?.file?.files?.length == 0 ? "mt-3" : ""
            }`}
          >
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div
                  className="w-iconl-box"
                  onClick={() => {
                    if (!profile?.profile) {
                      history.push("/login");
                    } else {
                      setSelectedItem(item?.file);
                      toggleShowReport();
                    }
                  }}
                >
                  <img src={wIcon} alt="w icon 1" />
                </div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div
                  className="w-iconl-box"
                  onClick={() => {
                    if (!profile?.profile) {
                      history.push("/login");
                    } else {
                      setSelectedItem(item?.file);
                      togglePost();
                    }
                  }}
                >
                  <img src={wIcon2} alt="w icon 2" />
                </div>
                <div className="w-textl-box">{item.file?.commentCount == 0 ? "" : item.file?.commentCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div className="w-iconl-box">
                  <img
                    src={item.file?.isLiked ? liked : wIcon3}
                    alt="w icon 3"
                    onClick={() => {
                      if (!profile?.profile) {
                        history.push("/login");
                      } else {
                        likeAndUnlikePost(item.file?.isLiked ? "unlike" : "like", item.file?._id);
                      }
                    }}
                  />
                </div>
                <div className="w-textl-box">{item.file?.likeCount == 0 ? "" : item.file?.likeCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div className="w-iconl-box">
                  <img src={wIcon4} alt="w icon 4" />
                </div>
                <div className="w-textl-box">{item.file?.viewCount == 0 ? "" : item.file?.viewCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
                <Dropdown.Toggle as="span" id="dropdown-basic" className="w-iconl-box dropdown-toggle">
                  <img
                    src={wIcon5}
                    alt="w icon 5"
                    onClick={(e) => {
                      if (!profile?.profile) {
                        history.push("/login");
                      }
                    }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu share-post">
                  <FacebookShareButton
                    url={getShareUrl(item.file?.slug)}
                    description={item.file?.description}
                    className="custom-share-post"
                  >
                    <Dropdown.Item className="dropdown-item" eventKey={"1"}>
                      <Link to="#" className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                        <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.7974 16.1444V10.7915H12.5942L12.8637 8.70543H10.7974V7.37352C10.7974 6.76958 10.9652 6.35797 11.8313 6.35797H12.936V4.49269C12.4013 4.43612 11.8639 4.40872 11.3263 4.41061C9.73351 4.41061 8.64314 5.38274 8.64314 7.16813V8.70543H6.8418V10.7915H8.64314V16.1444H10.7974Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>

                        <div className="dropdown-item-text custom-share-dropdown-text">Facebook</div>
                      </Link>
                    </Dropdown.Item>
                  </FacebookShareButton>
                  <TwitterShareButton url={getShareUrl(item.file?.slug)} className="custom-share-post">
                    <Dropdown.Item className="dropdown-item" eventKey={"2"}>
                      <Link to="#" className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                        <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.0317 5L8.05085 11.6187L3 17H4.13674L8.55875 12.2886L12.1316 17H16L10.6984 10.009L15.3997 5H14.263L10.1905 9.33911L6.90008 5H3.0317ZM4.70336 5.8258H6.48051L14.3281 16.1741H12.5509L4.70336 5.8258Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>

                        <div className="dropdown-item-text custom-share-dropdown-text">Twitter</div>
                      </Link>
                    </Dropdown.Item>
                  </TwitterShareButton>
                  <Dropdown.Item className="dropdown-item" eventKey={"3"}>
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedItem(item?.file);
                        toggleDmPosts();
                      }}
                      className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                    >
                      <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14 7.83325C15.1046 7.83325 16 6.93782 16 5.83325C16 4.72868 15.1046 3.83325 14 3.83325C12.8954 3.83325 12 4.72868 12 5.83325C12 6.93782 12.8954 7.83325 14 7.83325Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6 12.5C7.10457 12.5 8 11.6046 8 10.5C8 9.39543 7.10457 8.5 6 8.5C4.89543 8.5 4 9.39543 4 10.5C4 11.6046 4.89543 12.5 6 12.5Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 17.1666C15.1046 17.1666 16 16.2712 16 15.1666C16 14.0621 15.1046 13.1666 14 13.1666C12.8954 13.1666 12 14.0621 12 15.1666C12 16.2712 12.8954 17.1666 14 17.1666Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.72656 11.5066L12.2799 14.1599"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.2732 6.83997L7.72656 9.4933"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="dropdown-item-text">Direct</div>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <ReportPost show={showReport} onHide={toggleShowReport} selectedItem={selectedItem} page="viewpost" />
      <DmPosts show={showDmPost} onHide={toggleDmPosts} selectedItem={selectedItem} page="viewpost" />
      <ViewPost
        page="education"
        show={showPost}
        onHide={togglePost}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        media={props.media}
        setMedia={props.setMedia}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)),
  viewPost: (id, callback) => dispatch(viewPost(id, callback)),
  feedReceived: (payload) => dispatch(feedReceived(payload)),
  postReceived: (payload) => dispatch(postReceived(payload)),
  likesReceived: (payload) => dispatch(likesReceived(payload)),
});

const mapStateToProps = (state) => ({
  getPosts: getPosts(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PostCard));
