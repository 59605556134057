import React, { useEffect, useRef, useState } from "react";
import {
  deleteOrganizationsReg,
  getOrganizations,
  regionListings,
  updateOrganizationsReg,
} from "../../../store/region";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noRecord from "../../../include/images/nrb-img.svg";
import { Link } from "react-router-dom";
import ShowMoreModal from "../../common/showMoreModal/showMoreModal";
import Pagination from "../../common/Pagination/pagination";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../../utils/helperFunctions";
import { getOrganizationList, sideBarApisListings } from "../../../store/sidebarApis";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import trash from "../../../include/images/trash-24x24.svg";
import Select from "react-select";
import AddModal from "../../common/addModal/addModal";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import defaultOrgImg from "../../../include/images/organisation.png";

const Organizations = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    organization: "",
  });

  const [showAdd, setShowAdd] = useState(false);
  const organizationDropdown = transformDropdownData(props.sideBarApisListings?.organizationList?.data || []);

  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      organization: selectedOptions,
    }));
  };
  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (!props.sideBarApisListings?.organizationList?.data) {
      props.getOrganizationList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getOrganizations(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const organizationDetail = props.regionDetails && props.regionDetails.organization;

  const [showMoreModal, setShowMoreModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const isMobile = useCheckMobileScreen();
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((organizationDetail?.totalCount ?? 0) / (organizationDetail?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const addMore = () => {
    setLoading(true);
    let payload = {
      organizationIds: extractIds(selectedPreference?.organization),
    };
    props.updateOrganizations(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          organization: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getOrganizations(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      organizationIds: [ids],
    };
    props.deleteOrganizations(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getOrganizations(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            {props.editMode && (
              <div className="d-flex justify-content-between mb-4">
                <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                  Add Organization
                </Link>
                <div class="d-flex flex-wrap justify-content-end w-75">
                  <Select
                    className="basic-single w-50 text-capitalize"
                    classNamePrefix="select-search"
                    isMulti
                    placeholder="Select"
                    styles={customStyles1}
                    options={organizationDropdown}
                    value={selectedPreference.organization}
                    onChange={handleSelectChange}
                  />
                  <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                    Save
                  </Link>
                </div>{" "}
              </div>
            )}
            {loading ? (
              <ul class="tbc-organization-list d-flex flex-wrap">
                {Array.from({ length: 48 }, (item, index) => (
                  <div class=" org-skeleton">
                    <Skeleton borderRadius={"10px"} height="150px" />
                  </div>
                ))}
              </ul>
            ) : (
              <ul class="tbc-organization-list d-flex flex-wrap">
                {!isMobile && (
                  <li class="organisation-item org-card position-relative">
                    {
                      <div className="organisation-box d-flex flex-wrap">
                        <div class="organisation-logo ">
                          <Link>
                            {!imageError ? (
                              <img
                                src={process.env.REACT_APP_MEDIA + props.splashData.salvexLogo.small}
                                onError={handleImageError}
                                className="org-img sm-img-fix"
                                alt=""
                              />
                            ) : (
                              <img
                                src={process.env.REACT_APP_MEDIA + props.splashData.salvexLogo.medium}
                                alt=""
                                className="org-img sm-img-fix"
                              />
                            )}
                          </Link>
                        </div>
                        <div class="organisation-text " style={{ height: "170px" }}>
                          <h6 className="text-transfrom  mb-0" style={{ minHeight: "0px" }}>
                            {" "}
                            <Link>{`${capitalizeContentInBrackets(props.splashData.salvexName)}`}</Link>
                          </h6>
                          <div class="organisation-text-footer">
                            <p className="caps-text d-flex align-items-center space-between">
                              <span>{props.splashData.salvexText}</span>
                            </p>
                          </div>
                        </div>
                        <Link
                          onClick={() => props.setMenuItem("1")}
                          className="btn btn-default splash-donate-btn mt-2"
                          style={{ maxWidth: "50%", lineHeight: "16px", bottom: "5px" }}
                        >
                          Select Species to Donate Commercial Assets
                        </Link>
                      </div>
                    }
                  </li>
                )}
                {isMobile && (
                  <li class="organisation-item org-card position-relative">
                    {
                      <div className="organisation-box d-flex flex-wrap">
                        <div class="organisation-logo ">
                          <Link>
                            {!imageError ? (
                              <img
                                src={process.env.REACT_APP_MEDIA + props.splashData.salvexLogo.small}
                                onError={handleImageError}
                                className="org-img sm-img-fix"
                                alt=""
                              />
                            ) : (
                              <img
                                src={process.env.REACT_APP_MEDIA + props.splashData.salvexLogo.medium}
                                alt=""
                                className="org-img sm-img-fix"
                              />
                            )}
                          </Link>
                        </div>
                        <div class="organisation-text ">
                          <h6 className="text-transfrom  mb-0" style={{ minHeight: "0px" }}>
                            {" "}
                            <Link>{`${capitalizeContentInBrackets(props.splashData.salvexName)}`}</Link>
                          </h6>
                        </div>
                        <div class="organisation-text-footer">
                          <p className="caps-text d-flex align-items-center space-between">
                            <span>{props.splashData.salvexText}</span>
                          </p>
                        </div>
                        <Link onClick={() => props.setMenuItem("1")} className="btn btn-default mt-2">
                          Select Species to Donate Commercial Assets
                        </Link>
                      </div>
                    }
                  </li>
                )}
                {organizationDetail &&
                  organizationDetail.data &&
                  organizationDetail.data.length > 0 &&
                  organizationDetail.data.map((item) => {
                    const donationUrl = item?.donationUrl?.split("//").pop();
                    return (
                      <li class="organisation-item org-card position-relative">
                        {props.editMode && (
                          <button
                            type="button"
                            class="trash-button-box"
                            onClick={(e) => {
                              handleDelete(item._id);
                            }}
                          >
                            <img src={trash} alt="w volumne" />
                          </button>
                        )}
                        {
                          <div className="organisation-box d-flex flex-wrap ">
                            <div class="organisation-logo ">
                              <Link
                                to={{
                                  pathname: `/organizations/${item?.slug}`,
                                  state: { id: item._id },
                                }}
                              >
                                {!imageError && item?.coverImage?.small ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                    onError={handleImageError}
                                    className="org-img sm-img-fix"
                                    alt=""
                                  />
                                ) : !imageError && item?.coverImage?.medium ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                    alt=""
                                    className="org-img sm-img-fix"
                                  />
                                ) : (
                                  <img src={defaultOrgImg} alt="" className="org-img sm-img-fix" />
                                )}
                              </Link>
                            </div>
                            <div class="organisation-text ">
                              <h6 className="text-transfrom " style={{ marginBottom: "0px" }}>
                                {" "}
                                <Link
                                  to={{
                                    pathname: `/organizations/${item?.slug}`,
                                    state: { id: item?._id },
                                  }}
                                >
                                  {`${capitalizeContentInBrackets(item?.name)}`}
                                </Link>
                              </h6>
                              <div class="organisation-text-footer">
                                <div className="d-flex space-between">
                                  <small>Species :</small>
                                  {item?.species?.length > 0 && (
                                    <span
                                      class="map-switch view-all-btn1 fs-11 org-tab-mob link-text hover-underline"
                                      style={{
                                        marginTop: "3px",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        if (item?.species?.length > 0) {
                                          setSelectedData(item);
                                          toggleShowMoreModal();
                                        }
                                      }}
                                    >
                                      View All
                                    </span>
                                  )}
                                </div>

                                <p className="caps-text d-flex align-items-center space-between">
                                  <span>
                                    {item?.species && item?.species.length > 0
                                      ? item?.species.filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                          .length > 0 // Filter species that have a scientific name
                                        ? item?.species
                                            .filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                            .map((ie, r, filteredSpecies) =>
                                              r < 2 ? (
                                                <span key={r}>
                                                  {ie?.displayCommonName || ie?.scientificName}
                                                  {r + 1 < filteredSpecies.length && r < 1 ? ", " : ""}
                                                  {r === 1 && filteredSpecies.length > 2 ? " ..." : ""}
                                                </span>
                                              ) : null,
                                            )
                                        : "No scientific names available"
                                      : "Not yet added"}
                                  </span>
                                </p>
                                {donationUrl ? (
                                  <a
                                    href={`https://${donationUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="btn btn-default position-absolute"
                                    style={{
                                      minWidth: "80px",
                                      lineHeight: "12px",
                                      bottom: "10px",
                                      left: "25%",
                                    }}
                                  >
                                    Donate Direct
                                  </a>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      </li>
                    );
                  })}
              </ul>
            )}
            {organizationDetail && organizationDetail?.totalCount > 42 && (
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />
            )}
          </div>
          <ShowMoreModal
            show={showMoreModal}
            onHide={toggleShowMoreModal}
            listData={selectedData?.species}
            heading={`Species Supported by ${
              selectedData?.name ? capitalizeContentInBrackets(selectedData?.name) : ""
            }`}
            isNonEditable={true}
          />
          <AddModal
            show={showAdd}
            onHide={() => setShowAdd(false)}
            heading={"Add Organization"}
            page="region"
            type="organization"
            id={props.id}
            alert="organization"
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrganizations: (params, callback) => dispatch(getOrganizations(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  updateOrganizations: (params, data, callback) => dispatch(updateOrganizationsReg(params, data, callback)),
  deleteOrganizations: (params, data, callback) => dispatch(deleteOrganizationsReg(params, data, callback)),
});

const mapStateToProps = (state) => ({
  regionDetails: regionListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Organizations));
