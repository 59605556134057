import React, { useEffect, useState } from "react";
import noPost from "../../include/images/sponsorsmall.svg";
import { connect } from "react-redux";
import { getSponsorUser, getstoryUser, getUser } from "../../store/users";
import { cancelSponsor, cancelStory, cancelSubscription } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import DeletePost from "../../common/alerts/deleteReason";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";

const SponsoredSpecies = (props) => {
  const data = props?.getUser?.sponsor?.data;
  const storyData = props?.getUser?.story?.data;
  const [page, setPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteStory, setShowDeleteStory] = useState(false);
  const [eId, setID] = useState();
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  useEffect(() => {
    const payload = {
      isActive: false,
      page: page,
    };
    if (page > 1) {
      props.getsponsor(payload, (res) => {
        if (res.status == 200) {
        } else {
        }
      });
      props.getstoryUser(payload, (res) => {
        if (res.status == 200) {
        } else {
        }
      });
    }
  }, [page]);
  const toggleDeleteModal = (item) => {
    setID(item);
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleStoryModal = (item) => {
    setID(item);
    setShowDeleteStory(!showDeleteStory);
  };
  const handleSubscription = () => {
    const payload = {
      reason: reason,
      subscription_id: eId?.subscription_id,
    };
    setLoading(true);
    props.cancelSponsor(eId?.species?._id, payload, (res) => {
      if (res.status == 200) {
        setReason("");
        toggleDeleteModal(null);
        setLoading(false);
        props.getsponsor((res) => {
          if (res.status == 200) {
          } else {
          }
        });
        toast(<AlertSuccess message={res?.data?.data} />);
      } else {
        setReason("");
        toggleDeleteModal(null);
        setLoading(false);
        toast(<AlertError message={res?.data?.message} />);
      }
    });
  };
  const handleStory = () => {
    const payload = {
      reason: reason,
      subscription_id: eId?.subscription_id,
    };
    setLoading(true);
    props.cancelStory(eId?.species?._id, payload, (res) => {
      if (res.status == 200) {
        setReason("");
        toggleStoryModal(null);
        setLoading(false);
        props.getstoryUser( (res) => {
          if (res.status == 200) {
          } else {
          }
        });
        toast(<AlertSuccess message={res?.data?.data} />);
      } else {
        setReason("");
        toggleStoryModal(null);
        setLoading(false);
        toast(<AlertError message={res?.data?.message} />);
      }
    });
  };
  return (
    <div class="widget-media-block w-body-content mt-2 pt-0">
      <>
        {data && data.length > 0 && <h5 className="mb-3 mt-2">Sponsored</h5>}
        <ul className="d-flex flex-wrap">
          {data && data.length > 0
            ? data.map((item, index) => (
                <li key={index} className="ei-grid-item position-relative ei-grid-item-sponsor">
                  <div className="ei-grid-box">
                    <div className="ei-grid-box-image list-card-image">
                      {item?.species?.coverImage?.original ? (
                        <img src={process.env.REACT_APP_MEDIA + item?.species?.coverImage?.original} alt="" />
                      ) : (
                        <img src={getDefaultImage(item?.species?.speciesCategoryName)} alt="" />
                      )}
                    </div>
                    <div className="ei-grid-box-overlay" onClick={() => toggleDeleteModal(item)}>
                      <p className="text-transform mb-0">
                        <u>Cancel Sponsorship</u>
                      </p>
                    </div>
                  </div>
                </li>
              ))
            : null}
        </ul>
      </>

      <>
        {storyData && storyData.length > 0 && <h5>Story</h5>}
        <ul className="d-flex flex-wrap mt-2">
          {storyData && storyData.length > 0
            ? storyData.map((item, index) => (
                <li key={index} className="ei-grid-item position-relative ei-grid-item-sponsor">
                  <div className="ei-grid-box">
                    <div className="ei-grid-box-image list-card-image">
                      {item?.species?.coverImage?.original ? (
                        <img src={process.env.REACT_APP_MEDIA + item?.species?.coverImage?.original} alt="" />
                      ) : (
                        <img src={getDefaultImage(item?.species?.speciesCategoryName)} alt="" />
                      )}
                    </div>
                    <div className="ei-grid-box-overlay" onClick={() => toggleStoryModal(item)}>
                      <p className="text-transform">
                        <u>Cancel Sponsorship</u>
                      </p>
                    </div>
                  </div>
                </li>
              ))
            : null}
        </ul>
      </>

      {(!data || data.length === 0) && (!storyData || storyData.length === 0) && (
        <div className="panel-body flex-grow-1 no-sponsor position-relative">
          <img src={noPost} alt="No Sponsored Species Found" />
          <Link
            className="btn btn-default position-absolute h-40 pt-10"
            to={{ pathname: "/sponsor", state: { id: props.id } }}
            style={{ bottom: "28%", left: "35%" }}
          >
            Sponsor
          </Link>
        </div>
      )}

      <DeletePost
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loading}
        deleteHandler={handleSubscription}
        reason={reason}
        setReason={setReason}
      />
      <DeletePost
        show={showDeleteStory}
        onHide={toggleStoryModal}
        loadingDelete={loading}
        deleteHandler={handleStory}
        reason={reason}
        setReason={setReason}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsor: (param, callback) => {
    dispatch(getSponsorUser(param, callback));
  },
  getstoryUser: (callback) => {
    dispatch(getstoryUser(callback));
  },
  cancelSponsor: (param, data, callback) => {
    dispatch(cancelSponsor(param, data, callback));
  },
  cancelStory: (param, data, callback) => {
    dispatch(cancelStory(param, data, callback));
  },
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SponsoredSpecies));
