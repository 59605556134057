import React from "react";
import { Modal } from "react-bootstrap";

const AlertModal = (props) => {
    return (
        <Modal
            className=" fade custom-modal-popup"
            show={props.show}
            onHide={() => props.onHide()}
            enforceFocus={true}
            backdropClassName={"delete-backdrop"}
            //   backdrop={uploadImageModal ? false : true}
            centered
        >
            <Modal.Body>
                <div class="modal-alert-box">
                    <h4>Alert! </h4>
                    <p>{props?.message}</p>
                </div>
                <div class="modal-action-btn">
                    <a
                        href="javascript:void(0)"
                        class={`btn btn-delete btn-md btn-red  ${props.loadingDelete && "btn-disabled"
                            }`}
                        onClick={() => !props.loadingDelete && props.deleteHandler()}
                    >
                        {props?.type}
                    </a>
                    <a
                        href="javascript:void(0)"
                        style={{ background: "#fff" }}
                        class="btn btn-cancel global-cancel-button"
                        onClick={() => props.onHide()}
                    >
                        Cancel
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AlertModal;
