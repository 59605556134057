import React from "react";
import { Modal } from "react-bootstrap";
import Editor from "../../common/editor/editor";


//Delete Modal for Post which Reason Textarea
const DeletePost = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup "
      show={props.show}
      onHide={() => props.onHide(null)}
      enforceFocus={true}
      dialogClassName="modal-400w"
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>Are you sure you want to Cancel Sponsorship?</p>
        </div>
        <div className="mt-3">
          <textarea
            type="text"
            className="form-control w-100 text-in"
            onChange={(e) => {
              props.setReason(e.target.value);
            }}
            placeholder="Reason for cancellation"
            value={props.reason}
          />
        </div>
        <div class="modal-action-btn">
          <a
            href="#!"
            class={`btn btn-delete btn-md delete-btn-bg  ${props.loadingDelete && "disabled"}`}
            onClick={() => !props.loadingDelete && props.deleteHandler()}
          >
            Confirm
          </a>
          <a
            href="#!"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide(null)}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletePost;
