import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { deleteZoo, getZoo, speciesListings, updateZoo } from "../../store/species";
import App from "../../common/maps/map";
import Skeleton from "react-loading-skeleton";
import {
  removeTags,
  capitalizeContentInBrackets,
  transformDropdownData,
  extractIds,
} from "../../utils/helperFunctions";
import HTMLReactParser from "html-react-parser";
import marker from "../../include/images/marker.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../common/Pagination/pagination";
import { getZooList, sideBarApisListings } from "../../store/sidebarApis";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import trash from "../../include/images/trash-24x24.svg";
import Select from "react-select";
import noRecord from "../../include/images/nrb-img.svg";
import ShowMoreModal from "../common/showMoreModal/showMoreModal";
import AddModal from "../common/addModal/addModal";
import defaultZooImg from "../../include/images/Zoo.png";
const Zoo = (props) => {
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    zoo: "",
  });
  const [showAdd, setShowAdd] = useState(false);
  const zooDropdown = transformDropdownData(props.sideBarApisListings?.zoo?.data || []);

  useEffect(() => {
    if (!props.sideBarApisListings?.zoo?.data) {
      props.getZooList({}, (res) => {});
    }
  }, []);
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      zoo: selectedOptions,
    }));
  };
  const handleImageError = () => {
    setImageError(true);
  };
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getZoo(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const zooDetails = props.speciesListings && props.speciesListings.zoo && props.speciesListings.zoo;
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((zooDetails?.totalCount ?? 0) / (zooDetails?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      zooIds: extractIds(selectedPreference?.zoo),
    };
    props.updateZoo(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          zoo: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getZoo(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      zooIds: [ids],
    };
    props.deleteZoo(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getZoo(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <div class="container" ref={topElementRef}>
      <div class="row">
        <div class="col-md-12">
          <div class="tbc-body-inner">
            {props.editMode && (
              <div className="d-flex justify-content-between mb-4">
                <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                  Add Zoos & Wildlife Reserves
                </Link>
                <div class="d-flex flex-wrap justify-content-end w-75">
                  <Select
                    className="basic-single w-50 text-capitalize z-2"
                    classNamePrefix="select-search"
                    isMulti
                    placeholder="Select"
                    styles={customStyles1}
                    options={zooDropdown}
                    value={selectedPreference.zoo}
                    onChange={handleSelectChange}
                  />
                  <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                    Save
                  </Link>
                </div>
              </div>
            )}
            {zooDetails && zooDetails?.data?.length == 0 && (
              <ul class="ei-grid-list d-flex flex-wrap justify-content-center">
                <div class="no-records-container d-flex align-items-center justify-content-center">
                  <div class="no-records-box text-center">
                    <div class="nrb-image">
                      <img src={noRecord} alt="" />
                    </div>
                    <h6>No Records Found</h6>
                  </div>
                </div>
              </ul>
            )}
            {loading ? (
              <ul class="ei-grid-list d-flex flex-wrap">
                {Array.from({ length: 42 }, (item, index) => (
                  <div class="org-skeleton">
                    <Skeleton borderRadius={"10px"} height="200px" />
                  </div>
                ))}
              </ul>
            ) : (
              <ul class="tbc-organization-list d-flex flex-wrap">
                {zooDetails &&
                  zooDetails?.data.length > 0 &&
                  zooDetails?.data.map((item) => {
                    const zooUrl = item?.url?.split("//")?.pop();
                    return (
                      <li class="organisation-item position-relative">
                        {props.editMode && (
                          <button
                            type="button"
                            class="trash-button-box z-1"
                            onClick={(e) => {
                              handleDelete(item._id);
                            }}
                          >
                            <img src={trash} alt="w volumne" />
                          </button>
                        )}
                        {
                          <div className="organisation-box d-flex flex-wrap">
                            <div class="organisation-logo ">
                              <Link
                                to={{
                                  pathname: `/zoos-and-wildlife-reserves/${item?.slug}`,
                                  state: { id: item._id },
                                }}
                              >
                                {!imageError && item?.coverImage?.small ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                    onError={handleImageError}
                                    className="org-img sm-img-fix"
                                    alt=""
                                  />
                                ) : !imageError && item?.coverImage?.medium ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                    alt=""
                                    className="org-img sm-img-fix"
                                  />
                                ) : (
                                  <img src={defaultZooImg} alt="" className="org-img sm-img-fix" />
                                )}
                              </Link>
                            </div>
                            <div class="organisation-text" style={{ cursor: "default" }}>
                              <h6>
                                {" "}
                                <Link
                                  className="text-transfrom "
                                  to={{
                                    pathname: `/zoos-and-wildlife-reserves/${item?.slug}`,
                                    state: { id: item?._id },
                                  }}
                                >
                                  {`${capitalizeContentInBrackets(item?.name)}`}
                                </Link>
                                <div>
                                  <small className="organisation-text-footer">
                                    {item?.location?.countryName === "United States"
                                      ? item?.location &&
                                        (item.location.stateName || item.location.cityName) &&
                                        `${item.location.stateName || ""}${
                                          item.location.stateName && item.location.cityName ? ", " : ""
                                        }${item.location.cityName || ""}`
                                      : item?.location?.countryName}
                                  </small>
                                </div>
                              </h6>

                              <div class="organisation-text-footer">
                                <div className="d-flex space-between">
                                  <small>Species :</small>
                                  {item?.species?.length > 0 ? (
                                    <p
                                      class=" map-switch view-all-btn1 fs-12 org-tab-mob hover-underline"
                                      style={{
                                        minWidth: "100px",
                                        lineHeight: "12px",
                                        marginLeft: zooUrl ? "30px" : "0px",
                                      }}
                                      onClick={() => {
                                        if (item?.species?.length > 0) {
                                          setSelectedData(item);
                                          toggleShowMoreModal();
                                        }
                                      }}
                                    >
                                      View All
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <p className="caps-text">
                                  {item?.species && item?.species.length > 0
                                    ? item?.species.filter((ie) => ie?.displayCommonName || ie?.scientificName).length >
                                      0
                                      ? item?.species
                                          .filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                          .map((ie, r, filteredSpecies) =>
                                            r < 2 ? (
                                              <span key={r}>
                                                {ie?.displayCommonName || ie?.scientificName}
                                                {r + 1 < filteredSpecies.length && r < 1 ? ", " : ""}
                                                {r === 1 && filteredSpecies.length > 2 ? " ..." : ""}
                                              </span>
                                            ) : null,
                                          )
                                      : "No scientific names available"
                                    : "Not yet added"}
                                </p>
                                {zooUrl ? (
                                  <a
                                    target="_blank"
                                    href={`https://${zooUrl}`}
                                    class="btn btn-default"
                                    style={{
                                      minWidth: "100px",
                                      lineHeight: "12px",
                                    }}
                                  >
                                    Visit Zoo
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      </li>
                    );
                  })}
              </ul>
            )}
            {zooDetails && zooDetails?.totalCount > 42 && (
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageLinks={pageLinks}
                totalPages={pageLinks.length}
              />
            )}
          </div>
        </div>
        <ShowMoreModal
          show={showMoreModal}
          onHide={toggleShowMoreModal}
          listData={selectedData?.species}
          heading={`Species found in ${selectedData?.name ? capitalizeContentInBrackets(selectedData?.name) : ""}`}
          isNonEditable={true}
        />
        <AddModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          heading={"Add Zoos & Wildlife Reserves"}
          page="species"
          type="zoo"
          id={props.id}
          alert="zoos & wildlife reserves"
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getZoo: (params, callback) => dispatch(getZoo(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  updateZoo: (params, data, callback) => dispatch(updateZoo(params, data, callback)),
  deleteZoo: (params, data, callback) => dispatch(deleteZoo(params, data, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Zoo));
