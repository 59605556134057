import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSpeciesDetails, speciesListings } from "../../store/species";
import noRecord from "../../include/images/nrb-img.svg";
import Editor from "../../common/editor/editor";

// Helper function to check if a string contains HTML tags
const isHTML = (str) => /<\/?(ul|ol)[\s\S]*?>/i.test(str);


const Threats = (props) => {
  const details = props?.speciesListings && props?.speciesListings?.speciesDetail;

  const NThreat = details?.data?.naturalThreat;
  const naturalThreat = NThreat?.split(",");

  const HThreat = details?.data?.humanThreat;
  const humanThreat = HThreat?.split(",");

  const CThreat = details?.data?.climateChangeThreat;
  const climateChangeThreat = CThreat?.split(",");

  return (
    <div className="row">
      {props?.editMode ? (
        <div className="col-md-12">
          <div className="tbc-body-inner">
            <div className="ei-heading d-flex justify-content-between align-items-center mb-2">
              <h4>Threats</h4>
            </div>
            <ul className="tbc-grid-list d-flex flex-wrap mt-3">
              <li className="tbc-grid-item">
                <div className="tbc-grid-box">
                  <h4>Natural Threats</h4>
                  <Editor
                    id="threat1"
                    name="naturalThreat"
                    onChange={(data) => {
                      props.setEditState((prevState) => ({
                        ...prevState,
                        naturalThreat: data.target.value,
                      }));
                    }}
                    editorLoaded={true}
                    type="threats"
                    value={props.editState.naturalThreat}
                  />
                </div>
              </li>
              <li className="tbc-grid-item">
                <div className="tbc-grid-box">
                  <h4>Human Threats</h4>
                  <Editor
                    id="threat2"
                    name="humanThreat"
                    onChange={(data) => {
                      props.setEditState((prevState) => ({
                        ...prevState,
                        humanThreat: data.target.value,
                      }));
                    }}
                    editorLoaded={true}
                    type="threats"
                    value={props.editState.humanThreat}
                  />
                </div>
              </li>
              <li className="tbc-grid-item">
                <div className="tbc-grid-box">
                  <h4>Climate Change Threats</h4>
                  <Editor
                    id="threat3"
                    name="climateChangeThreat"
                    onChange={(data) => {
                      props.setEditState((prevState) => ({
                        ...prevState,
                        climateChangeThreat: data.target.value,
                      }));
                    }}
                    editorLoaded={true}
                    type="threats"
                    value={props.editState.climateChangeThreat}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="col-md-12">
          {(details?.data?.naturalThreat ||
            details?.data?.climateChangeThreat ||
            details?.data?.humanThreat) && (
            <div className="tbc-body-inner">
              <div className="ei-heading d-flex justify-content-between align-items-center mb-2">
                <h4>Threats</h4>
              </div>
              <ul className="tbc-grid-list d-flex flex-wrap mt-3">
                {details?.data?.naturalThreat && (
                  <li className="tbc-grid-item">
                    <div className="tbc-grid-box">
                      <h4>Natural Threats</h4>
                      <ul className="default-list">
                        {naturalThreat.map((item, index) => (
                          isHTML(item) ? (
                            <span key={index} className="caps-text" dangerouslySetInnerHTML={{ __html: item }} />
                          ) : (
                            <li key={index} className="default-item caps-text">{item}</li>
                          )
                        ))}
                      </ul>
                    </div>
                  </li>
                )}

                {details?.data?.humanThreat && (
                  <li className="tbc-grid-item">
                    <div className="tbc-grid-box">
                      <h4>Human Threats</h4>
                      <ul className="default-list">
                        {humanThreat.map((item, index) => (
                          isHTML(item) ? (
                            <span key={index} className="caps-text" dangerouslySetInnerHTML={{ __html: item }} />
                          ) : (
                            <li key={index} className="default-item caps-text">{item}</li>
                          )
                        ))}
                      </ul>
                    </div>
                  </li>
                )}

                {details?.data?.climateChangeThreat && (
                  <li className="tbc-grid-item">
                    <div className="tbc-grid-box">
                      <h4>Climate Change Threats</h4>
                      <ul className="default-list">
                        {climateChangeThreat.map((item, index) => (
                          isHTML(item) ? (
                            <span key={index} className="caps-text" dangerouslySetInnerHTML={{ __html: item }} />
                          ) : (
                            <li key={index} className="default-item caps-text">{item}</li>
                          )
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}

          {!details?.data?.naturalThreat &&
            !details?.data?.humanThreat &&
            !details?.data?.climateChangeThreat && (
              <div className="tbc-body-inner">
                <div className="ei-heading d-flex justify-content-between align-items-center mb-2">
                  <h4>Threats</h4>
                </div>
                <ul className="ei-grid-list d-flex flex-wrap justify-content-center">
                  <div className="no-records-container d-flex align-items-center justify-content-center">
                    <div className="no-records-box text-center">
                      <div className="nrb-image">
                        <img src={noRecord} alt="" />
                      </div>
                      <h6>No Records Found</h6>
                    </div>
                  </div>
                </ul>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Threats));
