import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "user",
  initialState: {
    checkProfileCompletion: {},
    userData: {},
    userDropdownData: {},
    userPreferences: {},
    myFeeds: {},
    loading: false,
    profile: null,
    myPost: {},
    followers: {},
    following: {},
    followersCount: 0,
    followingCount: 0,
    species: {},
    speciesCategory: {},
    region: {},
    organization: {},
    environmentalist: {},
    wetMarket: {},
    science: {},
    zoo: {},
    profileInfo:{},
    guestProfile:{},
    sponsor:{},
    story:{},
    transaction:{},
  },
  reducers: {
    followersCountChanger: (misc, action) => {
      if (action?.type) {
      }
      misc.followersCount = misc?.followersCount + 1;
    },
    checkProfileCompletionRequested: (misc, action) => {
      misc.loading = true;
    },
    checkProfileCompletionReceived: (misc, action) => {
      misc.checkProfileCompletion = action.payload;
      misc.loading = false;
    },
    checkProfileCompletionFailed: (misc, action) => {
      misc.loading = false;
    },
    profileRequested: (state, action) => {
      state.loading = true;
    },
    profileReceived: (state, action) => {
      state.profile = action.payload;
    },
    profileRequestFailed: (state, action) => {
      state.profile = false;
    },
    followingRequested: (state, action) => {
      state.loading = true;
    },
    followingReceived: (state, action) => {
      state.following = action.payload;
    },
    followingRequestFailed: (state, action) => {
      state.loading = false;
    },
    followersRequested: (state, action) => {
      state.loading = true;
    },
    followersReceived: (state, action) => {
      state.followers = action.payload;
    },
    followersRequestFailed: (state, action) => {
      state.loading = false;
    },
    userRequested: (misc, action) => {
      misc.loading = true;
    },
    userReceived: (misc, action) => {
      misc.userData = action.payload;
      misc.loading = false;
    },
    userFailed: (misc, action) => {
      misc.loading = false;
    },
    userPreferencesRequested: (misc, action) => {
      misc.loading = true;
    },
    userPreferencesReceived: (misc, action) => {
      misc.userPreferences = action.payload;
      misc.loading = false;
    },
    zooPreferencesReceived: (misc, action) => {
      misc.zoo = action.payload;
      misc.loading = false;
    },
    scienceAndEducationPreferencesReceived: (misc, action) => {
      misc.science = action.payload;
      misc.loading = false;
    },
    wetMarketPreferencesReceived: (misc, action) => {
      misc.wetMarket = action.payload;
      misc.loading = false;
    },
    environmentalistPreferencesReceived: (misc, action) => {
      misc.environmentalist = action.payload;
      misc.loading = false;
    },

    organizationPreferencesReceived: (misc, action) => {
      misc.organization = action.payload;
      misc.loading = false;
    },
    speciesCategoryPreferencesReceived: (misc, action) => {
      misc.speciesCategory = action.payload;
      misc.loading = false;
    },
    speciesPreferencesReceived: (misc, action) => {
      misc.species = action.payload;
      misc.loading = false;
    },
    regionPreferencesReceived: (misc, action) => {
      misc.region = action.payload;
      misc.loading = false;
    },
    userPreferencesFailed: (misc, action) => {
      misc.loading = false;
    },
    userDropdownRequested: (misc, action) => {
      misc.loading = true;
    },
    userDropdownReceived: (misc, action) => {
      misc.userDropdownData = action.payload;
      misc.loading = false;
    },
    userDropdownFailed: (misc, action) => {
      misc.loading = false;
    },

    feedRequested: (misc, action) => {
      misc.loading = true;
    },
    feedReceived: (misc, action) => {
      misc.myFeeds = action.payload;
      misc.loading = false;
    },
    feedFailed: (misc, action) => {
      misc.loading = false;
    },

    postRequested: (misc, action) => {
      misc.loading = true;
    },
    postReceived: (misc, action) => {
      misc.myPost = action.payload;
      misc.loading = false;
    },
    postFailed: (misc, action) => {
      misc.loading = false;
    },
    profileInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    profileInfoReceived: (misc, action) => {
      misc.profileInfo = action.payload;
      misc.loading = false;
    },
    profileInfoFailed: (misc, action) => {
      misc.loading = false;
    },
    guestProfileRequested: (misc, action) => {
      misc.loading = true;
    },
    guestProfileReceived: (misc, action) => {
      misc.guestProfile = action.payload;
      misc.loading = false;
    },
    guestProfileFailed: (misc, action) => {
      misc.loading = false;
    },
    sponsorRequested: (misc, action) => {
      misc.loading = true;
    },
    sponsorReceived: (misc, action) => {
      misc.sponsor = action.payload;
      misc.loading = false;
    },
    sponsorFailed: (misc, action) => {
      misc.loading = false;
    },
    storyRequested: (misc, action) => {
      misc.loading = true;
    },
    storyReceived: (misc, action) => {
      misc.story = action.payload;
      misc.loading = false;
    },
    storyFailed: (misc, action) => {
      misc.loading = false;
    },
    transactionRequested: (misc, action) => {
      misc.loading = true;
    },
    transactionReceived: (misc, action) => {
      misc.transaction = action.payload;
      misc.loading = false;
    },
    transactionFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  checkProfileCompletionRequested,
  checkProfileCompletionReceived,
  checkProfileCompletionFailed,
  profileRequested,
  profileReceived,
  profileRequestFailed,
  followingReceived,
  followingRequestFailed,
  followingRequested,
  followersReceived,
  followersRequestFailed,
  followersRequested,
  userRequested,
  userReceived,
  userFailed,
  userDropdownRequested,
  userDropdownReceived,
  userDropdownFailed,
  userPreferencesRequested,
  userPreferencesReceived,
  userPreferencesFailed,
  feedRequested,
  feedReceived,
  feedFailed,
  postRequested,
  postReceived,
  postFailed,
  regionPreferencesReceived,
  speciesPreferencesReceived,
  speciesCategoryPreferencesReceived,
  organizationPreferencesReceived,
  environmentalistPreferencesReceived,
  wetMarketPreferencesReceived,
  scienceAndEducationPreferencesReceived,
  zooPreferencesReceived,
  profileInfoRequested,
  profileInfoReceived,
  profileInfoFailed,
  guestProfileRequested,
  guestProfileReceived,
  guestProfileFailed,
  sponsorRequested,
  sponsorReceived,
  sponsorFailed,
  transactionRequested,
  transactionReceived,
  transactionFailed,
  storyRequested,
  storyReceived,
  storyFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "user";

// for step 1
export const sendOtpForSignup = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/sendOtpForSignup`,
      method: "POST",
      callback,
      data,
    })
  );
};

//   for step 2
export const verifyOtpAndSignup = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/verifyOtpAndSignup`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const resendOtpAndSignup = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/resendOtpForSignup`,
      method: "POST",
      callback,
      data,
    })
  );
};

// step 3
export const updateYourProfile = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile`,
      method: "PUT",
      callback,
      data,
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  );
};

// step 4
export const getCheckProfileCompletion = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/profileCompletion`,
      method: "get",
      callback,
      onStart: checkProfileCompletionRequested.type,
      onSuccess: checkProfileCompletionReceived.type,
      onError: checkProfileCompletionFailed.type,
    })
  );
};

export const userLogin = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/${baseUrl}/login`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const userRefreshToken = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/${baseUrl}/refreshToken`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const userLogout = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/${baseUrl}/logout`,
      method: "POST",
      callback,
    })
  );
};

export const userForgotPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/${baseUrl}/forgotPassword`,
      method: "POST",
      callback,
      data,
    })
  );
};
export const followUnfollowFriends = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/following/${data}`,
      method: "POST",
      callback,
    })
  );
};

export const followUnfollowMultipleFriends = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/following/`,
      method: "POST",
      data,
      callback,
    })
  );
};

export const getUsersData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/user`,
      method: "get",
      params,
      callback,
      onStart: userRequested.type,
      onSuccess: userReceived.type,
      onError: userFailed.type,
    })
  );
};

export const getUsersDropdownData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/user/dropdown`,
      method: "get",
      params,
      callback,
      onStart: userDropdownRequested.type,
      onSuccess: userDropdownReceived.type,
      onError: userDropdownFailed.type,
    })
  );
};

export const getUsersPreferences = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/preference/${params}`,
      method: "get",
      callback,
      onStart: userPreferencesRequested.type,
      onSuccess:
        params == "region"
          ? regionPreferencesReceived
          : params == "species"
            ? speciesPreferencesReceived
            : params == "speciesCategory"
              ? speciesCategoryPreferencesReceived
              : params == "organization"
                ? organizationPreferencesReceived
                : params == "environmentalist"
                  ? environmentalistPreferencesReceived
                  : params == "wetMarket"
                    ? wetMarketPreferencesReceived
                    : params == "scienceAndEducation"
                      ? scienceAndEducationPreferencesReceived
                      : params == "user"
                        ? userPreferencesReceived
                        : zooPreferencesReceived.type,
      onError: userPreferencesFailed.type,
    })
  );
};

export const getMyFeedData = (params, callback) => (dispatch) => {
  let url = `/post/public?page=${params.page}&sort=${params.sort}&order=${params.order}&${params.section}=${params.id}`;
  if (params.type) {
    url = `/post/public?tab=${params.type}&page=${params.page}&sort=${params.sort}&order=${params.order}&${params.section}=${params.id}`;
  }
  if (!params.section) {
    url = `/post/public?page=${params.page}&sort=${params.sort}&order=${params.order}`;
  }
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: feedRequested.type,
      onSuccess: feedReceived.type,
      onError: feedFailed.type,
    })
  );
};

export const getMyPostData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/post`,
      method: "get",
      params,
      callback,
      onStart: postRequested.type,
      onSuccess: postReceived.type,
      onError: postFailed.type,
    })
  );
};

export const addMyPost = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/post`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const updateMyPost = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/post/${params}`,
      method: "PUT",
      callback,
      data,
    })
  );
};
export const deleteMyPost = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/post/${params}`,
      method: "Delete",
      callback,
    })
  );
};

export const getProfileData = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/preference/profile`,
      method: "get",
      callback,
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  );
};
export const forgotPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/user/forgotPassword`,
      method: "post",
      callback,
      data,
    })
  );
};

export const getFollowingList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/following`,
      method: "get",
      params,
      callback,
      onStart: followingRequested.type,
      onSuccess: followingReceived.type,
      onError: followingRequestFailed.type,
    })
  );
};
export const verifyCodeForgotPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/user/verifyResetPasswordCode`,
      method: "post",
      data,
      callback,
    })
  );
};

export const getFollowersList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/followers`,
      params,
      method: "get",
      callback,
      onStart: followersRequested.type,
      onSuccess: followersReceived.type,
      onError: followersRequestFailed.type,
    })
  );
};
export const resetPassword = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auth/user/resetPassword/${id}`,
      method: "post",
      data,
      callback,
    })
  );
};
//Volunteer Preference API
export const volunteerPreference = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/volunteer/${params}`,
      method: "POST",
      callback,
      data,
    })
  );
};
//Get login Profile
export const getProfileInfo = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile`,
      method: "get",
      callback,
      onStart: profileInfoRequested.type,
      onSuccess: profileInfoReceived.type,
      onError: profileInfoFailed.type,
    })
  );
};
//Get guest Profile
export const getGuestProfile = (data,callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/profile/${data}`,
      method: "get",
      callback,
      onStart: guestProfileRequested.type,
      onSuccess: guestProfileReceived.type,
      onError: guestProfileFailed.type,
    })
  );
};
export const getSponsorUser = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/sponsorships`,
      method: "get",
      callback,
      onStart: sponsorRequested.type,
      onSuccess: sponsorReceived.type,
      onError: sponsorFailed.type,
    })
  );
};
export const getstoryUser = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/stories`,
      method: "get",
      callback,
      onStart: storyRequested.type,
      onSuccess: storyReceived.type,
      onError: storyFailed.type,
    })
  );
};
export const getTransaction = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/transactions`,
      method: "get",
      callback,
      onStart: transactionRequested.type,
      onSuccess: transactionReceived.type,
      onError: transactionFailed.type,
    })
  );
};
export const getUser = createSelector(
  (state) => state.entities.user,
  (user) => user
);
