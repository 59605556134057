import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  organizationListing,
  getWorkDetails,
  getTeamDetails,
  getProgramDetails,
  getPartnerDetails,
  getReportDetails,
  getMediaDetails,
  getOrgLiveFeeds,
  getSpecies,
  updateOrganizationDetails,
} from "../../store/organization";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Details from "../../components/organizationDetail/details";
import Works from "../../components/organizationDetail/works";
import Teams from "../../components/organizationDetail/teams";
import Partners from "../../components/organizationDetail/partners";
import Programs from "../../components/organizationDetail/programs";
import Reports from "../../components/organizationDetail/reports";
import Instagram from "../../include/images/Instagram-w.svg";
import facebook from "../../include/images/facebook.svg";
import website from "../../include/images/Website_icon.svg";
import twitter from "../../include/images/twitter-w.svg";
import { getRegionList, sideBarApisListings } from "../../store/sidebarApis";
import { getMisc, getReferences, getSetting } from "../../store/misc";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Species from "../../components/environmentalistDetail/species";
import defaultcover from "../../include/images/organization-cover.svg";
import defaultprofile from "../../include/images/organization-profile.svg";
import UserUpload from "../../components/common/userUpload/userUpload";
import App from "../../common/maps/map";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import { capitalizeContentInBrackets, capitalizeFirstLetterWord, cleanAndFormatHtml, validateFiles } from "../../utils/helperFunctions";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import parse from "html-react-parser";
import shareSvg from "../../include/images/share.svg";
import editIcon from "../../include/images/edit.svg";
import ShareModal from "../../components/common/sharemodal/shareModal";
import { getProfile } from "../../utils/localStorageServices";
import EditOrganization from "./edit-organization-detail";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import defaultOrgImg from "../../include/images/organisation.png";

const OrganizationDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("1");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const profile = getProfile();
  const [isEditable, setIsEditable] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editState, setEditState] = useState({});
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      setId(id);
      // setIsEditable(profile?.profile?.interestedOrganizations?.includes(id));
      setLoadingDetail(true);
      props.getOrganizationDetails(id, (res) => {
        if (res && res.status == 200) {
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getOrganizationDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setId(res?.data?.data?._id);
          // setIsEditable(profile?.profile?.interestedOrganizations?.includes(res?.data?.data?._id));
          setLoadingDetail(false);
        }
      });
    }
  }, []);
  Useauthredirect();
  useEffect(() => {
    if (id) {
      props.getWorkDetails(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getTeamDetails(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getProgramDetails(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getPartnerDetails(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getReportDetails(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getOrgLiveFeeds({ id: id, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getSpecies(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: id,
        page: 1,
        section: "organization",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);

  const details = props.organizationListing && props.organizationListing?.organizationDetail;
  const date = new Date(details?.data?.updatedAtVolunteer);
  const works = props.organizationListing && props.organizationListing?.workDetail;

  const team = props.organizationListing && props.organizationListing?.teamDetail;

  const partner = props.organizationListing && props.organizationListing?.partnerDetail;

  const program = props.organizationListing && props.organizationListing?.programDetail;

  const reports = props.organizationListing && props.organizationListing?.reportDetail;

  const species = props.organizationListing && props.organizationListing?.species;

  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const liveFeeds = props.organizationListing && props.organizationListing.liveFeeds;
  const donationUrl = details?.data?.donationUrl?.split("//").pop();
  useEffect(() => {
    setEditState({
      name: details?.data?.name || "",
      introduction: details?.data?.introduction || "",
      description: details?.data?.description || "",
      coverImage: details?.data?.coverImage?.original
        ? details?.data?.coverImage?.original
        : details?.data?.coverImage?.small,
    });
  }, [editMode, details]);
  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };
  const combinedData = [
    ...(species.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
      image: item?.coverImage?.small,
    })),
  ];
  const orgMapData = [
    {
      id: details.data?._id,
      name: details.data?.name,
      coordinates: details.data?.geoLocation,
      slug: details.data?.slug,
      section: "organizations",
      image: details.data?.coverImage?.small,
    },
  ];
  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "1", target: "#pills-one", label: "Details", count: null, data: [1, 2] },
    { id: "2", target: "#pills-two", label: "Work", count: works?.totalCount, data: works?.data },
    { id: "3", target: "#pills-three", label: "Our Team", count: team?.totalCount, data: team?.data },
    { id: "4", target: "#pills-four", label: "Our Partners", count: partner?.totalCount, data: partner?.data },
    { id: "5", target: "#pills-five", label: "Programs", count: program?.totalCount, data: program?.data },
    { id: "6", target: "#pills-six", label: "Species", count: species?.totalCount, data: species?.data },
    { id: "7", target: "#pills-seven", label: "Reports", count: reports?.totalCount, data: reports?.data },
    // { id: "8", target: "#pills-eight", label: "Media", count: null, data: [1, 2] },
    { id: "9", target: "#pills-nine", label: "Live Feeds", count: liveFeeds?.totalCount, data: liveFeeds?.data },
    { id: "10", target: "#pills-ten", label: "Map", count: null, data: [1, 2] },
    { id: "11", target: "#pills-eleven", label: "Media and Posts", count: null, data: [1, 2] },
    { id: "12", target: "#pills-twelve", label: "References", count: null, data: references },
  ];
  const handleSave = (e) => {
    e.preventDefault();

    const filesToValidate = [];
    if (editState?.coverImage && editState?.coverImage instanceof File) filesToValidate.push(editState.coverImage);

    if (!validateFiles(filesToValidate)) {
      setLoadingDetail(false);
      return;
    }

    const uploadPromises = [];
    const newEditState = { ...editState };

    const handleFileUpload = (file, key) => {
      const ext = file.name.split(".").pop();
      const fileName = `${uuidv4()}.${ext}`;
      newEditState[key] = `public/${fileName}`;
      return Storage.put(fileName, file, {
        completeCallback: () => {},
        progressCallback: () => {},
        errorCallback: (err) => {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        },
      });
    };

    if (editState.coverImage && editState.coverImage instanceof File) {
      uploadPromises.push(handleFileUpload(editState.coverImage, "coverImage"));
    }

    Promise.all(uploadPromises).then(() => {
      const payload = {
        coverImage: newEditState.coverImage,
        name: newEditState.name,
        description: newEditState.description,
        introduction: newEditState.introduction,
      };

      props.updateOrganizationDetails(details.data._id, payload, (res) => {
        if (res.status === 200) {
          setLoadingDetail(false);
          setEditMode(false);
          toast(<AlertSuccess message="Information Saved" />);
        } else {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        }
      });
    });
  };
  return (
    <PageLayout>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {editMode ? (
        <EditOrganization
          details={details}
          items={items}
          isEditable={isEditable}
          editMode={editMode}
          id={id}
          setSelectedId={setSelectedId}
          menuItem={menuItem}
          openShareModal={openShareModal}
          setOpenShareModal={setOpenShareModal}
          donationUrl={donationUrl}
          handleMenuItemClick={handleMenuItemClick}
          addTargetBlankToLinks={addTargetBlankToLinks}
          combinedData={combinedData}
          orgMapData={orgMapData}
          miscSettingCitation={miscSettingCitation}
          editState={editState}
          setEditState={setEditState}
        />
      ) : (
        <main id="main">
          {/* <div class="org-hero-container">
           {loadingDetail ? <div class="org-hero-bg org-hero-bg1"><Skeleton borderRadius={"10px"} height={"100%"} /> </div> : <div class="org-hero-bg org-hero-bg1">
            <img
              src={
                details?.data?.bannerImage?.original ? process.env.REACT_APP_MEDIA +
                  details?.data?.bannerImage?.original : defaultcover
              }
              alt=""
            />
          </div>}
      </div> */}
          <div class="org-detail-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="org-detail-main d-flex flex-wrap align-items-start mt-2">
                    <div class="od-logo">
                      {loadingDetail ? (
                        <div class="od-logo-inner">
                          <Skeleton borderRadius={"10px"} height={"100%"} />{" "}
                        </div>
                      ) : (
                        <div class="od-logo-inner">
                          { details?.data?.coverImage?.original ?<img
                            src={
                              details?.data?.coverImage?.original
                                ? process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original
                                : defaultprofile
                            }
                            alt=""
                          />:<img
                          src={defaultOrgImg}
                          alt=""
                        />}
                        </div>
                      )}
                      <ul class="hero-social-media-list d-flex flex-wrap">
                        {details?.data?.websiteUrl ? (
                          <li class="ltb-icon-item">
                            <a
                              href={details?.data?.websiteUrl}
                              target="_blank"
                              rel="noreferrer"
                              class="ltb-icon-box custom-icon"
                            >
                              <svg
                                width="40"
                                height="1em"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 21C17.9706 21 22 16.9706 22 12C22 7.02944 17.9706 3 13 3C8.02944 3 4 7.02944 4 12C4 16.9706 8.02944 21 13 21Z"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4 12H22"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.9984 3C15.2496 5.46452 16.5289 8.66283 16.5984 12C16.5289 15.3372 15.2496 18.5355 12.9984 21C10.7473 18.5355 9.46796 15.3372 9.39844 12C9.46796 8.66283 10.7473 5.46452 12.9984 3Z"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {details?.data?.socialMediaLink?.facebook ? (
                          <li class="ltb-icon-item">
                            <a
                              href={details?.data?.socialMediaLink?.facebook}
                              target="_blank"
                              class="ltb-icon-box custom-icon"
                              rel="noreferrer"
                            >
                              <i class="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {details?.data?.socialMediaLink?.twitter ? (
                          <li class="ltb-icon-item">
                            <a
                              href={details?.data?.socialMediaLink?.twitter}
                              target="_blank"
                              class="ltb-icon-box custom-icon"
                              rel="noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                              </svg>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {details?.data?.socialMediaLink?.instagram ? (
                          <li class="ltb-icon-item">
                            <a
                              href={details?.data?.socialMediaLink?.instagram}
                              target="_blank"
                              class="ltb-icon-box custom-icon"
                              rel="noreferrer"
                            >
                              <i class="fab fa-instagram"></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                    <div class="od-content">
                      <span class="text-title text-green">Organizations</span>
                      <div class="od-content-heading d-flex">
                        {loadingDetail ? (
                          <Skeleton borderRadius={"10px"} height={40} width={200} />
                        ) : (
                          <h1 className="caps-text ">
                            {details?.data?.name && capitalizeContentInBrackets(details?.data?.name)}
                            <div class="od-content-icon" style={{ marginRight: "40px" }}>
                              {/* <Link onClick={() => setOpenShareModal(true)}><img
                              src={shareSvg} alt="" /></Link> */}
                              {profile?.profile?.isVolunteer && isEditable && (
                                <Link
                                  onClick={() => {
                                    setEditMode(true);
                                  }}
                                >
                                  <img src={editIcon} alt="" />
                                </Link>
                              )}
                            </div>
                          </h1>
                        )}
                        {details?.data?.isAddedToDonation === true ? (
                          <a
                            href={donationUrl ? `https://${donationUrl}` : "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`btn btn-default btn-donate-org ${!donationUrl ? "disabled" : ""}`}
                          >
                            Donate Direct
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      {loadingDetail ? (
                        <ul class="w-100 mt-4">
                          <Skeleton count={3} borderRadius={"10px"} width={"100%"} />
                        </ul>
                      ) : (
                        <ul class="page-detail-head-list ml-0">
                          {details.data?.introduction ? parse(details.data.introduction) : ""}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loadingDetail ? (
            <div class="tabs-block-container">
              <div class="tbc-head ">
                <Skeleton
                  borderRadius={"10px"}
                  height={"7.5vh"}
                  baseColor="var(--dark-green-color)"
                  highlightColor="#0cb40c"
                />
              </div>
              <div class="container mt-5">
                {" "}
                <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
              </div>
            </div>
          ) : (
            <div class="tabs-block-container new-tabs-block-container">
              <div class="tbc-head ">
                <div class="container">
                  <div class="row">
                    <div className="col-md-12">
                      <form className="d-lg-none">
                        <select
                          className="form-select"
                          id="tabSelector"
                          onChange={(e) => handleMenuItemClick(e.target.value)}
                          value={menuItem}
                        >
                          {items.map(
                            (item) =>
                              item.data?.length > 0 && (
                                <option key={item.id} value={item.id}>
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </option>
                              ),
                          )}
                        </select>
                      </form>
                      <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                        {items.map(
                          (item) =>
                            item.data?.length > 0 && (
                              <li className="nav-item" key={item.id} role="presentation">
                                <div
                                  className={`nav-link ${menuItem === item.id && "active"}`}
                                  id={`pills-${item.id}-tab`}
                                  data-bs-toggle="pill"
                                  data-bs-target={`#pills-${item.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`pills-${item.id}`}
                                  aria-selected={menuItem === item.id}
                                  onClick={() => handleMenuItemClick(item.id)}
                                >
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </div>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tbc-body overflow-hidden">
                <div class="tab-content tab-content-slider" id="pills-tabContent">
                  {menuItem == "1" && (
                    <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                      <Details menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                    </div>
                  )}
                  {menuItem == "2" && (
                    <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                      <Works menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                    </div>
                  )}

                  {menuItem == "3" && (
                    <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                      <Teams menuItem={menuItem} id={id} />
                    </div>
                  )}

                  {menuItem == "4" && (
                    <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                      <Partners menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                    </div>
                  )}

                  {menuItem == "5" && (
                    <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                      <Programs menuItem={menuItem} id={id} name={details?.data?.name} setSelectedId={setSelectedId} />
                    </div>
                  )}

                  {menuItem == "6" && (
                    <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                      <Species menuItem={menuItem} id={id} setSelectedId={setSelectedId} page="organization" />
                    </div>
                  )}
                  {menuItem == "7" && (
                    <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                      <Reports menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                    </div>
                  )}
                  {menuItem == "8" && (
                    <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                      <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" />
                    </div>
                  )}
                  {menuItem == "9" && (
                    <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                      <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" />
                    </div>
                  )}
                  {menuItem == "10" && (
                    <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                      <div className="container">
                        <div class="tbc-map-full detail-page-map region-detail-map">
                          <div class="map-hero-image map-wet-market">
                            {combinedData && (
                              <App
                                data={combinedData}
                                data1={orgMapData}
                                mapType="detail-page"
                                isMultiMarker={true}
                                zoom={"zoom"}
                                singleMap={true}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {menuItem == "11" && (
                    <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                      <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" data={details?.data} />
                    </div>
                  )}
                  {menuItem == "12" && (
                    <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                      <References menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="organization" isEdit={editMode} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <ShareModal
            show={openShareModal}
            onHide={() => setOpenShareModal(!openShareModal)}
            name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
            id={details?.data?._id}
            category="organization"
          />
          <div class="container custom-citation">
            <div className="custom-citation-text">Citations</div>
            <div
              className="custom-citation-text"
              dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
            ></div>
          </div>
        </main>
      )}
      {/* {details?.data?.updatedByVolunteer?.firstName && (
        <div className="container">
          {" "}
          Edit by{" "}
          <span className="text-capitalize">
            {details?.data?.updatedByVolunteer?.firstName} {details?.data?.updatedByVolunteer?.lastName}{" "}
          </span>
          at {date.toLocaleString()}
        </div>
      )} */}
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) => dispatch(getOrganizationDetails(params, callback)),
  getWorkDetails: (params, callback) => dispatch(getWorkDetails(params, callback)),
  getTeamDetails: (params, callback) => dispatch(getTeamDetails(params, callback)),
  getProgramDetails: (params, callback) => dispatch(getProgramDetails(params, callback)),
  updateOrganizationDetails: (params, data, callback) => dispatch(updateOrganizationDetails(params, data, callback)),
  getPartnerDetails: (params, callback) => dispatch(getPartnerDetails(params, callback)),
  getSpecies: (params, callback) => dispatch(getSpecies(params, callback)),
  getReportDetails: (params, callback) => dispatch(getReportDetails(params, callback)),
  getOrgLiveFeeds: (params, callback) => dispatch(getOrgLiveFeeds(params, callback)),
  getMediaDetails: (params, callback) => dispatch(getMediaDetails(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
  getMisc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrganizationDetail));
