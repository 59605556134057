import { toast } from "react-toastify";
import AlertError from "../common/alerts/alertError";

export const getSlug = (Text) => {
  return Text?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const removeTags = (str) => {
  if (str === null || str === "") {
    return false;
  } else str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, "");
};

export const dotDesc = (description, limit) => {
  const dots = "...";
  if (description && description.length > limit) {
    description = description.substring(0, limit) + dots;
  }
  return description;
};

export const capitalizeFirstLetter = (string) => {
  // Check if string is null or undefined
  if (!string) {
    return string;
  }

  // Check if string is not a string
  if (typeof string !== 'string') {
    return string;
  }

  // If string is valid, capitalize first letter
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function dateTimeToTimestamp(dateTimeString, format) {
  const dateObj = new Date(dateTimeString);
  const timestamp = dateObj.getTime();
  return timestamp;
}

export const getRelativeTime = (dateTime) => {
  const timestamp = dateTimeToTimestamp(dateTime);

  const now = new Date();
  const timeDiff = now.getTime() - timestamp;

  // Define time intervals in milliseconds
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  if (timeDiff < minute) {
    const seconds = Math.floor(timeDiff / 1000);
    return seconds + (seconds > 1 ? " seconds ago" : " second ago");
  } else if (timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute);
    return minutes + (minutes > 1 ? " minutes ago" : " minute ago");
  } else if (timeDiff < day) {
    const hours = Math.floor(timeDiff / hour);
    return hours + (hours > 1 ? " hours ago" : " hour ago");
  } else if (timeDiff < week) {
    const days = Math.floor(timeDiff / day);
    return days + (days > 1 ? " days ago" : " day ago");
  } else if (timeDiff < month) {
    const weeks = Math.floor(timeDiff / week);
    return weeks + (weeks > 1 ? " weeks ago" : " week ago");
  } else if (timeDiff < year) {
    const months = Math.floor(timeDiff / month);
    return months + (months > 1 ? " months ago" : " month ago");
  } else {
    const years = Math.floor(timeDiff / year);
    return years + (years > 1 ? " years ago" : " year ago");
  }
};

export const capitalizeContentInBrackets = (name) => {
  if (typeof name !== 'string') {
    return name; // Return the name unchanged if it's not a string
  }

  const regex = /\(([^)]+)\)/;
  const matches = name?.match(regex);
  if (matches && matches.length > 1) {
    const contentInsideBrackets = matches[1];
    const capitalizedContent = contentInsideBrackets.toUpperCase();
    return name.replace(contentInsideBrackets, capitalizedContent);
  }
  return name;
};
export const capitalizeFirstLetterWord = (str) => {
  if (!str) return "";
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}
export const transformDropdownData = (data, labelKey = 'name', valueKey = '_id') => {
  return data.map(item => ({
    label:capitalizeContentInBrackets(item[labelKey]),
    value: item[valueKey]
  }));
};

export const validateFiles = (files) => {
  for (const file of files) {
    if (file.size > 25 * 1024 * 1024) {
      toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
      return false;
    }
    if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(file.type)) {
      toast(<AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpg, jpeg, png, and webp." />);
      return false;
    }
  }
  return true;
};
export const extractIds = (selectedOptions) => {
  return selectedOptions?.map(option => option.value);
};
export const cleanAndFormatHtml = (html) => {
  if (!html || typeof html !== 'string') {
    return ''; // Return an empty string if html is undefined or not a string
  }

  return html
    // Add target="_blank" to all links
    .replace(/<a /g, '<a target="_blank" ')
    // Replace non-breaking spaces with normal spaces
    .replace(/&nbsp;/g, ' ')
    // Remove empty <p> tags
    .replace(/<p><\/p>/g, '')
    // Remove paragraphs that only have <br> tags
    .replace(/<p><br><\/p>/g, '')
    // Optional: trim unnecessary spaces between tags
    .replace(/>\s+</g, '><');
};

