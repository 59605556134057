import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import Select from "react-select";
import { addData, getCountry, getMisc, getUsCitiesDropdown, getUsState } from "../../../store/misc";
import { getUser } from "../../../store/users";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import { Amplify, Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

// Modal to add new Species, organization, zoo, and Wetmarket
const AddModal = (props) => {
  const [selectedEditItem, setSelectedEditItem] = useState({});
  const [selectDetailTabImage, setSelectDetailTabImage] = useState(null);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [usStateDropdown, setUsStateDropdown] = useState([]);
  const [usCityDropdown, setUsCityDropdown] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [selectLocation, setSelectLocation] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status

  useEffect(() => {
    setLoadingCountry(true);
    props.getCountry((res) => {
      if (res && res.status === 200) {
        setLoadingCountry(false);
      } else {
        setLoadingCountry(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    setLoadingState(true);
    props.getUsState((res) => {
      if (res && res.status === 200) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    if (stateId) {
      setLoadingCity(true);
      const payload = {
        id: stateId,
      };
      props.getUsCitiesDropdown(payload, (res) => {
        if (res && res.status === 200) {
          setLoadingCity(false);
        } else {
          setLoadingCity(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [stateId]);

  const country = props.getMisc?.Countries?.data || {};
  const state = props.getMisc?.usState?.data || {};
  const cities = props.getMisc?.usCitiesItems?.data || [];

  useEffect(() => {
    if (country) {
      const data =country.length > 0 && country?.map((item) => ({
        name: item.name,
        code: item.code,
        _id: item._id,
      }));
      setCountryDropdown((prev) => (JSON.stringify(prev) === JSON.stringify(data) ? prev : data));
    }

    if (state) {
      const sData = state.length > 0 && state?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setUsStateDropdown((prev) => (JSON.stringify(prev) === JSON.stringify(sData) ? prev : sData));
    }

    if (cities) {
      const cData =cities.length > 0 && cities?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setUsCityDropdown((prev) => (JSON.stringify(prev) === JSON.stringify(cData) ? prev : cData));
    }
  }, [country, state, cities]);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Set submitting state to true

    const imagePromiseContainer = [];
    if (selectDetailTabImage) {
      const file = selectDetailTabImage;
      const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
      imagePromiseContainer.push(
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            setIsSubmitting(false); // Set submitting state to false in case of error
            return toast(<AlertError message={"Something Went Wrong"} />);
          },
        }),
      );
    }
    const imageResult = await Promise.all([...imagePromiseContainer]);
    const imagePayload = imageResult.map((it) => ({
      media: "public/" + it.key,
      mediaType: "image",
      mediaSource: "s3",
    }));

    const payload = {
      detailPage: props.page,
      [props.page]: props.id,
      requestToAdd: props.type,
      name: selectedEditItem.name || "",
      country: selectLocation.country || "",
      state: selectLocation.state || "",
      city: selectLocation.city || "",
      address: selectedEditItem.address || "",
      zipCode: selectedEditItem.zipCode || "",
      image: imagePayload.length > 0 ? imagePayload[0].media : "",
    };

    props.addData(payload, (res) => {
      setIsSubmitting(false); // Set submitting state to false after response

      if (res && res.status === 200) {
        toast(
          <AlertSuccess
            message={`Your request to add the ${props.alert} has been sent to Admin. Please wait till it is reviewed and added to the system. You will be notified once your request is approved.`}
          />,
        );
        props.onHide();
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  let reorderedCountryDropdown = [];

  if (Array.isArray(countryDropdown)) {
    reorderedCountryDropdown = [...countryDropdown];
    const usIndex = reorderedCountryDropdown.findIndex((country) => country.name === "United States");

    if (usIndex > -1) {
      const usOption = reorderedCountryDropdown.splice(usIndex, 1)[0];
      reorderedCountryDropdown.unshift(usOption);
    }
  }
  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div className="modal-content d-flex flex-column">
        <div className="modal-header modal-head view-all-header">
          <h5 className="modal-title text-capitalize" style={{ width: "90%" }}>
            {props.heading}
          </h5>
          <button
            type="button"
            className="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" className="modal-close-btn" />
          </button>
        </div>

        <Modal.Body className="custom-modal-body flex-grow-1 w-80 overflow-hidden" style={{ padding: "30px 0px" }}>
          <div className="two-row-column d-flex flex-wrap w-75 ps-5 add-modal">
            <div className="tr-column position-relative w-100">
              <div className="tr-column-content w-100 h-100 align-middle">
                <div className="ff-column w-100 p-0">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEditItem.name || ""}
                    id="name"
                    placeholder="Name"
                    onChange={(e) => {
                      const data = { ...selectedEditItem };
                      data.name = e.target.value;
                      setSelectedEditItem(data);
                    }}
                  />
                </div>
                <div className="ff-column p-0 pb-5 mb-4">
                  <label htmlFor="selectImage" className="form-label">
                    Image / Logo
                  </label>
                  <span className="file-input">
                    <input
                      className="form-control position-absolute"
                      id="inputGroupFile02"
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      onChange={(e) => {
                        const data = { ...selectedEditItem };
                        data.detailPageImage = true;
                        setSelectDetailTabImage(e.target.files[0]);
                        setSelectedEditItem(data);
                      }}
                    />
                  </span>
                </div>
                <div className="ff-column w-100 mb-4 p-0">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEditItem.address || ""}
                    id="address"
                    placeholder="Address"
                    onChange={(e) => {
                      const data = { ...selectedEditItem };
                      data.address = e.target.value;
                      setSelectedEditItem(data);
                    }}
                  />
                </div>
                <div className=" w-100 p-0 mb-4">
                  <label htmlFor="countrySelect" className="form-label">
                    Country
                  </label>
                  <Select
                    value={
                      selectLocation?.country
                        ? {
                            name: selectLocation?.countryName,
                            value: selectLocation?.country,
                          }
                        : null
                    }
                    id="countrySelect"
                    placeholder="Select Country"
                    styles={customStyles1}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    options={reorderedCountryDropdown}
                    name={countryDropdown}
                    onChange={(event) => {
                      const data = { ...selectLocation };
                      data.countryName = event.name;
                      data.country = event.code;
                      setSelectLocation(data);
                    }}
                  />
                  <div className="invalid-feedback">{error.country ? <p>{error.country}</p> : ""}</div>
                </div>

                {selectLocation?.country === "US" || selectLocation?.countryName === "United States" ? (
                  <div className="ff-column w-100 p-0">
                    <label htmlFor="stateSelect" className="form-label">
                      State
                    </label>
                    <Select
                      id="stateSelect"
                      placeholder={false}
                      styles={customStyles1}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={usStateDropdown}
                      isLoading={loadingState}
                      loadingMessage={() => "Loading ..."}
                      name={usStateDropdown}
                      onChange={(event) => {
                        const data = { ...selectLocation };
                        data.state = event.name;
                        data.value = event.id;
                        setStateId(event.id);
                        setSelectLocation(data);
                      }}
                    />
                    <div className="invalid-feedback">{error.state ? <p>{error.state}</p> : ""}</div>
                  </div>
                ) : (
                  <div className="ff-column w-100 p-0">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <input
                      type="text"
                      className={error.state ? "invalid-border form-control " : "form-control "}
                      id="state"
                      placeholder="State"
                      value={selectLocation.state || ""}
                      onChange={(e) => {
                        const data = { ...selectLocation };
                        data.state = e.target.value;
                        setSelectLocation(data);
                      }}
                    />
                    <div className="invalid-feedback">{error.state ? <p>{error.state}</p> : ""}</div>
                  </div>
                )}
                {usCityDropdown.length > 0 && selectLocation?.country === "US" && selectLocation?.state ? (
                  <div className="ff-column w-100 p-0">
                    <label htmlFor="floatingSelect" className="form-label">
                      City
                    </label>
                    <Select
                      id="citySelect"
                      placeholder={false}
                      styles={customStyles1}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={usCityDropdown}
                      name={usCityDropdown}
                      isLoading={loadingCity}
                      loadingMessage={() => "Loading ..."}
                      onChange={(event) => {
                        const data = { ...selectLocation };
                        data.city = event.name;
                        data.value = event.id;
                        setSelectLocation(data);
                      }}
                    />
                    <div className="invalid-feedback">{error.city ? <p>{error.city}</p> : ""}</div>
                  </div>
                ) : (
                  <div className="ff-column w-100 p-0">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className={error.city ? "invalid-border form-control" : "form-control"}
                      id="city"
                      placeholder="City"
                      value={selectLocation.city || ""}
                      onChange={(e) => {
                        const data = { ...selectLocation };
                        data.city = e.target.value;
                        setSelectLocation(data);
                      }}
                    />
                    <div className="invalid-feedback">{error.city ? <p>{error.city}</p> : ""}</div>
                  </div>
                )}
                <div className="ff-column w-100 mb-4 p-0">
                  <label htmlFor="address" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={selectedEditItem.zipCode || ""}
                    id="address"
                    placeholder="Address"
                    onChange={(e) => {
                      const data = { ...selectedEditItem };
                      data.zipCode = e.target.value;
                      setSelectedEditItem(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row row-form-button justify-content-end me-4">
            <div className="col-sm-6 ps-sm-0">
              <button
                type="submit"
                className="btn btn-default btn-block w-50"
                onClick={handleSubmit}
                disabled={isSubmitting} // Disable button while submitting
              >
                {isSubmitting ? "Submitting..." : "Submit"} {/* Show "Submitting..." while submitting */}
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountry: (callback) => dispatch(getCountry(callback)),
  getUsState: (callback) => dispatch(getUsState(callback)),
  getUsCitiesDropdown: (data, callback) => dispatch(getUsCitiesDropdown(data, callback)),
  addData: (data, callback) => dispatch(addData(data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AddModal));
