import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import Details from "../../components/Region/regionDetailsPage/details";
import EnvironmentThreats from "../../components/Region/regionDetailsPage/environmentThreats";
import Zoo from "../../components/Region/regionDetailsPage/zoo";
import EndangeredSpecies from "../../components/Region/regionDetailsPage/endangeredSpecies";
import Organizations from "../../components/Region/regionDetailsPage/organizations";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getBlog,
  getNews,
  getOrganizations,
  getRegionDetails,
  getRegionLiveFeeds,
  getRegionMedia,
  getScienceEducation,
  getSpeciesReg,
  getWetMarkets,
  getZoo,
  regionListings,
  updateRegionDetails,
} from "../../store/region";
import ScienceEducation from "../../components/Region/regionDetailsPage/scienceEducation";
import { getRegionList, sideBarApisListings } from "../../store/sidebarApis";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { environmentalistListings, getEnvironmentalist } from "../../store/environmentalist";
import Environmentalist from "../../components/Region/regionDetailsPage/environmentalist";
import App from "../../common/maps/map";
import Loader from "../../common/loader/loader";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { getMisc, getReferences, getSetting } from "../../store/misc";
import Skeleton from "react-loading-skeleton";
import UserUpload from "../../components/common/userUpload/userUpload";
import axios from "axios";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import ListingCard from "../../components/common/listingCard/listingCard";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import { getProfile } from "../../utils/localStorageServices";
import EditRegionDetail from "./edit-region-detail";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { cleanAndFormatHtml, validateFiles } from "../../utils/helperFunctions";
import { v4 as uuidv4 } from "uuid";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { Amplify, Auth, Storage } from "aws-amplify";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const RegionDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("11");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const currentUrl = location.pathname;
  const pathSegments = location.pathname.split("/");
  const [coordinates, setCoordinates] = useState(null);
  const profile = getProfile();
  const [isEdit, setIsEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editState, setEditState] = useState({});
  const [follow, setFollow] = useState(false);
  const areAllArraysEmpty = (obj) => {
    const keys = [
      "interestedEnvironmentalists",
      "interestedOrganizations",
      "interestedRegions",
      "interestedScienceAndEducations",
      "interestedSpecies",
      "interestedWetMarkets",
      "interestedZoos",
    ];

    for (const key of keys) {
      if (!Array.isArray(obj[key]) || obj[key]?.length > 0) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      setId(id);
      if (profile?.profile) {
        setIsEdit(profile?.profile?.interestedRegions?.includes(id) || areAllArraysEmpty(profile?.profile));
      }
      setLoadingDetail(true);
      props.getRegionDetails(id, (res) => {
        if (res && res.status == 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      setLoadingDetail(true);
      props.getRegionDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setId(res?.data?.data?._id);
          setIsEdit(
            profile?.profile?.interestedRegions?.includes(res?.data?.data?._id) || areAllArraysEmpty(profile?.profile),
          );
          if (profile?.profile) {
            setIsEdit(
              profile?.profile?.interestedRegions?.includes(res?.data?.data?._id) ||
                areAllArraysEmpty(profile?.profile),
            );
          }
        } else {
          setLoadingDetail(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [location]);
  useEffect(() => {
    if (profile?.profile && Array.isArray(profile?.profile?.preferredRegions)) {
      if (profile?.profile?.preferredRegions.includes(id)) {
        setFollow(true);
      } else {
        setFollow(false); 
      }
    } else {
      setFollow(false); 
    }
  }, [id]);
  
  //Getting geo location by region name
  const getCoordinates = async (locationName) => {
    try {
      const apiKey = "AIzaSyB0e6NXLm5QM6WrRb1whqfG0UdpY72bvMg";
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        locationName,
      )}&key=${apiKey}`;

      const response = await axios.get(apiUrl);

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        const lat = location.lat;
        const lng = location.lng;

        return { lat, lng };
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (id) {
      props.getSpeciesReg(id, (res) => {
        if (res && res.status == 200) {
          setLoadingDetail(false);
        }
      });
      props.getWetMarkets(id, {}, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getOrganizations(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getScienceEducation(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getZoo(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getEnvironmentalist({ regions: id }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getRegionLiveFeeds({ id, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: id,
        page: 1,
        section: "region",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);

  const details =
    pathSegments &&
    props.regionDetails &&
    props.regionDetails.regionDetails &&
    pathSegments[pathSegments.length - 1] === props.regionDetails.regionDetails.slug
      ? props.regionDetails.regionDetails
      : null;

  const date = new Date(details?.updatedAtVolunteer);
  const blog = props.regionDetails && props.regionDetails.blog?.data;
  const endangeredSepecies = props.regionDetails && props.regionDetails.species;
  const threats = props.regionDetails && props.regionDetails?.wetMarkets;
  const news = props.regionDetails && props.regionDetails.news?.data;
  const organizations = props.regionDetails && props.regionDetails.organization;
  const science = props.regionDetails && props.regionDetails.scienceAndEducation;
  const zoo = props.regionDetails && props.regionDetails.zoo;
  const environmentalist = props?.environmentalistListings?.environmentalist;
  const allRegionDetails = props?.sideBarApisListings?.regionList?.data;
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  let splashText = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const livefeeds = props.regionDetails && props.regionDetails.liveFeeds;
  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };
  Useauthredirect();
  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);
  useEffect(() => {
    const locationName = details?.name;
    if (locationName) {
      getCoordinates(locationName).then((coordinates) => {
        if (coordinates) {
          setCoordinates(coordinates);
        } else {
          console.error("Failed to get coordinates for", locationName);
        }
      });
    }
  }, [details?.name]);
  useEffect(() => {
    setEditState({
      name: details?.name || "",
      description: details?.description || "",
      coverImage: details?.coverImage?.original ? details?.coverImage?.original : details?.coverImage?.large,
      region: "",
    });
  }, [editMode, details]);

  const usRegions =
    allRegionDetails && allRegionDetails.length > 0 && allRegionDetails.filter((item) => item.country == "US");

  const combinedData = [
    ...(endangeredSepecies?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
      image: item?.coverImage?.small,
    })),
    ...(organizations?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      image: item?.coverImage?.small,
      section: "organizations",
    })),
    ...(environmentalist?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "environmentalists",
      image: item?.coverImage?.small,
    })),
    ...(Array.isArray(zoo?.data)
      ? zoo?.data.map((item) => ({
          id: item?._id,
          name: item.name,
          coordinates: item.geoLocation,
          slug: item?.slug,
          section: "zoos-and-wildlife-reserves",
          image: item?.coverImage?.small,
        }))
      : []),
    ...(Array.isArray(threats?.data)
      ? threats?.data.map((item) => ({
          id: item?._id,
          name: item?.name,
          coordinates: item?.geoLocation,
          slug: item?.slug,
          section: "war-on-the-environment-threats",
          image: item?.coverImage?.small,
        }))
      : []),
  ];

  const regionMapData = [
    {
      id: details?._id,
      name: details?.name,
      coordinates: {
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
      },
      slug: details?.slug,
      section: "regions",
      image: details?.coverImage?.small,
    },
  ];
  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "11", target: "#pills-eleven", label: "Media and Posts", count: null, data: [1, 2] },
    {
      id: "1",
      target: "#pills-one",
      label: "Endangered Species",
      count: endangeredSepecies?.totalCount,
      data: endangeredSepecies?.data,
    },
    // { id: "2", target: "#pills-two", label: 'Environmental Landscape', count: null, data: [1, 2] },
    {
      id: "3",
      target: "#pills-three",
      label: "War on the Environment – Threats",
      count: threats?.totalCount,
      data: threats?.data,
    },
    { id: "4", target: "#pills-four", label: "Zoos & Wildlife Reserves", count: zoo?.totalCount, data: zoo?.data },
    {
      id: "5",
      target: "#pills-five",
      label: "Organizations & Donating",
      count: organizations?.totalCount + 1,
      data: organizations?.data?.length > 0 ? organizations?.data : [1, 2],
    },
    { id: "6", target: "#pills-six", label: "Science & Education", count: science?.totalCount, data: science?.data },
    {
      id: "7",
      target: "#pills-seven",
      label: "Scientists & Activists",
      count: environmentalist?.totalCount,
      data: environmentalist?.data,
    },
    // { id: "8", target: "#pills-eight", label: "Media", count: null, data: [1, 2] },
    { id: "9", target: "#pills-nine", label: "Live Feeds", count: livefeeds?.totalCount, data: livefeeds?.data },
    { id: "10", target: "#pills-ten", label: "Map", count: null, data: [1, 2] },
    { id: "12", target: "#pills-twelve", label: "References", count: null, data: references },
  ];
  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
    setLoadingDetail(true);
    const filesToValidate = [];
    if (editState?.coverImage && editState?.coverImage instanceof File) filesToValidate.push(editState.coverImage);

    if (!validateFiles(filesToValidate)) {
      setLoadingDetail(false);
      return;
    }

    const uploadPromises = [];
    const newEditState = { ...editState };

    const handleFileUpload = (file, key) => {
      const ext = file.name.split(".").pop();
      const fileName = `${uuidv4()}.${ext}`;
      newEditState[key] = `public/${fileName}`;
      return Storage.put(fileName, file, {
        completeCallback: () => {},
        progressCallback: () => {},
        errorCallback: (err) => {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        },
      });
    };

    if (editState.coverImage && editState.coverImage instanceof File) {
      uploadPromises.push(handleFileUpload(editState.coverImage, "coverImage"));
    }

    Promise.all(uploadPromises).then(() => {
      const payload = {
        coverImage: newEditState.coverImage,
        state: newEditState.region.label,
        description: newEditState.description,
      };

      props.updateRegionDetails(details._id, payload, (res) => {
        if (res.status === 200) {
          props.getRegionDetails(details._id, (res1) => {
            setLoadingDetail(false);
            if (res1.status === 200) {
              toast(<AlertSuccess message="Information Saved" />);
            } else {
              toast(<AlertError message="Something Went Wrong" />);
            }
          });
        } else {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        }
      });
    });
  };
  return (
    <PageLayout>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {editMode ? (
        <EditRegionDetail
          loadingDetail={loadingDetail}
          details={details}
          menuItem={menuItem}
          id={id}
          isEdit={isEdit}
          editMode={editMode}
          items={items}
          combinedData={combinedData}
          splashText={splashText}
          addTargetBlankToLinks={addTargetBlankToLinks}
          miscSettingCitation={miscSettingCitation}
          setMenuItem={setMenuItem}
          regionMapData={regionMapData}
          setSelectedId={setSelectedId}
          editState={editState}
          setEditState={setEditState}
          setEditMode={setEditMode}
          allRegionDetails={allRegionDetails}
          handleMenuItemClick={handleMenuItemClick}
        />
      ) : (
        <main id="main">
          <Details
            setEditMode={setEditMode}
            loading={loadingDetail}
            menuItem={menuItem}
            id={id}
            isEdit={isEdit}
            follow={follow}
            setFollow={setFollow}
          />
          {loadingDetail ? (
            <div class="tabs-block-container">
              <div class="tbc-head ">
                <Skeleton
                  borderRadius={"10px"}
                  height={"7.5vh"}
                  baseColor="var(--dark-green-color)"
                  highlightColor="#0cb40c"
                />
              </div>
              <div class="tbc-body overflow-hidden ">
                <div className="container d-sm-block d-lg-none">
                  <Skeleton
                    borderRadius={"10px"}
                    height={"80vh"}
                    width={"100%"}
                    style={{ marginTop: "40px", display: "flex" }}
                  />
                </div>
                <div class="tab-content tab-content-slider d-none d-lg-block" id="pills-tabContent">
                  <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tbc-body-inner">
                            <div class="education-container d-flex">
                              <div className="w-25">
                                <Skeleton borderRadius={"10px"} height={"80vh"} width={"100%"} />
                              </div>
                              <div className=" education-body custom-mt-5" id="mapTab" role="tablist">
                                {[1, 2, 3, 4].map((skeletonItem) => (
                                  <div class="widget d-flex flex-wrap" key={skeletonItem}>
                                    <div class="widget-icon z-0">
                                      <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
                                    </div>
                                    <div class="widget-content z-0">
                                      <div class="widget-text-holder">
                                        <div class="w-heading-block position-relative">
                                          <div class="w-heading-box d-flex align-items-center flex-wrap ">
                                            <div className="w-label-box fw-semibold text-capitalize">
                                              <Skeleton borderRadius={"10px"} height={45} width={150} />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-image-holder mt-35 z-0">
                                          <div className="d-flex flex-wrap">
                                            <div
                                              className=" position-relative"
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <Skeleton borderRadius={"10px"} height={"40vh"} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div id="tabBtnContainer" className={`education-right`} style={{ right: "0px" }}>
                                <Skeleton borderRadius={"10px"} height={"80vh"} width={"100%"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="tabs-block-container new-tabs-block-container">
              <div className="tbc-head">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="d-lg-none">
                        <select
                          className="form-select"
                          id="tabSelector"
                          onChange={(e) => handleMenuItemClick(e.target.value)}
                          value={menuItem}
                        >
                          {items.map(
                            (item) =>
                              item.data?.length > 0 && (
                                <option key={item.id} value={item.id}>
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </option>
                              ),
                          )}
                        </select>
                      </form>
                      <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                        {items.map(
                          (item) =>
                            item.data?.length > 0 && (
                              <li className="nav-item" key={item.id} role="presentation">
                                <div
                                  className={`nav-link ${menuItem === item.id && "active"}`}
                                  id={`pills-${item.id}-tab`}
                                  data-bs-toggle="pill"
                                  data-bs-target={`#pills-${item.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`pills-${item.id}`}
                                  aria-selected={menuItem === item.id}
                                  onClick={() => handleMenuItemClick(item.id)}
                                >
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </div>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tbc-body overflow-hidden">
                <div class="tab-content tab-content-slider" id="pills-tabContent">
                  {menuItem == "1" && (
                    <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                      <EndangeredSpecies loadingDetail={loadingDetail} menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "3" && (
                    <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                      <EnvironmentThreats menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "4" && (
                    <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                      <Zoo menuItem={menuItem} id={id} />
                    </div>
                  )}

                  {menuItem == "5" && (
                    <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                      <Organizations menuItem={menuItem} id={id} splashData={splashText} setMenuItem={setMenuItem} />
                    </div>
                  )}
                  {menuItem == "6" && (
                    <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                      <ScienceEducation menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "7" && (
                    <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                      <Environmentalist menuItem={menuItem} id={id} environmentalist={environmentalist} />
                    </div>
                  )}
                  {menuItem == "8" && (
                    <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                      <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="region" />
                    </div>
                  )}
                  {menuItem == "9" && (
                    <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                      <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="region" />
                    </div>
                  )}
                  {menuItem == "10" && (
                    <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                      {loadingDetail ? (
                        <div className="container">
                          {" "}
                          <Skeleton
                            borderRadius={"10px"}
                            height={"80vh"}
                            width={"100%"}
                            style={{ margin: "auto", display: "flex" }}
                          />
                        </div>
                      ) : (
                        <div className="container">
                          <div class="tbc-map-full detail-page-map region-detail-map">
                            <div class="map-hero-image map-wet-market">
                              {combinedData && (
                                <App
                                  data={combinedData}
                                  data1={regionMapData}
                                  mapType="detail-page"
                                  isMultiMarker={true}
                                  zoom={"zoom"}
                                  singleMap={true}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {menuItem == "11" && (
                    <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                      <Education
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        section="region"
                        data={details}
                      />
                    </div>
                  )}
                  {menuItem == "12" && (
                    <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                      <References
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        section="region"
                        isEdit={editMode}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div class="container custom-citation">
            <div className="custom-citation-text">Citations</div>
            <div
              className="custom-citation-text"
              dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
            ></div>
            {details?.updatedByVolunteer?.firstName && (
              <div className="last-edited">
                {" "}
                Last edited on {date.toLocaleString()} by{" "}
                <span className="text-capitalize">
                  {details?.updatedByVolunteer?.firstName} {details?.updatedByVolunteer?.lastName}{" "}
                </span>
              </div>
            )}
          </div>
        </main>
      )}
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getRegionDetails: (params, callback) => dispatch(getRegionDetails(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getBlog: (params, data, callback) => dispatch(getBlog(params, data, callback)),
  getRegionMedia: (params, callback) => dispatch(getRegionMedia(params, callback)),
  getSpeciesReg: (params, callback) => dispatch(getSpeciesReg(params, callback)),
  getWetMarkets: (params, data, callback) => dispatch(getWetMarkets(params, data, callback)),
  getNews: (params, callback) => dispatch(getNews(params, callback)),
  getOrganizations: (params, callback) => dispatch(getOrganizations(params, callback)),
  getScienceEducation: (params, callback) => dispatch(getScienceEducation(params, callback)),
  getEnvironmentalist: (params, callback) => dispatch(getEnvironmentalist(params, callback)),
  getRegionLiveFeeds: (params, callback) => dispatch(getRegionLiveFeeds(params, callback)),
  getZoo: (params, data, callback) => dispatch(getZoo(params, data, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  updateRegionDetails: (params, data, callback) => dispatch(updateRegionDetails(params, data, callback)),
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
});

const mapStateToProps = (state) => ({
  regionDetails: regionListings(state),
  sideBarApisListings: sideBarApisListings(state),
  environmentalistListings: environmentalistListings(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RegionDetail));
