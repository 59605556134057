import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  capitalizeFirstLetterWord,
  cleanAndFormatHtml,
  transformDropdownData,
} from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import shareSvg from "../../include/images/share.svg";
import Details from "../../components/speciesDetailsPages/details";
import Organizations from "../../components/speciesDetailsPages/organizations";
import People from "../../components/speciesDetailsPages/people";
import Region from "../../components/speciesDetailsPages/region";
import ScienceAndEducation from "../../components/speciesDetailsPages/scienceAndEducation";
import Zoo from "../../components/speciesDetailsPages/zoo";
import WetMarkets from "../../components/speciesDetailsPages/wetMarkets";
import UserUpload from "../../components/common/userUpload/userUpload";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import App from "../../common/maps/map";
import USMap from "../../common/maps/regionDetail/usMap";
import WorldMap from "../../common/maps/regionDetail/worldMap";
import ShowMoreModal from "../../components/common/showMoreModal/showMoreModal";
import ShareModal from "../../components/common/sharemodal/shareModal";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import defaultImage from "../../include/images/default_image_species.svg";
import editIcon from "../../include/images/edit.svg";
import { getProfile } from "../../utils/localStorageServices";
import { Dropdown } from "react-bootstrap";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";

const EditSpeciesDetail = ({
  loadingDetail,
  details,
  zoo,
  menuItem,
  setMenuItem,
  setOpenShareModal,
  capitalizeText,
  handleMenuItemClick,
  items,
  id,
  setSelectedId,
  splashText,
  combinedData,
  speciesMapData,
  toggleShowMoreModal,
  usState,
  regionsName,
  type,
  usRegions,
  allRegionDetails,
  setType,
  showMoreModal,
  openShareModal,
  addTargetBlankToLinks,
  miscSettingCitation,
  editMode,
  editState,
  setEditState,
  dangerLevelDropdown,
  isEditable,
  setEditMode,
  region,
  getDefaultImage,
}) => {
  const indexesToRemove = [8];
  const filteredItems = items.filter((item, index) => !indexesToRemove.includes(index));
  const [imgSrc, setImgSrc] = useState(null);
  const [detailImgSrc, setDetailImgSrc] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    dangerLevelDropdown.find((option) => option.label == capitalizeFirstLetter(details?.data?.dangerLevelName)),
  );
  const date = new Date(details?.data?.updatedAtVolunteer);
  const [selectMainReg, setSelectMainReg] = useState(null);
  const options = allRegionDetails?.map((region) => ({
    value: region._id,
    label: region.country === "US" ? `${region.state}, ${region.countryName}` : region.countryName,
  }));
  let initialSelectedOption = options?.find((option) => option.value === editState?.defaultRegion._id);
  const isMobile = useCheckMobileScreen();
  const profile = getProfile();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "dangerLevel") {
      setSelectedValue({ value: value.value, label: value.label });
    }
    if (name == "defaultRegion") {
      setSelectMainReg({ value: value, label: e.target.label });
    }
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleImageChange = (e, img) => {
    const file = e.target.files[0];
    if (file) {
      setEditState((prevState) => ({
        ...prevState,
        [img]: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        if (img == "coverImage") {
          setImgSrc(reader.result);
        } else if (img == "detailTabImage") {
          setDetailImgSrc(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const displayRegions = details?.data?.regions?.slice(0, 3);
  const remainingRegions = details?.data?.regions?.slice(3);
  return (
    <main id="main">
      <div class="content-container overflow-hidden pt-4">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="listing-details-container d-flex flex-wrap justify-content-between">
                {loadingDetail ? (
                  <div class="ldc-image">
                    <Skeleton borderRadius={"10px"} width={"100%"} height={"100%"} />
                  </div>
                ) : (
                  <div class="ldc-image species-detail-image">
                    <div class="species-details-img">
                      {details?.data?.coverImage?.original ? (
                        <img src={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original} alt="" />
                      ) : (
                        <img src={getDefaultImage(details?.data?.speciesCategoryName)} alt="" />
                      )}
                    </div>
                  </div>
                )}
                <div class="ldc-text">
                  <div class="ldc-text-head">
                    <span class="text-title text-red text-capitalize text-dangerLevel">
                      {loadingDetail ? <Skeleton borderRadius={"10px"} /> : details?.data?.dangerLevelName}
                    </span>
                    <div class="ldc-text-main species-name d-flex">
                      {loadingDetail ? (
                        <Skeleton borderRadius={"10px"} height={40} width={200} />
                      ) : (
                        <>
                          <div>
                            <h1>
                              <span className="text-capitalize d-flex">
                                {details?.data?.displayCommonName || details?.data?.scientificName
                                  ? capitalizeContentInBrackets(
                                      details?.data?.displayCommonName || details?.data?.scientificName,
                                    )
                                  : details?.data?.name}
                              </span>{" "}
                              <div
                                className="ml-20 d-flex"
                                style={isMobile ? { position: "absolute", right: "0px", top: "100%" } : {}}
                              >
                                {" "}
                                <Link onClick={() => setOpenShareModal(true)}>
                                  <img src={shareSvg} alt="" />
                                </Link>
                                {isEditable && profile?.profile?.isVolunteer && (
                                  <Link
                                    className="ml-10"
                                    onClick={() => {
                                      setEditMode(true);
                                    }}
                                  >
                                    <img src={editIcon} alt="" />
                                  </Link>
                                )}
                              </div>
                            </h1>
                          </div>
                          <div>
                            <img
                              src={process.env.REACT_APP_MEDIA + details?.data?.dangerLevelImage?.small}
                              class="ldc-text-main-icon"
                              alt=""
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <small className="text-capitalize pointer-event-none scientific-text">
                      {details?.data?.displayCommonName && (
                        <span>{details?.data?.scientificName ? details?.data?.scientificName : "-"}</span>
                      )}
                    </small>
                    <small className="text-capitalize pointer-event-none">
                      {(!loadingDetail || details?.data?.speciesCategoryName) && (
                        <>
                          {details?.data?.speciesCategoryName && (
                            <span>
                              ESA Categories -
                              {details?.data?.speciesCategoryName ? details?.data?.speciesCategoryName : "-"}
                            </span>
                          )}
                        </>
                      )}
                    </small>
                  </div>
                  <div class="ldc-text-body">
                    <ul class="ltb-details-list d-flex flex-wrap">
                      <li class="ltb-details-item">
                        <div
                          class={
                            zoo?.data?.length
                              ? `ltb-details-box cursor-pointer ${menuItem == "6" && "active"}`
                              : `ltb-details-box pointer-event-none`
                          }
                          onClick={() => setMenuItem("6")}
                        >
                          <h4 className="">National Parks/ Sanctuaries</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <>
                              {zoo && zoo?.data?.length > 0 ? (
                                <>
                                  {zoo.data.map(
                                    (item, idx) =>
                                      idx <= 1 && (
                                        <p className={`caps-text ${idx === 1 ? "mt-0" : ""}`} key={idx}>
                                          {item?.name}
                                        </p>
                                      ),
                                  )}
                                  {zoo.data.length > 4 && <span>...</span>}
                                </>
                              ) : (
                                <h4>-</h4>
                              )}
                            </>
                          )}
                        </div>
                      </li>
                      <li class="ltb-details-item">
                        <div className="ltb-details-box pointer-event-none">
                          <h4>Common Names</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <p className="caps-text" data-tooltip={capitalizeText(details?.data?.name)}>
                              {details?.data?.name?.split(",")?.slice(0, 8).join(", ")}
                              {details?.data?.name?.split(",")?.length > 8 && " ..."}
                            </p>
                          )}
                        </div>
                      </li>
                      <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <h4>Regions</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <>
                              {details?.data?.regions.length > 0 ? (
                                <>
                                  <p className="caps-text">
                                    {displayRegions.map((region, index) => (
                                      <span key={region._id} className="d-block">
                                        {region.state ? `${region.state}, ${region.countryName}` : region.countryName}
                                        {index < displayRegions.length - 1 ? ", " : ""}
                                      </span>
                                    ))}
                                  </p>

                                  {remainingRegions.length > 0 && (
                                    <div className="td-link-text fw-semibold">
                                      <Dropdown bsPrefix="header-name-box fw-medium dropdown cursor-pointer">
                                        <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle">
                                          <p
                                            className="td-a-icon"
                                            style={{ color: "#47AD1D", cursor: "pointer" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded={false}
                                          >
                                            <u>View More</u>
                                          </p>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          bsPrefix="dropdown-menu dropdown-menu-end"
                                          style={{ background: "#fff", maxHeight: "200px", overflowY: "auto" }}
                                        >
                                          <Dropdown.Item key={region._id}>
                                            {remainingRegions.map((region) => (
                                              <tr>
                                                {region.state
                                                  ? `${region.state}, ${region.countryName}`
                                                  : region.countryName}
                                              </tr>
                                            ))}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <p className="caps-text">-</p>
                              )}
                            </>
                          )}
                        </div>
                      </li>
                      <li className="ltb-details-item">
                        <div className="ltb-details-box pointer-event-none">
                          <h4>Population</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <p>
                              {details?.data?.population
                                ? Number(details.data.population).toLocaleString("en-US")
                                : "-"}
                            </p>
                          )}
                        </div>
                      </li>
                      <li class="ltb-details-item">
                        <div class="ltb-details-box pointer-event-none">
                          <h4>Habitat</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : details?.data?.habitat ? (
                            <p className="caps-text" data-tooltip={capitalizeText(details?.data?.habitat)}>
                              {details?.data?.habitat.split(",").slice(0, 8).join(", ")}
                              {details?.data?.habitat.split(",").length > 8 && " ..."}
                            </p>
                          ) : (
                            <h4>-</h4>
                          )}
                        </div>
                      </li>

                      <li class="ltb-details-item">
                        <div class="ltb-details-box pointer-event-none">
                          <h4>Weight(in lbs) and Life Span</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <p>
                              {details?.data?.weight || details?.data?.lifeSpan ? (
                                <>
                                  {details?.data?.weight
                                    ? details?.data?.weight.length > 20
                                      ? details?.data?.weight.slice(0, 20) + "..."
                                      : details?.data?.weight
                                    : ""}

                                  {details?.data?.weight && details?.data?.lifeSpan && " and "}

                                  {details?.data?.lifeSpan ? details?.data?.lifeSpan : ""}
                                </>
                              ) : (
                                "-"
                              )}
                            </p>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Details loading={loadingDetail} menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
            </div>
          </div>
        </div>
      </div>
      {loadingDetail ? (
        <div class="tabs-block-container">
          <div class="tbc-head ">
            <Skeleton
              borderRadius={"10px"}
              height={"7.5vh"}
              baseColor="var(--dark-green-color)"
              highlightColor="#0cb40c"
            />
          </div>
          <div class="container">
            {" "}
            <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
          </div>
        </div>
      ) : (
        <div class="tabs-block-container new-tabs-block-container">
          <div class="tbc-head ">
            <div class="container">
              <div class="row">
                <div className="col-md-12">
                  <form className="d-lg-none">
                    <select
                      className="form-select basic-single "
                      id="tabSelector"
                      onChange={(e) => handleMenuItemClick(e.target.value)}
                      value={menuItem}
                    >
                      {filteredItems.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </option>
                      ))}
                    </select>
                  </form>
                  <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                    {filteredItems.map((item) => (
                      <li className="nav-item" key={item.id} role="presentation">
                        <div
                          className={`nav-link ${menuItem === item.id && "active"}`}
                          id={`pills-${item.id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${item.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${item.id}`}
                          aria-selected={menuItem === item.id}
                          onClick={() => handleMenuItemClick(item.id)}
                        >
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tbc-body overflow-hidden">
            <div class="tab-content tab-content-slider" id="pills-tabContent">
              {menuItem == "1" && (
                <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                  <Details
                    loading={loadingDetail}
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    editMode={editMode}
                    handleChange={handleChange}
                    editState={editState}
                    handleImageChange={handleImageChange}
                    detailImgSrc={detailImgSrc}
                    setEditState={setEditState}
                  />
                </div>
              )}
              {menuItem == "2" && (
                <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                  <Organizations
                    menuItem={menuItem}
                    id={id}
                    name={details?.data?.name}
                    slug={details?.data?.slug}
                    splashData={splashText}
                    editMode={editMode}
                  />
                </div>
              )}
              {menuItem == "3" && (
                <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                  <People menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "4" && (
                <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                  <Region
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    loading={loadingDetail}
                    editMode={editMode}
                    region={details.data.regions}
                  />
                </div>
              )}
              {menuItem == "5" && (
                <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                  <ScienceAndEducation menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "6" && (
                <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                  <Zoo menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "7" && (
                <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                  <WetMarkets
                    menuItem={menuItem}
                    id={id}
                    editMode={editMode}
                    editState={editState}
                    setEditState={setEditState}
                  />
                </div>
              )}
              {menuItem == "8" && (
                <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                  <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "9" && (
                <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                  <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "10" && (
                <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                  <div className="container">
                    <div class="tbc-map-full detail-page-map region-detail-map">
                      <div class="map-hero-image map-wet-market">
                        {combinedData && (
                          <App
                            data={combinedData}
                            data1={speciesMapData}
                            mapType="detail-page"
                            isMultiMarker={true}
                            zoom={"zoom"}
                            singleMap={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {menuItem == "11" && (
                <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                  <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "12" && (
                <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                  <References
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    section="species"
                    isEdit={editMode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {menuItem == "6" && (
        <div className="container">
          <div className="d-flex space-between species-region-text" style={{ marginTop: "20px" }}>
            <p className="map-switch cursor-pointer" onClick={() => toggleShowMoreModal()}>
              View All Regions Name
            </p>

            {usState?.length > 0 && (
              <p
                className="map-switch cursor-pointer"
                onClick={() => {
                  if (type == "us") {
                    setType("world");
                  } else if (type == "world") {
                    setType("us");
                  }
                }}
              >
                Switch to {type === "us" ? "All " : "US"} region map
              </p>
            )}
          </div>
          <div class="tbc-map-full">
            <div class="map-hero-image">
              {(regionsName && regionsName?.length === 1 && regionsName[0]?.countryName === "United States") ||
              type == "us" ? (
                <USMap
                  name={
                    (regionsName && regionsName.length === 1) || type == "us"
                      ? usState?.map((item) => item?.state)
                      : usState?.map((item) => item?.countryName)
                  }
                  data={usRegions}
                  setSelectedId={setSelectedId}
                  page="species"
                  isNotClickable={true}
                />
              ) : (
                <WorldMap
                  name={regionsName && regionsName?.length && regionsName?.map((item) => item?.countryName)}
                  data={allRegionDetails}
                  setSelectedId={setSelectedId}
                  page="species"
                  isNotClickable={true}
                  setType={setType}
                  showAllMap={true}
                />
              )}
            </div>
            <ShowMoreModal
              show={showMoreModal}
              onHide={toggleShowMoreModal}
              listData={regionsName}
              heading={`${details?.data?.name} found in these regions`}
              isNonEditable={true}
              type="regions"
            />
          </div>
        </div>
      )}
      <ShareModal
        show={openShareModal}
        onHide={() => setOpenShareModal(!openShareModal)}
        name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
        id={details?.data?._id}
        category="species"
      />
      <div className="container custom-citation">
        <div className="custom-citation-text">Citations</div>
        <div
          className="custom-citation-text"
          dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
        ></div>
        {details?.data?.updatedByVolunteer?.firstName && (
          <div className="last-edited">
            {" "}
            Last edited on {date.toLocaleString()} by{" "}
            <span className="text-capitalize">
              {details?.data?.updatedByVolunteer?.firstName} {details?.data?.updatedByVolunteer?.lastName}{" "}
            </span>
          </div>
        )}
      </div>
    </main>
  );
};

export default EditSpeciesDetail;
