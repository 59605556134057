import React from 'react';
import { Accordion } from 'react-bootstrap';
import { capitalizeContentInBrackets } from '../../../utils/helperFunctions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

//Home Page Sidebar dropdown for Environmentalist

const EnvironmentalistDropdown = (props) => {
    const renderEnvironmentalistList = (environmentalistList) => {
        return (
            <ul className="ei-category-list">
                {environmentalistList.map((item, i) => (
                    <li className="ei-category-item" key={i}>
                        <Link
                            to={{
                                pathname: `/environmentalists/${item?.slug}`,
                                state: { id: item?._id },
                            }}
                            className="ei-category-box text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllEnvironmentalist ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessEnvironmentalist}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.environmentalistList.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickEnvironmentalist}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isEnvirMenu && (
                        <>
                            {props.environmentalistList &&
                                props.environmentalistList.length > 0 && (
                                    <Accordion.Item
                                        className="tab-pane fade show active accordion-item"
                                        id="two-tab-pane"
                                        eventKey="4"
                                    >
                                        <Accordion.Header
                                            className="accordion-header d-lg-block d-none"
                                            id="headingTwo"
                                        >
                                            Environmentalists
                                        </Accordion.Header>

                                        <Accordion.Body className="accordion-body">
                                            {renderEnvironmentalistList(
                                                props.handleViewAllEnvironmentalist
                                                    ? props.dividedEnvironmentalistData.flat()
                                                    : props.handleViewLessEnvironmentalist
                                                        ? props.poppedEnvironmentalistData
                                                        : props.environmentalistList.slice(0, 15)
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.environmentalistList &&
                        props.environmentalistList.length > 0 && (
                            <Accordion.Item
                                className="tab-pane fade accordion-item"
                                id="two-tab-pane"
                                eventKey="4"
                            >
                                <Accordion.Header
                                    className="accordion-header d-lg-block d-none"
                                    id="headingTwo"
                                >
                                    Environmentalists
                                </Accordion.Header>

                                <Accordion.Body className="accordion-body">
                                    {renderEnvironmentalistList(
                                        props.handleViewAllEnvironmentalist
                                            ? props.dividedEnvironmentalistData.flat()
                                            : props.handleViewLessEnvironmentalist
                                                ? props.poppedEnvironmentalistData
                                                : props.environmentalistList.slice(0, 15)
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                </>
            )}
        </>
    );
};

export default EnvironmentalistDropdown;
