import React, { useState } from "react";
import iconImage from "../../../include/images/Icon-image-01.jpg";
import cornerUpLeft from "../../../include/images/corner-up-left.svg";
import heart from "../../../include/images/heart.svg";
import wIcon from "../../../include/images/w-icon-1.svg";
import wIcon2 from "../../../include/images/w-icon-2.svg";
import wIcon3 from "../../../include/images/w-icon-3.svg";
import liked from "../../../include/images/fill-heart-icon.svg";
import wIcon4 from "../../../include/images/w-icon-4.svg";
import wIcon5 from "../../../include/images/w-icon-5.svg";
import close from "../../../include/images/x-close-24x24.svg";
import closeBlack from "../../../include/images/ch-close-icon.svg";
import feed from "../../../include/images/feed-Icon.svg";
import button from "../../../include/images/button-img-1.svg";
import button2 from "../../../include/images/button-img-2.svg";
import button3 from "../../../include/images/button-img-3.svg";

import { useRef } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ImageHolder from "../../../common/customStyles/imageHolder";
import { getProfile } from "../../../utils/localStorageServices";
import { getRelativeTime } from "../../../utils/helperFunctions";
import ReportPost from "./reportPost";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import trash from "../../../include/images/trash-24x24.svg";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

const PostComment = (props) => {
  const ref = useRef();
  const profile = getProfile();
  const [showReport, setShowReport] = useState(false);
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const mediaType = props?.selectedItem?.files[0]?.original
    ? props?.selectedItem?.files[0]?.original?.split(".")[1]
    : ".jpg";
  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const selectedItem = props?.myPostData?.filter((item) => item._id === props?.selectedItem?._id)[0];
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };
  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };
  return (
    <>
      <Modal
        className="modal fade signup-modal font-family-poppins custom-post-modal"
        id="commentModal"
        dialogClassName="modal-dialog modal-dialog-centered xs-modal post-comment-modal"
        contentClassName="modal-content"
        show={props?.show}
        onHide={props?.onHide}
      >
        <div class="signup-modal-parent d-flex flex-wrap">
          <div class="signup-modal-box comment-modal-adjust">
            <Modal.Header className="signup-modal-header d-lg-none pt-3">
              <div class="smh-insider position-relative">
                <div class="modal_close-box d-lg-none">
                  <div class="signup-modal-close  comment-close ms-auto" data-bs-dismiss="modal">
                    <img src={closeBlack} alt="x close icon" onClick={() => props?.onHide()} />
                  </div>
                </div>
              </div>
            </Modal.Header>
            <div class="widget d-flex flex-wrap">
              <div class="widget-icon">
                <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                  <ImageHolder
                    image={selectedItem?.createdBy ? selectedItem.createdBy?.profilePicture?.medium : profilePicture}
                    name={selectedItem?.name?.slice(0, 1)}
                  />
                </div>
              </div>
              <div class="widget-content">
                <div class="widget-text-holder">
                  <div class="w-heading-block position-relative">
                    <div class="w-heading-box d-flex align-items-center flex-wrap">
                      <div class="w-label-box fw-semibold text-capitalize">
                        {selectedItem?.createdBy
                          ? `${selectedItem.createdBy.firstName} ${selectedItem.createdBy.lastName}`
                          : ""}
                      </div>
                      <span class="w-dot-box"></span>
                      <div class="w-time-box d-flex align-items-center">
                        <span class="w-time-count">{getRelativeTime(selectedItem?.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-text-box">
                    <p>{selectedItem?.description}</p>
                  </div>
                  {selectedItem?.files?.length > 0 && (
                    <React.Fragment>
                      {selectedItem.files.length > 1 ? (
                        <AwesomeSlider className="w-image-holder" bullets={false}>
                          {selectedItem.files.map((file, index) => (
                            <div key={index}>
                              {["mp4", "mpeg", "MP4", "webm", "video"].includes(file.mediaType) ? (
                                <video controls>
                                  <source src={process.env.REACT_APP_MEDIA + file.original} />
                                </video>
                              ) : (
                                <img src={process.env.REACT_APP_MEDIA + file.original} alt="" />
                              )}
                            </div>
                          ))}
                        </AwesomeSlider>
                      ) : (
                        <div className="w-image-holder">
                          {selectedItem.files.map((file, index) => (
                            <div key={index}>
                              {["mp4", "mpeg", "MP4", "webm", "video"].includes(file.mediaType) ? (
                                <video controls style={{ width: "100%", height: "100%", objectFit: "contain" }}>
                                  <source src={process.env.REACT_APP_MEDIA + file.original} />
                                </video>
                              ) : (
                                <img
                                  src={process.env.REACT_APP_MEDIA + file.original}
                                  alt=""
                                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <div class="w-iconlist d-flex flex-wrap align-items-center justify-content-between">
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div
                          class="w-iconl-box"
                          onClick={() => {
                            toggleShowReport();
                          }}
                        >
                          <img src={wIcon} alt="w icon 1" />
                        </div>
                      </div>
                    </div>
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div class="w-iconl-box">
                          <img src={wIcon2} alt="w icon 2" onClick={() => props?.toggleAllComments()} />
                        </div>
                        <div class="w-textl-box">
                          {selectedItem?.commentCount == 0 ? "" : selectedItem?.commentCount}
                        </div>
                      </div>
                    </div>
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div class="w-iconl-box">
                          <img src={wIcon3} alt="w icon 3" />
                          <img
                            src={selectedItem?.isLiked ? liked : wIcon3}
                            alt="w icon 3"
                            onClick={() =>
                              props?.likeUnlikePost(selectedItem?.isLiked ? "unlike" : "like", selectedItem?._id)
                            }
                          />
                        </div>
                        <div class="w-textl-box">{selectedItem?.likeCount == 0 ? "" : selectedItem?.likeCount}</div>
                      </div>
                    </div>
                    <div class="w-iconlist-item">
                      <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                        <div class="w-iconl-box">
                          <img src={wIcon4} alt="w icon 4" />
                        </div>
                        <div class="w-textl-box">{selectedItem?.viewCount == 0 ? "" : selectedItem?.viewCount}</div>
                      </div>
                    </div>
                    <div class="w-iconlist-item">
                      <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
                        <Dropdown.Toggle as="span" id="dropdown-basic" className="w-iconl-box dropdown-toggle">
                          <img src={wIcon5} alt="w icon 5" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu share-post">
                          <FacebookShareButton
                            url={getShareUrl(selectedItem?.slug)}
                            description={selectedItem?.description}
                            className="custom-share-post"
                          >
                            <Dropdown.Item className="dropdown-item" eventKey={"1"}>
                              <Link to="#" class="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                                <div class="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.7974 16.1444V10.7915H12.5942L12.8637 8.70543H10.7974V7.37352C10.7974 6.76958 10.9652 6.35797 11.8313 6.35797H12.936V4.49269C12.4013 4.43612 11.8639 4.40872 11.3263 4.41061C9.73351 4.41061 8.64314 5.38274 8.64314 7.16813V8.70543H6.8418V10.7915H8.64314V16.1444H10.7974Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>

                                <div class="dropdown-item-text custom-share-dropdown-text">Facebook</div>
                              </Link>
                            </Dropdown.Item>
                          </FacebookShareButton>
                          <TwitterShareButton url={getShareUrl(selectedItem?.slug)} className="custom-share-post">
                            <Dropdown.Item className="dropdown-item" eventKey={"2"}>
                              <Link
                                to="#"
                                className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                              >
                                <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.0317 5L8.05085 11.6187L3 17H4.13674L8.55875 12.2886L12.1316 17H16L10.6984 10.009L15.3997 5H14.263L10.1905 9.33911L6.90008 5H3.0317ZM4.70336 5.8258H6.48051L14.3281 16.1741H12.5509L4.70336 5.8258Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>

                                <div className="dropdown-item-text custom-share-dropdown-text">Twitter</div>
                              </Link>
                            </Dropdown.Item>
                          </TwitterShareButton>
                          <Dropdown.Item className="dropdown-item" eventKey={"3"}>
                            <Link
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                props.toggleDmPosts();
                              }}
                              class="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                            >
                              <div class="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 7.83325C15.1046 7.83325 16 6.93782 16 5.83325C16 4.72868 15.1046 3.83325 14 3.83325C12.8954 3.83325 12 4.72868 12 5.83325C12 6.93782 12.8954 7.83325 14 7.83325Z"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 12.5C7.10457 12.5 8 11.6046 8 10.5C8 9.39543 7.10457 8.5 6 8.5C4.89543 8.5 4 9.39543 4 10.5C4 11.6046 4.89543 12.5 6 12.5Z"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M14 17.1666C15.1046 17.1666 16 16.2712 16 15.1666C16 14.0621 15.1046 13.1666 14 13.1666C12.8954 13.1666 12 14.0621 12 15.1666C12 16.2712 12.8954 17.1666 14 17.1666Z"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.72656 11.5066L12.2799 14.1599"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12.2732 6.83997L7.72656 9.4933"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div class="dropdown-item-text">Direct</div>
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-widet-row">
              <div class="widget d-flex flex-wrap">
                <div class="widget-icon">
                  <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                    <ImageHolder
                      image={selectedItem?.createdBy ? selectedItem.createdBy?.profilePicture?.medium : profilePicture}
                      name={selectedItem?.name?.slice(0, 1)}
                    />
                  </div>
                </div>
                <div class="widget-content">
                  <div class="w-content-upper">
                    {props?.newCommentData?.media && (
                      <div class="w-image-holder">
                        {["video/mp4", "video/mpeg", "video/MP4", "video/webm"]?.find(
                          (i) => i == props?.newCommentData?.media?.type,
                        ) ? (
                          <div
                            class="m-10 position-relative"
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                          >
                            <video
                              controls
                              style={{
                                width: "100%",
                                height: "260px",
                                objectFit: "cover",
                              }}
                            >
                              <source src={`${URL.createObjectURL(props.newCommentData?.media)}`} />
                            </video>
                            <button
                              type="button"
                              class="trash-button-box"
                              onClick={(e) => {
                                props.setNewCommentData((prev) => ({
                                  ...prev,
                                  media: null,
                                }));
                              }}
                            >
                              <img src={trash} alt="w volumne" />
                            </button>
                          </div>
                        ) : (
                          <div
                            class="m-10 position-relative"
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "260px",
                                objectFit: "cover",
                              }}
                              src={`${URL.createObjectURL(props.newCommentData?.media)}`}
                              alt=""
                            />
                            <button
                              type="button"
                              class="trash-button-box"
                              onClick={(e) => {
                                props.setNewCommentData((prev) => ({
                                  ...prev,
                                  media: null,
                                }));
                              }}
                            >
                              <img src={trash} alt="w volumne" />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div class="w-textarea-block">
                      <textarea
                        class="w-textarea"
                        placeholder="Type Your Comment"
                        onChange={(e) => {
                          const data = { ...props?.newCommentData };
                          data.text = e.target.value;
                          props?.setNewCommentData(data);
                        }}
                        value={props?.newCommentData?.text}
                      ></textarea>
                    </div>
                  </div>
                  <div class="w-content-lower d-flex flex-wrap align-items-center justify-content-between">
                    <div class="w-button-holder d-flex flex-wrap align-items-center">
                      <div class="w-button position-relative">
                        <input
                          type="file"
                          id="inputGroupImg1"
                          accept=".jpg,.jpeg,.png,.gif,.webp"
                          onChange={(e) => {
                            const value = { ...props?.newCommentData };
                            value.isMediaNew = true;
                            value.media = e.target.files[0];
                            props?.setNewCommentData(value);
                          }}
                        />
                        <label type="button" class="w-button-box d-flex align-items-center justify-content-center">
                          <img src={button} alt="image1" />
                        </label>
                      </div>
                      <div class="w-button position-relative">
                        <input
                          type="file"
                          id="inputGroupImg2"
                          accept="video/MP4,
                          video/mp4,
                          video/webm,
                          video/mpeg"
                          onChange={(e) => {
                            const value = { ...props?.newCommentData };
                            value.isMediaNew = true;
                            value.media = e.target.files[0];
                            props?.setNewCommentData(value);
                          }}
                        />
                        <label type="button" class="w-button-box d-flex align-items-center justify-content-center">
                          <img src={button2} alt="video" />
                        </label>
                      </div>
                    </div>
                    <div class="w-btn-row">
                      <Link
                        class={`${
                          props?.loading || !props?.newCommentData?.text
                            ? "btn btn-default btn-sm disabled"
                            : "btn btn-default btn-sm"
                        }`}
                        onClick={() => props?.handlePostComment()}
                      >
                        Comment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="signup-modal-close-box d-none d-lg-block">
            <div class="signup-modal-close comment-close ms-auto" data-bs-dismiss="modal">
              <img src={close} alt="x close icon" onClick={() => props?.onHide()} />
            </div>
          </div>
        </div>
      </Modal>
      <ReportPost show={showReport} onHide={toggleShowReport} selectedItem={props?.selectedItem} />
    </>
  );
};

export default PostComment;
