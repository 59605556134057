import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getReportDetails, organizationListing, getOrganizationDetails } from "../../store/organization";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import download from "../../include/images/download.svg";
import { getwetmarketReports, wetMarketData } from "../../store/wetMarket";
import Pagination from "../common/Pagination/pagination";
import { capitalizeContentInBrackets } from "../../utils/helperFunctions";
import noRecord from "../../include/images/nrb-img.svg";


const Reports = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const details =
    props.page == "wetMarket"
      ? props.wetMarketData && props.wetMarketData.reports
      : props.organizationListing && props.organizationListing.reportDetail;
  const [initialRender, setInitialRender] = useState(true); // State to track initial render

  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      if (props.page == "wetMarket") {
        props.getwetmarketReports(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      } else {
        props.getReportDetails(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {loading ? (
                <>
                  {Array.from({ length: 4 }, (item, index) => (
                    <div class="report-box">
                      <div className="text-capitalize">
                        <Skeleton borderRadius={"10px"} width="844px" height={"40px"} />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <ul class="report-list">
                    {details && details.data && details.data.length ? (
                      details?.data.map((item) => {
                        return (
                          <li class="report-item">
                            <div class="report-box">
                              <a href={`${process.env.REACT_APP_MEDIA}${item?.file?.original}`} target="_blank">
                                <h4 className="text-capitalize text-transfrom ">
                                  {`${capitalizeContentInBrackets(item?.name)}`}
                                  <small className="text-capitalize text-transfrom ">{item?.reportedBy}</small>
                                </h4>
                              </a>
                              <a
                                class="report-box-download"
                                href={`${process.env.REACT_APP_MEDIA}${item?.file?.original}`}
                                download
                                target="_blank"
                              >
                                <img src={download} alt="" />
                              </a>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="no-records-container d-flex align-items-center justify-content-center" style={{ height: "auto" }}>
                        <div className="no-records-box text-center">
                          <div className="nrb-image">
                            <img src={noRecord} alt="No Records" />
                          </div>
                          <h6>No Records Found</h6>
                        </div>
                      </div>
                    )}
                  </ul>
                </>
              )}
              {details && details?.totalCount > 52 && (
                <Pagination
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  pageLinks={pageLinks}
                  totalPages={pageLinks.length}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) => dispatch(getOrganizationDetails(params, callback)),
  getReportDetails: (params, callback) => dispatch(getReportDetails(params, callback)),
  getwetmarketReports: (params, callback) => dispatch(getwetmarketReports(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
  wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Reports));
