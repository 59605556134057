import React from "react";
import {
    GoogleMap,
    InfoWindowF,
    Marker,
} from "@react-google-maps/api";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import orgIcon from "../../include/images/Marker/Organisations.svg"
import wetMarketIcon from "../../include/images/Marker/Wetmarket.svg";
import speciesIcon from "../../include/images/Marker/Species.svg";
import zooIcon from "../../include/images/Marker/Zoos.svg";
import envIcon from "../../include/images/Marker/Environmentalist.svg";
const DetailMapMarker = (props) => {
    const [zoom, setZoom] = useState(2.2);
    const [center, setCenter] = useState({
        lat: 0.745,
        lng: 0.745,
    });
    const [showInfo, setShowInfo] = useState(false);
    const [hoveredCords, setHoveredCords] = useState({ lat: "", lng: "" });
    let center1;
    useEffect(() => {
        if (props?.singleMap) {
            center1 = {
                lat: props?.data[0]?.coordinates.latitude,
                long: props?.data[0]?.coordinates.longitude
            }
        }
    }, [])
    const mapContainerStyle = {
        marginTop: "21px",
        height: "773px",
        width: "100%",
        margin: "auto"
    };
    const mapData =
        (props.data &&
            props.data?.map((item) => ({
                name: item.name,
                coordinates: item.coordinates,
                section: item.section,
                slug: item.slug ? item.slug : item.id,
                image: item.image
            }))) ||
        [];
    const mapData1 = (props.data1 &&
        props.data1?.map((item) => ({
            name: item.name,
            coordinates: item.coordinates,
            section: item.section,
            slug: item.slug ? item.slug : item.id,
            image: item.image
        }))) ||
        [];

    const history = useHistory();

    const handleMarkerClick = (item) => {
        const url = `/${item.section}/${item.slug}`
        history.push({
            pathname: url,
            state: { id: item.id },
        });
    };
    const chooseMarker = (section) => {
        if (section == "organizations") {
            return orgIcon;
        } else if (section == "environmentalists") {
            return envIcon;
        } else if (section == "zoos-and-wildlife-reserves") {
            return zooIcon;
        } else if (section == "war-on-the-environment-threats") {
            return wetMarketIcon;
        } else if (section == "endangered-species") {
            return speciesIcon;
        }
    }
    console.log("MapDat", mapData, mapData1)
    return (
        <GoogleMap
            id="marker-example"
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={props?.singleMap ? center1 : center}

        >
            {props.isMultiMarker &&
                mapData &&
                mapData.length > 0 &&
                mapData.map((item, i) => (
                    <Marker
                        key={i}
                        icon={{
                            url: chooseMarker(item.section),
                        }}
                        onMouseOver={() => {
                            setHoveredCords({
                                lat: +item?.coordinates?.latitude,
                                lng: +item?.coordinates?.longitude,
                            });
                            setShowInfo(true);
                        }}
                        onMouseOut={() => {
                            setHoveredCords({ lat: "", lng: "" });
                            setShowInfo(false);
                        }}
                        onClick={() => handleMarkerClick(item)}
                        position={{
                            lat: +item?.coordinates?.latitude,
                            lng: +item?.coordinates?.longitude,
                        }}
                    >
                        {showInfo &&
                            hoveredCords.lat == item?.coordinates?.latitude && (
                                <InfoWindowF className="infoWindow-container">
                                    <div className="infoWindow-container">
                                        <div className="infoWindow">
                                            <p>{item.name}</p>
                                            {item.image && <img src={process.env.REACT_APP_MEDIA + item.image} alt="Marker point" />}
                                        </div>
                                    </div>
                                </InfoWindowF>
                            )}
                    </Marker>
                ))}
            {mapData1 &&
                mapData1.length > 0 &&
                mapData1.map((item, i) => (
                    <Marker
                        key={i}
                        // icon={{
                        //     url: marker1,
                        // }}
                        onMouseOver={() => {
                            setHoveredCords({
                                lat: +item?.coordinates?.latitude,
                                lng: +item?.coordinates?.longitude,
                            });
                            setShowInfo(true);
                        }}
                        onMouseOut={() => {
                            setHoveredCords({ lat: "", lng: "" });
                            setShowInfo(false);
                        }}
                        onClick={() => handleMarkerClick(item)}
                        position={{
                            lat: +item?.coordinates?.latitude,
                            lng: +item?.coordinates?.longitude,
                        }}
                    >
                        {showInfo &&
                            hoveredCords.lat == item?.coordinates?.latitude && (
                                <InfoWindowF className="infoWindow-container">
                                    <div className="infoWindow-container">
                                        <div className="infoWindow">
                                            <p>{item.name}</p>
                                            {item.image && <img src={process.env.REACT_APP_MEDIA + item.image} alt="Marker point" />}
                                        </div>
                                    </div>
                                </InfoWindowF>
                            )}
                    </Marker>
                ))}
        </GoogleMap>
    );
};

export default DetailMapMarker;
