import React from "react";

const ImageHolder = (props) => {
  function RGBcolor() {
    var R = Math.floor(Math.random() * 256);
    var G = Math.floor(Math.random() * 256);
    var B = Math.floor(Math.random() * 256);
    var randomcolor = "rgb(" + R + "," + G + "," + B + ")";
    return randomcolor;
  }
  return props?.image ? (
    <img
      className={props?.className ? props?.className : ""}
      src={props.page == "post" ? props.image : process.env.REACT_APP_MEDIA + props?.image}
    />
  ) : (
    <div
      style={{ backgroundColor: RGBcolor() }}
      className="customize-profile-img caps-text"
    >
      {props?.name?.slice(0, 1)}
    </div>
  );
};

export default ImageHolder;
