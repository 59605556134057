import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import imgIcon from "../../../include/images/button-img-1.svg";
import trash from "../../../include/images/trash-24x24.svg";
import videosIcon from "../../../include/images/button-img-2.svg";
import smileIcon from "../../../include/images/button-img-3.svg";
import {
  addMyPost,
  getMyFeedData,
  getMyPostData,
  updateMyPost,
} from "../../../store/users";
import AddPostSelections from "./addPostSelections";
import { Dropdown } from "react-bootstrap";
import { getProfile } from "../../../utils/localStorageServices";
import ProfileLayout from "../../../layout/profileLayout/profileLayout";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage, Auth } from "aws-amplify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { sideBarApisListings } from "../../../store/sidebarApis";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const AddPost = (props) => {
  var ref = useRef(null);
  const location = useLocation();
  const [type, setType] = useState({
    postType: location?.postType,
    item: location?.item,
  })
  const history = useHistory();
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] =
    useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [showWetMarket, setShowWetMarket] = useState(false);
  const [showScienceAndEducation, setShowScienceAndEducation] = useState(false);
  const [showOrganizationAndZoo, setShowOrganizationAndZoo] = useState(false);
  const [tempSelectScienceName, setTempSelectScienceName] = useState([]);
  const [tempSelectRegionName, setTempSelectRegionName] = useState([]);
  const [tempSelectOrgName, setTempSelectOrgName] = useState([]);
  const [tempSelectZooName, setTempSelectZooName] = useState([]);
  const [tempSelectEnvName, setTempSelectEnvName] = useState([]);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState(
    []
  );
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);
  const [tempSelectScience, setTempSelectScience] = useState([]);
  const [tempSelectRegion, setTempSelectRegion] = useState([]);
  const [tempSelectOrg, setTempSelectOrg] = useState([]);
  const [tempSelectZoo, setTempSelectZoo] = useState([]);
  const [tempSelectWetMarket, setTempSelectWetMarket] = useState([]);
  const [tempSelectWetMarketName, setTempSelectWetMarketName] = useState([]);
  const [data, setData] = useState({
    isNewImage: false,
    img: [],
    caption: "",
    type: "public",
  });
  const [loading, setLoading] = useState(false);
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const toggleRegion = () => {
    setShowRegion(!showRegion);
  };
  const toggleWetMarket = () => {
    setShowWetMarket(!showWetMarket);
  };

  const toggleScience = () => {
    setShowScienceAndEducation(!showScienceAndEducation);
  };

  const toggleOrgAndZoo = () => {
    setShowOrganizationAndZoo(!showOrganizationAndZoo);
  };

  const profile = getProfile();
  const profilePic = profile?.profile?.profilePicture?.original;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (type?.postType === "edit" && props?.sideBarApisListings) {
      
      const { item } = type;
      const { sideBarApisListings: options } = props;
  
      if (!item || !options) return;
  
      const value = {
        ...data,
        img: item?.files || [],
        caption: item?.description.replace(/<br>/g, '\n').replace(/&nbsp;/g, ' '),
        type: item?.type,
        id: item?._id
      };
  
      const filterById = (ids, list) => {
        const idSet = new Set(ids.map(obj => obj._id)); 
        return list?.filter(it => idSet.has(it._id));
      };
  
      setData(value);
      setTempSelectRegion(item?.regions);
      setTempSelectRegionName(filterById(item?.regions, options?.regionList?.data));
      setTempSelectOrgName(filterById(item?.organizations, options?.organizationList?.data));
      setTempSelectOrg(item?.organizations);
      setTempSelectScienceName(filterById(item?.scienceAndEducations, options?.scienceAndEducation?.data));
      setTempSelectScience(item?.scienceAndEducations);
      setTempSelectSpeciesCategory(item?.speciesCategories);
      setTempSelectSpeciesName(filterById(item?.species, options?.speciesList?.data));
      setTempSelectSpecies(item?.species);
      setTempSelectWetMarketName(filterById(item?.wetMarkets, options?.wetMarket?.data));
      setTempSelectWetMarket(item?.wetMarkets);
      setTempSelectZooName(filterById(item?.zoos, options?.zoo?.data));
      setTempSelectZoo(item.zoos);
    }
  }, []);
  
  const addPostHandler = () => {
    if (data?.caption == "") {
      toast(
        <AlertError
          message={
            "Please Enter Description for this Post"
          }
        />
      );
      return;
    }
    if (
      tempSelectSpeciesName.length > 0 ||
      tempSelectScienceName.length > 0 ||
      tempSelectRegionName.length > 0 ||
      tempSelectOrgName.length > 0 ||
      tempSelectZooName.length > 0 ||
      tempSelectWetMarketName.length > 0
    ) {
      const promiseContainer = [];
      if (Array.from(data?.img)?.length) {
        for (let i = 0; i < Array.from(data?.img)?.length; i++) {
          ref && ref.current && ref.current.continuousStart();
          setLoading(true);
          const file = data?.img[i];
          const fSize = Math.round(file.size / 1048576);
          const fType = file.type;
          const ext = file.name?.split(".").pop();
          if (fSize > 25) {
            ref?.current?.complete();
            setLoading(false);
            toast(
              <AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />
            );
            return;
          } else if (
            ![
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/webp",
              "video/MP4",
              "video/mp4",
              "video/webm",
              "video/mpeg",
            ].includes(fType)
          ) {
            ref?.current?.complete();
            setLoading(false);
            toast(
              <AlertError message="Media is not of correct format and hence cannot be uploaded. Valid Media formats are jpeg, jpg, png, webp, MP4 ,mpeg and webm." />
            );
            return;
          } else {
            const fileName = uuidv4() + "." + ext;

            promiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => { },
                progressCallback: (progress) => { },
                errorCallback: (err) => {
                  return (
                    ref?.current?.complete(),
                    setLoading(false),
                    toast(<AlertError message={"Something Went Wrong"} />)
                  );
                },
              })
            );
          }
        }
        const allPromise = Promise.all([...promiseContainer]);
        allPromise.then((result) => {
          const payload = {
            files: result?.map((it) => ({
              media: "public/" + it?.key,
              mediaType: ["mp4", "mpeg", "MP4", "webm"]?.find(
                (i) => i == it?.key?.split(".")[1]
              )
                ? "video"
                : "image",
            })),
            name: profile?.profile?.firstName + profile?.profile?.lastName,
            description: data?.caption ? data?.caption.replace(/\n/g, '<br>').replace(/^( +)/gm, spaces => '&nbsp;'.repeat(spaces.length)) : "",
            type: data?.type ? data?.type : "",
            regions: tempSelectRegionName?.map((it) => it?._id),
            species: tempSelectSpeciesName?.map((it) => it?._id),
            speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
            organizations: tempSelectOrgName?.map((it) => it?._id),
            wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
            scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
            zoos: tempSelectZooName?.map((it) => it?._id),
          };
          props?.addMyPost(payload, (res) => {
            if (res && res.status == 200) {
              const payload = {
                page: 1,
                sort: "createdAt",
                order: "desc",
              };
              props?.getMyFeedData(payload, (res) => {
                if (res && res.status == 200) {
                  ref?.current?.complete();
                  setLoading(false);
                  toast(<AlertSuccess message="Post Added successfully" />);
                  history.push("/home");
                } else {
                  setLoading(false);
                  ref?.current?.complete();
                }
              });
            } else {
              ref?.current?.complete();
              setLoading(false);
              ref?.current?.complete();
              toast(
                <AlertError
                  message={
                    res?.data?.message
                      ? res?.data?.message
                      : "Something went wrong"
                  }
                />
              );
            }
          });
        });
      } else {
        const payload = {
          name: profile?.profile?.firstName + profile?.profile?.lastName,
          description: data?.caption ? data?.caption.replace(/\n/g, '<br>').replace(/^( +)/gm, spaces => '&nbsp;'.repeat(spaces.length)) : "",
          type: data?.type ? data?.type : "",
          regions: tempSelectRegionName?.map((it) => it?._id),
          species: tempSelectSpeciesName?.map((it) => it?._id),
          speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
          organizations: tempSelectOrgName?.map((it) => it?._id),
          wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
          scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
          zoos: tempSelectZooName?.map((it) => it?._id),
          files: [],
        };
        props?.addMyPost(payload, (res) => {
          if (res && res.status == 200) {
            const payload = {
              page: 1,
              sort: "createdAt",
              order: "desc",
            };
            props?.getMyFeedData(payload, (res) => {
              if (res && res.status == 200) {
                ref?.current?.complete();
                setLoading(false);
                toast(<AlertSuccess message="Post Added successfully" />);
                history.push("/home");
              } else {
                setLoading(false);
                ref?.current?.complete();
              }
            });
          } else {
            ref?.current?.complete();
            setLoading(false);
            ref?.current?.complete();
            toast(
              <AlertError
                message={
                  res?.data?.message ? res?.data?.message : "Something went wrong"
                }
              />
            );
          }
        });
      }
    } else {
      toast(
        <AlertError
          message={
            "Please Select any one Preferences"
          }
        />
      );
    }
  };

  const editPostHandler = () => {
    if (data?.caption == "") {
      toast(
        <AlertError
          message={
            "Please Enter Description for this Post"
          }
        />
      );
      return;
    }
    if (
      tempSelectSpeciesName?.length > 0 ||
      tempSelectScienceName?.length > 0 ||
      tempSelectRegionName?.length > 0 ||
      tempSelectOrgName?.length > 0 ||
      tempSelectZooName?.length > 0 ||
      tempSelectWetMarketName?.length > 0
    ) {
      const promiseContainer = [];
      if (Array.from(data?.img)?.length) {
        for (let i = 0; i < Array.from(data?.img)?.length; i++) {
          if (data?.img[i]?.name) {
            ref && ref.current && ref.current.continuousStart();
            setLoading(true);
            const file = data?.img[i];
            const fSize = Math.round(file.size / 1048576);
            const fType = file.type;
            const ext = file.name?.split(".").pop();
            if (fSize > 25) {
              ref?.current?.complete();
              setLoading(false);
              toast(
                <AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />
              );
              return;
            } else if (
              ![
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/webp",
                "video/MP4",
                "video/mp4",
                "video/webm",
                "video/mpeg",
              ].includes(fType)
            ) {
              ref?.current?.complete();
              setLoading(false);
              toast(
                <AlertError message="Media is not of correct format and hence cannot be uploaded. Valid Media formats are jpeg, jpg, png, webp, MP4 ,mpeg and webm." />
              );
              return;
            } else {
              const fileName = uuidv4() + "." + ext;

              promiseContainer.push(
                Storage.put(fileName, file, {
                  completeCallback: (event) => { },
                  progressCallback: (progress) => { },
                  errorCallback: (err) => {
                    return (
                      ref?.current?.complete(),
                      setLoading(false),
                      toast(<AlertError message={"Something Went Wrong"} />)
                    );
                  },
                })
              );
            }
          } else {
            promiseContainer.push(data?.img[i]);
          }
        }
        const allPromise = Promise.all([...promiseContainer]);
        allPromise.then((result) => {
          const payload = {
            files: result?.map((it) => ({
              media: it?.original ? it?.original : "public/" + it?.key,
              mediaType: it?.mediaType ? it?.mediaType : ["mp4", "mpeg", "MP4", "webm"]?.find(
                (i) => i == it?.key?.split(".")[1]
              )
                ? "video"
                : "image",
            })),
            name: profile?.profile?.firstName + profile?.profile?.lastName,
            description: data?.caption ? data?.caption.replace(/\n/g, '<br>').replace(/^( +)/gm, spaces => '&nbsp;'.repeat(spaces.length)) : "",
            type: data?.type ? data?.type : "",
            regions: tempSelectRegionName?.map((it) => it?._id),
            species: tempSelectSpeciesName?.map((it) => it?._id),
            speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
            organizations: tempSelectOrgName?.map((it) => it?._id),
            wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
            scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
            zoos: tempSelectZooName?.map((it) => it?._id),
          };
          props?.updateMyPost(data?.id, payload, (res) => {
            if (res && res.status == 200) {
              const payload = {
                page: 1,
                sort: "createdAt",
                order: "desc",
              };
              props?.getMyFeedData(payload, (res) => {
                if (res && res.status == 200) {
                  ref?.current?.complete();
                  setLoading(false);
                  toast(<AlertSuccess message="Post Updated successfully" />);
                  history.push("/home");
                } else {
                  setLoading(false);
                  ref?.current?.complete();
                }
              });
            } else {
              ref?.current?.complete();
              setLoading(false);
              ref?.current?.complete();
              toast(
                <AlertError
                  message={
                    res?.data?.message
                      ? res?.data?.message
                      : "Something went wrong"
                  }
                />
              );
            }
          });
        });
      } else {
        const payload = {
          name: profile?.profile?.firstName + profile?.profile?.lastName,
          description: data?.caption ? data?.caption.replace(/\n/g, '<br>').replace(/^( +)/gm, spaces => '&nbsp;'.repeat(spaces.length)) : "",
          type: data?.type ? data?.type : "",
          regions: tempSelectRegionName?.map((it) => it?._id),
          species: tempSelectSpeciesName?.map((it) => it?._id),
          speciesCategories: tempSelectSpeciesCategory?.map((it) => it),
          organizations: tempSelectOrgName?.map((it) => it?._id),
          wetMarkets: tempSelectWetMarketName?.map((it) => it?._id),
          scienceAndEducations: tempSelectScienceName?.map((it) => it?._id),
          zoos: tempSelectZooName?.map((it) => it?._id),
          files: [],
        };
        props?.updateMyPost(data?.id, payload, (res) => {
          if (res && res.status == 200) {
            const payload = {
              page: 1,
              sort: "createdAt",
              order: "desc",
            };
            props?.getMyFeedData(payload, (res) => {
              if (res && res.status == 200) {
                ref?.current?.complete();
                setLoading(false);
                toast(<AlertSuccess message="Post Updated successfully" />);
                history.push("/home");
              } else {
                setLoading(false);
                ref?.current?.complete();
              }
            });
          } else {
            ref?.current?.complete();
            setLoading(false);
            ref?.current?.complete();
            toast(
              <AlertError
                message={
                  res?.data?.message ? res?.data?.message : "Something went wrong"
                }
              />
            );
          }
        });
      }
    } else {
      toast(
        <AlertError
          message={
            "Please Select any one Preferences"
          }
        />
      );
    }
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <ProfileLayout>
        <div class="panel-main-content d-flex flex-column">
          <div class="panel-head">
            {/* <div class="breadcrumb-row d-flex flex-wrap">
              <div class="breadcrumb-box">
                <Link to="/home">Home</Link>
              </div>
            </div> */}
            <h1>{type?.postType == "edit" ? "Edit Post" : "Add Post"}</h1>
          </div>
          <div class="panel-body flex-grow-1">
            <div class="widget d-flex flex-wrap add-green-border">
              <div class="widget-icon">
                <div class="w-icon d-flex align-items-center justify-content-center overflow-hidden">
                  <img
                    src={
                      profilePic ? process.env.REACT_APP_MEDIA + profilePic : ""
                    }
                    alt="feed icon"
                  />
                </div>
              </div>
              <div class="widget-content">
                <div class="w-content-upper">
                  <div class="w-textarea-block">
                    <textarea
                      class="w-textarea add-post-textarea"
                      placeholder="Write something here"
                      onChange={(e) => {
                        const value = { ...data };
                        value.caption = e?.target?.value;
                        setData((prev) => ({
                          ...prev,
                          caption: value?.caption,
                        }));
                      }}
                      value={data?.caption}
                    ></textarea>
                  </div>
                </div>
                <div class="w-content-lower">
                  <div class="w-button-holder d-flex flex-wrap align-items-center">
                    <div class="w-button position-relative pl-0">
                      <input
                        type="file"
                        multiple
                        id="inputGroupImg"
                        accept=".jpg,.jpeg,.png,.gif,.webp"
                        onChange={(e) => {
                          const value = { ...data };
                          value.isNewImage = true;
                          value.img = e.target.files;
                          setData((prev) => ({
                            ...prev,
                            img: [
                              ...Array.from(prev?.img),
                              ...Array.from(value?.img),
                            ],
                            isNewImage: value?.isNewImage,
                          }));
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="inputGroupImg"
                        class="w-button-box d-flex align-items-center justify-content-center "
                      >
                        <img src={imgIcon} alt="image1" />
                      </label>
                    </div>
                    <div class="w-button position-relative">
                      <input
                        type="file"
                        multiple
                        id="inputGroupVideo"
                        accept="video/MP4, video/mp4, video/webm, video/mpeg"
                        onChange={(e) => {
                          const value = { ...data };
                          value.isNewImage = true;
                          value.img = e.target.files;
                          setData((prev) => ({
                            ...prev,
                            img: [
                              ...Array.from(prev?.img),
                              ...Array.from(value?.img),
                            ],
                            isNewImage: value?.isNewImage,
                          }));
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="inputGroupVideo"
                        class="w-button-box d-flex align-items-center justify-content-center"
                      >
                        <img src={videosIcon} alt="video" />
                      </label>
                    </div>
                  </div>

                  {data?.img && (
                    <div class="w-image-holder mt-35">
                      <div className=" d-flex flex-wrap ">
                        {Array.from(data?.img).map((item, i) =>
                          (item?.type &&
                            [
                              "video/mp4",
                              "video/mpeg",
                              "video/MP4",
                              "video/webm",
                            ]?.find((i) => i == item?.type)) ||
                            (item?.mediaType != "image" && !item?.type &&
                              ["mp4", "mpeg", "MP4", "webm"]?.find(
                                (i) => i == item?.original?.split(".")[1]
                              )) ? (
                            <div
                              class="m-10 position-relative"
                              style={{
                                width:
                                  Array.from(data?.img)?.length >= 3
                                    ? "30%"
                                    : Array.from(data?.img)?.length == 2
                                      ? "45%"
                                      : `${100 / Array.from(data?.img)?.length}%`,
                                objectFit: "cover",
                              }}
                            >
                              <video
                                controls
                                style={{
                                  width: "100%",
                                  height:
                                    Array.from(data?.img)?.length >= 3
                                      ? "200px"
                                      : `260px`,
                                  objectFit: "cover",
                                }}
                              >
                                <source
                                  src={
                                    data?.img[i]?.name
                                      ? `${URL.createObjectURL(data?.img[i])}`
                                      : process.env.REACT_APP_MEDIA +
                                      data?.img[i].original
                                  }
                                />
                              </video>
                              <button
                                type="button"
                                class="trash-button-box"
                                onClick={(e) => {
                                  const a = Array.from(data?.img)?.filter(
                                    (ir) => ir?.name != item?.name
                                  );

                                  setData((prev) => ({
                                    ...prev,
                                    img: a,
                                  }));
                                }}
                              >
                                <img src={trash} alt="w volumne" />
                              </button>
                            </div>
                          ) : (
                            <div
                              class="m-10 position-relative"
                              style={{
                                width:
                                  Array.from(data?.img)?.length >= 3
                                    ? "30%"
                                    : Array.from(data?.img)?.length == 2
                                      ? "45%"
                                      : `${100 / Array.from(data?.img)?.length}%`,
                                objectFit: "cover",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height:
                                    Array.from(data?.img)?.length >= 3
                                      ? "200px"
                                      : `260px`,
                                  objectFit: "cover",
                                }}
                                src={
                                  data?.img[i]?.name
                                    ? `${URL.createObjectURL(data?.img[i])}`
                                    : process.env.REACT_APP_MEDIA + data?.img[i]?.original
                                }
                                alt=""
                              />
                              <button
                                type="button"
                                class="trash-button-box"
                                onClick={(e) => {
                                  let a;
                                  if (item.name) {
                                    a = Array.from(data?.img)?.filter((ir) => ir?.name !== item?.name);
                                  } else {
                                    a = Array.from(data?.img)?.filter((ir, index) => index !== i);
                                  }
                                  setData((prev) => ({
                                    ...prev,
                                    img: a,
                                  }));
                                }}
                              >
                                <img src={trash} alt="w volumne" />
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  <div class="col-text-area" style={{ marginTop: "20px" }}>
                    <div class="col-text-item">
                      <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-text-label">Categories of Species</div>
                        {tempSelectSpeciesName?.length > 0 ? (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleSpeciesAndCategories()}
                          >
                            {`${tempSelectSpeciesName?.length} Selected`}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleSpeciesAndCategories()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="col-text-item">
                      <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-text-label">Science & Education</div>
                        {tempSelectScienceName?.length > 0 ? (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleScience()}
                          >
                            {`${tempSelectScienceName?.length} Selected`}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleScience()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="col-text-item">
                      <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-text-label">
                          Organizations, Zoos and Wildlife Reserves
                        </div>
                        {tempSelectOrgName?.length > 0 || tempSelectZooName?.length > 0 ? (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleOrgAndZoo()}
                          >
                            {`${tempSelectOrgName?.length +
                              tempSelectZooName?.length
                              } Selected`}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleOrgAndZoo()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="col-text-item">
                      <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-text-label">Regions</div>
                        {tempSelectRegionName?.length > 0 ? (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleRegion()}
                          >
                            {`${tempSelectRegionName?.length} Selected`}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleRegion()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="col-text-item">
                      <div class="col-text-box w-100 h-100 d-flex flex-wrap align-items-center justify-content-between">
                        <div class="col-text-label">
                          War and Threats Environment
                        </div>
                        {tempSelectWetMarketName?.length > 0 ? (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleWetMarket()}
                          >
                            {`${tempSelectWetMarketName?.length} Selected`}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="col-select-btn"
                            onClick={() => toggleWetMarket()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>

                    <div class="col-btn-group d-flex align-items-center justify-content-end">
                      {/* <div class="col-dropdown-menu">
                        <Dropdown className="dropdown">
                          <Dropdown.Toggle
                            className="has-green-toggle dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div class="dropdown-toggle-inside d-flex align-items-center">
                              <div class="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                {data?.type == "public" ? (
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50004C14.6663 4.81814 11.6816 1.83337 7.99967 1.83337C4.31778 1.83337 1.33301 4.81814 1.33301 8.50004C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M1.33301 8.5H14.6663"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.99967 1.83337C9.66719 3.65894 10.6148 6.02806 10.6663 8.50004C10.6148 10.972 9.66719 13.3411 7.99967 15.1667C6.33215 13.3411 5.38451 10.972 5.33301 8.50004C5.38451 6.02806 6.33215 3.65894 7.99967 1.83337Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.3337 14.5V13.1667C11.3337 12.4594 11.0527 11.7811 10.5526 11.281C10.0525 10.781 9.37424 10.5 8.66699 10.5H3.33366C2.62641 10.5 1.94814 10.781 1.44804 11.281C0.947944 11.7811 0.666992 12.4594 0.666992 13.1667V14.5"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.99967 7.83333C7.47243 7.83333 8.66634 6.63943 8.66634 5.16667C8.66634 3.69391 7.47243 2.5 5.99967 2.5C4.52691 2.5 3.33301 3.69391 3.33301 5.16667C3.33301 6.63943 4.52691 7.83333 5.99967 7.83333Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15.333 14.5V13.1667C15.3326 12.5758 15.1359 12.0019 14.7739 11.5349C14.4119 11.0679 13.9051 10.7344 13.333 10.5867"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M10.667 2.58667C11.2406 2.73354 11.749 3.06714 12.1121 3.53488C12.4752 4.00262 12.6722 4.57789 12.6722 5.17C12.6722 5.76212 12.4752 6.33739 12.1121 6.80513C11.749 7.27287 11.2406 7.60647 10.667 7.75334"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>
                              <div class="dl-text" style={{ color: "#fff" }}>
                                {data?.type == "public"
                                  ? "Public"
                                  : "Friends"}
                              </div>
                              <div class="chevron-down-wrap d-flex align-items-center justify-content-center">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 6L8 10L12 6"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                              className="dropdown-item"
                              onClick={(e) => {
                                const value = { ...data };
                                value.type = "public";

                                setData((prev) => ({
                                  ...prev,
                                  type: value?.type,
                                }));
                              }}
                              eventKey={"1"}
                            >
                              <a
                                href="#"
                                class="dropdown-link w-100 h-100 d-flex flex-wrap align-items-center"
                              >
                                <div class="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50004C14.6663 4.81814 11.6816 1.83337 7.99967 1.83337C4.31778 1.83337 1.33301 4.81814 1.33301 8.50004C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M1.33301 8.5H14.6663"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.99967 1.83337C9.66719 3.65894 10.6148 6.02806 10.6663 8.50004C10.6148 10.972 9.66719 13.3411 7.99967 15.1667C6.33215 13.3411 5.38451 10.972 5.33301 8.50004C5.38451 6.02806 6.33215 3.65894 7.99967 1.83337Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div class="dl-text" >Public</div>
                              </a>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              onClick={(e) => {
                                const value = { ...data };
                                value.type = "private";

                                setData((prev) => ({
                                  ...prev,
                                  type: value?.type,
                                }));
                              }}
                              eventKey={"2"}
                            >
                              <a
                                href="#"
                                class="dropdown-link w-100 h-100 d-flex flex-wrap align-items-center"
                              >
                                <div class="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.6667 7.83337H3.33333C2.59695 7.83337 2 8.43033 2 9.16671V13.8334C2 14.5698 2.59695 15.1667 3.33333 15.1667H12.6667C13.403 15.1667 14 14.5698 14 13.8334V9.16671C14 8.43033 13.403 7.83337 12.6667 7.83337Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M4.66699 7.83337V5.16671C4.66699 4.28265 5.01818 3.43481 5.6433 2.80968C6.26842 2.18456 7.11627 1.83337 8.00033 1.83337C8.88438 1.83337 9.73223 2.18456 10.3573 2.80968C10.9825 3.43481 11.3337 4.28265 11.3337 5.16671V7.83337"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div class="dl-text">Private</div>
                              </a>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item"
                              onClick={(e) => {
                                const value = { ...data };
                                value.type = "private";

                                setData((prev) => ({
                                  ...prev,
                                  type: "private",
                                }));
                              }}
                              eventKey={"3"}
                            >
                              <a
                                href="#"
                                class="dropdown-link w-100 h-100 d-flex flex-wrap align-items-center"
                              >
                                <div class="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.3337 14.5V13.1667C11.3337 12.4594 11.0527 11.7811 10.5526 11.281C10.0525 10.781 9.37424 10.5 8.66699 10.5H3.33366C2.62641 10.5 1.94814 10.781 1.44804 11.281C0.947944 11.7811 0.666992 12.4594 0.666992 13.1667V14.5"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.99967 7.83333C7.47243 7.83333 8.66634 6.63943 8.66634 5.16667C8.66634 3.69391 7.47243 2.5 5.99967 2.5C4.52691 2.5 3.33301 3.69391 3.33301 5.16667C3.33301 6.63943 4.52691 7.83333 5.99967 7.83333Z"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15.333 14.5V13.1667C15.3326 12.5758 15.1359 12.0019 14.7739 11.5349C14.4119 11.0679 13.9051 10.7344 13.333 10.5867"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M10.667 2.58667C11.2406 2.73354 11.749 3.06714 12.1121 3.53488C12.4752 4.00262 12.6722 4.57789 12.6722 5.17C12.6722 5.76212 12.4752 6.33739 12.1121 6.80513C11.749 7.27287 11.2406 7.60647 10.667 7.75334"
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div class="dl-text">Friends Only</div>
                              </a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}
                      <div class="col-btn">
                        <a
                          href="#"
                          class="btn btn-default btn-post"
                          onClick={!loading ?() =>
                            type?.postType == "edit"
                              ? editPostHandler()
                              : addPostHandler()
                          :()=>{}}
                        >
                          {loading ?"Updating":"Post"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddPostSelections
          toggleSpeciesAndCategories={toggleSpeciesAndCategories}
          toggleRegion={toggleRegion}
          toggleWetMarket={toggleWetMarket}
          toggleScience={toggleScience}
          toggleOrgAndZoo={toggleOrgAndZoo}
          showRegion={showRegion}
          setShowRegion={setShowRegion}
          showOrganizationAndZoo={showOrganizationAndZoo}
          setShowOrganizationAndZoo={setShowOrganizationAndZoo}
          showScienceAndEducation={showScienceAndEducation}
          setShowScienceAndEducation={setShowScienceAndEducation}
          showWetMarket={showWetMarket}
          setShowWetMarket={setShowWetMarket}
          showSpeciesAndCategories={showSpeciesAndCategories}
          setshowSpeciesAndCategories={setshowSpeciesAndCategories}
          tempSelectEnvName={tempSelectEnvName}
          tempSelectOrgName={tempSelectOrgName}
          tempSelectRegionName={tempSelectRegionName}
          tempSelectScienceName={tempSelectScienceName}
          tempSelectWetMarketName={tempSelectWetMarketName}
          tempSelectZooName={tempSelectZooName}
          setTempSelectEnvName={setTempSelectEnvName}
          setTempSelectOrgName={setTempSelectOrgName}
          setTempSelectRegionName={setTempSelectRegionName}
          setTempSelectScienceName={setTempSelectScienceName}
          setTempSelectWetMarketName={setTempSelectWetMarketName}
          setTempSelectZooName={setTempSelectZooName}
          tempSelectSpeciesName={tempSelectSpeciesName}
          setTempSelectSpeciesName={setTempSelectSpeciesName}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
          postType={type?.postType}
          editItem={type?.item}
          tempSelectSpecies={tempSelectSpecies}
          setTempSelectSpecies={setTempSelectSpecies}
          tempSelectScience={tempSelectScience}
          setTempSelectScience={setTempSelectScience}
          tempSelectRegion={tempSelectRegion}
          setTempSelectRegion={setTempSelectRegion}
          setTempSelectOrg={setTempSelectOrg}
          tempSelectOrg={tempSelectOrg}
          tempSelectZoo={tempSelectZoo}
          setTempSelectZoo={setTempSelectZoo}
          tempSelectWetMarket={tempSelectWetMarket}
          setTempSelectWetMarket={setTempSelectWetMarket}
        />
      </ProfileLayout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addMyPost: (params, callback) => dispatch(addMyPost(params, callback)),
  updateMyPost: (params, data, callback) =>
    dispatch(updateMyPost(params, data, callback)),
  getMyFeedData: (params, callback) =>
    dispatch(getMyFeedData(params, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AddPost));
