import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  getMediaDetails,
  organizationListing,
  getOrgLiveFeeds,
} from "../../../store/organization";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { getRegionLiveFeeds, regionListings } from "../../../store/region";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HTMLReactParser from "html-react-parser";
import { removeTags, capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import { environmentalistListings, getEnvLiveFeeds } from "../../../store/environmentalist";
import noRecord from "../../../include/images/nrb-img.svg";
import { getSpeciesLiveFeeds, speciesListings } from "../../../store/species";
import { getZooLiveFeeds, zooListings } from "../../../store/zoo";
import { getWetMarketLiveFeeds, wetMarketData } from "../../../store/wetMarket";
import Pagination from "../Pagination/pagination";

const LiveFeeds = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [initialRender, setInitialRender] = useState(true); // State to track initial render

  const fetchData = (params, callback) => {
    setLoading(true);
    const fetchDataFunction = (() => {
      switch (props.section) {
        case "species":
          return props.getSpeciesLiveFeeds;
        case "wetMarket":
          return props.getWetMarketLiveFeeds;
        case "organization":
          return props.getOrgLiveFeeds;
        case "region":
          return props.getRegionLiveFeeds;
        case "environmentalist":
          return props.getEnvLiveFeeds;
        case "zoos":
          return props.getZooLiveFeeds;
        default:
          return null;
      }
    })();
    if (fetchDataFunction) {
      fetchDataFunction(params, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
          if (callback) {
            callback(res);
          }
        }
      });
    }
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      fetchData(params, (res) => {});
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  let details;

  switch (props.section) {
    case "organization":
      details = props.organizationListing && props.organizationListing.liveFeeds;
      break;

    case "region":
      details = props.regionDetails && props.regionDetails.liveFeeds;
      break;

    case "species":
      details = props.speciesListings && props.speciesListings.liveFeeds;
      break;

    case "wetMarket":
      details = props.wetMarketData && props.wetMarketData.liveFeeds;
      break;

    case "environmentalist":
      details = props.environmentalistListings && props.environmentalistListings.liveFeeds;
      break;

    case "zoos":
      details = props.zooListings && props.zooListings.liveFeeds;
      break;

    default:
      // Default case if none of the specified cases match
      details = null;
  }

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.filteredCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <>
                {loading ? (
                  <ul className="tbc-grid-list d-flex flex-wrap ul-padding ">
                    {[1, 2].map((item) => (
                      <li class="tbc-grid-item">
                        <div class="tbc-grid-box">
                          <h4>
                            <Skeleton borderRadius={"10px"} width="500px" height="220px" />
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <ul class="card-list d-lg-flex flex-lg-wrap" style={{ marginBottom: "68px" }}>
                      {details && details.data && details.data?.length ? (
                        details.data?.map((item) => {
                          return (
                            <li class="card-item">
                              <a
                                href={item?.url}
                                class="card-box w-100 h-100 d-flex flex-wrap"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div class="card-img-box overflow-hidden">
                                  <div class="card-img-inside">
                                    <img
                                      class="card-img"
                                      src={
                                        item?.coverImage?.medium
                                          ? process.env.REACT_APP_MEDIA + item?.coverImage?.medium
                                          : ""
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div class="card-content-box">
                                  <h5 className="text-transfrom ">{`${capitalizeContentInBrackets(item?.title)}`}</h5>
                                  <div class="card-content-feeds w-100">
                                    <span>
                                      <b>Location:</b>{" "}
                                      {item.address
                                        ? dotDesc(
                                            HTMLReactParser(
                                              removeTags(item.address.charAt(0).toUpperCase() + item.address.slice(1)),
                                            ),
                                            40,
                                          )
                                        : ""}
                                    </span>
                                  </div>
                                  <div class="card-content-feeds w-100">
                                    <span>
                                      <b>Source:</b>{" "}
                                      {item.source
                                        ? dotDesc(
                                            HTMLReactParser(
                                              removeTags(item.source.charAt(0).toUpperCase() + item.source.slice(1)),
                                            ),
                                            40,
                                          )
                                        : ""}
                                    </span>
                                  </div>
                                  <div class="card-content-feeds w-100">
                                    <p
                                      title={HTMLReactParser(
                                        removeTags(item?.info?.charAt(0).toUpperCase() + item?.info?.slice(1)),
                                      )}
                                    >
                                      <b>Info:</b>{" "}
                                      {item.info
                                        ? dotDesc(
                                            HTMLReactParser(
                                              removeTags(item.info.charAt(0).toUpperCase() + item.info.slice(1)),
                                            ),
                                            100,
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })
                      ) : (
                        <ul className="blog-list d-flex flex-wrap justify-content-center">
                          <div
                            className="no-records-container d-flex align-items-center justify-content-center"
                            style={{ height: "auto" }}
                          >
                            <div className="no-records-box text-center">
                              <div className="nrb-image">
                                <img src={noRecord} alt="" />
                              </div>
                              <h6>No Records Found</h6>
                            </div>
                          </div>
                        </ul>
                      )}
                      {details && details?.filteredCount > 20 && (
                        <Pagination
                          handlePageClick={handlePageClick}
                          currentPage={currentPage}
                          pageLinks={pageLinks}
                          totalPages={pageLinks.length}
                        />
                      )}
                    </ul>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMediaDetails: (params, callback) => dispatch(getMediaDetails(params, callback)),
  getOrganizationDetails: (params, callback) => dispatch(getOrganizationDetails(params, callback)),
  getEnvLiveFeeds: (params, callback) => dispatch(getEnvLiveFeeds(params, callback)),
  getOrgLiveFeeds: (params, callback) => dispatch(getOrgLiveFeeds(params, callback)),
  getRegionLiveFeeds: (params, callback) => dispatch(getRegionLiveFeeds(params, callback)),
  getSpeciesLiveFeeds: (params, callback) => dispatch(getSpeciesLiveFeeds(params, callback)),
  getWetMarketLiveFeeds: (params, callback) => dispatch(getWetMarketLiveFeeds(params, callback)),
  getZooLiveFeeds: (params, callback) => dispatch(getZooLiveFeeds(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
  regionDetails: regionListings(state),
  environmentalistListings: environmentalistListings(state),
  speciesListings: speciesListings(state),
  zooListings: zooListings(state),
  wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LiveFeeds));
