import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import { getProfile } from "../../../utils/localStorageServices";
import Skeleton from "react-loading-skeleton";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import SpeciesDropdown from "./speciesDropdown";
import RegionsDropdown from "./regionsDropdown";
import OrganizationDropdown from "./organizationDropdown";
import EnvironmentalistDropdown from "./environmentalistDropdown";
import ZooDropdown from "./zooDropdown";
import ScienceDropdown from "./scienceDropdown";
import WetmarketDropdown from "./wetmarketDropdown";
const PageInfoSidebar = (props) => {
  const profile = getProfile();
  const otherData =
    props.otherRegion &&
    props.otherRegion.length &&
    props.otherRegion.map((item) => item);

  // news data
  const newsCatData =
    props.newsCategory &&
    props.newsCategory.length &&
    props.newsCategory.map((item) => item);

  // blog data

  const blogCatData =
    props.blogCategories &&
    props.blogCategories.length &&
    props.blogCategories.map((item) => item);

  // species data

  const speciesData =
    props.speciesCategories &&
    props.speciesCategories.length &&
    props.speciesCategories.map((item) => item);

  // organization Data

  const organizationData =
    props.organizationList &&
    props.organizationList.length &&
    props.organizationList.map((item) => item);

  //Environmentalist data

  const environmentalistData =
    props.environmentalistList &&
    props.environmentalistList.length &&
    props.environmentalistList.map((item) => item);

  // Zoo and wildlife reserves Data

  const zooAndWildLifeData =
    props.zooAndParksList &&
    props.zooAndParksList.length &&
    props.zooAndParksList.map((item) => item);

  // Science and education Data

  const scienceAndEducationData =
    props.scienceAndEducationCategory &&
    props.scienceAndEducationCategory.length &&
    props.scienceAndEducationCategory.map((item) => item);

  // Wet Market Data

  const wetMarketData =
    props.wetmarketList &&
    props.wetmarketList.length &&
    props.wetmarketList.map((item) => item);

  const [handleViewAll, setHandleViewAll] = useState(false);
  const [handleViewLess, setHandleViewLess] = useState(false);
  const [handleViewAllNews, setHandleViewAllNews] = useState(false);
  const [handleViewLessNews, setHandleViewLessNews] = useState(false);
  const [handleViewAllBlog, setHandleViewAllBlog] = useState(false);
  const [handleViewLessBlog, setHandleViewLessBlog] = useState(false);
  const [dividedOrganizationData, setDividedOrganizationData] = useState([]);
  const [poppedOrganizationData, setPoppedOrganizationData] = useState([]);
  const [handleViewAllOrganization, setHandleViewAllOrganization] =
    useState(false);
  const [handleViewLessOrganization, setHandleViewLessOrganization] =
    useState(false);

  const [dividedEnvironmentalistData, setDividedEnvironmentalistData] =
    useState([]);
  const [poppedEnvironmentalistData, setPoppedEnvironmentalistData] = useState(
    []
  );
  const [handleViewAllEnvironmentalist, setHandleViewAllEnvironmentalist] =
    useState(false);
  const [handleViewLessEnvironmentalist, setHandleViewLessEnvironmentalist] =
    useState(false);

  const [dividedZooData, setDividedZooData] = useState([]);
  const [poppedZooData, setPoppedZooData] = useState([]);
  const [handleViewAllZoo, setHandleViewAllZoo] = useState(false);
  const [handleViewLessZoo, setHandleViewLessZoo] = useState(false);

  const [dividedWetMarketData, setDividedWetMarketData] = useState([]);
  const [poppedWetMarketData, setPoppedWetMarketData] = useState([]);
  const [handleViewAllWetMarket, setHandleViewAllWetMarket] = useState(false);
  const [handleViewLessWetMarket, setHandleViewLessWetMarket] = useState(false);

  const [dividedScienceData, setDividedScienceData] = useState([]);
  const [poppedScienceData, setPoppedScienceData] = useState([]);
  const [handleViewAllScience, setHandleViewAllScience] = useState(false);
  const [handleViewLessScience, setHandleViewLessScience] = useState(false);

  const [dividedSpeciesData, setDividedSpeciesData] = useState([]);
  const [poppedSpeciesData, setPoppedSpeciesData] = useState([]);
  const [handleViewAllSpecies, setHandleViewAllSpecies] = useState(false);
  const [handleViewLessSpecies, setHandleViewLessSpecies] = useState(false);

  // for species
  const handleClickSpecies = () => {
    setHandleViewAllSpecies(!handleViewAllSpecies);
    setHandleViewLessSpecies(false);
    const divided = [];

    for (let i = 0; i < speciesData.length; i += 15) {
      divided.push(speciesData.slice(i, i + 15));
    }
    setDividedSpeciesData(divided);
  };

  const handleClickLessSpecies = () => {
    setHandleViewAllSpecies(false);
    setHandleViewLessSpecies(!handleViewLessSpecies);
    const popped = speciesData.splice(0, 14);
    setPoppedSpeciesData(popped);
  };

  // Organization

  const handleClickOrganization = () => {
    setHandleViewAllOrganization(!handleViewAllOrganization);
    setHandleViewLessOrganization(false);
    const divided = [];

    for (let i = 0; i < organizationData.length; i += 15) {
      divided.push(organizationData.slice(i, i + 15));
    }
    setDividedOrganizationData(divided);
  };

  const handleClickLessOrganization = () => {
    setHandleViewAllOrganization(false);
    setHandleViewLessOrganization(!handleViewLessOrganization);
    const popped = organizationData.splice(0, 14);
    setPoppedOrganizationData(popped);
  };

  // Environmentalist

  const handleClickEnvironmentalist = () => {
    setHandleViewAllEnvironmentalist(!handleViewAllEnvironmentalist);
    setHandleViewLessEnvironmentalist(false);
    const divided = [];

    for (let i = 0; i < environmentalistData.length; i += 15) {
      divided.push(environmentalistData.slice(i, i + 15));
    }
    setDividedEnvironmentalistData(divided);
  };

  const handleClickLessEnvironmentalist = () => {
    setHandleViewAllEnvironmentalist(false);
    setHandleViewLessEnvironmentalist(!handleViewLessEnvironmentalist);
    const popped = environmentalistData.splice(0, 14);
    setPoppedEnvironmentalistData(popped);
  };

  // Zoo

  const handleClickZoo = () => {
    setHandleViewAllZoo(!handleViewAllZoo);
    setHandleViewLessZoo(false);
    const divided = [];

    for (let i = 0; i < zooAndWildLifeData.length; i += 15) {
      divided.push(zooAndWildLifeData.slice(i, i + 15));
    }
    setDividedZooData(divided);
  };

  const handleClickLessZoo = () => {
    setHandleViewAllZoo(false);
    setHandleViewLessZoo(!handleViewLessZoo);
    const popped = zooAndWildLifeData.splice(0, 14);
    setPoppedZooData(popped);
  };

  // WetMarket

  const handleClickWet = () => {
    setHandleViewAllWetMarket(!handleViewAllWetMarket);
    setHandleViewLessWetMarket(false);
    const divided = [];

    for (let i = 0; i < wetMarketData.length; i += 15) {
      divided.push(wetMarketData.slice(i, i + 15));
    }
    setDividedWetMarketData(divided);
  };

  const handleClickLessWet = () => {
    setHandleViewAllWetMarket(false);
    setHandleViewLessWetMarket(!handleViewLessWetMarket);
    const popped = wetMarketData.splice(0, 14);
    setPoppedWetMarketData(popped);
  };

  // Science

  const handleClickScience = () => {
    setHandleViewAllScience(!handleViewAllScience);
    setHandleViewLessScience(false);
    const divided = [];

    for (let i = 0; i < scienceAndEducationData.length; i += 15) {
      divided.push(scienceAndEducationData.slice(i, i + 15));
    }
    setDividedScienceData(divided);
  };

  const handleClickLessScience = () => {
    setHandleViewAllScience(false);
    setHandleViewLessScience(!handleViewLessScience);
    const popped = scienceAndEducationData.splice(0, 14);
    setPoppedScienceData(popped);
  };
  const [isRegionMenu, setIsRegionMenu] = useState(false);
  const [isNewsMenu, setIsNewsMenu] = useState(false);
  const [isBlogMenu, setIsBlogMenu] = useState(false);
  const [isSpeciesMenu, setIsSpeciesMenu] = useState(true);
  const [isOrgMenu, setIsOrgMenu] = useState(false);
  const [isEnvirMenu, setIsEnvirMenu] = useState(false);
  const [isZooMenu, setIsZooMenu] = useState(false);
  const [isWetMenu, setIsWetMenu] = useState(false);
  const [isScienceMenu, setIsScienceMenu] = useState(false);

  const togglerRegion = () => {
    if (props.showMenu) {
      setIsRegionMenu(!isRegionMenu);
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsRegionMenu(!isRegionMenu);
    }
  };
  const togglerSpecies = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsSpeciesMenu(!isSpeciesMenu);
    }
  };
  const togglerOrganization = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsOrgMenu(!isOrgMenu);
    }
  };

  const togglerZoo = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsZooMenu(!isZooMenu);
    }
  };

  const togglerEnv = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsEnvirMenu(!isEnvirMenu);
    }
  };

  const togglerScience = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isWetMenu) {
        setIsWetMenu(!isWetMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      setIsScienceMenu(!isScienceMenu);
    }
  };

  const togglerWet = () => {
    if (props.showMenu) {
      if (isRegionMenu) {
        setIsRegionMenu(!isRegionMenu);
      }

      if (isNewsMenu) {
        setIsNewsMenu(!isNewsMenu);
      }
      if (isBlogMenu) {
        setIsBlogMenu(!isBlogMenu);
      }
      if (isSpeciesMenu) {
        setIsSpeciesMenu(!isSpeciesMenu);
      }
      if (isZooMenu) {
        setIsZooMenu(!isZooMenu);
      }
      if (isOrgMenu) {
        setIsOrgMenu(!isOrgMenu);
      }
      if (isEnvirMenu) {
        setIsEnvirMenu(!isEnvirMenu);
      }
      if (isScienceMenu) {
        setIsScienceMenu(!isScienceMenu);
      }
      setIsWetMenu(!isWetMenu);
    }
  };

  const isMobile = useCheckMobileScreen();



  const selectData = (item) => {
    props.setSelectedSpeciesCategory(item?._id);
    props.setSelectedSpeciesCategoryName(item?.name);
  };

  const [selectedCategory, setSelectedCategory] = useState();

  const handleLink = () => {
    toast(
      <AlertError message="It seems that the menu data is currently unavailable. Our team is working diligently to update the information. In the meantime, feel free to explore other sections of our site or contact us for immediate assistance. Thank you for your understanding." />)
  }
  return (
    <>
      {props?.loading ?
        <div >
          <Skeleton borderRadius={"10px"} height={"100vh"} />
        </div > :
        <div class={`ei-category-accordion custom-ei-accordion ${isMobile ? "accordion-height" : ""}`}>
          <div class="d-flex d-lg-block align-items-start">
            <ul
              class="nav nav-tabs ei-category-nav d-lg-none d-flex"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerSpecies}
                  className={
                    isSpeciesMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Endangered Species
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerRegion}
                  className={
                    isRegionMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Regions
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerOrganization}
                  className={
                    isOrgMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Organizations
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerEnv}
                  className={
                    isEnvirMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Environmentalists
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerZoo}
                  className={
                    isZooMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Zoos & Wildlife Reserves
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerScience}
                  className={
                    isScienceMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  Science & Educations
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={togglerWet}
                  className={
                    isWetMenu ? "nav-link active mobile-sub" : "nav-link active"
                  }
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="two-tab-pane"
                  aria-selected="true"
                >
                  War on the Environment - Threats
                </button>
              </li>
            </ul>
            <Accordion
              className="tab-content accordion ei-category-tab accordion-flush add-plus-minus-arrow"
              id="myTabContent"
              defaultActiveKey={["1"]}
            >
              {props?.showMenu === false && (
                <div class="tab-intro-wrapper">
                  <p>
                    Research all 16,000 endangered animals by Species, Regions,
                    Organizations, Zoos & Wildlife, Science & Education, and Global
                    Threats.
                  </p>
                </div>
              )}
              <SpeciesDropdown
                showMenu={props.showMenu}
                isSpeciesMenu={isSpeciesMenu}
                speciesCategories={props.speciesCategories}
                handleViewAllSpecies={handleViewAllSpecies}
                dividedSpeciesData={dividedSpeciesData}
                selectedCategory={selectedCategory}
                selectData={selectData}
                setSelectedCategory={setSelectedCategory}
                handleViewLessSpecies={handleViewLessSpecies}
                poppedSpeciesData={poppedSpeciesData}
                handleClickLessSpecies={handleClickLessSpecies}
                handleClickSpecies={handleClickSpecies}
              />
              <RegionsDropdown
                showMenu={props.showMenu}
                isRegionMenu={isRegionMenu}
                regions={props.regions}
              />
              <OrganizationDropdown
                showMenu={props.showMenu}
                isOrgMenu={isOrgMenu}
                organizationList={props.organizationList}
                handleViewAllOrganization={handleViewAllOrganization}
                dividedOrganizationData={dividedOrganizationData}
                handleViewLessOrganization={handleViewLessOrganization}
                poppedOrganizationData={poppedOrganizationData}
                handleClickLessOrganization={handleClickLessOrganization}
                handleClickOrganization={handleClickOrganization}
              />

              <EnvironmentalistDropdown
                showMenu={props.showMenu}
                isEnvirMenu={isEnvirMenu}
                environmentalistList={props.environmentalistList}
                handleViewAllEnvironmentalist={handleViewAllEnvironmentalist}
                dividedEnvironmentalistData={dividedEnvironmentalistData}
                handleViewLessEnvironmentalist={handleViewLessEnvironmentalist}
                poppedEnvironmentalistData={poppedEnvironmentalistData}
                handleClickLessEnvironmentalist={handleClickLessEnvironmentalist}
                handleClickEnvironmentalist={handleClickEnvironmentalist}
              />

              <ZooDropdown
                showMenu={props.showMenu}
                isZooMenu={isZooMenu}
                zooAndParksList={props.zooAndParksList}
                handleViewAllZoo={handleViewAllZoo}
                dividedZooData={dividedZooData}
                handleViewLessZoo={handleViewLessZoo}
                poppedZooData={poppedZooData}
                handleClickLessZoo={handleClickLessZoo}
                handleClickZoo={handleClickZoo}
              />
              <ScienceDropdown
                showMenu={props.showMenu}
                isScienceMenu={isScienceMenu}
                scienceAndEducationCategory={props.scienceAndEducationCategory}
                handleViewAllScience={handleViewAllScience}
                dividedScienceData={dividedScienceData}
                handleViewLessScience={handleViewLessScience}
                poppedScienceData={poppedScienceData}
                handleClickLessScience={handleClickLessScience}
                handleClickScience={handleClickScience}
              />

              <WetmarketDropdown
                showMenu={props.showMenu}
                isWetMenu={isWetMenu}
                wetmarketList={props.wetmarketList}
                handleViewAllWetMarket={handleViewAllWetMarket}
                dividedWetMarketData={dividedWetMarketData}
                handleViewLessWetMarket={handleViewLessWetMarket}
                poppedWetMarketData={poppedWetMarketData}
                handleClickLessWet={handleClickLessWet}
                handleClickWet={handleClickWet}
              />


            </Accordion>
          </div>
        </div >
      }
    </>
  );
};

export default PageInfoSidebar;
