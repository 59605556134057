import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "environmentalist",
  initialState: {
    environmentalist: {},
    allEnvironmentalist: [],
    environmentalistDetail: {},
    media: {},
    liveFeeds: {},
    species: {},
    organization: {},
    region: {},
    loading: false,
  },
  reducers: {
    environmentalistRequested: (misc, action) => {
      misc.loading = true;
    },
    environmentalistReceived: (misc, action) => {
      misc.environmentalist = action.payload;
      misc.loading = false;
    },
    environmentalistRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    allEnvironmentalistRequested: (misc, action) => {
      misc.loading = true;
    },
    allEnvironmentalistReceived: (misc, action) => {
      misc.allEnvironmentalist = action.payload?.data;
      misc.loading = false;
    },
    allEnvironmentalistRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    environmentalistDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    environmentalistDetailReceived: (misc, action) => {
      misc.environmentalistDetail = action.payload;
      misc.loading = false;
    },
    environmentalistDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    mediaRequested: (misc, action) => {
      misc.loading = true;
    },
    mediaReceived: (misc, action) => {
      misc.media = action.payload;
      misc.loading = false;
    },
    mediaFailed: (misc, action) => {
      misc.loading = false;
    },
    liveFeedsRequested: (misc, action) => {
      misc.loading = true;
    },
    liveFeedsReceived: (misc, action) => {
      misc.liveFeeds = action.payload;
      misc.loading = false;
    },
    liveFeedsFailed: (misc, action) => {
      misc.loading = false;
    },
    speciesRequested: (misc, action) => {
      misc.loading = true;
    },
    speciesReceived: (misc, action) => {
      misc.species = action.payload;
      misc.loading = false;
    },
    speciesFailed: (misc, action) => {
      misc.loading = false;
    },
    organizationRequested: (misc, action) => {
      misc.loading = true;
    },
    organizationReceived: (misc, action) => {
      misc.organization = action.payload;
      misc.loading = false;
    },
    organizationFailed: (misc, action) => {
      misc.loading = false;
    },
    regionRequested: (misc, action) => {
      misc.loading = true;
    },
    regionReceived: (misc, action) => {
      misc.region = action.payload;
      misc.loading = false;
    },
    regionFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  environmentalistRequested,
  environmentalistReceived,
  environmentalistRequestFailed,
  allEnvironmentalistRequested,
  allEnvironmentalistReceived,
  allEnvironmentalistRequestFailed,
  environmentalistDetailRequested,
  environmentalistDetailReceived,
  environmentalistDetailRequestFailed,
  mediaRequested,
  mediaReceived,
  mediaFailed,
  liveFeedsRequested,
  liveFeedsReceived,
  liveFeedsFailed,
  speciesRequested,
  speciesReceived,
  speciregion,
  speciesFailed,
  organizationRequested,
  organizationReceived,
  organizationFailed,
  regionRequested,
  regionReceived,
  regionFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "environmentalist";

export const getEnvironmentalist = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "get",
      params,
      callback,
      onStart: environmentalistRequested.type,
      onSuccess: environmentalistReceived.type,
      onError: environmentalistRequestFailed.type,
    })
  );
};

export const getAllEnvironmentalist = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown`,
      params,
      method: "get",
      callback,
      onStart: allEnvironmentalistRequested.type,
      onSuccess: allEnvironmentalistReceived.type,
      onError: allEnvironmentalistRequestFailed.type,
    })
  );
};

export const getEnvironmentalistDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "get",
      callback,
      onStart: environmentalistDetailRequested.type,
      onSuccess: environmentalistDetailReceived.type,
      onError: environmentalistDetailRequestFailed.type,
    })
  );
};

export const getEnvMedia = (params, callback) => (dispatch) => {
  const mediaType = params.mediaType || "media";
  const url = `${baseUrl}/media/${mediaType === "document" ? "doc/" : ""}${params.id}?page=${params.page}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: mediaRequested.type,
      onSuccess: mediaReceived.type,
      onError: mediaFailed.type,
    })
  );
};
export const getEnvLiveFeeds = (params, callback) => (dispatch) => {
  const url = `${baseUrl}/liveFeed/${params.id}?page=${params.page}`;
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: liveFeedsRequested.type,
      onSuccess: liveFeedsReceived.type,
      onError: liveFeedsFailed.type,
    })
  );
};
export const getSpeciesEnv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: speciesRequested.type,
      onSuccess: speciesReceived.type,
      onError: speciesFailed.type,
    })
  );
};
export const getOrganizationEnv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/organization/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: organizationRequested.type,
      onSuccess: organizationReceived.type,
      onError: organizationFailed.type,
    })
  );
};
export const getRegionEnv = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region/${params.id ? params.id : params}?page=${params.page ? params.page : 1}`,
      method: "get",
      callback,
      onStart: regionRequested.type,
      onSuccess: regionReceived.type,
      onError: regionFailed.type,
    })
  );
};
export const updateRegionEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};
export const updateOrganizationEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/organization/${params}`,
      method: "put",
      data,
      callback
    })
  );
};
export const updateSpeciesEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};
export const deleteSpeciesEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${params}`,
      method: "delete",
      data,
      callback,
    })
  );
};
export const deleteOrganizationEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/organization/${params}`,
      method: "delete",
      data,
      callback
    })
  );
};
export const deleteRegionEnv = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region/${params}`,
      method: "delete",
      data,
      callback
    })
  );
};
export const environmentalistListings = createSelector(
  (state) => state.entities.environmentalist,
  (environmentalist) => environmentalist
);
