import React, { useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import { getZooDetails, zooListings } from "../../store/zoo";
import { wetMarketData } from "../../store/wetMarket";

const Details = (props) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const details = props.wetMarketData && props.wetMarketData.details.data;

  return (
    <>
      {details ?
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="tbc-body-inner">
                <div class="tbc-details d-flex flex-wrap region-details-main ">
                  <div class="tbc-details-right detail-right-tbc" style={{ fontSize: "14px" }}>
                    {details?.description ? parse(details?.description) : ""}
                  </div>
                  <div class="tbc-details-left r-image" >
                    {!imageError ? <img src={
                      details?.coverImage?.large
                        ? process.env.REACT_APP_MEDIA +
                        details?.coverImage?.large
                        : ""
                    } onError={handleImageError}
                      alt="" /> : <img src={
                        details?.coverImage?.original
                          ? process.env.REACT_APP_MEDIA +
                          details?.coverImage?.original
                          : ""
                      } alt="" />}
                  </div>
                  <div class="tbc-details-bg">
                    {!imageError ? <img src={
                      details?.coverImage?.large
                        ? process.env.REACT_APP_MEDIA +
                        details?.coverImage?.large
                        : ""
                    } onError={handleImageError}
                      alt="" /> : <img src={
                        details?.coverImage?.original
                          ? process.env.REACT_APP_MEDIA +
                          details?.coverImage?.original
                          : ""
                      } alt="" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div>  <Skeleton borderRadius={"10px"} className="centered-skeleton" /></div>}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
  wetMarketData: wetMarketData(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Details));
