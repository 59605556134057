import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker, OverlayView } from "@react-google-maps/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../alerts/alertError";

const containerStyle = {
  width: "100%",
  height: "700px",
  borderRadius: "10px"
};

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

// Adjust the defaultCenter to a central location within the United States
let defaultCenter = { lat: 39.8283, lng: -98.5795 }; // Coordinates for the center of the contiguous United States

const InteractiveUSMap = (props) => {
  const history = useHistory();
  const [stateGeometries, setStateGeometries] = useState([]);
  const usStatesCoordinates = {
    "Alabama": { "lat": 32.806671, "lng": -86.79113, "lab": "AL" },
    "Alaska": { "lat": 65.016036, "lng": -149.737474, "lab": "AK" },
    "Arizona": { "lat": 33.729759, "lng": -111.431221, "lab": "AZ" },
    "Arkansas": { "lat": 34.969704, "lng": -92.373123, "lab": "AR" },
    "California": { "lat": 36.778259, "lng": -119.417931, "lab": "CA" },
    "Colorado": { "lat": 39.550051, "lng": -105.782067, "lab": "CO" },
    "Connecticut": { "lat": 41.603221, "lng": -73.087749, "lab": "CT" },
    "Delaware": { "lat": 38.910832, "lng": -75.52767, "lab": "DE" },
    "Florida": { "lat": 27.994402, "lng": -81.760254, "lab": "FL" },
    "Georgia": { "lat": 33.040619, "lng": -83.643074, "lab": "GA" },
    "Hawaii": { "lat": 21.306944, "lng": -157.858333, "lab": "HI" },
    "Idaho": { "lat": 44.240459, "lng": -114.478828, "lab": "ID" },
    "Illinois": { "lat": 40.349457, "lng": -88.986137, "lab": "IL" },
    "Indiana": { "lat": 39.849426, "lng": -86.258278, "lab": "IN" },
    "Iowa": { "lat": 42.011539, "lng": -93.210526, "lab": "IA" },
    "Kansas": { "lat": 38.5266, "lng": -96.726486, "lab": "KS" },
    "Kentucky": { "lat": 37.66814, "lng": -84.670067, "lab": "KY" },
    "Louisiana": { "lat": 31.169546, "lng": -91.867805, "lab": "LA" },
    "Maine": { "lat": 45.1, "lng": -69.168052, "lab": "ME" },
    "Maryland": { "lat": 39.045753, "lng": -76.641273, "lab": "MD" },
    "Massachusetts": { "lat": 42.407211, "lng": -71.382437, "lab": "MA" },
    "Michigan": { "lat": 44.314844, "lng": -85.602364, "lab": "MI" },
    "Minnesota": { "lat": 46.729553, "lng": -94.6859, "lab": "MN" },
    "Mississippi": { "lat": 32.354668, "lng": -89.398528, "lab": "MS" },
    "Missouri": { "lat": 37.964253, "lng": -91.831833, "lab": "MO" },
    "Montana": { "lat": 46.879682, "lng": -110.362566, "lab": "MT" },
    "Nebraska": { "lat": 41.492537, "lng": -99.901813, "lab": "NE" },
    "Nevada": { "lat": 38.80261, "lng": -116.419389, "lab": "NV" },
    "New Hampshire": { "lat": 43.193852, "lng": -71.572395, "lab": "NH" },
    "New Jersey": { "lat": 40.058324, "lng": -74.405661, "lab": "NJ" },
    "New Mexico": { "lat": 34.97273, "lng": -105.032363, "lab": "NM" },
    "New York": { "lat": 43.299428, "lng": -74.217933, "lab": "NY" },
    "North Carolina": { "lat": 35.759573, "lng": -79.0193, "lab": "NC" },
    "North Dakota": { "lat": 47.551493, "lng": -101.002012, "lab": "ND" },
    "Ohio": { "lat": 40.417287, "lng": -82.907123, "lab": "OH" },
    "Oklahoma": { "lat": 35.007752, "lng": -97.092877, "lab": "OK" },
    "Oregon": { "lat": 43.804133, "lng": -120.554201, "lab": "OR" },
    "Pennsylvania": { "lat": 41.203322, "lng": -77.194525, "lab": "PA" },
    "Rhode Island": { "lat": 41.580095, "lng": -71.477429, "lab": "RI" },
    "South Carolina": { "lat": 33.836081, "lng": -81.163725, "lab": "SC" },
    "South Dakota": { "lat": 43.969515, "lng": -99.901813, "lab": "SD" },
    "Tennessee": { "lat": 35.517491, "lng": -86.580447, "lab": "TN" },
    "Texas": { "lat": 31.968599, "lng": -99.901813, "lab": "TX" },
    "Utah": { "lat": 39.32098, "lng": -111.093731, "lab": "UT" },
    "Vermont": { "lat": 44.558803, "lng": -72.577841, "lab": "VT" },
    "Virginia": { "lat": 37.431573, "lng": -78.656894, "lab": "VA" },
    "Washington": { "lat": 47.751074, "lng": -120.740139, "lab": "WA" },
    "West Virginia": { "lat": 38.597626, "lng": -80.454903, "lab": "WV" },
    "Wisconsin": { "lat": 43.78444, "lng": -88.787868, "lab": "WI" },
    "Wyoming": { "lat": 43.075968, "lng": -107.290284, "lab": "WY" }
  };
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const handleRegionClick = (regionName) => {
    const regionData = props.data.find((entry) => entry.state === regionName);

    if (regionData) {
      history.push(`/regions/united-states/${regionData.slug}`, {
        id: regionData._id,
      });
    } else {
      toast(<AlertError message="Sorry, but data for this region is currently unavailable. We are working diligently to update our information. Please check back soon for the latest updates. Thank you for your understanding" />);
    }
  };

  const mapOptions = {
    disableDefaultUI: false, // Remove all default UI controls
    gestureHandling: "auto", // Disable gesture handling (double-click and scroll zoom)
    styles: [
      {
        featureType: "administrative",
        elementType: "labels",
        stylers: [{ visibility: "on" }],
      },
    ],
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: usStatesCoordinates[props.name]?.lat,
        lng: usStatesCoordinates[props.name]?.lng,
      }}
      zoom={props.name == "Alaska" ? 4 : 6}
      mapTypeId="terrain"
      options={mapOptions}
    >
      {/* Render US regions based on stateGeometries */}
      {/* {Object.entries(usStatesCoordinates).map(([state, coords]) => (
        <OverlayView
          key={state}
          position={{ lat: coords.lat, lng: coords.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div
            style={{
              position: 'absolute',
              padding: '5px',
              borderRadius: '5px',
              cursor: 'pointer',
              transform: 'translate(-50%, -50%)', // Center the marker on the coordinates
            }}
            onClick={() => handleRegionClick(state)}
          >
            {coords.lab}
          </div>
        </OverlayView>
      ))} */}
      {props.name && (
        <Marker
          position={{
            lat: usStatesCoordinates[props.name]?.lat,
            lng: usStatesCoordinates[props.name]?.lng,
          }}
        />
      )}
    </GoogleMap >
  );
};

export default InteractiveUSMap;
