import React, { useEffect, useState } from "react";
import SpeciesAndCategoryEditModal from "../feedEditModals/speciesAndCategoryEditModal";
import RegionEditModal from "../feedEditModals/regionEditModal";
import ScienceAndEducationEditModal from "../feedEditModals/scienceAndEducationEditModal";
import OrganizationEditModal from "../feedEditModals/organizationEditModal";
import WetMarketEditModal from "../feedEditModals/wetMarketEditModal";
const RightFeedSidebar = ({
  showSpeciesAndCategories,
  toggleSpeciesAndCategories,
  toggleRegion,
  toggleWetMarket,
  toggleScience,
  toggleOrgAndZoo,
  showRegion,
  setShowRegion,
  showOrganizationAndZoo,
  setShowOrganizationAndZoo,
  showScienceAndEducation,
  setShowScienceAndEducation,
  showWetMarket,
  setShowWetMarket,
  tempSelectEnvName,
  tempSelectOrgName,
  tempSelectRegionName,
  tempSelectScienceName,
  tempSelectWetMarketName,
  tempSelectZooName,
  setTempSelectEnvName,
  setTempSelectOrgName,
  setTempSelectRegionName,
  setTempSelectScienceName,
  setTempSelectWetMarketName,
  setTempSelectZooName,
  tempSelectSpeciesName,
  setTempSelectSpeciesName,
  setshowSpeciesAndCategories,
  tempSelectSpeciesCategory,
  setTempSelectSpeciesCategory,
  editItem,
  tempSelectSpecies,
  setTempSelectSpecies,
  tempSelectScience,
  setTempSelectScience,
  tempSelectRegion,
  setTempSelectRegion,
  setTempSelectOrg,
  tempSelectOrg,
  tempSelectZoo,
  setTempSelectZoo,
  tempSelectWetMarket,
  setTempSelectWetMarket,
  ...props
}) => {
  const [isOrgMenu, setIsOrgMenu] = useState(true);
  const [isZooMenu, setIsZooMenu] = useState(false);

  // for edit modals

  // temp state for selection

  const togglerZoo = () => {
    setIsZooMenu(!isZooMenu);
    if (isOrgMenu) {
      setIsOrgMenu(!isOrgMenu);
    }
  };

  const togglerOrg = () => {
    setIsOrgMenu(!isOrgMenu);
    if (isZooMenu) {
      setIsZooMenu(!isZooMenu);
    }
  };

  const [isEdit, setIsEdit] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);

  // for preference list data
  const [loadingMain, setLoadingMain] = useState(false);
  const [regionData, setRegionData] = useState(null);
  const [speciesData, setSpeciesData] = useState(null);
  const [speciesCatData, setSpeciesCatData] = useState(null);
  const [envData, setEnvData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [wetMarketData, setWetMarketData] = useState(null);
  const [zooData, setZooData] = useState(null);
  const [scienceAndEducationData, setScienceAndEducationData] = useState(null);

  return (
    <>
      <SpeciesAndCategoryEditModal
        show={showSpeciesAndCategories}
        onHide={toggleSpeciesAndCategories}
        loading={loadingMain}
        setLoading={setLoadingMain}
        speciesData={speciesData}
        isEdit={true}
        setIsEdit={setIsEdit}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        setSpeciesData={setSpeciesData}
        setSpeciesCatData={setSpeciesCatData}
        speciesCatData={speciesCatData}
        tempSelectSpeciesCategory={tempSelectSpeciesCategory}
        setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
        tempSelectSpecies={tempSelectSpecies}
        setTempSelectSpecies={setTempSelectSpecies}
        tempSelectSpeciesName={tempSelectSpeciesName}
        setTempSelectSpeciesName={setTempSelectSpeciesName}
        isPost={true}
        postType={props?.postType}
      />

      <RegionEditModal
        show={showRegion}
        onHide={toggleRegion}
        loading={loadingMain}
        regionData={regionData}
        setLoading={setLoadingMain}
        isEdit={true}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        setIsEdit={setIsEdit}
        setRegionData={setRegionData}
        tempSelectRegion={tempSelectRegion}
        setTempSelectRegion={setTempSelectRegion}
        tempSelectRegionName={tempSelectRegionName}
        setTempSelectRegionName={setTempSelectRegionName}
        isPost={true}
      />

      <ScienceAndEducationEditModal
        show={showScienceAndEducation}
        onHide={toggleScience}
        loading={loadingMain}
        scienceAndEducationData={scienceAndEducationData}
        setLoading={setLoadingMain}
        isEdit={true}
        setIsEdit={setIsEdit}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        setScienceAndEducationData={setScienceAndEducationData}
        tempSelectScience={tempSelectScience}
        tempSelectScienceName={tempSelectScienceName}
        setTempSelectScience={setTempSelectScience}
        setTempSelectScienceName={setTempSelectScienceName}
        isPost={true}
      />
      <OrganizationEditModal
        show={showOrganizationAndZoo}
        onHide={toggleOrgAndZoo}
        loading={loadingMain}
        setLoading={setLoadingMain}
        orgData={orgData}
        zooData={zooData}
        isEdit={true}
        setIsEdit={setIsEdit}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        isOrgMenu={isOrgMenu}
        setIsOrgMenu={setIsOrgMenu}
        isZooMenu={isZooMenu}
        setIsZooMenu={setIsZooMenu}
        togglerOrg={togglerOrg}
        togglerZoo={togglerZoo}
        setOrgData={setOrgData}
        setZooData={setZooData}
        tempSelectOrg={tempSelectOrg}
        tempSelectOrgName={tempSelectOrgName}
        setTempSelectOrg={setTempSelectOrg}
        setTempSelectOrgName={setTempSelectOrgName}
        tempSelectZoo={tempSelectZoo}
        tempSelectZooName={tempSelectZooName}
        setTempSelectZoo={setTempSelectZoo}
        setTempSelectZooName={setTempSelectZooName}
        isPost={true}
      />

      <WetMarketEditModal
        show={showWetMarket}
        onHide={toggleWetMarket}
        loading={loadingMain}
        setLoading={setLoadingMain}
        isEdit={true}
        setIsEdit={setIsEdit}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
        wetMarketData={wetMarketData}
        setWetMarketData={setWetMarketData}
        tempSelectWetMarket={tempSelectWetMarket}
        tempSelectWetMarketName={tempSelectWetMarketName}
        setTempSelectWetMarket={setTempSelectWetMarket}
        setTempSelectWetMarketName={setTempSelectWetMarketName}
        isPost={true}
      />
    </>
  );
};

export default RightFeedSidebar;
