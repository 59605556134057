import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import close from "../../../include/images/close.svg";

//Modal to show the regions in Navbar
const ShowRegModal = (props) => {
    const { regions } = props;

    const [keyword, setKeyword] = useState("");
    const [expandedContinents, setExpandedContinents] = useState({});
    if (!Array.isArray(regions)) {
        return null;
    }

    const regionsByContinent = regions.reduce((acc, region) => {
        const continentName = region.continent?.name || "Others";
        if (!acc[continentName]) {
            acc[continentName] = [];
        }
        acc[continentName].push(region);
        return acc;
    }, {});

    const filteredRegionsByContinent = Object.keys(regionsByContinent).reduce(
        (acc, continentName) => {
            const filteredRegions = regionsByContinent[continentName].filter(
                (region) =>
                    region.name.toLowerCase().includes(keyword.toLowerCase())
            );
            if (filteredRegions.length > 0) {
                acc[continentName] = filteredRegions;
            }
            return acc;
        },
        {}
    );

    const renderContinentRegions = () => {
        let continentNames = Object.keys(filteredRegionsByContinent);
        continentNames.sort((a, b) => a.localeCompare(b));

        if (continentNames.includes("United States")) {
            continentNames = ["United States", ...continentNames.filter(name => name !== "United States")];
        }

        return (
            <div className="row">
                {continentNames.map((continentName) => (
                    <div key={continentName} className="col-md-4">
                        <div className="cnl-box">
                            <h5>{continentName}</h5>
                            <ul>
                                {filteredRegionsByContinent[continentName].slice(0, expandedContinents[continentName] ? undefined : 6).map((region) => (
                                    <li key={region._id}>
                                        <Link
                                            onClick={() => props.onHide()}
                                            to={{
                                                pathname: `/regions/${region.country === 'US' ? 'united-states/' : ''}${region.slug}`,
                                                state: { id: region?._id },
                                            }}
                                        >
                                            {region.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            {filteredRegionsByContinent[continentName].length > 6 && (
                                <button
                                    className="btn btn-link pt-0 text-start"
                                    onClick={() =>
                                        setExpandedContinents({
                                            ...expandedContinents,
                                            [continentName]: !expandedContinents[continentName],
                                        })
                                    }
                                >
                                    {expandedContinents[continentName] ? "Show Less" : "View All"}
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    return (
        <Modal
            className="modal fade environmental-inclusion-modal custom-floating-input"
            id="addOrganizationModal"
            dialogClassName="modal-dialog modal-dialog-centered modal-size-reg"
            contentClassName="endangered-viewall"
            show={props.show}
            onHide={() => props.onHide()}
            enforceFocus={false}
        >
            <div className="modal-content d-flex flex-column">
                <div className="modal-header pb-0 modal-head view-all-header" style={{ justifyContent: "normal" }}>
                    <h5 className="modal-title text-capitalize">Regions</h5>
                    <div className="search-region-modal">
                        <input
                            type="text"
                            className="form-control region-search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <img
                            src={close}
                            className="search-region-modal-close"
                            alt=""
                            onClick={(e) => setKeyword("")}
                        />
                    </div>
                    <button
                        type="button"
                        className="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
                        onClick={() => props.onHide()}
                    >
                        <img src={close} alt="" className="modal-close-btn" />
                    </button>
                </div>
                <Modal.Body
                    className="custom-modal-body w-100 d-flex flex-column align-items-center justify-content-center"
                    style={{ maxHeight: "80vh", overflowY: "auto" }}
                >
                    <div className="cdm-upper" style={{ width: "100%", height: "400px", overflowY: "scroll" }}>
                        <div className="container-fluid">
                            <div className="row">
                                {renderContinentRegions()}
                            </div>
                        </div>
                    </div>
                    <div className="row position-relative w-100 view-all-region" >
                        <Link
                            to="/regions"
                            type="submit"
                            className="btn btn-default position-absolute"
                        >
                            View All
                        </Link>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default React.memo(ShowRegModal);
