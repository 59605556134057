import React, { useState } from "react";
import { connect } from "react-redux";
import { getSpeciesDetails, speciesListings } from "../../store/species";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import { getRegionList, sideBarApisListings } from "../../store/sidebarApis";
import Editor from "../../common/editor/editor";

const Details = (props) => {
  const { editMode, editState, handleChange, handleImageChange, detailImgSrc } = props;
  const [showFullDescription, setShowFullDescription] = useState(false);

  const details = props.speciesListings && props.speciesListings.speciesDetail;

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
    }
    return description;
  };

  return (
    <>
      {props.loading ? (
        <div className="tbc-details tbc-details-skeleton d-flex flex-wrap w-100 mt-5">
          <Skeleton borderRadius={"10px"} width="100%" height="500px" />
        </div>
      ) : (
        <div className="tbc-details detail-tab-text d-flex flex-wrap" style={{ marginTop: "40px" }}>
          {editMode ? (
            <Editor
              id="textarea"
              name="description"
              className="w-100"
              onChange={(data) => {
                handleChange(data);
              }}
              editorLoaded={true}
              value={editState.description}
            />
          ) : (
            <>
              {details?.data?.description ? (
                <>
                  {showFullDescription
                    ? parse(details?.data?.description)
                    : parse(dotDesc(stripHtmlTags(details?.data?.description), 1500))}
                  {details?.data?.description?.length > 1500 && (
                    <span className="read-more-detail" onClick={() => setShowFullDescription(!showFullDescription)}>
                      {showFullDescription ? " Show less" : " Read more"}
                    </span>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Details));
