export const setToken = (refresh, auth) => {
  if (refresh) localStorage.setItem("x-refresh-token", refresh);
  localStorage.setItem("x-auth-token", auth);
};

export const getToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token");
  if (type === "auth") return localStorage.getItem("x-auth-token");
  return false;
};

export const clearAllToken = () => {
  localStorage.removeItem("x-refresh-token");
  localStorage.removeItem("x-auth-token");
  localStorage.removeItem("data");
};

export const setProfile = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};
export const getProfile = () => {
  if (localStorage.getItem("data")) {
    return JSON.parse(localStorage.getItem("data"));
  }
  //  else {
  //   clearAllToken();
  //   setRedirectUrl(window.location.href);
  //   window.location.href = "/login";
  // }
};

export const setRedirectUrl = (data) => {
  localStorage.setItem("redirectUrl", data);
};

export const getRedirectUrl = () => {
  return localStorage.getItem("redirectUrl");
};

export const checkToken = () => {
  const token = localStorage.getItem("x-auth-token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

// Function to clear user session and perform logout
export const logout = () => {
  // Clear user data from local storage
  localStorage.removeItem("data");
  localStorage.removeItem("x-auth-token");
  localStorage.removeItem("x-refresh-token");
  sessionStorage.removeItem("viewedPosts");
  // Redirect to the logout URL
  const logoutUrl = getLogoutUrl();
  // window.location.href = logoutUrl;
  return logoutUrl;
};

export const getLogoutUrl = () => {
  // logout URL
  return "/login";
};

export const removeKey = (keyName) => {
  localStorage.removeItem(keyName);
};
// Function to add a post ID to the array of viewed posts during the session
export const addPostToViewedDuringSession = (postId) => {
  const viewedPosts = getPostIdsViewedDuringSession();
  if (!viewedPosts.includes(postId)) {
    viewedPosts.push(postId);
    sessionStorage.setItem("viewedPosts", JSON.stringify(viewedPosts));
  }
};

// Function to retrieve the array of post IDs viewed during the session
export const getPostIdsViewedDuringSession = () => {
  const viewedPosts = sessionStorage.getItem("viewedPosts");
  return viewedPosts ? JSON.parse(viewedPosts) : [];
};

// Function to check if a post has been viewed during the session
export const hasPostBeenViewedDuringSession = (postId) => {
  const viewedPosts = getPostIdsViewedDuringSession();
  return viewedPosts.includes(postId);
};

// Function to clear the array of viewed post IDs at the end of the session
export const clearViewedPosts = () => {
  sessionStorage.removeItem("viewedPosts");
};

//To store the preferences of the Volunteer
export const setVolunteerPreference = (data) => {
  sessionStorage.setItem("preferences", JSON.stringify(data));
};

export const getVolunteerPreference = () => {
  const preferences = sessionStorage.getItem("preferences");
  return preferences ? JSON.parse(preferences) : null;
};
export const removeKeySession = (keyName) => {
  sessionStorage.removeItem(keyName);
};


