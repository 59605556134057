import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeContentInBrackets } from '../../../utils/helperFunctions';

//Home Page Sidebar dropdown for WetMarket
const WetmarketDropdown = (props) => {
    const renderWetmarketList = (wetmarketList) => {
        return (
            <ul className="ei-category-list">
                {wetmarketList.map((item, i) => (
                    <li className="ei-category-item" key={i}>
                        <Link
                            to={{
                                pathname: `/war-on-the-environment-threats/${item?.slug}`,
                                state: { id: item?._id },
                            }}
                            className="ei-category-box text-capitalize"
                        >
                            {`${capitalizeContentInBrackets(item.name)} (${item.speciesCount})`}
                        </Link>
                    </li>
                ))}
                {props.handleViewAllWetMarket ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewLess"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickLessWet}
                        >
                            View Less
                        </div>
                    </li>
                ) : props.wetmarketList.length > 15 ? (
                    <li className="ei-category-item">
                        <div
                            style={{ color: "#47AD1D" }}
                            id="viewAll"
                            className="ei-category-box view-all cursor-pointer"
                            onClick={props.handleClickWet}
                        >
                            View All
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <>
            {props.showMenu ? (
                <>
                    {props.isWetMenu && (
                        <>
                            {props.wetmarketList && props.wetmarketList.length > 0 && (
                                <Accordion.Item
                                    className="tab-pane fade show active accordion-item"
                                    id="two-tab-pane"
                                    eventKey="7"
                                >
                                    <Accordion.Header
                                        className="accordion-header d-lg-block d-none"
                                        id="headingTwo"
                                    >
                                        War on the Environment - Threats
                                    </Accordion.Header>

                                    <Accordion.Body className="accordion-body">
                                        {renderWetmarketList(
                                            props.handleViewAllWetMarket
                                                ? props.dividedWetMarketData.flat()
                                                : props.handleViewLessWetMarket
                                                    ? props.poppedWetMarketData
                                                    : props.wetmarketList.slice(0, 15)
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {props.wetmarketList && props.wetmarketList.length > 0 && (
                        <Accordion.Item
                            className="tab-pane fade accordion-item"
                            id="two-tab-pane"
                            eventKey="7"
                        >
                            <Accordion.Header
                                className="accordion-header d-lg-block d-none"
                                id="headingTwo"
                            >
                                War on the Environment - Threats
                            </Accordion.Header>

                            <Accordion.Body className="accordion-body">
                                {renderWetmarketList(
                                    props.handleViewAllWetMarket
                                        ? props.dividedWetMarketData.flat()
                                        : props.handleViewLessWetMarket
                                            ? props.poppedWetMarketData
                                            : props.wetmarketList.slice(0, 15)
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </>
            )}
        </>
    );
};

export default WetmarketDropdown;
