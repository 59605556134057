import React, { useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../common/loader/loader";

const PageLayout = (props) => {
  const [isTextSearched, setIsTextSearched] = useState(false)
  return (
    <>
      <Header textSearched={isTextSearched} setIsTextSearched={setIsTextSearched} />
      {props.loading ? (
        <div className="loader-container">
          {" "}
          <Loader />
        </div>
      ) : (
        isTextSearched ? "" : props.children
      )}
      <Footer />
    </>
  );
};

export default PageLayout;
