import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  getTeamDetails,
  organizationListing,
} from "../../store/organization";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../common/Pagination/pagination";
import { capitalizeContentInBrackets } from "../../utils/helperFunctions"
const Teams = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const details =
    props.organizationListing && props.organizationListing.teamDetail;
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true)
      props.getTeamDetails(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false)
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: 'smooth',
      });
    }
  };
  const pageLinks = Array.from({ length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) }, (_, i) => i + 1);

  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <>
                {loading ? (
                  <ul class="tbc-our-team-list d-flex flex-wrap">
                    {Array.from({ length: 42 }, (item, index) => (
                      <li class="tbc-our-team-item">
                        <div class="tbc-our-team-box">
                          <Skeleton borderRadius={"10px"} height={"200px"} />
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <ul class="tbc-our-team-list d-flex flex-wrap">
                      {details && details.data && details.data.length
                        ? details?.data.map((item) => {
                          return (
                            <li class="tbc-our-team-item">
                              <div class="tbc-our-team-box">
                                <div class="tbc-our-team-image">
                                  <img
                                    src={
                                      process.env.REACT_APP_MEDIA +
                                      item?.profileImage?.original
                                    }
                                    alt=""
                                  />
                                </div>
                                <div class="tbc-our-team-text">
                                  <p className="text-capitalize text-transfrom ">
                                    {`${capitalizeContentInBrackets(item?.name)}`}
                                  </p>
                                  <small className="text-capitalize text-transfrom ">
                                    {item?.designation}
                                  </small>
                                </div>
                              </div>
                            </li>
                          );
                        })
                        : ""}
                    </ul>
                  </>
                )}
                {details && details?.totalCount > 48 && (<Pagination
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                  pageLinks={pageLinks}
                  totalPages={pageLinks.length}
                />)}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrganizationDetails: (params, callback) =>
    dispatch(getOrganizationDetails(params, callback)),
  getTeamDetails: (params, callback) =>
    dispatch(getTeamDetails(params, callback)),
});

const mapStateToProps = (state) => ({
  organizationListing: organizationListing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Teams));
