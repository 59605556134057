import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import HTMLReactParser from "html-react-parser";
import { removeTags, capitalizeContentInBrackets } from "../../../utils/helperFunctions";

const LiveFeedsListCard = (props) => {
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  const donationUrl = props?.donationUrl?.split("//").pop();
  const handleLink = () => {
    if (!props?.description) {
      toast(
        <AlertError message="Sorry, but data for this organization is currently unavailable.We are working diligently to update our information.Please check back soon for the latest updates.Thank you for your understanding." />,
      );
    }
  };
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };

  return (
    <li class="organisation-item liveFeeds-item">
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton borderRadius={"10px"} height={"230px"} />
        </div>
      ) : (
        <div className="organisation-box d-flex flex-wrap" style={{ height: "230px" }}>
          <div>
            <div class="organisation-logo ">
              <a href={props.slug} target="_blank" rel="noopener noreferrer">
                {!imageError ? (
                  <img
                    className="org-img sm-img-fix"
                    src={process.env.REACT_APP_MEDIA + props.img}
                    onError={handleImageError}
                    alt=""
                  />
                ) : (
                  <img className="org-img sm-img-fix" src={process.env.REACT_APP_MEDIA + props.img1} alt="" />
                )}
              </a>
            </div>
            {props.previewImg && (
              <div class="organisation-logo mt-4">
                <a href={props.slug} target="_blank" rel="noopener noreferrer">
                  <img className="org-img sm-img-fix" src={process.env.REACT_APP_MEDIA + props.previewImg} alt="" />
                </a>
              </div>
            )}
          </div>

          <div class="organisation-text live-feeds-text">
            <h6>
              {" "}
              <a href={props.slug} target="_blank" rel="noopener noreferrer" className="text-capitalize">
                {dotDesc(capitalizeContentInBrackets(props?.name), 60)}
              </a>
            </h6>
            <div class="organisation-text-footer live-feed-footer">
              <small>Location :</small>
              <p className="caps-text">
                {dotDesc(
                  HTMLReactParser(removeTags(props.address.charAt(0).toUpperCase() + props.address.slice(1))),
                  60,
                )}
              </p>
              <small>Source :</small>
              <p className="caps-text">
                {dotDesc(HTMLReactParser(removeTags(props.source.charAt(0).toUpperCase() + props.source.slice(1))), 80)}
              </p>
              <small>Info :</small>
              <p title={HTMLReactParser(removeTags(props.info.charAt(0).toUpperCase() + props.info.slice(1)))}>
                {dotDesc(HTMLReactParser(removeTags(props.info.charAt(0).toUpperCase() + props.info.slice(1))), 120)}
              </p>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default LiveFeedsListCard;
